import React from 'react'
import { environment } from '../../environment'
import InfoboxItems from './InfoboxItem'
import '../../styles/infobox.css'

function Infobox(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('infobox')
        console.log(params)
    }

    const noBgImage = params?.block?.[params.locale]?.NoBgImage ? params.block[params.locale].NoBgImage : false

    if (noBgImage) {
        return (
            <section className={'infobox-noBgImage'}>
                <div className="wrapper">
                    <div className={'container mx-auto px-8 my-20'}>
                        {params.block[params.locale]['Title'] && (
                            <h2 className={'c-' + params.block[params.locale]['TitleColor']}>
                                {params.block[params.locale]['Title']}
                            </h2>
                        )}
                        {params.block[params.locale]['Subtitle'] && (
                            <h3 className="opacity-50">{params.block[params.locale]['Subtitle']}</h3>
                        )}
                    </div>
                    <div className="bg-[#f6f6f5] py-12">
                        <div className={'container mx-auto px-8'}>
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
                                {params.block[params.locale].hasOwnProperty('Item') &&
                                    Object.keys(params.block[params.locale]['Item']).map((item, i) => (
                                        <InfoboxItems
                                            key={'InfoboxItem-' + i}
                                            block={params.block[params.locale]['Item'][item]}
                                            line={params.line}
                                            noBgImage={noBgImage}
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <section className={'infobox'}>
            <div className="wrapper">
                <div className={'container mx-auto px-8 my-20'}>
                    {params.block[params.locale]['Title'] && (
                        <h2 className={'c-' + params.block[params.locale]['TitleColor']}>
                            {params.block[params.locale]['Title']}
                        </h2>
                    )}
                    {params.block[params.locale]['Subtitle'] && (
                        <h3 className="opacity-50">{params.block[params.locale]['Subtitle']}</h3>
                    )}
                    <div className={'infobox-boxes grid d-flex ai-center'}>
                        {params.block[params.locale].hasOwnProperty('Item') &&
                            Object.keys(params.block[params.locale]['Item']).map((item, i) => (
                                <InfoboxItems
                                    key={'InfoboxItem-' + i}
                                    block={params.block[params.locale]['Item'][item]}
                                    line={params.line}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Infobox
