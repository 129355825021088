import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Alert, Box, Container, Typography } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'

import useFormattedPrice from '../../hooks/useFormattedPrice'
import { environment } from '../../../environment'
import Spinner from '../../components/Spinner'
import useFetchLac from '../../hooks/useFetchLac'
import { API } from '../api'
import ErrorBox from '../../../components/ErrorBox'
import ChooseButton from '../../components/ChooseButton'

import theme from '../../../styles/MUI-theme'
import FormPaymentTransfer from './FormPaymentTransfer'
import DialogAuthentication from './DialogAuthentication'
import CheckPaymentStatusLAC from './CheckPaymentStatusLAC'

const Step5Lac = () => {
    const { t, i18n } = useTranslation()
    const { data, loading, error, fetchData } = useFetchLac()
    const { id, locale } = useParams()

    // const [isSuccess, setIsSuccess] = useState([])
    const [isHiddenButton, setIsHiddenButton] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [afterPayment, setAfterPayment] = useState(false)
    const [retryToPay, setRetryToPay] = useState(false)

    const [showAuthentication, setShowAuthentication] = useState(false)

    const price =
        useFormattedPrice(
            parseFloat(data?.variables?.quote_calculation_requiredPaymentAllCars_gross).toFixed(2)
        ).replace('.', ',') || ''

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        if (id) {
            const url = `${API[`GET_PROCESS_DATA`]}?pid=${id}`
            fetchData(url, 'GET')
        }
    }, [id, showAuthentication])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('Process data LAC step 5:', data)

        data && data.variables?.bank_token && setAfterPayment(true)

        data && data.show_auth_form ? setShowAuthentication(true) : setShowAuthentication(false)
    }, [data])

    const handleHideButton = () => {
        setIsHiddenButton(false)
    }

    const handleShowButton = () => {
        setIsHiddenButton(true)
    }

    const handleClick = () => {
        setIsHiddenButton(true)
        setIsLoading(true)

        fetch(`${API['NAVIGATION_NEXT']}&pid=${id}&navigation=bank_payment`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(t('errors.try_again'))
                }
                return response.json()
            })
            .then(data => {
                if (data.variables?.bank_token) {
                    const token = data.variables.bank_token
                    const redirectUrl = `${process.env.REACT_APP_P24_URL}${token}`
                    // const redirectUrl = `https://sandbox.przelewy24.pl/trnRequest/${token}`
                    // const redirectUrl = `https://secure.przelewy24.pl/trnRequest/${token}`
                    window.location.href = redirectUrl
                }
            })
            .catch(error => {
                console.error('Error:', error)
            })
    }

    return ( 
    <>
    {error && (
        <Container sx={{ mt: 2 }}>
            <ErrorBox error={error} />
        </Container>
    )}
    {data ? (
        <>
            {afterPayment && data?.form_key === 'reservation_payment' && <Spinner />}
            {showAuthentication && !retryToPay && (
                <DialogAuthentication
                    showAuthentication={showAuthentication}
                    setShowAuthentication={setShowAuthentication}
                />
            )}
            {!showAuthentication &&
                (!afterPayment ? (
                    <div className="container mx-auto px-8 my-6">
                        <Box textAlign="center" mb={2}>
                            <CheckCircle color="secondary" sx={{ fontSize: 120 }} />
                        </Box>
                        <Box maxWidth="500px" my={4} mx="auto">
                            <Box mb={4}>
                                {data ? (
                                    <>
                                        <Typography
                                            variant="h1"
                                            fontSize={24}
                                            fontWeight="bold"
                                            textAlign="center"
                                            mb={3}
                                        >
                                            {t('LAC.reservation_payment.thank_you')}
                                        </Typography>
                                        <Typography variant="h2" fontSize={18} fontWeight="normal" textAlign="left">
                                            {t('LAC.reservation_payment.info_text')}
                                        </Typography>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Box>
                            <Typography variant="h3" fontSize={20} textAlign="left" fontWeight="bold" mb={2}>
                                {t('LAC.reservation_payment.pay_deposit')}
                            </Typography>
                            <Box
                                mb={2}
                                sx={{
                                    p: 2,
                                    backgroundColor: theme.palette.primary.main,
                                    borderRadius: '4px',
                                    color: '#FAFAFA',
                                }}
                            >
                                <Typography color="#FAFAFA">{t('LAC.reservation_payment.deposit_amount')}</Typography>
                                <Typography fontWeight="bold" fontSize="40px" color="#FAFAFA">
                                    {price}
                                </Typography>
                            </Box>

                            {data?.variables?.client_type === 'business' &&
                                !data?.variables?.payment_transfer_email && (
                                    <FormPaymentTransfer
                                        handleHideButton={handleHideButton}
                                        handleShowButton={handleShowButton}
                                    />
                                )}

                            {!isHiddenButton && (
                                <>
                                    <Alert severity="info" sx={{ mb: 2 }}>
                                        {t('LAC.reservation_payment.obligatory_payment')}
                                    </Alert>
                                    <ChooseButton id="L5" handleClick={handleClick}>
                                        {t('LAC.reservation_payment.submit_btn')}
                                    </ChooseButton>
                                </>
                            )}
                            {isLoading && <Spinner />}
                        </Box>
                    </div>
                ) : (
                    <CheckPaymentStatusLAC setAfterPayment={setAfterPayment} setRetryToPay={setRetryToPay} />
                ))}
        </>
    ) : (
        <Box textAlign="center">{loading && <Spinner />}</Box>
    )}
    </>
    )
}

export default Step5Lac
