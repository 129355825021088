import React from 'react'
import { environment } from './../../environment'
import NewsList from '../../portlets/news/NewsList'
const ListComponents = {
    'news-details-list': NewsList,
}
function ListBox(params) {
    if (window.origin.includes(environment.localhost)) {
        console.log('ListBox')
        console.log(params)
    }
    let min = ' min-' + (params.block.hasOwnProperty('Minified') ? params.block['Minified'] : false) + 'm'
    let innerSection = 'list-box' + min
    let innerContainer = 'container'
    const type = params.block.TypesToCollect[0].split(':')[0]
    const typeList = type + '-list'
    let idList = { id: typeList }
    return (
        <section className={innerSection} {...idList}>
            <div className="wrapper">
                <div className={innerContainer}>
                    {ListComponents.hasOwnProperty(type + '-list') &&
                        React.createElement(ListComponents[type + '-list'], {
                            key: params.block.TypesToCollect[0].split(':')[1],
                            block: params.block,
                            line: params.line,
                        })}
                </div>
            </div>
        </section>
    )
}

export default ListBox
