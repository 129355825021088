import { InputAdornment, TextField, Tooltip } from '@mui/material'
import { Field } from 'formik'
import React, { useEffect, useState } from 'react'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import { useTranslation } from 'react-i18next'

export default function FormBusiness({ userData, handleUserDataChange, touched, errors }) {
    const { t, i18n } = useTranslation()

    return (
        <div className="mt-8 ">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <Field
                    as={TextField}
                    value={userData.companyName}
                    name="companyName"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.companyName && Boolean(errors.companyName)}
                    helperText={touched.companyName && errors.companyName}
                    fullWidth
                    placeholder={t('LAC.client_data.company_name')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonOutlineOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Field
                    as={TextField}
                    value={userData.userNip}
                    name="userNip"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.userNip && Boolean(errors.userNip)}
                    helperText={touched.userNip && errors.userNip}
                    fullWidth
                    placeholder={t('LAC.client_data.nip')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <RemoveCircleOutlineOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Field
                    as={TextField}
                    value={userData.userEmail}
                    name="userEmail"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.userEmail && Boolean(errors.userEmail)}
                    helperText={touched.userEmail && errors.userEmail}
                    fullWidth
                    placeholder={t('LAC.client_data.email')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlineIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Tooltip title={t('LAC.client_data.validation.prefix')}>
                    <Field
                        as={TextField}
                        value={userData.userPhoneNumber}
                        name="userPhoneNumber"
                        onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                        error={touched.userPhoneNumber && Boolean(errors.userPhoneNumber)}
                        helperText={touched.userPhoneNumber && errors.userPhoneNumber}
                        fullWidth
                        placeholder={t('LAC.client_data.phone')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PhoneInTalkOutlinedIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Tooltip>
            </div>
        </div>
    )
}
