import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'

const BackButtonInForm = ({ handleClick, sx }) => {
    const { t } = useTranslation()

    return (
        <Box textAlign="left" mt={0} mb={1}>
            <Button onClick={handleClick} sx={{ pl: 0, fontSize: { xs: 12, lg: 16 }, fontWeight: 'bold', ...sx }}>
                <ArrowBackIosNewRoundedIcon color="secondary" sx={{ marginRight: 1 }} /> {t('btn.back')}
            </Button>
        </Box>
    )
}
export default BackButtonInForm
