import React from 'react'
import { useState, createContext } from 'react'
import { MultistepForm, Step } from './MultistepForm'

import FormStep1 from './FormStep1'
import { FormProvider } from './FormContext'
import FormStep1CMS from './FormStep1CMS'

export const LocaleContext = createContext({
    locale: '',
    RAC: true,
})

const Form = ({ params, ...props }) => {
    const [locale] = useState(props.locale)
    const contextValue = {
        locale,
    }
    return (
        <LocaleContext.Provider value={contextValue}>
            <FormProvider params={params}>
                <MultistepForm>
                    <Step step={1}>
                        {params.line === 'online' ? <FormStep1 params={params} line={props.line} /> : <FormStep1CMS params={params} line={props.line} />}
                    </Step>
                </MultistepForm>
            </FormProvider>
        </LocaleContext.Provider>
    )
}

export default Form
