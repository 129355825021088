import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Container } from '@mui/material'

import { API, FORM_KEY } from '../api'
import { environment } from '../../environment'
import useFetch from '../hooks/useFetch'
import Spinner from '../components/Spinner'
import Stepper from '../components/Stepper'
import BackButtonInForm from '../components/BackButtonInForm'

import ReservationDetials from './ReservationDetails'
import Form5 from './Step5/Form5'
import ErrorBox from './ErrorBox'

import '../../styles/form.css'
import Notify from './Notify'
import CheckPaymentStatusRAC from './CheckPaymentStatusRAC'

const FormStep5 = () => {
    const { t, i18n } = useTranslation()
    const { data, loading, error, fetchData } = useFetch()
    const { id, locale } = useParams()

    const [afterPayment, setAfterPayment] = useState(false)
    const [retryToPay, setRetryToPay] = useState(false)
    const [promoCodeIsAdded, setPromoCodeIsAdded] = useState(false)
    const [promoCode, setPromoCode] = useState('')
    const [krdVeryficationIsSuccess, setKrdVeryficationIsSuccess] = useState(true)

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        const url = `${API[`GET_DATA`]}?pid=${id}`
        fetchData(url, 'GET')
    }, [id, promoCodeIsAdded])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('PROCESS DATA:', data)

        data && data.variables?.bank_token && setAfterPayment(true)

        data && data.variables?.full_calculation_promotional_code_used === 'yes' && setPromoCodeIsAdded(true)

        data && data.variables?.promo_code && setPromoCode(data.variables?.promo_code)

        data && data?.variables?.krd_verification_success === 'no' && setKrdVeryficationIsSuccess(false)
    }, [data])

    const handleClickEditStep4 = () => {
        fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=${FORM_KEY['step4']}`, 'GET')
    }

    return (
        <>
            {error && (
                <Container sx={{ mt: 2 }}>
                    <ErrorBox error={error} />
                </Container>
            )}
            {data ? (
                <>
                    {afterPayment && data?.form_key === 'reservation_payment' && <Spinner />}
                    {krdVeryficationIsSuccess ? (
                        !afterPayment ? (
                            <Container>
                                <Box py={retryToPay ? 3 : 1}>
                                    {!retryToPay && (
                                        <BackButtonInForm handleClick={handleClickEditStep4}>
                                            {t('btn.back')}
                                        </BackButtonInForm>
                                    )}
                                    <Stepper activeStep={5} />
                                    {!retryToPay && <ReservationDetials activeStep={5} />}

                                    <Box py={4}>
                                        <Form5
                                            processData={data}
                                            promoCodeIsAdded={promoCodeIsAdded}
                                            setPromoCodeIsAdded={setPromoCodeIsAdded}
                                            promoCode={promoCode}
                                            setPromoCode={setPromoCode}
                                        />
                                        {/* <BackButtonInForm handleClick={handleClickEditStep4}>{t('btn.back')}</BackButtonInForm> */}
                                    </Box>
                                </Box>
                            </Container>
                        ) : (
                            <>
                                <CheckPaymentStatusRAC
                                    setAfterPayment={setAfterPayment}
                                    setRetryToPay={setRetryToPay}
                                />
                            </>
                        )
                    ) : (
                        <Notify formKey="krd_negative_verification" />
                    )}
                </>
            ) : (
                <Box textAlign="center">{loading && <Spinner />}</Box>
            )}
        </>
    )
}

export default FormStep5
