import React from 'react'
import Links from '../../components/Links.js'
import { environment } from '../../environment.js'

function FooterLinksItem(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('FooterLinks Item')
        console.log(params)
    }
    return (
        <div className='links-wrapper'>
            {params.block &&
                Object.keys(params.block).map((item, i) => (
                    <div 
                        className='link-box'
                        key={'FooterLinks-' + item}
                    >   
                        {(params.block[item]['Link'] && params.block[item]['LinkName']) && <Links
                            class={'link c-color1'}
                            url={params.block[item]['Link']}
                            target={params.block[item]['InNewOpen']}
                            name={params.block[item]['LinkName']}
                            line={params.line}
                        />}
                    </div>
                ))
            }
        </div>
    )
}

export default FooterLinksItem
