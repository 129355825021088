import React, { useEffect } from 'react'
import { environment } from '../../environment'
import { useTranslation } from 'react-i18next'

function Logged(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Logged')
        console.log(params)
    }
    const { t, i18n } = useTranslation()
    let lang = window.location.pathname.split('/')[1].length > 2 || window.location.pathname.split('/')[1].length === 0 ? 'pl' : window.location.pathname.split('/')[1]
    let langMeta = document.documentElement.lang;
    lang = lang === langMeta ? lang : lang;
    useEffect(() => {
        i18n.changeLanguage(lang)
    }, [lang])

    return (
        <>
            <a 
                href={'#'} 
                title={t('header.nav.hello') +params.name} 
                className={'btn-link empty '+(params.type === 'desktop' ? '' : 'text-white')}
                onClick={e => {
                    e.preventDefault()
                }}
            >
                {t('header.nav.hello')}{params.name}
            </a>
            <a
                href={environment.logoutUrl}
                title={t('header.nav.logout')}
                className={'btn-link '+(params.type === 'desktop' ? 'empty' : 'primary')}
            >
                {t('header.nav.logout')}
            </a>
        </>
    )
}
export default Logged

