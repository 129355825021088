import { useEffect, useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import Inline from 'yet-another-react-lightbox/plugins/inline'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import './photoGallery.css'
import useHostname from '../hooks/useHostname'

export default function PhotoGallery({ photos }) {
    const origin = useHostname()
    const [open, setOpen] = useState(false)
    const [index, setIndex] = useState(0)
    const [photosList, setPhotosList] = useState([])

    const [animationDuration, setAnimationDuration] = useState(500);
    const [maxZoomPixelRatio, setMaxZoomPixelRatio] = useState(4);
    const [zoomInMultiplier, setZoomInMultiplier] = useState(2);
    const [doubleTapDelay, setDoubleTapDelay] = useState(300);
    const [doubleClickDelay, setDoubleClickDelay] = useState(300);
    const [doubleClickMaxStops, setDoubleClickMaxStops] = useState(2);
    const [keyboardMoveDistance, setKeyboardMoveDistance] = useState(50);
    const [wheelZoomDistanceFactor, setWheelZoomDistanceFactor] =
      useState(100);
    const [pinchZoomDistanceFactor, setPinchZoomDistanceFactor] =
      useState(100);
    const [scrollToZoom, setScrollToZoom] = useState(false);
    const slides = [
        { src: `${origin}/images/car-rent/test-car.jpg`, width: 1200, height: 800 },
        { src: `${origin}/images/Sale/Sale-1.jpg`, width: 1200, height: 800 },
    ]

    useEffect(() => {
        if (photos.length > 0) {
            let photoSlides = []
            photos.forEach(item => {
                if (item.path) {
                    let obj = {
                        src: item.path,
                        width: 1200,
                        height: 800,
                    }
                    photoSlides.push(obj)
                } else {
                    setPhotosList(slides)
                }
            })
            setPhotosList(photoSlides)
        } else {
            setPhotosList(slides)
        }
    }, [origin])

    const toggleOpen = state => () => setOpen(state)

    const updateIndex = ({ index: current }) => setIndex(current)
    return (
        <>
            <Lightbox
                index={index}
                slides={photosList}
                plugins={[Inline]}
                on={{
                    view: updateIndex,
                    click: toggleOpen(true),
                }}
                carousel={{
                    padding: 0,
                    spacing: 0,
                    imageFit: 'cover',
                }}
                inline={{
                    style: {
                        width: '100%',
                        maxWidth: '1200px',
                        aspectRatio: '16 / 9',
                        margin: '0 auto',
                        overflow: 'hidden',
                    },
                }}
            />

            <Lightbox
                open={open}
                close={toggleOpen(false)}
                index={index}
                slides={photosList}
                on={{ view: updateIndex }}
                animation={{ zoom: animationDuration}}
                controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
                plugins={[Zoom]}
                zoom={{
                    maxZoomPixelRatio,
                    zoomInMultiplier,
                    doubleTapDelay,
                    doubleClickDelay,
                    doubleClickMaxStops,
                    keyboardMoveDistance,
                    wheelZoomDistanceFactor,
                    pinchZoomDistanceFactor,
                    scrollToZoom,
                  }}
            />
        </>
    )
}
