import { Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import { t } from 'i18next';

export default function LabelText() {
  const [expanded, setExpanded] = useState(false);
  const locale = window.location.href.includes('/en') ? 'en' : 'pl'

  const handleToggle = () => {
    setExpanded(!expanded);
  };
  return (
    <Typography component="div">
      <Box component="div" sx={{ mt: 1, fontSize: "12px" }}><span className='text-[red]'>*</span> {t('LAC.popup_contact.cons_title')}</Box>
      <Box component="ol" sx={{ pl: 2, listStyle: 'auto', fontSize:"11px" }}>
        <Box component="li" sx={{ mt: 1 }}>
        {t('LAC.popup_contact.cons_1')}
        </Box>
        <Box component="li" sx={{ mt: 1 }}>
        {t('LAC.popup_contact.cons_2')}
        </Box>
        <AnimatePresence initial={false}>
          {expanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Box component="li" sx={{ mt: 1 }}>
                {t('LAC.popup_contact.cons_3')}
              </Box>
              <Box component="li" sx={{ mt: 1 }}>
                {t('LAC.popup_contact.cons_4')}
              </Box>
              <Box component="li" sx={{ mt: 1 }}>
                {t('LAC.popup_contact.cons_5')}
              </Box>
              <Box component="li" sx={{ mt: 1 }}>
                {t('LAC.popup_contact.cons_6')}
              </Box>
              <Box component="li" sx={{ mt: 1 }}>
                {t('LAC.popup_contact.cons_7')}
              </Box>
              <Box component="li" sx={{ mt: 1 }}>
                {t('LAC.popup_contact.cons_8')}
              </Box>
              <Box component="li" sx={{ mt: 1 }}>
                {t('LAC.popup_contact.cons_9')}
              </Box>
              {locale === 'pl' ?
                <Box component="li" sx={{ mt: 1 }}>
                  {t('LAC.popup_contact.cons_10')}
                </Box>
              : null}
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
      <button type='button' onClick={handleToggle} className='c-color1 mt-5 cursor-pointer font-bold text-[14px]'>
        {expanded ? t('LAC.popup_contact.see_less') : t('LAC.popup_contact.see_more')}
      </button>
    </Typography>
  )
}
