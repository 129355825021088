import React from 'react'
import { Box, ListItemIcon } from '@mui/material'
import DirectionsRailwayOutlinedIcon from '@mui/icons-material/DirectionsRailwayOutlined'
import FlightOutlinedIcon from '@mui/icons-material/FlightOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import EmojiTransportationOutlinedIcon from '@mui/icons-material/EmojiTransportationOutlined'
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined'
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined'
import DirectionsBoatOutlinedIcon from '@mui/icons-material/DirectionsBoatOutlined'
import DirectionsBusFilledOutlinedIcon from '@mui/icons-material/DirectionsBusFilledOutlined'
import NotListedLocationOutlinedIcon from '@mui/icons-material/NotListedLocationOutlined'
import theme from '../../styles/MUI-theme'

const IconSearchResult = ({ icon, ...props }) => {
    return (
        <>
            {props.type === 'blue' ? (
                <ListItemIcon
                    sx={{
                        backgroundColor: theme.palette.primary.light,
                        width: '56px',
                        height: '56px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: 2,
                        borderRadius: '100%',
                        ...props,
                    }}
                >
                    {icon === 'Airport' && <FlightOutlinedIcon color={props.color || 'primary'} />}
                    {icon === 'RailwayStation' && <DirectionsRailwayOutlinedIcon color={props.color || 'primary'} />}
                    {icon === 'Ferry' && <DirectionsBoatOutlinedIcon color={props.color || 'primary'} />}
                    {icon === 'Bus' && <DirectionsBusFilledOutlinedIcon color={props.color || 'primary'} />}
                    {icon === 'Location' && <LocationOnOutlinedIcon color={props.color || 'primary'} />}
                    {icon === 'Address' && <EmojiTransportationOutlinedIcon color={props.color || 'primary'} />}
                    {icon === 'Info' && <InfoOutlinedIcon color={props.color || 'primary'} />}
                    {!icon && <LocationOnOutlinedIcon color={props.color || 'primary'} />}
                </ListItemIcon>
            ) : (
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignContent={'center'}
                    flexWrap={'wrap'}
                    minWidth={props.hideBackground ? 'auto' : { xs: '48px', lg: '64px' }}
                    width={props.hideBackground ? 'auto' : { xs: '48px', lg: '64px' }}
                    height={props.hideBackground ? 'auto' : { xs: '48px', lg: '64px' }}
                    bgcolor={props.hideBackground ? 'none' : '#F2F2F2'}
                    borderRadius={'4px'}
                    mr={props.noMargin ? 0 : { xs: 1, lg: 2 }}
                    sx={{
                        ...props,
                    }}
                >
                    {icon === 'Airport' && <FlightOutlinedIcon sx={{ color: theme.palette.primary.dark }} />}
                    {icon === 'RailwayStation' && (
                        <DirectionsRailwayOutlinedIcon sx={{ color: theme.palette.primary.dark }} />
                    )}
                    {icon === 'Ferry' && <DirectionsBoatOutlinedIcon sx={{ color: theme.palette.primary.dark }} />}
                    {icon === 'Bus' && <DirectionsBusFilledOutlinedIcon sx={{ color: theme.palette.primary.dark }} />}
                    {icon === 'Location' && <LocationOnOutlinedIcon sx={{ color: theme.palette.primary.dark }} />}
                    {icon === 'Address' && (
                        <EmojiTransportationOutlinedIcon sx={{ color: theme.palette.primary.dark }} />
                    )}
                    {icon === 'Info' && <InfoOutlinedIcon sx={{ color: theme.palette.primary.dark }} />}
                    {icon === 'Car' && <DirectionsCarOutlinedIcon sx={{ color: theme.palette.primary.dark }} />}
                    {icon === 'Inventory' && <InventoryOutlinedIcon sx={{ color: theme.palette.primary.dark }} />}
                    {icon === 'Package' && <ShieldOutlinedIcon sx={{ color: theme.palette.primary.dark }} />}
                    {icon === 'Filter' && <TuneOutlinedIcon sx={{ color: theme.palette.primary.dark }} />}
                    {icon === 'NotListedLocationOutlinedIcon' && (
                        <NotListedLocationOutlinedIcon sx={{ color: theme.palette.primary.dark }} />
                    )}
                    {icon === 'YES' && <CheckOutlinedIcon sx={{ color: theme.palette.success.main }} />}
                    {icon === 'NO' && <CloseOutlinedIcon sx={{ color: theme.palette.error.main }} />}
                </Box>
            )}
        </>
    )
}

export default IconSearchResult
