import { useState, useEffect } from 'react';
import { API } from '../../../forms/LAC/api';

const useTags = () => {
  const [tagsData, setTagsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function getTags() {
        setLoading(true)
        try {
          const url = API['GET_TAGS']
          const response = await fetch(url)
          const data = await response.json()
          if(data) {
            setLoading(false)
            setTagsData(data)
          }
        } catch (error) {
          setError(error)
        }
    }
    getTags()
}, [])

  return { tagsData, loading, error };
};

export default useTags;