import React, { useState, useRef } from 'react'
import { environment } from '../../environment'
import CarsReservationsCarouselItem from './CarsReservationsCarouselItem'
import '../../styles/slick-custom-banner.css'
import Slider from 'react-slick'


function CarsReservationsCarousel(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        //console.log('CarsReservationsCarousel')
        //console.log(params)
    }

    const nextArrow = `<svg id="Layer_1" enable-background="new 0 0 512 512" viewBox="0 0 128 128" x="0px" y="0px"xmlns="http://www.w3.org/2000/svg"><path fill="white" id="Right_Arrow_4_" d="m44 108c-1.023 0-2.047-.391-2.828-1.172-1.563-1.563-1.563-4.094 0-5.656l37.172-37.172-37.172-37.172c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l40 40c1.563 1.563 1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"/></svg>`
    const prevArrow = `
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <path fill="white" id="Right_Arrow_4_" d="M336,80c4.1,0,8.2,1.6,11.3,4.7c6.3,6.3,6.3,16.4,0,22.6L198.6,256l148.7,148.7
            c6.3,6.3,6.3,16.4,0,22.6c-6.3,6.2-16.4,6.3-22.6,0l-160-160c-6.3-6.3-6.3-16.4,0-22.6l160-160C327.8,81.6,331.9,80,336,80L336,80z"
            />
        </svg>
    `
    const [currentSlide, setCurrentSlide] = useState(0),
        autoTime = params.block[params.locale]['RotationTime']
            ? parseFloat(params.block[params.locale]['RotationTime'])
            : 5000,
        speedSliderTime = 1000,
        sliderRef = useRef(),
        progresRef = useRef()

    const changeSlideTo = index => {
        progresRef.current.style.animation = 'none'
        sliderRef.current.slickGoTo(index)
    }

    const NextArrow = props => {
        const { className, style, onClick } = props
        return (
            <div
                className={className}
                onClick={onClick}
                style={{
                    ...style,
                    display: 'block',
                    width: '30px',
                    height: '30px',
                }}
                dangerouslySetInnerHTML={{ __html: nextArrow }}
            ></div>
        )
    }

    const PrevArrow = props => {
        const { className, style, onClick } = props
        return (
            <div
                className={className}
                onClick={onClick}
                style={{
                    ...style,
                    display: 'block',
                    width: '30px',
                    height: '30px',
                }}
                dangerouslySetInnerHTML={{ __html: prevArrow }}
            ></div>
        )
    }
    let autoRotate = params.block.hasOwnProperty('AutoRotate') ? params.block['AutoRotate'] : true;
    const [index, setIndex] = React.useState(0);
    let block = params.block[params.locale]['Item'];

    const labels = [];
    Object.keys(block).map((item, i) => (
        labels.push( block[item].hasOwnProperty('Title') ? block[item]['Title'] : 'Tytuł')
    ))

    var settings = {
        dots: true,
        customPaging: function(slider, i) {
            return (
                <div className="btn-link secondary">{labels[slider]}</div>
            )
        },
        infinite: true,
        speed: speedSliderTime,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: autoRotate,
        autoplaySpeed: autoTime,
        pauseOnHover: false,
        vertical: false,
        verticalSwiping: false,
        arrows: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        afterChange: current => {
            setCurrentSlide(current)
        },
    }

    return (
        <section className={'cars-res-carousel'}>
            <div className="wrapper">
                <>
                    <div className='carousel-labels d-flex ai-center jc-center'>
                        {params.block[params.locale].hasOwnProperty('Item') &&
                            Object.keys(block).map((item, i) => (
                                <div
                                    onClick={(e) => setIndex(i)}
                                    key={'CarsReservationsCarouselLabel-' + i}
                                    className={'btn-link secondary'+ (index === i ? ' active' : '')}
                                >
                                    {block[item].hasOwnProperty('Title') ? block[item]['Title'] : 'Tytuł'}
                                </div>
                            ))
                        }
                    </div>
                    <div>
                        {/*params.block[params.locale].hasOwnProperty('Item') &&
                            Object.keys(block).map((item, i) => (
                                <div className={index === i ? '' : 'hidden'}>
                                    <CarsReservationsCarouselItem
                                        key={'CarsReservationsCarouselItem-' + i}
                                        block={block[item]}
                                        line={params.line}
                                    />
                                </div>
                            ))
                        */}
                        <Slider {...settings} ref={sliderRef}>
                            {params.block[params.locale].hasOwnProperty('Item') &&
                                Object.keys(params.block[params.locale]['Item']).map((item, i) => (
                                    <div 
                                        className={index === i ? '' : 'hidden1'}
                                        title={block[item].hasOwnProperty('Title') ? block[item]['Title'] : 'Tytuł'}
                                        key={'CarsReservationsCarousel-' + i}
                                    >
                                        <CarsReservationsCarouselItem
                                            block={block[item]}
                                            line={params.line}
                                            button={params.button}
                                            locale={params.locale}
                                            recaptchaToken={params.recaptchaToken}
                                            setErrorMsg={params.setErrorMsg}
                                        />
                                    </div>
                                ))}
                        </Slider>
                    </div>
                </>
            </div>
        </section>
    )
}

export default CarsReservationsCarousel
