import * as Yup from 'yup'
import { isValidPESEL } from '../../RAC/Step4/Schema'

const validationSchema = (t, isLogged) => {
    return Yup.object().shape({
        userFirstName: Yup.string()
            .required(t('LAC.client_data.validation.name_1'))
            .min(2, t('LAC.client_data.validation.name_2'))
            .max(80, t('LAC.client_data.validation.name_3'))
            .matches(/^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, t('LAC.client_data.validation.name_2')),
        userLastName: Yup.string()
            .required(t('LAC.client_data.validation.surname_1'))
            .min(2, t('LAC.client_data.validation.surname_2'))
            .max(80, t('LAC.client_data.validation.surname_3'))
            .matches(/^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ\s'\-]+$/, t('LAC.client_data.validation.surname_2')),
        userEmail: Yup.string()
            .email(t('LAC.client_data.validation.email'))
            .required(t('LAC.client_data.validation.email')),
        userPhoneNumber: Yup.string().when(['userCountry'], {
            is: (userCountry) => !isLogged && userCountry !== 'PL',
            then: schema => 
                schema
                    .test('starts-with-plus', t('LAC.client_data.validation.phone_4'), value => {
                        return value ? value.startsWith('+') : false;
                    })
                    .test('no-special-characters', t('LAC.client_data.validation.phone_1'), value => {
                        return value ? /^[\d+]+$/.test(value) : true;
                    })
                    .test('valid-phone-number', t('LAC.client_data.validation.phone_3'), value => {
                        if (!value || !value.startsWith('+')) return true;
                        return /^\+(?![+()\-])(?=\d{7,15}$)\d+$/.test(value); 
                    })
                    .required(t('LAC.client_data.validation.phone_2')),
            otherwise: schema => 
                schema.when([], {
                    is: () => !isLogged,
                    then: schema => 
                        schema
                            .test('starts-with-plus', t('LAC.client_data.validation.phone_1'), value => {
                                return value ? !value.includes('+') : false;
                            })
                            .test('no-special-characters', t('LAC.client_data.validation.phone_1'), value => {
                                return value ? /^[\d+]+$/.test(value) : true;
                            })
                            .test('valid-phone-number', t('LAC.client_data.validation.phone_3'), value => {
                                if (!value) return true;
                                const isValidPLNumber = /^(?![+()\-])(?=\d{7,15}$)\d+$/.test(value);
                                return isValidPLNumber;
                            })
                            .required(t('LAC.client_data.validation.phone_2')),
                    otherwise: schema => schema.optional(),
                }),
        }),
        userStreet: Yup.string().when('clientType', {
            is: value => value === 'ind',
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.street_1'))
                    .max(200, t('LAC.client_data.validation.street_1')),
            otherwise: schema => schema.optional(),
        }),
        userHouse: Yup.string().when('clientType', {
            is: value => value === 'ind',
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.house_1'))
                    .max(200, t('LAC.client_data.validation.house_1')),
            otherwise: schema => schema.optional(),
        }),
        userPostCode: Yup.string().when(['userCountry', 'clientType'], {
            is: (country, clientType) => country === "PL" && clientType === 'ind',
            then: schema =>
                schema
                    .matches(/^\d{2}-\d{3}$/, t('LAC.client_data.validation.postcode_1'))
                    .required(t('LAC.client_data.validation.postcode_1')),
            otherwise: schema => schema.optional(),
        }),
        userCity: Yup.string().when('clientType', {
            is: value => value === 'ind',
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.city_1'))
                    .min(2, t('LAC.client_data.validation.city_1'))
                    .max(200, t('LAC.client_data.validation.city_1')),
            otherwise: schema => schema.optional(),
        }),
        companyName: Yup.string().when('clientType', {
            is: value => value === 'business',
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.company_name'))
                    .max(200, t('LAC.client_data.validation.company_name')),
            otherwise: schema => schema.optional(),
        }),
        companyStreet: Yup.string().when('clientType', {
            is: value => value === 'business',
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.company_street'))
                    .max(200, t('LAC.client_data.validation.company_street')),
            otherwise: schema => schema.optional(),
        }),
        companyHouse: Yup.string().when('clientType', {
            is: value => value === 'business',
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.company_house'))
                    .max(200, t('LAC.client_data.validation.company_house')),
            otherwise: schema => schema.optional(),
        }),
        companyPostCode: Yup.string().when(['userCountry', 'clientType'], {
            is: (country, clientType) => country === "PL" && clientType === 'business',
            then: schema =>
                schema
                    .matches(/^\d{2}-\d{3}$/, t('LAC.client_data.validation.company_postcode'))
                    .required(t('LAC.client_data.validation.company_postcode')),
            otherwise: schema => schema.optional(),
        }),
        companyCity: Yup.string().when('clientType', {
            is: value => value === 'business',
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.company_city'))
                    .min(2, t('LAC.client_data.validation.company_city'))
                    .max(200, t('LAC.client_data.validation.company_city')),
            otherwise: schema => schema.optional(),
        }),
        companyEmail: Yup.string().when('clientType', {
            is: value => value === 'business',
            then: schema =>
                schema
                    .email(t('LAC.client_data.validation.email'))
                    .required(t('LAC.client_data.validation.email')),
            otherwise: schema => schema.optional(),
        }),
        companyPhone: Yup.string().when('clientType', {
            is: value => value === 'business',
            then: schema => {
                if (isLogged) {
                    return schema.optional();
                } else {
                    return schema
                        .matches(/^(?![+()\-])(?=\d{7,15}$)\d+$/, t('LAC.client_data.validation.phone_1'))
                        .required(t('LAC.client_data.validation.phone_2'));
                }
            },
            otherwise: schema => schema.optional(),
        }),
        userNip: Yup.string().when(['userCountry', 'clientType'], {
            is: (country, clientType) => country === "PL" && clientType === 'business',
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.nip_3'))
                    .matches(/^\d{10}$/, t('LAC.client_data.validation.nip_1'))
                    .test('isValidNIP', t('LAC.client_data.validation.nip_2'), value => {
                        const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7]
                        const digits = value.toString().split('').map(Number)
                        const checksum =
                            digits.reduce((acc, digit, index) => {
                                if (index === 9) return acc
                                return acc + digit * weights[index]
                            }, 0) % 11
                        return checksum === digits[9]
                    }),
            otherwise: schema => schema.optional(),
        }),
        companyAdditionalData: Yup.string().when(['userCountry', 'clientType'], {
            is: (country, clientType) => country !== "PL" && clientType === 'business',
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.nip_3')),
            otherwise: schema => schema.optional(),
        }),
        userAdditionalFirstName: Yup.string().when('isOtherDriver', {
            is: value => value == true,
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.name_4'))
                    .min(2, t('LAC.client_data.validation.name_2'))
                    .max(80, t('LAC.client_data.validation.name_3'))
                    .matches(/^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, t('LAC.client_data.validation.name_2')),
            otherwise: schema => schema.optional(),
        }),
        userAdditionalLastName: Yup.string().when('isOtherDriver', {
            is: value => value == true,
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.surname_4'))
                    .min(2, t('LAC.client_data.validation.surname_2'))
                    .max(80, t('LAC.client_data.validation.surname_3'))
                    .matches(/^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ\s'\-]+$/, t('LAC.client_data.validation.surname_2')),
            otherwise: schema => schema.optional(),
        }),
        userAdditionalPhone: Yup.string().when('isOtherDriver', {
            is: value => value == true,
            then: schema =>
                schema
                    .matches(/^(?![+()\-])(?=\d{7,15}$)\d+$/, t('LAC.client_data.validation.phone_1'))
                    .required(t('LAC.client_data.validation.phone_2')),
            otherwise: schema => schema.optional(),
        }),
        userPesel: Yup.string().when(['userCountry', 'clientType'], {
            is: (country, clientType) => country === 'PL' && clientType === 'ind',
            then: schema =>
                schema
                    .length(11, t('LAC.client_data.validation.PESEL_1'))
                    .test('isValidPESEL', t('LAC.client_data.validation.PESEL_2'), value => isValidPESEL(value))
                    .required(t('LAC.client_data.validation.PESEL_2')),
            otherwise: schema => schema.optional(),
        }),
    })
}

export default validationSchema
