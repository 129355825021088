import { environment } from '../../environment'

export default function setLoginLink() {
    let url = window.location.pathname + environment.page
    url = url.replace('index.html', '')

    return (
        window.location.origin + environment.loginUrl + '?return=' + url
    ) /*'&lang='+lang;*/ /*for EN vers of login page */
}
