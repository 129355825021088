import React, { useEffect } from 'react'
import { environment } from '../../environment'
import FooterLinksItems from './FooterLinksItem'
import FooterSocialsItems from './FooterSocialsItem'
import FooterPickupsItems from './FooterPickupsItem'
import FooterBrandsItems from './FooterBrandsItem'
import TextLinks from '../../components/TextLinks.js'
import '../../styles/big-footer.css'
import { useTranslation } from 'react-i18next'
import Svg from '../../components/Svg';

function Footer(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Big Footer 2')
        console.log(params)
    }

    const { t, i18n } = useTranslation()
    
    let type = params.block[params.locale].hasOwnProperty('Type') ? params.block[params.locale]['Type'] : 'none';
    let HeadlineLinks = params.block[params.locale].hasOwnProperty('HeadlineLinks') ? params.block[params.locale]['HeadlineLinks'] : '',
        ShowLinks = params.block[params.locale].hasOwnProperty('ShowLinks')
            ? params.block[params.locale]['ShowLinks'] === true
                ? true
                : false
            : false,
        HeadlineNewsletter = params.block[params.locale].hasOwnProperty('HeadlineNewsletter') ? params.block[params.locale]['HeadlineNewsletter'] : '',
        TextNewsletter = params.block[params.locale].hasOwnProperty('Text') ? params.block[params.locale]['Text'] : '',
        ShowNewsletter = params.block[params.locale].hasOwnProperty('ShowNewsletter')
            ? params.block[params.locale]['ShowNewsletter'] === true
                ? true
                : false
            : false,
        HeadlineQuestion = params.block[params.locale].hasOwnProperty('HeadlineQuestion') ? params.block[params.locale]['HeadlineQuestion'] : '',
        ShowQuestion = params.block[params.locale].hasOwnProperty('ShowQuestion')
            ? params.block[params.locale]['ShowQuestion'] === true
                ? true
                : false
            : false,
        HeadlineSocial = params.block[params.locale].hasOwnProperty('HeadlineSocial') ? params.block[params.locale]['HeadlineSocial'] : '',
        ShowSocial = params.block[params.locale].hasOwnProperty('ShowSocial')
            ? params.block[params.locale]['ShowSocial'] === true
                ? true
                : false
            : false,
        HeadlinePickup = params.block[params.locale].hasOwnProperty('HeadlinePickup') ? params.block[params.locale]['HeadlinePickup'] : '',
        ShowPickup = params.block[params.locale].hasOwnProperty('ShowPickup')
            ? params.block[params.locale]['ShowPickup'] === true
                ? true
                : false
            : false,
        HeadlineBrands = params.block[params.locale].hasOwnProperty('HeadlineBrands') ? params.block[params.locale]['HeadlineBrands'] : '',
        ShowBrands = params.block[params.locale].hasOwnProperty('ShowBrands')
            ? params.block[params.locale]['ShowBrands'] === true
                ? true
                : false
            : false;

    
    let CallName = params.block[params.locale].hasOwnProperty('CallName') ? params.block[params.locale]['CallName'] : '',
        CallLink = params.block[params.locale].hasOwnProperty('CallLink') ? params.block[params.locale]['CallLink'] : '',
        MailName = params.block[params.locale].hasOwnProperty('MailName') ? params.block[params.locale]['MailName'] : '',
        MailLink = params.block[params.locale].hasOwnProperty('MailLink') ? params.block[params.locale]['MailLink'] : '';
    
    let LinksItems = params.block[params.locale].hasOwnProperty('Link') ? params.block[params.locale]['Link'] : [];
    let SocialsItems = params.block[params.locale].hasOwnProperty('Social') ? params.block[params.locale]['Social'] : [];
    let BrandsItems = params.block[params.locale].hasOwnProperty('Brand') ? params.block[params.locale]['Brand'] : [];
    let PickupsItems = params.block[params.locale].hasOwnProperty('Place') ? params.block[params.locale]['Place'] : [];

    return (
        <section className={'big-footer'}>
            <div className="wrapper">
                <div className={'container mx-auto px-8'}>
                    <div className='orig-box d-flex'>
                        <div className='left-box'>
                            {ShowLinks && 
                                <div className='links-box'>
                                    {HeadlineLinks !== '' && <h3 className="c-color3">{HeadlineLinks}</h3>}
                                    <FooterLinksItems block={LinksItems} line={params.line}/>
                                </div>
                            }
                        </div>
                        <div className='right-box'>
                            {ShowNewsletter && 
                                <div className='newsletter-box'>
                                    {HeadlineNewsletter !== '' && <h3 className="c-color3">{HeadlineNewsletter}</h3>}
                                    {TextNewsletter !== '' && 
                                        <div className='text-box opacity-50'>
                                            <TextLinks text={TextNewsletter} line={params.line} />
                                        </div>
                                    }
                                    <div className="d-flex ai-center news-input-box">
                                        <fieldset>
                                            <input
                                                id="newsletter-field"
                                                className="newsl"
                                                name="newsletter"
                                                label="Newsletter"
                                                placeholder={t('portlets.big_footer.type_mail')}
                                            />
                                        </fieldset>
                                        <div id="newsletter-button">
                                            <a className="button b-color1" id="newsresult-button" href={'?newsletter=MAIL'} onClick={(e) => { e.preventDefault(); console.log('click newslatter')}}><Svg name="MenuArrowLeft" /></a>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='right-bottom-box d-flex'>
                                {ShowQuestion && 
                                    <div className='questions-box'>
                                        {HeadlineQuestion !== '' && <h3 className="c-color3">{HeadlineQuestion}</h3>}
                                        <div className='questions-buttons d-flex ai-center'>
                                            {CallName !== '' && CallLink !== '' && 
                                                <a className="btn-link secondary" href={'tel:'+CallLink}>{CallName}</a>
                                            }
                                            {MailName !== '' && MailLink !== '' && 
                                                <a className="btn-link secondary" href={'mailto:'+MailLink}>{MailName}</a>
                                            }
                                        </div>
                                    </div>
                                }
                                {ShowSocial && 
                                    <div className='socials-box'>
                                        {HeadlineSocial !== '' && <h3 className="c-color3">{HeadlineSocial}</h3>}
                                        <FooterSocialsItems block={SocialsItems} line={params.line} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {ShowPickup && type !== 'none' &&
                        <div className='pickup-section' id="pickup-section">
                            {HeadlinePickup !== '' && <h3 className="c-color3">{HeadlinePickup}</h3>}
                            <FooterPickupsItems block={PickupsItems} line={params.line} type={type} locale={params.locale} />
                        </div>
                    }
                    {ShowBrands && 
                        <div className='brands-section'>
                            {HeadlineBrands !== '' && <h3 className="c-color3">{HeadlineBrands}</h3>}
                            <FooterBrandsItems block={BrandsItems} line={params.line} />
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}

export default Footer
