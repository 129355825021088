import React from 'react'
import { Box, Button, Tooltip, Zoom } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

const ChooseButton = ({ children, sx, ...props }) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const { t } = useTranslation()

    return (
        <Box textAlign="center">
            <Tooltip TransitionComponent={Zoom} title={!props.showButton ? t('LAC.client_data.tooltip_text') : ''} disableHoverListener={props.showButton == undefined ? true : false} arrow>
                <span className='block my-4'>
                    <Button
                        id={props?.id}
                        type={props?.type}
                        disabled={props?.disabled}
                        fullWidth={fullScreen}
                        variant="contained"
                        disableElevation
                        sx={{
                            minWidth: '200px',
                            py: { xs: 2, md: 2 },
                            color: 'white',
                            textTransfrom: 'uppercase',
                            fontWeight: 'bold',
                            // backgroundColor: '#ef7d14 !important',
                            backgroundColor: '#f07d14 !important',
                            ':hover': {
                                backgroundColor: '#0b4185 !important',
                                color: 'white',
                            },
                            '&[disabled]': {
                                backgroundColor: '#757575 !important',
                                color: '#b0b0b0 !important', 
                                cursor: 'not-allowed !important'
                            },
                            ...sx,
                        }}
                        onClick={props?.handleClick}
                    >
                        {children}
                    </Button>
                </span>
            </Tooltip>
        </Box>
    )
}
export default ChooseButton
