import React, { useEffect, useState } from 'react'
import { environment } from '../../environment'
import { useTranslation } from 'react-i18next'
import PopupLogin from './PopupLogin'

function NotLogged(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('NotLogged')
        console.log(params)
    }
    const [showModal, setShowModal] = useState(false)
    const { t, i18n } = useTranslation()
    let lang = window.location.pathname.split('/')[1].length > 2 || window.location.pathname.split('/')[1].length === 0 ? 'pl' : window.location.pathname.split('/')[1]
    let langMeta = document.documentElement.lang;
    lang = lang === langMeta ? lang : lang;
    useEffect(() => {
        i18n.changeLanguage(lang)
    }, [lang])
    function setLoginLink() {
        let url = window.location.pathname + environment.page
        url =  url.replace('index.html', '')
        
        return window.location.origin+environment.loginUrl+'?return='+url; /*'&lang='+lang;*/ /*for EN vers of login page */
    }

    return (
        <>
            <a 
                onClick={() => {setShowModal(true)}}
                title={t('header.nav.login')} 
                className={'btn-link cursor-pointer '+(params.type === 'desktop' ? 'empty' : 'primary')}
            >
                {t('header.nav.login')}
            </a>
            <a
                href={environment.registrationPage[lang]}
                title={t('header.nav.register')}
                className={'btn-link empty '+(params.type === 'desktop' ? '' : 'text-white')}
            >
                {params.type === 'desktop' ? t('header.nav.register') : t('header.nav.no_account_register')}
            </a>
            {showModal && 
                <PopupLogin 
                    showModal={showModal}
                    setShowModal={setShowModal}
                    loginUrl={setLoginLink()}
                    registerUrl={environment.registrationPage[lang]}
                    title={t('login_popup_title')}
                />
            }
        </>
    )
}
export default NotLogged
