import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import TabsClientPanel from './TabsClientPanel'
import setLoginLink from '../setLoginLink'
import { environment } from '../../../environment'
import PopupLogin from '../../../components/Nav/PopupLogin'

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export default function TabsClient({ tabPanel1, tabPanel2, isLogged }) {
    const { t } = useTranslation()
    const [value, setValue] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const lang = window.location.href.includes('/en/') ? 'en' : 'pl'

    const handleChange = (event, newValue) => {
        // setValue(newValue)
    }

    return isLogged ? (
        <>{tabPanel1}</>
    ) : (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 0 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={t('RAC.step_five.new_customer')} {...a11yProps(0)} />
                    <Tab
                        label={t('RAC.step_five.have_account')}
                        {...a11yProps(1)}
                        onClick={e => {
                            e.preventDefault()
                            setShowModal(true)
                            // window.location.href = setLoginLink()
                        }}
                    />
                </Tabs>
            </Box>
            <TabsClientPanel value={value} index={0}>
                {tabPanel1}
            </TabsClientPanel>
            <TabsClientPanel value={value} index={1}>
                {tabPanel2}
            </TabsClientPanel>
            {showModal && 
                <PopupLogin 
                    showModal={showModal}
                    setShowModal={setShowModal}
                    loginUrl={setLoginLink()}
                    registerUrl={environment.registrationPage[lang]}
                    title={t('login_popup_title')}
                />
            }
        </Box>
    )
}
