import React from 'react'
import { environment } from './../../environment'
import TextLinks from '../../components/TextLinks'
import Cookie from 'universal-cookie'
import Svg from '../../components/Svg'
import Image from '../../components/Image'
import Links from '../../components/Links'

function Infobar(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Infobar')
        console.log(params)
    }

    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-' + params.block['en']['Background']
    }
    let innerSection = 'infobar-box w-100',
        innerContainer = 'container',
        noPadd = 'container',
        Icon = params.block['en'].hasOwnProperty('Icon')
            ? params.block['en']['Icon'].hasOwnProperty('link')
                ? params.block['en']['Icon']['link']
                : ''
            : '',
        Title = params.block['en'].hasOwnProperty('Title') ? params.block['en']['Title'] : '',
        Text = params.block['en'].hasOwnProperty('Text') ? params.block['en']['Text'] : '',
        infoBar = document.getElementById('infobar'),
        main = document.getElementsByTagName('main')[0],
        Choice = params.block['en'].hasOwnProperty('Choice') ? params.block['en']['Choice'] : 'empty',
        ChoiceIs =
            Choice === 'empty'
                ? 'empty'
                : Choice.hasOwnProperty('Button')
                  ? 'button'
                  : Choice.hasOwnProperty('Link')
                    ? 'link'
                    : 'empty',
        ButtonLink =
            Choice === 'empty'
                ? ''
                : Choice.hasOwnProperty('Button')
                  ? Choice['Button'].hasOwnProperty('ButtonLink')
                      ? Choice['Button']['ButtonLink']
                      : ''
                  : '',
        ButtonName =
            Choice === 'empty'
                ? ''
                : Choice.hasOwnProperty('Button')
                  ? Choice['Button'].hasOwnProperty('ButtonName')
                      ? Choice['Button']['ButtonName']
                      : ''
                  : '',
        ButtonPosition =
            Choice === 'empty'
                ? 'left'
                : Choice.hasOwnProperty('Button')
                  ? Choice['Button'].hasOwnProperty('ButtonPosition')
                      ? Choice['Button']['ButtonPosition']
                      : 'left'
                  : 'left',
        ButtonType =
            Choice === 'empty'
                ? 'primary'
                : Choice.hasOwnProperty('Button')
                  ? Choice['Button'].hasOwnProperty('ButtonType')
                      ? Choice['Button']['ButtonType']
                      : 'primary'
                  : 'primary',
        ButtonNewOpen =
            Choice === 'empty'
                ? 'false'
                : Choice.hasOwnProperty('Button')
                  ? Choice['Button']['NewTab']
                      ? 'true'
                      : 'false'
                  : 'false',
        Link =
            Choice === 'empty'
                ? ''
                : Choice.hasOwnProperty('Link')
                  ? Choice['Link'].hasOwnProperty('Link')
                      ? Choice['Link']['Link']
                      : ''
                  : '',
        LinkName =
            Choice === 'empty'
                ? ''
                : Choice.hasOwnProperty('Link')
                  ? Choice['Link'].hasOwnProperty('LinkName')
                      ? Choice['Link']['LinkName']
                      : ''
                  : '',
        LinkPosition =
            Choice === 'empty'
                ? 'left'
                : Choice.hasOwnProperty('Link')
                  ? Choice['Link'].hasOwnProperty('LinkPosition')
                      ? Choice['Link']['LinkPosition']
                      : 'left'
                  : 'left',
        LinkNewOpen =
            Choice === 'empty'
                ? 'false'
                : Choice.hasOwnProperty('Link')
                  ? Choice['Link']['NewTab']
                      ? 'true'
                      : 'false'
                  : 'false'

    if (params.block['en']['InnerBackground'] !== '' && params.block['en'].hasOwnProperty('InnerBackground')) {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack
            noPadd = noPadd + ' no-padd-tb'
        } else {
            innerSection = innerSection + ' ' + colorBack
            noPadd = noPadd + ' no-padd'
        }
    }

    function setCookiesInfobar(name, value, days) {
        const cookiess = new Cookie()
        let dayTo = new Date(Date.now() + days * 86400000)
        cookiess.set(name, value, { path: '/', expires: dayTo })
        setTimeout(function () {
            if (infoBar) infoBar.style.display = 'none'
            if (infoBar && main) main.style.padding = '0px 0px 0px 0px'
            if (document.body.classList.contains('show-infobar')) {
                document.body.classList.remove('show-infobar')
            }
        }, 1)
    }
    let height = infoBar ? infoBar.offsetHeight : 0
    setTimeout(function () {
        height = infoBar ? infoBar.offsetHeight : 0
    }, 1)
    if (!document.body.classList.contains('show-infobar') && height > 1) {
        document.body.classList.add('show-infobar')
    }

    if (infoBar && main)
        setTimeout(function () {
            main.style.padding = height + 'px 0px 0px 0px'
        }, 1)
    function checksum(text) {
        var chk = 0x12345678
        var len = text.length
        for (var i = 0; i < len; i++) {
            chk += text.charCodeAt(i) * (i + 1)
        }

        return (chk & 0xffffffff).toString(16)
    }
    const cookiess = new Cookie()
    let cookieVal = cookiess.get('__' + environment.cookieName + '_ibar')
    if (!cookieVal || cookieVal === '' || cookieVal !== checksum(Text) || params.line === 'offline') {
        if (cookieVal !== checksum(Text) && infoBar) infoBar.style.display = 'block'
        return (
            <section className={innerSection}>
                <div className="wrapper">
                    <div className={noPadd}>
                        <div className={innerContainer}>
                            {Title &&
                                params.block['en']['HideTitle'] !== false &&
                                params.block['en'].hasOwnProperty('HideTitle') && <h4>{Title}</h4>}
                            <div className="info-bar d-flex ai-center">
                                {params.block['en'].hasOwnProperty('Icon') && Icon !== '' && (
                                    <div className="icon-box">
                                        <Image src={Icon} width="50" alt={Title} />
                                    </div>
                                )}
                                {Text && (
                                    <div className="text">
                                        <TextLinks text={Text} line={'offline'} />
                                    </div>
                                )}
                                <div className="right-box d-flex ai-center">
                                    {ChoiceIs === 'button' && (
                                        <div className={'button-box w-100 ta-' + ButtonPosition}>
                                            <Links
                                                line={'offline'}
                                                url={ButtonLink}
                                                class={'button ' + ButtonType}
                                                name={ButtonName}
                                                target={ButtonNewOpen}
                                            />
                                        </div>
                                    )}
                                    {ChoiceIs === 'link' && (
                                        <div className={'link-box w-100 ta-' + LinkPosition}>
                                            <Links
                                                line={'offline'}
                                                url={Link}
                                                class="link-name"
                                                name={LinkName}
                                                target={LinkNewOpen}
                                            />
                                        </div>
                                    )}
                                    <div
                                        className="close"
                                        id="close-infobar"
                                        onClick={e => {
                                            e.preventDefault()
                                            setCookiesInfobar(
                                                '__' + environment.cookieName + '_ibar',
                                                checksum(Text),
                                                30
                                            )
                                        }}
                                    >
                                        <Svg name="CloseRound" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    } else {
        return ''
    }
}

export default Infobar
