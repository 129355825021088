import React from 'react'
import { Box } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import theme from '../../styles/MUI-theme.js'

const Stepper = ({ steps = 5, activeStep }) => {
    const circleSize = '24px'

    return (
        <Box display="flex" alignItems={'center'} mb={4}>
            <Box
                display="flex"
                justifyContent={'center'}
                alignItems={'center'}
                width={circleSize}
                height={circleSize}
                borderRadius={'100%'}
                bgcolor={theme.palette.secondary.main}
            >
                <DoneIcon fontSize="10px" sx={{ color: 'white' }} />
            </Box>
            {Array.from({ length: steps - 1 }).map((step, index) => (
                <Box key={`step-${index}`} display="flex" alignItems={'center'} sx={{ width: `${100 / (steps - 1)}%` }}>
                    <Box
                        width={`calc(100% - ${circleSize})`}
                        height={'0.5px'}
                        bgcolor={
                            index === activeStep - 2 || index < activeStep - 1 ? theme.palette.secondary.main : '#ccc'
                        }
                    ></Box>
                    <Box
                        display="flex"
                        alignItems={'center'}
                        justifyContent={'center'}
                        width={circleSize}
                        height={circleSize}
                        fontSize={'12px'}
                        borderRadius={'100%'}
                        border={index === activeStep - 2 || index < activeStep - 1 ? 'none' : '1px solid #ccc'}
                        color={index === activeStep - 2 || index < activeStep - 1 ? 'white' : '#505154'}
                        bgcolor={
                            index === activeStep - 2 || index < activeStep - 1
                                ? theme.palette.secondary.main
                                : '#f4f4f4'
                        }
                    >
                        {index < activeStep - 2 ? <DoneIcon fontSize="10px" sx={{ color: 'white' }} /> : index + 2}
                    </Box>
                </Box>
            ))}
        </Box>
    )
}

export default Stepper
