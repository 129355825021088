import React from 'react'
import { useState, useContext } from 'react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import { Box, Stack } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'

import Datepicker from '../../components/Datepicker'
import MuiCheckbox from '../../components/MuiCheckbox'
import MuiTimepicker from '../../components/MuiTimePicker'

import SearchField from './Step1/SearchField'

import 'react-datepicker/dist/react-datepicker.css'
import '../../styles/form.css'

const FormStep1cms = params => {
    const { t } = useTranslation()

    const defaultTime = dayjs().set('hour', 12).set('minute', 0).set('second', 0).set('millisecond', 0)

    const [startDate, setStartDate] = useState(new Date(dayjs().set('second', 0).set('millisecond', 0).add(2, 'day')))
    const [endDate, setEndDate] = useState(new Date(dayjs().set('second', 0).set('millisecond', 0).add(7, 'day')))

    const [startTime, setStartTime] = useState(defaultTime)
    const [endTime, setEndTime] = useState(defaultTime)

    const initialValues = {
        startLocationPlace: {},
        endLocationPlace: {},
        startLocation: '',
        endLocation: '',
        startDate: startDate,
        endDate: endDate,
        startTime: defaultTime,
        endTime: defaultTime,
        theSamePlace: true,
    }
    return (
        <>
            <Formik initialValues={initialValues}>
                {({ values, errors, touched }) => (
                    <Form noValidate className="h-full">
                        <div className="p-5 lg:p-10 lg:pb-14 relative h-full">
                            <h2 className="text-orange-500 font-bold text-4xl lg:text-[44px] leading-tight mb-5">
                                {t('RAC.step_one.heading')}
                            </h2>

                            <SearchField
                                name="startLocation"
                                placeholder={t('RAC.step_one.label_searchfield')}
                                errors={errors['startLocation']}
                                mb="12px"
                            />

                            {/* <GeolocationComponent /> */}

                            <MuiCheckbox
                                name="theSamePlace"
                                label={t('RAC.step_one.label_checkbox')}
                                errors={errors['theSamePlace']}
                                touched={touched['theSamePlace']}
                                style={{ paddingLeft: '8px' }}
                            />

                            {!values.theSamePlace && (
                                <Box mb="4px">
                                    <SearchField
                                        name="endLocation"
                                        placeholder={t('RAC.step_one.label_searchfield_dropoff')}
                                        errors={errors['endLocation']}
                                    />
                                </Box>
                            )}
                            <Box
                                sx={{
                                    height: '56px',
                                    border: '1px solid #d1d1d1',
                                    borderRadius: '50px',
                                    padding: '10px 20px',
                                    mb: '20px',
                                }}
                            >
                                <Stack direction="row">
                                    <Box width="50%">
                                        <Datepicker
                                            name="startDate"
                                            selected={startDate}
                                            onChange={date => setStartDate(date)}
                                            selectsStart
                                            startDate={startDate}
                                            minDate={new Date()}
                                            endDate={endDate}
                                            locale={params.locale}
                                        />
                                    </Box>
                                    <Box width="50%" borderLeft="1px solid #d1d1d1" pl={2}>
                                        <MuiTimepicker
                                            name="startTime"
                                            defaultValue={startTime}
                                            value={startTime}
                                            onChange={time => setStartTime(time)}
                                            required
                                        />
                                    </Box>
                                </Stack>
                            </Box>

                            <Box
                                sx={{
                                    height: '56px',
                                    border: '1px solid #d1d1d1',
                                    borderRadius: '50px',
                                    padding: '10px 20px',
                                }}
                            >
                                <Stack direction="row">
                                    <Box width="50%">
                                        <Datepicker
                                            name="endDate"
                                            selected={endDate}
                                            onChange={date => setEndDate(date)}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            locale={params.locale}
                                        />
                                    </Box>
                                    <Box width="50%" borderLeft="1px solid #d1d1d1" pl={2}>
                                        <MuiTimepicker
                                            name="endTime"
                                            defaultValue={endTime}
                                            value={endTime}
                                            onChange={time => setEndTime(time)}
                                            required
                                        />
                                    </Box>
                                </Stack>
                            </Box>

                            <button
                                type="submit"
                                disabled
                                className="btn-link absolute bottom-[-35px] left-1/2 translate-x-[-50%]"
                            >
                                <SearchOutlinedIcon style={{ marginRight: '8px' }} />
                                {t('btn.search')}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default FormStep1cms
