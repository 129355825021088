import React, { useState, useEffect, useContext } from 'react'
import { CircularProgress, IconButton } from '@mui/material'
import { useFormikContext } from 'formik'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import { DialogContext } from './SearchField'
import { environment } from '../../../environment'

const GeolocationTargeo = ({name, handleCloseDialog, ...props}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [location, setLocation] = useState(null)
    const [place, setPlace] = useState()
    const [error, setError] = useState(null)

    const { setFieldValue, values } = useFormikContext()

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
            console.log('!!! GeolocationTargeo name: ', name)
        }
    }, [])

    useEffect(() => {
        location && handleSearch()
    }, [location])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('Geolocation place', place)

        place && setFieldValue(props?.LAC ? 'pickupTargeoAddress' : name, place?.label.split(', ').slice(0, 2).join(', '))
        place && setFieldValue(props?.LAC ? 'pickupTargeoPlace' : `${name}Place`, place)

        place && values.theSamePlace && setFieldValue(`endLocation`, place?.label.split(', ').slice(0, 2).join(', '))
        place && values.theSamePlace && setFieldValue(`endLocationPlace`, place)

        place && handleCloseDialog()
    }, [place])

    const getGpsCoords = () => {
        navigator.geolocation.getCurrentPosition(
            function (position) {
                console.log('Lokalizacja:', position)
            },
            function (error) {
                console.error('Błąd lokalizacji:', error)
            }
        )

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords
                    setLocation({ latitude, longitude })
                },
                err => {
                    setError(err.message)
                }
            )
        } else {
            setError('Geolocation is not supported by your browser.')
        }
    }

    const handleSearch = async () => {
        try {
            const response = await fetch(
                `https://address.reverse.geocoding.api.ongeo.pl/1.0/search/?api_key=${process.env.REACT_APP_ONEGEO_API_KEY}&maxresults=1&point=${location?.longitude},${location?.latitude}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Referer: 'https://qrqs.eu',
                        'User-Agent': navigator.userAgent,
                    },
                }
            )

            if (response.ok) {
                const data = await response.json()
                setPlace(data[0])
                setIsLoading(false)
            }
        } catch (error) {
            console.error('Error fetching data', error)
            setError(error)
        }
    }

    const handleClick = e => {
        e.stopPropagation()

        setIsLoading(true)
        getGpsCoords()
    }

    return (
        <IconButton
            color="primary"
            aria-label="geolocation"
            onClick={e => handleClick(e)}
            sx={{
                position: 'absolute',
                right: '16px',
                top: '16px',
                zIndex: '2',
            }}
        >
            {isLoading ? <CircularProgress size={20} /> : <GpsFixedIcon />}
        </IconButton>
    )
}

export default GeolocationTargeo
