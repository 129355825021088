import React, {useState, useCallback, useEffect} from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { environment } from '../../environment'
import CarsReservationsTabs from './CarsReservationsTabs'
import CarsReservationsBoxes from './CarsReservationsBoxes'
import CarsReservationsCarousel from './CarsReservationsCarousel'
import ErrorBox from '../../forms/RAC/ErrorBox'
import '../../styles/cars-reservations.css'

function CarsReservations(params) {
    const { executeRecaptcha } = useGoogleReCaptcha()

    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('carsReservations')
        console.log(params)
    }

    let type = (params.block[params.locale].hasOwnProperty('Type') ? params.block[params.locale]['Type'] : 'boxes');
    let TitleColor = params.block[params.locale].hasOwnProperty('TitleColor') ? 'c-'+params.block[params.locale]['TitleColor'] : 'c-color1';
    let ButtonType = params.block[params.locale].hasOwnProperty('ButtonType') ? params.block[params.locale]['ButtonType'] : 'color2';

    const [recaptchaToken, setRecaptchaToken] = useState() 
    const [errorMsg, setErrorMsg] = useState("") 
    
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available')
            return
        } 
        const token = await executeRecaptcha('yourAction') 
        setRecaptchaToken(token)
    }, [executeRecaptcha, setRecaptchaToken])
 
    useEffect(() => { 
        handleReCaptchaVerify()
    }, [handleReCaptchaVerify]) 

    return (
        <GoogleReCaptchaProvider reCaptchaKey={environment.REACT_APP_RECAPTCHA}>
        <section className={'cars-reservations '+type}>
            <div className="wrapper">
                <div className={'container mx-auto px-8 my-20'}>
                    {params.block[params.locale]['Title'] && <h2 className={TitleColor}>{params.block[params.locale]['Title']}</h2>}
                    {params.block[params.locale]['Subtitle'] && <h3 className='opacity-50'>{params.block[params.locale]['Subtitle']}</h3>}
                    {type === 'tabs' && <CarsReservationsTabs block={params.block} line={params.line} locale={params.locale} button={ButtonType} recaptchaToken={recaptchaToken} setErrorMsg={setErrorMsg}/>}
                    {(type === 'boxes' || type === '') && <CarsReservationsBoxes block={params.block} line={params.line} locale={params.locale} button={ButtonType} recaptchaToken={recaptchaToken} setErrorMsg={setErrorMsg} />}
                    {type === 'carousel' && <CarsReservationsCarousel block={params.block} line={params.line} locale={params.locale} button={ButtonType} recaptchaToken={recaptchaToken} setErrorMsg={setErrorMsg}/>}
                    {errorMsg && <ErrorBox error={errorMsg} sx={{ mt: 2, mb: 1 }} />}
                </div>
            </div>
        </section>
        </GoogleReCaptchaProvider>
    )
}

export default CarsReservations
