import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { string, object } from 'yup'
import { Alert, Box, InputAdornment, TextField } from '@mui/material'
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined'
import { environment } from '../../../environment'
import MuiCheckbox from '../../../components/MuiCheckbox'
import ChooseButton from '../../components/ChooseButton'
import useFetchLac from '../../hooks/useFetchLac'
import { API } from '../api'

import 'react-datepicker/dist/react-datepicker.css'
import '../../../styles/form.css'
import Spinner from '../../components/Spinner'
import ErrorBox from '../../../components/ErrorBox'

const FormPaymentTransfer = ({ handleHideButton, handleShowButton }) => {
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()
    const { data, loading, error, fetchData } = useFetchLac()

    const initialValues = {
        payment_transfer: false,
        payment_transfer_email: '',
    }

    const validationSchema = object().shape({
        payment_transfer_email: string().when('payment_transfer', {
            is: value => value === true,
            then: schema => schema.required(t('errors.schema.required')).email(t('errors.schema.invalid_email')),
            otherwise: schema => schema.optional(),
        }),
    })

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    const handleSubmit = async values => {
        let data = {
            payment_transfer_email: values.payment_transfer ? values.payment_transfer_email : {},
        }

        await fetchData(`${API['TRANSFER_PAYMENT']}&pid=${id}`, 'POST', data, locale)
    }

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return (
        <>
            {data?.variables?.payment_transfer_email ? (
                <Alert severity="success">
                    {t('LAC.payment_transfer.thank_you_payment_transfered')}{' '}
                    <b>{data?.variables?.payment_transfer_email}</b>
                </Alert>
            ) : (
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                        <Form>
                            <MuiCheckbox
                                name="payment_transfer"
                                label={t('LAC.payment_transfer.payment_transfer_checkbox_label')}
                                errors={errors['payment_transfer']}
                                touched={touched['payment_transfer']}
                                onClick={() => {
                                    values.payment_transfer ? handleHideButton() : handleShowButton()
                                }}
                            />

                            {values.payment_transfer && (
                                <Box mb={2}>
                                    <Field
                                        required
                                        as={TextField}
                                        name="payment_transfer_email"
                                        error={touched.payment_transfer_email && Boolean(errors.payment_transfer_email)}
                                        fullWidth
                                        placeholder="E-mail osoby do której ma być przekazana płatność"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AlternateEmailOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{ mb: 1 }}
                                    />
                                    <ErrorMessage name="payment_transfer_email" component="div" className="error" />
                                </Box>
                            )}

                            {values.payment_transfer &&
                                values.payment_transfer_email &&
                                (isSubmitting ? (
                                    <Spinner />
                                ) : (
                                    <ChooseButton disabled={isSubmitting} type="submit">
                                        {t('LAC.payment_transfer.btn_submit')}
                                    </ChooseButton>
                                ))}
                        </Form>
                    )}
                </Formik>
            )}
        </>
    )
}

export default FormPaymentTransfer
