import React from 'react'
import { ErrorMessage, useField } from 'formik'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// import { TimeClock } from '@mui/x-date-pickers/TimeClock'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import 'dayjs/locale/pl'

const MuiTimepicker = ({ name, ...props }) => {
    const [field, meta, helpers] = useField(name)

    // const { value } = meta
    const { setValue } = helpers

    return (
        <div className="timepicker">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
                <TimePicker
                    ampm={false}
                    {...field}
                    format="HH:mm"
                    minutesStep={15}
                    value={props.value}
                    onChange={time => setValue(time)}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                        },
                    }}
                    slots={{
                        openPickerIcon: AccessTimeOutlinedIcon,
                    }}
                    {...props}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                    }}
                />
            </LocalizationProvider>
            <ErrorMessage name={name} component="div" className="error" />
        </div>
    )
}
export default MuiTimepicker
