import React from 'react'
import { environment } from './environment'
import Container from './components/Container'
import Image from './components/Image.js'
import { useTranslation } from 'react-i18next'
import Links from './components/Links.js'

function Footer(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Footer')
        console.log(params)
    }
    const lang =
        window.location.pathname.split('/')[1].length > 2 || window.location.pathname.split('/')[1].length === 0
            ? 'pl'
            : window.location.pathname.split('/')[1]
    const { t, i18n } = useTranslation()

    return (
        <section className="footer">
            <Container>
                <div className="py-10">
                    <div className="flex mb-7 mx-auto justify-center flex-wrap">
                        {/* TO DO: href */}
                        <div className="left-box flex items-center gap-[40px] md:gap-[80px] flex-wrap">
                            <div className="block mx-auto w-[160px] h-auto">
                            <Links
                                withChild={true}
                                class={'link c-color1'}
                                url={environment.euFunds[lang]}
                                line={params.line}
                            >
                                <Image
                                    src={environment.logoFunduszeEu[lang]}
                                    alt={t('footer.nav.fund')}
                                    width="160"
                                    height="70"
                                />
                                </Links>
                            </div>
                            <div className="block mx-auto w-[160px]">
                                <Image
                                    src={environment.logoFunduszePolska}
                                    alt={t('footer.nav.poland')}
                                    width="160"
                                    height="38"
                                />
                            </div>
                            <Links
                                withChild={true}
                                class={'link c-color1'}
                                url={environment.euFunds[lang]}
                                line={params.line}
                            >
                                <Image
                                    src={environment.logoUniaFundusze}
                                    alt={t('footer.nav.europe')}
                                    width="160"
                                    height="36"
                                />
                            </Links>
                            <hr className="b-line-hr"/>
                            <div className="block mx-auto w-[150px] h-auto">
                                <a
                                    href="https://www.gov.pl/web/ncbr"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="block mx-auto w-[160px]"
                                >
                                    <Image
                                        src={environment.logoNcbr}
                                        alt={t('footer.nav.national')}
                                        width="160"
                                        height="40"
                                    />
                                </a>
                            </div>
                            <div className="block mx-auto h-auto d-flex ai-center">
                                <Image
                                    src={environment.logoKLJ[lang]}
                                    alt={t('footer.nav.consumer')}
                                    width="150"
                                    height="150"
                                />
                                <Image
                                    src={environment.logoEEA}
                                    alt={t('footer.nav.logoEEA')}
                                    width="130"
                                    height="130"
                                    className="ml-5"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col lg:flex-row justify-center lg:items-center">
                        <div className="flex flex-col lg:flex-row lg:items-center gap-8 mb-2 mr-8">
                            <p className="text-xs text-gray-500">{t('footer.nav.copyright')}</p>
                            <p className="text-xs text-gray-500">
                                {t('footer.nav.part_of')}
                                <a href="https://holding1.pl/" target="_blank" rel="noreferrer" title="Holding">
                                    <Image
                                        src={environment.logoHolding35}
                                        alt="Holding"
                                        className="inline-block mb-1 ml-3 max-w-12"
                                        width="48"
                                        height="48"
                                    />
                                </a>
                            </p>
                            <p className="text-xs text-gray-500">
                                {t('footer.nav.member_of')}
                                <a href="https://pzwlp.pl/" target="_blank" rel="noreferrer" title="PZWLP">
                                    <Image
                                        src={environment.logoPZWLP}
                                        alt={t('footer.nav.rental')}
                                        className="inline-block mb-1 ml-3"
                                        width="115"
                                        height="33"
                                    />
                                </a>
                            </p>
                        </div>

                        <p className="text-xs text-gray-500 mb-8">
                            Powered by{' '}
                            <a
                                href="https://qrqs.eu/"
                                target="_blank"
                                rel="noreferrer"
                                title="QUERCUS"
                                className="bold"
                            >
                                QRQS.eu
                            </a>
                        </p>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Footer
