import React, { useState } from 'react'
import { Box, Checkbox } from '@mui/material'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import FormContact from './PopupContactForms/FormContact'
import LabelText from './PopupContactForms/LabelText'

const PopupContact = ({ showAlert, setShowAlert, dialogTitle, idOffer, locale, noPopup = false }) => {
    const { t, i18n } = useTranslation()
    const [combinedLink, setCombinedLink] = useState('mailto:wynajem@express.pl')
    const [isDisabled, setIsDisabled] = useState(true)

    const validationSchema1 = Yup.object().shape({
        userFirstName: Yup.string()
            .required(t('LAC.client_data.validation.name_1'))
            .min(2, t('LAC.client_data.validation.name_2'))
            .max(80, t('LAC.client_data.validation.name_3'))
            .matches(/^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, t('LAC.client_data.validation.name_2')),

        userLastName: Yup.string()
            .required(t('LAC.client_data.validation.surname_1'))
            .min(2, t('LAC.client_data.validation.surname_2'))
            .max(80, t('LAC.client_data.validation.surname_3'))
            .matches(/^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, t('LAC.client_data.validation.surname_2')),
        userEmail: Yup.string()
            .email(t('LAC.client_data.validation.email'))
            .required(t('LAC.client_data.validation.email')),
        userPhoneNumber: Yup.string()
            .matches(/^(\+)?[\d- ()]+$/, t('LAC.client_data.validation.phone_1'))
            .required(t('LAC.client_data.validation.phone_2')),
    })

    const [userData, setUserData] = useState({
        userFirstName: '',
        userLastName: '',
        userEmail: '',
        userPhoneNumber: '',
        userMessage: '',
        userCompany: ''
    })

    const initialValues = {
        userFirstName: userData.userFirstName || '',
        userLastName: userData.userLastName || '',
        userEmail: userData.userEmail || '',
        userPhoneNumber: userData.userPhoneNumber || '',
        userMessage: userData.userMessage || '',
        userCompany: userData.userCompany || ''
    }

    function resetData() {
        handleUserDataChange('userFirstName', '')
        handleUserDataChange('userLastName', '')
        handleUserDataChange('userEmail', '')
        handleUserDataChange('userPhoneNumber', '')
        handleUserDataChange('userMessage', '')
        handleUserDataChange('userCompany', '')
    }
    const handleUserDataChange = (name, value, id = null) => {
        setUserData(prevState => {
            if (id) {
                if (value) {
                    return {
                        ...prevState,
                        [name]: id,
                    }
                } else {
                    return {
                        ...prevState,
                        [name]: '',
                    }
                }
            } else {
                return {
                    ...prevState,
                    [name]: value,
                }
            }
        })
        
        let mailto = 'mailto:wynajem@express.pl',
            mess = userData.userMessage !== '' ? userData.userMessage : '',
            number = userData.userPhoneNumber !== '' ? userData.userPhoneNumber : '',
            fname = userData.userFirstName !== '' ? userData.userFirstName : '',
            surname = userData.userLastName !== '' ? userData.userLastName : '',
            company = userData.userCompany !== '' ? userData.userCompany : '';

        let names = (fname || surname !== '' ? '%0A%0A' + fname + '%20' + surname : ''),
            firm = (company !== '' ? '%0A' + company : ''),
            phone = (number !== '' ? '%0A' + number : '');

        let message = mess + names + firm + phone;
        setCombinedLink(mailto + '?body=' + message)
    }

    return (
        <>
            <h2 className="text-3xl c-color3 text-left mb-12">{dialogTitle}</h2>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema1}
            >
                {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                    <Form>
                        <FormContact
                            valuealues={values}
                            userData={userData}
                            touched={touched}
                            errors={errors}
                            handleUserDataChange={handleUserDataChange}
                            noPopup={noPopup}
                        />
                        <div className='flex overflow-hidden mt-4 -ml-2.5'>
                            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                <Checkbox 
                                    required
                                    onChange={(e) => {setIsDisabled(!e.target.checked)}}
                                />
                            </Box>
                            <Box>
                                <LabelText />
                            </Box>
                        </div>
                        <div className="flex justify-center mt-6">
                            <a  className={'btn-link'+ (isDisabled ? ' disabled' : '')}
                                disabled={isDisabled}
                                href={combinedLink}
                                onClick={e => {
                                    if (isDisabled) {
                                        e.preventDefault()
                                    }
                                    else {
                                        resetData()
                                    }
                                }}
                                target="_top"
                            >
                                {t("LAC.popup_contact.send_button")}
                            </a>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default PopupContact