import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Box, Container } from '@mui/material'

import { API, FORM_KEY } from '../api'
import { environment } from '../../environment'
import BackButtonInForm from '../components/BackButtonInForm'
import Spinner from '../components/Spinner'
import Stepper from '../components/Stepper'
import useFetch from '../hooks/useFetch'

import ErrorBox from './ErrorBox'
import Form4 from './Step4/Form4'
import ReservationDetials from './ReservationDetails'

import '../../styles/form.css'

const FormStep4 = () => {
    const { t, i18n } = useTranslation()
    const { data, loading, error, fetchData } = useFetch()
    const { id, locale } = useParams()

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        const url = `${API[`GET_DATA`]}?pid=${id}`
        fetchData(url, 'GET')
    }, [id])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('PROCESS DATA:', data)
    }, [data])

    const handleClickEditStep3 = () => {
        fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=${FORM_KEY['step3']}`, 'GET')
    } 
    
    return (
        <Container>
            <Box py={1}>
                <BackButtonInForm handleClick={handleClickEditStep3}>{t('btn.back')}</BackButtonInForm>
                <Stepper activeStep={4} />
                <ReservationDetials activeStep={4} />
                {error && <ErrorBox error={error} />}
                <Box py={4}>{loading ? <Spinner /> : <Form4 processData={data} processDataIsLoading={loading} />}</Box> 
            </Box>
        </Container>
    )
}

export default FormStep4
