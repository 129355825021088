import { useEffect, useState } from 'react';

const useHostname = () => {
  const [origin, setOrigin] = useState('');

  useEffect(() => {
    const getHostname = () => {
      const { origin } = window.location;
      if(origin.includes('localhost') || origin.includes('dev-cms')) {
        setOrigin('https://dev-express.qrqs.eu');
      } else if (origin.includes('test-cms')) {
        setOrigin('https://test-express.qrqs.eu');
      } else {
        setOrigin('');
      }
    };

    getHostname();
  }, []); 
  return origin;
};

export default useHostname;