import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, List, ListItem, Typography } from '@mui/material'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import groupOpeningHours from '../../forms/hooks/useGroupedOpeningHours'
import { environment } from '../../environment'
import MapComponent from '../../forms/RAC/Step1/MapComponent'
import LinkText from '../../forms/components/LinkText'
import IconSearchResult from '../../forms/components/IconSearchResult'

const LocationDetailsLac = ({ place }) => {
    const { t } = useTranslation()
    const locale = window.location.href.includes('/en/') ? 'en' : 'pl'

    const [groupedOpeningHours, setGroupedOpeningHours] = useState(null)

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('PLACE ', place)

        const openingHours = groupOpeningHours(place.openingHours)

        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('openingHours ', openingHours)

        setGroupedOpeningHours(openingHours[0])
    }, [place])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('groupedOpeningHours ', groupedOpeningHours)
    }, [groupedOpeningHours])
    
    let gpsCoords = {
        'lon': place.gpsLon ? place.gpsLon : null,
        'lat': place.gpsLat ? place.gpsLat : null
    }
    return (
        <Box>
            <div className="flex gap-2 justify-center items-center">
                <IconSearchResult icon={place.type} my={1} />
                <span>{place.name}</span>
            </div>
            <h4 className="text-[#505154] font-bold mt-4 mb-4 text-center text-[16px]">{t('RAC.step_one.pickup')}</h4>

            {gpsCoords.lon !== null && (
                <Box
                    sx={{
                        height: '450px',
                        // maxWidth: "800px",
                        margin: "0 auto 20px"
                    }}
                >
                    <MapComponent gpsCoords={gpsCoords} id={'Two'}/>
                    <a
                        href="https://mapa.targeo.pl/"
                        id="TargeoMapContainerTwo"
                        style={{ position: 'relative', width: '100%', height: '450px' }}
                    >
                        Targeo
                    </a>
                </Box>
            )}

            <div className="max-w-[600px] mx-auto">
                {place?.priceIOH !== null && (
                    <Box mt={3} mb={2}>
                        <Typography fontWeight="bold" textAlign="center" my={2}>
                            {t('RAC.step_one.price')}
                        </Typography>

                        <Box display={'flex'} alignItems="center" gap={2} mt={1}>
                            <Box width="140px">
                                <Typography fontWeight="bold">{place.pickupPriceIOH} zł</Typography>
                            </Box>
                            <Box>
                                {/* {groupedOpeningHours && groupedOpeningHours.days.length > 0 ? (
                                    <>
                                        <Typography>
                                            {t('RAC.step_one.days_all.0')} -{' '}
                                            {groupedOpeningHours.days.length === 1 && t('RAC.step_one.days_all.0')}
                                            {groupedOpeningHours.days.length === 2 && t('RAC.step_one.days_all.1')}
                                            {groupedOpeningHours.days.length === 3 && t('RAC.step_one.days_all.2')}
                                            {groupedOpeningHours.days.length === 4 && t('RAC.step_one.days_all.3')}
                                            {groupedOpeningHours.days.length === 5 && t('RAC.step_one.days_all.4')}
                                            {groupedOpeningHours.days.length === 6 && t('RAC.step_one.days_all.5')}
                                            {groupedOpeningHours.days.length === 7 && t('RAC.step_one.days_all.6')}
                                        </Typography>
                                        <Typography>
                                            {groupedOpeningHours.timeFrom.substring(0, 5)} -
                                            {groupedOpeningHours.timeTo.substring(0, 5)}
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography>{place.openingHours.map(el => el.day)}</Typography>
                                )} */}
                                <Typography>
                                    Poniedziałek - Piątek
                                </Typography>
                                <Typography>
                                    08:00 - 18:00
                                </Typography>
                            </Box>
                        </Box>
                        <Divider />

                        <Box display={'flex'} alignItems="center" gap={2} my={1}>
                            <Box width="140px">
                                <Typography fontWeight="bold">{place?.pickupPriceOOH || 0} zł netto</Typography>
                                <Typography fontWeight="bold">{place?.pickupPriceOOHGross?.toFixed(0) || 0} zł brutto</Typography>
                            </Box>
                            <Box>
                                <Typography>{t('RAC.step_one.ooh')}</Typography>
                            </Box>
                        </Box>
                        <Divider />
                    </Box>
                )}

                {(place?.phoneNumber || place?.email) && (
                    <>
                        <Typography fontWeight="bold" textAlign="center">
                            {t('RAC.step_one.contact')}
                        </Typography>
                        <List>
                            {place?.phoneNumber && (
                                <ListItem sx={{ px: 0 }}>
                                    <PhoneOutlinedIcon color="primary" sx={{ mr: 1 }} />
                                    <LinkText href={`tel:${place?.phoneNumber}`}>{place?.phoneNumber}</LinkText>
                                </ListItem>
                            )}
                            {place?.email && (
                                <ListItem sx={{ px: 0 }}>
                                    <EmailOutlinedIcon color="primary" sx={{ mr: 1 }} />
                                    <LinkText href={`mailto:${place?.email}`}>{place?.email}</LinkText>
                                </ListItem>
                            )}
                        </List>
                    </>
                )}

                {place?.description && (
                    <>
                        <Divider />
                        <Typography fontWeight="bold" mt={2} mb={1} textAlign="center">
                            {t('RAC.step_one.comments')}
                        </Typography>
                        <Typography whiteSpace="pre-wrap">
                            {locale === 'pl'
                                ? place?.description.replace(/;/g, '\n\n')
                                : place?.descriptionEn.replace(/;/g, '\n\n')}
                        </Typography>
                    </>
                )}
            </div>
        </Box>
    )
}
export default LocationDetailsLac
