import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { environment } from '../../../environment'
import Spinner from '../../components/Spinner'
import useFetchLac from '../../hooks/useFetchLac'
import { API } from '../api'
import ErrorBox from '../../../components/ErrorBox'
import FormOfferOptions from './FormOfferOptions'

const GetProcessData = ({ locations, packages, services }) => {
    const { t, i18n } = useTranslation()
    const { data, loading, error, fetchData } = useFetchLac()
    const { id, locale } = useParams()

    const [processData, setProcessData] = useState([])

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        if (id) {
            const url = `${API[`GET_PROCESS_DATA`]}/?pid=${id}`
            fetchData(url, 'GET')
        }
    }, [id])

    useEffect(() => {
        setProcessData(data)
    }, [data])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('LAC GET_PROCESS_DATA:', processData)
    }, [data])

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return !loading ? (
        <FormOfferOptions
            locations={locations}
            packages={packages}
            services={services}
            processData={processData?.variables}
        />
    ) : (
        <Spinner />
    )
}

export default GetProcessData
