import React from 'react'
import Spinner from './Spinner'

const Loader = () => {

    return (
        <div className="absolute inset-0 flex justify-center">
            <Spinner />
        </div>
    )
}

export default Loader
