import React from 'react'
import { environment } from '../../environment'
import OffersListLAC from './OffersListLAC'
import OffersListDays from './OffersDays'
import OffersListNews from './OffersNews'
import { FormProvider } from '../../forms/LAC/FormContext'
import '../../styles/offers-list.css'

function OffersList(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('offersList')
        console.log(params)
    }
    let ButtonType = params.block[params.locale].hasOwnProperty('ButtonType') ? params.block[params.locale]['ButtonType'] : 'color1';

    return (
        <section className={'offersList offers-list-'+params.block[params.locale]['ListMode']}>
            <div className="wrapper">
                <div className={'container mx-auto px-8 my-20'}>
                    {(params.block[params.locale]['ListType'] === 'lac' && params.block[params.locale]['ListMode'] === 'filters') &&
                        <FormProvider params={params}>
                            <OffersListLAC params={params} button={ButtonType} />
                        </FormProvider>
                    }
                    {(params.block[params.locale]['ListType'] === 'lac' && params.block[params.locale]['ListMode'] === 'days') &&
                        <>
                            {params.block[params.locale]['Title'] && <h2 className={'c-'+params.block[params.locale]['TitleColor']}>{params.block[params.locale]['Title']}</h2>}
                            {params.block[params.locale]['SubTitle'] && <h3 className={'opacity-50'}>{params.block[params.locale]['SubTitle']}</h3>}
                            <FormProvider params={params}>
                                <OffersListDays params={params} button={ButtonType} />
                            </FormProvider>
                        </>
                    }
                    {(params.block[params.locale]['ListType'] === 'lac' && params.block[params.locale]['ListMode'] === 'news') &&
                        <>
                            {params.block[params.locale]['Title'] && <h2 className={'c-'+params.block[params.locale]['TitleColor']}>{params.block[params.locale]['Title']}</h2>}
                            {params.block[params.locale]['SubTitle'] && <h3 className={'opacity-50'}>{params.block[params.locale]['SubTitle']}</h3>}
                            <FormProvider params={params}>
                                <OffersListNews params={params} button={ButtonType} />
                            </FormProvider>
                        </>
                    }
                    {((params.block[params.locale]['ListType'] === 'ltr' || params.block[params.locale]['ListType'] === 'mtr') && params.block[params.locale]['ListMode'] === 'filters') &&
                        <FormProvider params={params}>
                            <OffersListLAC params={params} button={ButtonType} />
                        </FormProvider>
                    }
                </div>
            </div>
        </section>
    )
}

export default OffersList