import React from 'react'
import { environment } from '../../environment'
import Image from '../../components/Image'
import { t } from 'i18next'

export default function ContactBox(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('ContactBox')
        console.log(params)
    }

    return (
        <section className={'contact-box'}>
            <div className="wrapper">
                <div className={'container mx-auto px-8 my-20'}>
                    {params.block[params.locale]['Title'] && (
                        <h3 className="opacity-50">{params.block[params.locale]['Title']}</h3>
                    )}
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-8'>
                      {params.block?.[params.locale]?.Item && 
                        params.block[params.locale].Item.map((item, index) => (
                          <div key={index} className='flex flex-col items-center gap-4'>
                            {item?.Image?.link && <div className='max-w-[800px]'><Image className=" rounded-3xl" src={item.Image.link} alt={item.Title} /></div>}
                            {item.Name && <h4 className='font-semibold'>{item.Name}</h4>}
                            {item.Phone && <div>{t('portlets.contact_box.phone')} <a href={`tel:${item.Phone.replaceAll(' ', '')}`} className='c-color3'>{item.Phone}</a></div>}
                            {item.Email && <div>e-mail: <a href={`mailto:${item.Email}`} className='c-color3'>{item.Email}</a></div>}
                          </div>
                        ))
                      }
                    </div>
                </div>
            </div>
        </section>
    )
}
