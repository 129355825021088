export const smallBagsOptions = [
    {
        label: '1 mała walizka',
        value: 1,
    },
    {
        label: '2 małe walizki',
        value: 2,
    },
    {
        label: '3 małe walizki',
        value: 3,
    },
    {
        label: '4 małe walizki',
        value: 4,
    },
    {
        label: '5 małych walizek',
        value: 5,
    },
    {
        label: '6 małych walizek',
        value: 6,
    },
    {
        label: '7 małych walizek',
        value: 7,
    },
    {
        label: '8 małych walizek',
        value: 8,
    },
]

export const smallBagsOptionsEn = [
    {
        label: '1 small',
        value: 1,
    },
    {
        label: '2 small',
        value: 2,
    },
    {
        label: '3 small',
        value: 3,
    },
    {
        label: '4 small',
        value: 4,
    },
    {
        label: '5 small',
        value: 5,
    },
    {
        label: '6 small',
        value: 6,
    },
    {
        label: '7 small',
        value: 7,
    },
    {
        label: '8 small',
        value: 8,
    },
]
