import React, { useState, useRef } from 'react'
import { environment } from '../../environment'
import CarsReservationsTabsItem from './CarsReservationsTabsItem'

function CarsReservationsTabs(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('CarsReservationsTabs')
        console.log(params)
    }
    const [index, setIndex] = React.useState(0);
    let block = params.block[params.locale]['Item'];
    return (
        <section className={'cars-res-tabs'}>
            <div className="wrapper">
                <>
                    <div className='tabs-labels d-flex ai-center jc-center'>
                        {params.block[params.locale].hasOwnProperty('Item') &&
                            Object.keys(block).map((item, i) => (
                                <div
                                    onClick={(e) =>{params.setErrorMsg(''); setIndex(i)}}
                                    key={'CarsReservationsTabsLabel-' + i}
                                    className={'btn-link secondary'+ (index === i ? ' active' : '')}
                                >
                                    {block[item].hasOwnProperty('Title') ? block[item]['Title'] : 'Tytuł'}
                                </div>
                            ))
                        }
                    </div>
                    <div>
                        {params.block[params.locale].hasOwnProperty('Item') &&
                            Object.keys(block).map((item, i) => (
                                <div 
                                    className={index === i ? '' : 'hidden'}
                                    key={'CarsReservationsTabsItem-' + i}
                                >
                                    <CarsReservationsTabsItem
                                        block={block[item]}
                                        line={params.line}
                                        button={params.button}
                                        locale={params.locale}
                                        recaptchaToken={params.recaptchaToken}
                                        setErrorMsg={params.setErrorMsg}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </>
            </div>
        </section>
    )
}

export default CarsReservationsTabs
