import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { API, FORM_KEY } from '../api'

const useFetchLac = () => {
    const { t } = useTranslation()
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const navigate = useNavigate()

    const { locale } = useParams()

    const fetchData = async (url, method = 'GET', body = null, paramsLocale) => {
        setLoading(true)
        try {
            let options = { method: method }

            if (method === 'POST')
                options = {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json',
                        // ...headers
                    },
                    body: JSON.stringify(body),
                }

            const response = await fetch(url, options)

            if (!response.ok) {
                throw new Error(t('errors.try_again'))
            }

            const responseData = await response.json()
            setData(responseData)
            setError(null)

            if (responseData.status === 'error') {
                throw new Error(t('errors.try_again'))
            }

            if (responseData.form_key) {
                if (responseData.form_key === 'krd_negative_verification' || responseData.form_key === 'krd_reject')
                    navigate(`/${locale || 'pl'}/krd_negative_verification`) 
            }
            
            if (responseData.form_key && responseData.variables.pid) {
                responseData.form_key === 'offer_presentation' &&
                    navigate(
                        locale === 'pl'
                            ? `/pl/auto-dla-firmy/wynajem-elastyczny/krok-3/${responseData.variables.pid}`
                            : `/en/car-for-company/flexible-rental/step-3/${responseData.variables.pid}`
                    )

                responseData.form_key === 'client_data' &&
                    navigate(
                        locale === 'pl'
                            ? `/pl/auto-dla-firmy/wynajem-elastyczny/krok-4/${responseData.variables.pid}`
                            : `/en/car-for-company/flexible-rental/step-4/${responseData.variables.pid}`
                    )

                responseData.form_key === 'reservation_payment' &&
                    navigate(
                        locale === 'pl'
                            ? `/pl/auto-dla-firmy/wynajem-elastyczny/krok-5/${responseData.variables.pid}`
                            : `/en/car-for-company/flexible-rental/step-5/${responseData.variables.pid}`
                    )

                responseData.form_key === 'reservation_summary' &&
                    navigate(
                        locale === 'pl'
                            ? `/pl/auto-dla-firmy/wynajem-elastyczny/krok-6/${responseData.variables.pid}`
                            : `/en/car-for-company/flexible-rental/step-6/${responseData.variables.pid}`
                    ) 
            }
        } catch (error) {
            setError(t('errors.try_again'))
            // setError(error.message || 'Something went wrong')
        } finally {
            setLoading(false)
        }
    }

    return { data, loading, error, fetchData }
}

export default useFetchLac
