import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Typography,
    Autocomplete,
    TextField,
    Button,
    Paper,
} from '@mui/material'
import DialogWindow from '../../components/DialogWindow'
import { environment } from '../../../environment'
import FilterClass from './FilterCars'
import FilterCarsByEquipment from './FilterCarsByEquipment'
import CarClasses from './CarClasses'
import ChooseButton from '../../components/ChooseButton'
import { largeBagsOptions, largeBagsOptionsEn } from './const/largeBagsOptions'
import { smallBagsOptions, smallBagsOptionsEn } from './const/smallBagsOptions'
import { API } from '../../api'
import { useParams } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import Spinner from '../../components/Spinner'

const OfferPresentation = ({ offer }) => {
    const { t } = useTranslation()
    const { locale } = useParams()

    const [showDialogFilter, setShowDialogFilter] = useState(false)

    const [fuelTypes, setFuelTypes] = useState({})
    const [filterFuel, setFilterFuel] = useState({})
    const anyFuelFilterChecked = Object.values(filterFuel).some(value => value)

    const [transmissionTypes, setTransmissionTypes] = useState({})
    const [filterTransmission, setFilterTransmission] = useState({})
    const anyTransmissionFilterChecked = Object.values(filterTransmission).some(value => value)
    const [loadingDictFuelTypes, setLoadingDictFuelTypes] = useState(true)
    const [loadingDictTransmissionTypes, setLoadingDictTransmissionTypes] = useState(true)

    const [filterSeats, setFilterSeats] = useState({
        two: false,
        three: false,
        five: false,
        seven: false,
        nine: false,
    })
    const anySeatsFilterChecked = Object.values(filterSeats).some(value => value)

    const [filterSmallBags, setFilterSmallBags] = useState(smallBagsOptions[0].value)
    const [filterSmallBagsInputValue, setFilterSmallBagsInputValue] = useState([])

    const [filterLargeBags, setFilterLargeBags] = useState(largeBagsOptions[0].value)
    const [filterLargeBagsInputValue, setFilterLargeBagsInputValue] = useState([])

    const [filterGroupClasess, setFilterGroupClasses] = useState([])

    const getDictFuelTypes = async () => {
        try {
            const response = await fetch(API['GET_DICT_FUEL_TYPES']);
            if (!response.ok) {
              throw new Error(`${response.status}`);
            }
            const data = await response.json();
            setFuelTypes(data[locale])
          } catch (err) {
            console.error(err)
          } finally {
            setLoadingDictFuelTypes(false)
          }
    }    
    
    const getDictTransmissionTypes = async () => {
        try {
            const response = await fetch(API['GET_DICT_TRANSMISSION_TYPES']);
            if (!response.ok) {
              throw new Error(`${response.status}`);
            }
            const data = await response.json();
            setTransmissionTypes(data[locale])
          } catch (err) {
            console.error(err)
          } finally {
            setLoadingDictTransmissionTypes(false)
          }
    }
    
    useEffect(() => {
        getDictFuelTypes()
    
        getDictTransmissionTypes()
    
        locale === 'pl'
            ? setFilterSmallBagsInputValue(smallBagsOptions[0].label)
            : setFilterSmallBagsInputValue(smallBagsOptionsEn[0].label)
        locale === 'pl'
            ? setFilterLargeBagsInputValue(largeBagsOptions[0].label)
            : setFilterLargeBagsInputValue(largeBagsOptionsEn[0].label)
    }, [locale])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('Offer ', offer)
    }, [offer])

    useEffect(() => {
        const initialFilters = {}
        Object.keys(fuelTypes).forEach(key => {
            initialFilters[key] = false
        })
        setFilterFuel(initialFilters)
    }, [fuelTypes, locale])

    useEffect(() => {
        const initialFilters = {}
        Object.keys(transmissionTypes).forEach(key => {
            initialFilters[key] = false
        })
        setFilterTransmission(initialFilters)
    }, [transmissionTypes, locale])

    const handleCheckboxTransmission = event => {
        const { name, checked } = event.target
        setFilterTransmission(prevFilters => ({
            ...prevFilters,
            [name]: checked,
        }))
    }

    const handleCheckboxSeats = event => {
        const { name, checked } = event.target
        setFilterSeats(prevFilters => ({
            ...prevFilters,
            [name]: checked,
        }))
    }

    const handleCheckboxFuel = event => {
        const { name, checked } = event.target
        setFilterFuel(prevFilters => ({
            ...prevFilters,
            [name]: checked,
        }))
    }

    const handleSmallBagsValue = (event, newValue) => {
        setFilterSmallBags(newValue.value)
    }
    const handleSmallBagsInputValue = (event, newValue) => {
        setFilterSmallBagsInputValue(newValue)
    }

    const handleLargeBagsValue = (event, newValue) => {
        setFilterLargeBags(newValue.value)
    }
    const handleLargeBagsInputValue = (event, newValue) => {
        setFilterLargeBagsInputValue(newValue)
    }

    const handleShowDialogWindow = () => {
        setShowDialogFilter(true)
    }

    const handleCloseDialogWindow = () => {
        setShowDialogFilter(false)
    }

    const onFilterSelect = selectedClasses => {
        setFilterGroupClasses(selectedClasses)
    }

    const handleResetEquipmentFilters = () => {
        setFilterTransmission({})
        setFilterSeats({})
        setFilterFuel({})

        setFilterSmallBags(smallBagsOptions[0].value)
        setFilterSmallBagsInputValue(locale === 'pl' ? smallBagsOptions[0].label : smallBagsOptionsEn[0].label)

        setFilterLargeBags(largeBagsOptions[0].value)
        setFilterLargeBagsInputValue(locale === 'pl' ? largeBagsOptions[0].label : largeBagsOptionsEn[0].label)

        const initialFilters = {}
        Object.keys(transmissionTypes).forEach(key => {
            initialFilters[key] = false
        })
        setFilterTransmission(initialFilters)
    }

    return (
        <Box pb={4}>
            <FilterCarsByEquipment handleShowDialogWindow={handleShowDialogWindow} />

            <FilterClass onFilterSelect={onFilterSelect} />

            {!loadingDictFuelTypes && !loadingDictTransmissionTypes ? <CarClasses
                offer={offer}
                anyFuelFilterChecked={anyFuelFilterChecked}
                anyTransmissionFilterChecked={anyTransmissionFilterChecked}
                anySeatsFilterChecked={anySeatsFilterChecked}
                filters={{
                    filterTransmission,
                    filterSeats,
                    filterFuel,
                    filterSmallBags,
                    filterLargeBags,
                    filterGroupClasess,
                }}
                fuelTypes={fuelTypes}
                transmissionTypes={transmissionTypes}
            /> : <Spinner />}

            {showDialogFilter && (
                <DialogWindow
                    dialogTitle={'title'}
                    isOpen={showDialogFilter}
                    setIsOpen={setShowDialogFilter}
                    backButtonText={t('RAC.step_two.choose_options')}
                >

                    <BackButton closeIcon handleClick={handleCloseDialogWindow} />  

                    <Box>
                        <Typography fontWeight="bold" mb={1}>
                            {t('RAC.step_two.car_options.gearbox_type')}
                        </Typography>

                        <FormGroup>
                            <FormGroup>
                                {transmissionTypes &&
                                    Object.entries(filterTransmission).map(([transmissionType, isChecked]) => (
                                        <FormControlLabel
                                            key={transmissionType}
                                            control={
                                                <Checkbox
                                                    name={transmissionType}
                                                    checked={isChecked || false}
                                                    onChange={e => handleCheckboxTransmission(e)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label={transmissionTypes[transmissionType]}
                                        />
                                    ))}
                            </FormGroup>
                        </FormGroup>
                        <Divider sx={{ my: 2 }} />

                        <Typography fontWeight="bold" mb={1}>
                            {t('RAC.step_two.car_options.seats')}
                        </Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="two"
                                        checked={filterSeats.two}
                                        onChange={handleCheckboxSeats}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="2"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="three"
                                        checked={filterSeats.three}
                                        onChange={handleCheckboxSeats}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="3"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="five"
                                        checked={filterSeats.five}
                                        onChange={handleCheckboxSeats}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="5"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="seven"
                                        checked={filterSeats.seven}
                                        onChange={handleCheckboxSeats}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="7"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="nine"
                                        checked={filterSeats.nine}
                                        onChange={handleCheckboxSeats}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="9"
                            />
                        </FormGroup>
                        <Divider sx={{ my: 2 }} />

                        {/* <Typography fontWeight="bold" mb={1}>
                            {t('RAC.step_two.car_options.fuel')}
                        </Typography> 
                        <FormGroup>
                            {fuelTypes &&
                                Object.entries(filterFuel).map(([fuelType, isChecked]) => (
                                    <FormControlLabel
                                        key={fuelType}
                                        control={
                                            <Checkbox
                                                name={fuelType}
                                                checked={isChecked || false}
                                                onChange={e => handleCheckboxFuel(e)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label={fuelType}
                                    />
                                ))}
                        </FormGroup> 
                        <Divider sx={{ my: 2 }} /> */}

                        <Typography fontWeight="bold" mb={1}>
                            {t('RAC.step_two.car_options.luggage')}
                        </Typography>
                        <Autocomplete
                            id="smallBags"
                            disablePortal
                            disableClearable
                            freeSolo={false}
                            autoComplete={false}
                            defaultValue={filterSmallBags.value}
                            value={filterSmallBags.value}
                            onChange={handleSmallBagsValue}
                            inputValue={filterSmallBagsInputValue.value}
                            onInputChange={handleSmallBagsInputValue}
                            options={locale === 'pl' ? smallBagsOptions : smallBagsOptionsEn}
                            sx={{ width: 300 }}
                            PaperComponent={props => <Paper {...props} />}
                            renderInput={params => (
                                <TextField
                                    variant="standard"
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& .MuiInputLabel-shrink': { opacity: 0, transition: 'all 0.2s ease-in' },
                                    }}
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        readOnly: true,
                                        inputMode: 'none',
                                    }}
                                    label={t('RAC.step_two.car_options.small_bags')}
                                />
                            )}
                        />
                        <Autocomplete
                            id="largeBags"
                            disablePortal
                            disableClearable
                            freeSolo={false}
                            autoComplete={false}
                            defaultValue={filterLargeBags.value}
                            value={filterLargeBags.value}
                            onChange={handleLargeBagsValue}
                            inputValue={filterLargeBagsInputValue.value}
                            onInputChange={handleLargeBagsInputValue}
                            options={locale === 'pl' ? largeBagsOptions : largeBagsOptionsEn}
                            sx={{ width: 300 }}
                            PaperComponent={props => <Paper {...props} />}
                            renderInput={params => (
                                <TextField
                                    variant="standard"
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& .MuiInputLabel-shrink': { opacity: 0, transition: 'all 0.2s ease-in' },
                                    }}
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        readOnly: true,
                                        inputMode: 'none',
                                    }}
                                    label={t('RAC.step_two.car_options.large_bags')}
                                />
                            )}
                        />

                        <Box textAlign="center">
                            <ChooseButton sx={{ mt: 5 }} handleClick={handleCloseDialogWindow}>
                                {t('btn.choose')}
                            </ChooseButton>
                            <Button variant="text" onClick={handleResetEquipmentFilters}>
                                {t('RAC.step_two.clear')}
                            </Button>
                        </Box>
                    </Box>
                </DialogWindow>
            )}
        </Box>
    )
}

export default OfferPresentation
