import React, { useContext } from 'react'
import { useFormikContext } from 'formik'
import { ListItem, ListItemButton, ListItemText, Typography, IconButton } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import IconSearchResult from '../../components/IconSearchResult'

const PlaceItem = ({ place, setClickedPlace, handleCloseDialog }) => {
    const { setFieldValue, setFieldTouched } = useFormikContext()

    return (
        <ListItem disablePadding>
            <ListItemButton
                sx={{
                    px: 0,
                }}
                onClick={() => {
                    setFieldValue('pickupLocationExpress', place)
                    setFieldValue(`pickupLocationExpressAddress`, place.name)
                    // setTimeout(() => setFieldTouched(name, true))
                    setClickedPlace(place)
                    handleCloseDialog()
                }}
            >
                <IconSearchResult
                    icon={place.name.includes('PKS') ? 'Bus' : place.name.includes('Prom') ? 'Ferry' : place.type}
                    type="blue"
                />

                <ListItemText
                    primary={place.city}
                    secondary={place.name}
                    sx={{
                        pr: 2,
                    }}
                />
                <Typography color="primary" fontSize="small" pr={1} sx={{ textWrap: 'nowrap' }}>
                    {place.pickupPriceIOH === null || place.pickupPriceIOH === '' ? '' : `od ${place.pickupPriceIOH}zł`}
                </Typography>
            </ListItemButton>

            {/* <IconButton
                aria-label="info"
                color="primary"
                onClick={() => {
                    setClickedPlace(place)
                }}
            >
                <InfoOutlinedIcon fontSize="inherit" />
            </IconButton> */}
        </ListItem>
    )
}

export default PlaceItem
