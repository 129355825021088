import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useFormattedPrice from '../hooks/useFormattedPrice'
import { Alert, Box, Container, Divider, Typography } from '@mui/material'

import useFetchLac from '../hooks/useFetchLac'

import { environment } from '../../environment'

import '../../styles/form.css'
import { CheckCircle } from '@mui/icons-material'
import ErrorBox from '../../components/ErrorBox'
import Spinner from '../components/Spinner'
import { API } from './api'

const SummaryLac = () => {
    const { t, i18n } = useTranslation()
    const { data, loading, error, fetchData } = useFetchLac()
    const { id, locale } = useParams()

    const priceFullFormatted = useFormattedPrice(parseFloat(data?.variables?.quote_calculation_requiredPaymentAllCars_gross).toFixed(2))

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        const url = `${API[`GET_PROCESS_DATA`]}?pid=${id}`
        fetchData(url, 'GET')
    }, [id])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('PROCESS DATA:', data)
    }, [data])

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return data ? (
        <Container>
            <Box p={4} width="100%">
                <Box textAlign="center" mb={2}>
                    <CheckCircle color="secondary" sx={{ fontSize: 40 }} />
                </Box>
                <Typography variant="h1" textAlign="center" fontWeight="bold" fontSize={{ xs: 16, lg: 20 }} mb={3}>
                    {t('RAC.thank_you_page.title')}
                </Typography>
                {data?.variables?.reservation_number && (
                    <>
                        <Typography
                            variant="h2"
                            textAlign="left"
                            fontWeight="bold"
                            fontSize={{ xs: 12, lg: 16 }}
                            mb={1}
                        >
                            {t('LAC.summary.order_number')}:
                        </Typography>
                        <Typography fontWeight="normal" fontSize={{ xs: 12, lg: 16 }} mb={3}>
                            {data?.variables?.reservation_number}
                        </Typography>
                    </>
                )}
                <Typography variant="h2" textAlign="left" fontWeight="bold" fontSize={{ xs: 12, lg: 16 }} mb={1}>
                    {t('LAC.summary.status')}
                </Typography>
                <Alert severity="success" variant="standard" sx={{ mb: 3 }}>
                    {t('LAC.summary.reservation_paid')}
                </Alert>
                {priceFullFormatted && (
                    <>
                        <Typography
                            variant="h2"
                            textAlign="left"
                            fontWeight="bold"
                            fontSize={{ xs: 12, lg: 16 }}
                            mb={1}
                        >
                            {t('LAC.summary.paid')}
                        </Typography>
                        <Divider sx={{ mb: 1 }} />

                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                            <Typography fontSize={{ xs: 12, lg: 16 }} mb={1}>
                                {t('LAC.summary.rental_cost')}
                            </Typography>
                            <Typography fontSize={{ xs: 12, lg: 16 }} mb={1}>
                                {priceFullFormatted}
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
        </Container>
    ) : (
        <Spinner />
    )
}

export default SummaryLac
