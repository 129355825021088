export const isValidNIP = nip => {
    if (!/[0-9]{10}/.test(nip)) return false

    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7]
    let sum = 0

    for (let i = 0; i < 9; i++) {
        sum += parseInt(nip[i]) * weights[i]
    }

    const controlNumber = sum % 11 === 10 ? 0 : sum % 11
    return parseInt(nip[9]) === controlNumber
}

export const isValidPESEL = value => {
    if (!value) return true

    const month = Number(value.substring(2, 4))
    const day = Number(value.substring(4, 6))
    const controlDigit = Number(value.substring(10, 11))

    if ((month > 12 && month < 21) || month < 1) {
        console.log('error1')
        return false
    }

    if (day < 1 || day > 31) {
        console.log('error2')
        return false
    }

    const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]
    const digits = value.split('')

    let sum = 0

    weights.forEach((weight, i) => {
        sum += (Number(digits[i]) * weight) % 10
    })

    const calculatedControlDigit = (10 - (sum % 10)) % 10
    if (calculatedControlDigit !== controlDigit) {
        console.log('error3')
        return false
    }

    return true
}
