import React from 'react'
import Links from '../../components/Links'
import useWindowDimensions from '../../Device.js'
import { environment } from '../../environment.js'

function TopBannerCarouselItem(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        //console.log('TopBannerCarousel Item')
        //console.log(params)
    }

    let Image = params.block.hasOwnProperty('Image')
            ? params.block['Image'].hasOwnProperty('link')
                ? params.block['Image']['link']
                : environment.defaultImage
            : environment.defaultImage,
        MobImage = params.block.hasOwnProperty('ImageMobile')
            ? params.block['ImageMobile'].hasOwnProperty('link')
                ? params.block['ImageMobile']['link']
                : Image
            : Image,
        Link = params.block.hasOwnProperty('Link') ? params.block['Link'] : '',
        LinkName = params.block.hasOwnProperty('LinkName') ? params.block['LinkName'] : '',
        Title = params.block.hasOwnProperty('Title') ? params.block['Title'] : '',
        TitleColor = params.block.hasOwnProperty('TitleColor') ? 'c-' + params.block['TitleColor'] : 'c-color6',
        SubTitle = params.block.hasOwnProperty('SubTitle') ? params.block['SubTitle'] : '',
        LinkType = params.block.hasOwnProperty('ButtonType') ? params.block['ButtonType'] : 'color1',
        InNewOpen = params.block.hasOwnProperty('InNewOpen')
            ? params.block['InNewOpen'] === true
                ? true
                : false
            : false
    let img = Image,
        imgMob = '',
        imgUrl = '',
        bImage = ''

    if (params.width <= 600) {
        imgMob = MobImage
        if (imgMob !== undefined && imgMob !== '') {
            if (imgMob.includes(environment.siteSites)) {
                imgMob = imgMob.split(environment.siteSites)
                imgMob = imgMob[1]
                if (params.line === 'offline') {
                    imgUrl = window.origin + imgMob
                } else if (window.origin.includes(environment.localhost)) {
                    imgUrl = environment.siteUrlDev + imgMob
                } else {
                    imgUrl = window.origin + imgMob
                }
            } else if (window.origin.includes(environment.localhost)) {
                imgUrl = environment.siteUrlDev + imgMob
            } else {
                imgUrl = imgMob
            }
        } else {
            if (img !== undefined && img !== '') {
                if (img.includes(environment.siteSites)) {
                    img = img.split(environment.siteSites)
                    img = img[1]
                    if (params.line === 'offline') {
                        imgUrl = window.origin + img
                    } else if (window.origin.includes(environment.localhost)) {
                        imgUrl = environment.siteUrlDev + img
                    } else {
                        imgUrl = window.origin + img
                    }
                } else if (window.origin.includes(environment.localhost)) {
                    imgUrl = environment.siteUrlDev + img
                } else {
                    imgUrl = img
                }
            } else {
                imgUrl = environment.defaultImage
            }
        }
    } else {
        if (img !== undefined && img !== '') {
            if (img.includes(environment.siteSites)) {
                img = img.split(environment.siteSites)
                img = img[1]
                if (params.line === 'offline') {
                    imgUrl = window.origin + img
                } else if (window.origin.includes(environment.localhost)) {
                    imgUrl = environment.siteUrlDev + img
                } else {
                    imgUrl = window.origin + img
                }
            } else if (window.origin.includes(environment.localhost)) {
                imgUrl = environment.siteUrlDev + img
            } else {
                imgUrl = img
            }
        } else {
            imgUrl = environment.defaultImage
        }
    }
    bImage = 'url(' + imgUrl + ')'
    return (
        <div className={`carousel-item relative lg:static ${params.noSlider ? '!items-end' : ''}`} style={{ backgroundImage: bImage }}>
            {params.noSlider ? (
                <div className='mb-5 sm:mb-20 mx-6 absolute bottom-0'>
                    <div className={'flex flex-col items-start gap-4 sm:gap-6 ' + TitleColor}>
                        {!(Title === '') && <h1 className='text-2xl sm:text-5xl'>{Title}</h1>}
                        {!(SubTitle === '') && <h2 className='!text-lg sm:!text-2xl font-normal text-left'>{SubTitle}</h2>}
                    </div>
                </div>
            ) : (
                <div className="container d-flex f-column absolute bottom-0 md:static md:bottom-auto">
                    <div className="slide-inner">
                        <div className="main-container">
                            <div className={'main-text mb-30 ' + TitleColor}>
                                {!(Title === '') && <h2>{Title}</h2>}
                                {!(SubTitle === '') && <h3>{SubTitle}</h3>}
                            </div>
                            {LinkName !== '' && Link !== '' && (
                                <Links
                                    line={params.line}
                                    url={Link}
                                    target={InNewOpen}
                                    class={'btn-link b-' + LinkType}
                                    name={LinkName}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TopBannerCarouselItem
