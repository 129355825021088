import React from 'react'
import { IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DialogWindow from './DialogWindow'
import Links from '../../components/Links'

const AlertWindow = ({
    showAlert,
    setShowAlert,
    dialogTitle,
    textButton,
    links,
    line,
    locale,
    // setMinimum25h = () => {},
    // setMax29Days = () => {},
    ...props
}) => {
    return (
        <DialogWindow
            isOpen={showAlert}
            setIsOpen={setShowAlert}
            dialogTitle={dialogTitle}
            // setMinimum25h={setMinimum25h}
            // setMax29Days={setMax29Days}
        >
            <Typography>{dialogTitle}</Typography>

            <IconButton
                aria-label="close"
                onClick={() => {
                    setShowAlert(false)
                    // setMinimum25h()
                    // setMax29Days()
                }}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            {/*<LinkButton to={locale === 'en' ? links.en : links.pl} variant="contained" align="center" sx={{ mt: 5 }}>
                {textButton}
            </LinkButton>*/}

            <Typography textAlign="center" mt={2}>
                <Links
                    class="btn-link"
                    url={locale === 'en' ? links.en : links.pl}
                    target={true}
                    name={textButton}
                    line={line}
                />
            </Typography>
        </DialogWindow>
    )
}

export default AlertWindow
