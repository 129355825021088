import React from 'react'
import { environment } from '../../environment'
import PopupContact from '../../components/PopupContact'
import { t } from 'i18next'

export default function TextForm(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('TextForm')
        console.log(params)
    }

    return (
        <section className={'text-form'}>
            <div className="wrapper">
                <div className={'container mx-auto px-5 md:px-8 my-20'}>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                        {params.block[params.locale].Text ? (
                            <div>
                                {params.block[params.locale].Title ? (
                                    <h3 className="opacity-50 text-center lg:text-left">
                                        {params.block[params.locale].Title}
                                    </h3>
                                ) : null}
                                <div
                                    className="ul-custom"
                                    dangerouslySetInnerHTML={{ __html: params.block[params.locale].Text }}
                                />
                            </div>
                        ) : null}
                        <div className='border-2 p-6 md:p-12 rounded-2xl'>
                            <PopupContact
                                dialogTitle={params?.block?.[params.locale]?.FormTitle ? params.block[params.locale].FormTitle : t("LAC.popup_contact.title")}
                                idOffer="2417"
                                noPopup={true}
                                buttonId="W3"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
