let API = {
    GET_ALL_OFFERS: '/api/lac/getOffers',
    GET_BRANDS: '/api/dict/car_producents.json',
    GET_MODELS: '/api/dict/car_models_',
    GET_FUEL_TYPES: '/api/dict/fuel.json',
    GET_TAGS: '/api/dict/article_tags.json',
    GET_TRANSMISSION_TYPES: '/api/dict/transmission.json',
    GET_DRIVE_TYPES: '/api/dict/car_drive_type.json',
    GET_LOCATIONS: '/api/lac/getLocations',
    GET_OFFER_BY_ID: '/api/lac/getOfferById',
    GET_EQUIPMENT: '/api/dict/equipment_definitions.json',
    GET_OFFER_OPTIONS: '/ajax/Lac/getOfferOptions',
    GET_OFFER_ADDITIONAL_OPTIONS: '/ajax/Lac/additionalOptions/',
    POST_CONTACT_FORM: '/ajax/User/contact/',
    GET_PROCESS_DATA: `/ajax/Lac/processData/`,
    GET_TRANSPORT_PRICE_LIST: `/api/lac/transportPriceList`,
    POST_START_PROCESS: '/ajax/Lac/register/',
    POST_NEW_DATA: '/ajax/Lac/step/?navigation=next',
    GET_PREVIOUS_STEP: '/ajax/Lac/step/?navigation=',
    GET_CLASSES: '/api/dict/car_classes.json',
    GET_COMPANY_DATA: `/ajax/User/getCompanyData/`,
    NAVIGATION_NEXT: '/ajax/Lac/step/?navigation=next',
    GET_PAYMENT_STATUS: `/ajax/Payments/check/`,
    VERIFY_PAYMENT_HANDING: `/ajax/Lac/verifyPaymentHanding/`,
    TRANSFER_PAYMENT: '/ajax/Lac/step/?navigation=mail_payment_link',
    GET_FILE_REGULATIONS_USER_TERMS_AND_CONDITIONS: `/dokumenty/regulaminy/regulamin_konta_uzytkownika-10.2022.pdf`,
    GET_FILE_REGULATIONS_USER_TERMS_AND_CONDITIONS_EN: `/en/documents/regulations/REGULAMIN-KORZYSTANIA-Z-KONTA-UZYTKOWNIKA-PORTALU-EXPRESS_EN.pdf`,
}

if (window.location.host.includes('localhost')) {
    API = {
        GET_ALL_OFFERS: `https://dev-express.qrqs.eu${API.GET_ALL_OFFERS}`,
        GET_BRANDS: `https://dev-express.qrqs.eu${API.GET_BRANDS}`,
        GET_MODELS: ` https://dev-express.qrqs.eu${API.GET_MODELS}`,
        GET_FUEL_TYPES: `https://dev-express.qrqs.eu${API.GET_FUEL_TYPES}`,
        GET_TAGS: `https://dev-express.qrqs.eu${API.GET_TAGS}`,
        GET_TRANSMISSION_TYPES: `https://dev-express.qrqs.eu${API.GET_TRANSMISSION_TYPES}`,
        GET_DRIVE_TYPES: `https://dev-express.qrqs.eu${API.GET_DRIVE_TYPES}`,
        GET_LOCATIONS: `https://dev-express.qrqs.eu${API.GET_LOCATIONS}`,
        GET_OFFER_BY_ID: `https://dev-express.qrqs.eu${API.GET_OFFER_BY_ID}`,
        GET_EQUIPMENT: `https://dev-express.qrqs.eu${API.GET_EQUIPMENT}`,
        GET_OFFER_OPTIONS: `https://dev-express.qrqs.eu${API.GET_OFFER_OPTIONS}`,
        GET_OFFER_ADDITIONAL_OPTIONS: `https://dev-express.qrqs.eu${API.GET_OFFER_ADDITIONAL_OPTIONS}`,
        POST_CONTACT_FORM: `https://dev-express.qrqs.eu${API.POST_CONTACT_FORM}`,
        GET_PROCESS_DATA: `https://dev-express.qrqs.eu${API.GET_PROCESS_DATA}`,
        GET_TRANSPORT_PRICE_LIST: `https://dev-express.qrqs.eu${API.GET_TRANSPORT_PRICE_LIST}`,
        POST_START_PROCESS: `https://dev-express.qrqs.eu${API.POST_START_PROCESS}`,
        POST_NEW_DATA: `https://dev-express.qrqs.eu${API.POST_NEW_DATA}`,
        GET_PREVIOUS_STEP: `https://dev-express.qrqs.eu${API.GET_PREVIOUS_STEP}`,
        GET_CLASSES: `https://dev-express.qrqs.eu${API.GET_CLASSES}`,
        GET_COMPANY_DATA: `https://dev-express.qrqs.eu${API.GET_COMPANY_DATA}`,
        NAVIGATION_NEXT: `https://dev-express.qrqs.eu${API.NAVIGATION_NEXT}`,
        GET_PAYMENT_STATUS: `https://dev-express.qrqs.eu${API.GET_PAYMENT_STATUS}`,
        VERIFY_PAYMENT_HANDING: `https://dev-express.qrqs.eu${API.VERIFY_PAYMENT_HANDING}`,
        TRANSFER_PAYMENT: `https://dev-express.qrqs.eu${API.TRANSFER_PAYMENT}`,
        GET_FILE_REGULATIONS_USER_TERMS_AND_CONDITIONS: `https://test-express.qrqs.eu${API.GET_FILE_REGULATIONS_USER_TERMS_AND_CONDITIONS}`,
        GET_FILE_REGULATIONS_USER_TERMS_AND_CONDITIONS_EN: `https://test-express.qrqs.eu${API.GET_FILE_REGULATIONS_USER_TERMS_AND_CONDITIONS_EN}`,
    }
}

if (window.location.host.includes('dev-express')) {
    API = {
        ...API,
        GET_FILE_REGULATIONS_USER_TERMS_AND_CONDITIONS: `https://test-express.qrqs.eu${API.GET_FILE_REGULATIONS_USER_TERMS_AND_CONDITIONS}`,
        GET_FILE_REGULATIONS_USER_TERMS_AND_CONDITIONS_EN: `https://test-express.qrqs.eu${API.GET_FILE_REGULATIONS_USER_TERMS_AND_CONDITIONS_EN}`,
    }
}

export { API }

export const FORM_KEY = {
    date_locations_selection: 'step1',
    offer_presentation: 'step2',
    packages_equipment_selection: 'step3',
}
