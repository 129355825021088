import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { environment } from '../../environment'
import NavLangOffMob from '../../components/Nav/NavLangOffMob.js'

const LanguageDropdown = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedLanguage, setSelectedLanguage] = useState('')
    const dropdownRef = useRef(null) 
    const toggleDropdown = () => setIsOpen(!isOpen)
    const lang = window.location.pathname.split('/')[1].length > 2 || window.location.pathname.split('/')[1].length === 0 ? 'pl' : window.location.pathname.split('/')[1]
    const selectLanguage = language => {
        setSelectedLanguage(language)
        setIsOpen(false)
    }

    const handleClickOutside = event => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        setSelectedLanguage(lang)

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div id="buttonLang" ref={dropdownRef} className="relative inline-block text-left">
            <div>
                <button
                    type="button"
                    className="inline-flex justify-center items-center w-full px-2 py-2 text-sm text-navy-500 font-bold focus:outline-none"
                    id="options-menu"
                    aria-haspopup="true"
                    aria-expanded={isOpen}
                    onClick={toggleDropdown}
                >
                    {selectedLanguage.toUpperCase()}
                    <svg
                        className={`ml-2 h-5 w-5 transition-transform transform ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#f07d14"
                        aria-hidden="true"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            </div>

            {isOpen && (
                <NavLangOffMob />
            )}
        </div>
    )
}

export default LanguageDropdown
