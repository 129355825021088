import React, { useEffect } from 'react'
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { useField, useFormikContext } from 'formik'
import useFetch from '../hooks/useFetch'
import Spinner from './Spinner'
import ErrorBox from '../RAC/ErrorBox'
import { API } from '../api'

const CountriesSelect = ({ locale, name, label, value, required, setValue, ...props }) => {
    const { setFieldValue } = useFormikContext()
    const [field] = useField(name)

    const { data, loading, error, fetchData } = useFetch()

    useEffect(() => {
        const url = `${API[`GET_DICT_COUNTRIES`]}`
        fetchData(url, 'GET')
    }, [])

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return data ? (
        <FormControl fullWidth required={required}>
            <InputLabel id="nationality-select-label">{label}</InputLabel>
            <Select
                defaultValue={locale === 'pl' ? 'PL' : 'GB'}
                name={name}
                labelId={`${name}-select-label`}
                id={`${name}-select`}
                label={label}
                value={value || field.value}
                onChange={e => {
                    setFieldValue(name, e.target.value)
                    setValue(e.target.value)
                }}
            >
                {locale === 'pl'
                    ? Object.keys(data.pl).map(country => (
                          <MenuItem key={country} value={country}>
                              {data.pl[country]}
                          </MenuItem>
                      ))
                    : Object.keys(data.en).map(country => (
                          <MenuItem key={country} value={country}>
                              {data.en[country]}
                          </MenuItem>
                      ))}
            </Select>
        </FormControl>
    ) : (
        <Spinner size={20} />
    )
}

export default CountriesSelect
