import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import BackButton from '../../components/BackButton'

const DialogPickupLocation = ({ handleClose, open, children }) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            aria-labelledby="responsive-dialog-title"
            onClose={handleClose}
            PaperProps={{
                style: {
                    width: '100%',
                    minHeight: `calc(100% - 64px)`,
                    '@media (minWidth: 700px)': {
                        width: '700px',
                    },
                },
            }}
        >
            <DialogContent sx={{ p: 0 }}>
                <Box pb={2} sx={{ width: '100%' }}>
                    <BackButton closeIcon handleClick={handleClose} />

                    <Box py={2} px={{ xs: 2, lg: 4 }}>
                        {children}
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default DialogPickupLocation
