import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import Spinner from '../../components/Spinner'
import StepperLac from '../components/StepperLac'
import FormBusiness from './FormBusiness'
import FormInd from './FormInd'
import useFetchLac from '../../hooks/useFetchLac'
import { API } from '../api'
import { API as API2 } from '../../api'
import { environment } from '../../../environment'
import SummaryStep4 from '../components/SummaryStep4'
import validationSchema from './validationSchema'
import setLoginLink from '../../RAC/setLoginLink'
import DialogWindow from '../../components/DialogWindow'
import CloseIcon from '@mui/icons-material/Close';
import BackButtonInForm from '../../components/BackButtonInForm'
import PopupLogin from '../../../components/Nav/PopupLogin'

const Step4Lac = () => {
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()
    const [clientType, setClientType] = useState('business')
    const [isOtherDriver, setIsOtherDriver] = useState(false)
    const [processData, setProcessData] = useState([])
    const [loadingProcessData, setLoadingProcessData] = useState(true)
    const navigate = useNavigate()
    const { data, loading, error, fetchData } = useFetchLac()
    
    const [isLogged, setIsLogged] = useState(false)
    const translatedValidationSchema = validationSchema(t, isLogged)
    const [userDataInSystem, setUserDataInSystem] = useState(null)

    const [showDialog, setShowDialog] = useState(false)
    const [countryChangedInd, setCountryChangedInd] = useState(false);
    const [countryChangedBusiness, setCountryChangedBusiness] = useState(false);

    const [showModal, setShowModal] = useState(false)
    const lang = window.location.href.includes('/en/') ? 'en' : 'pl'

    useEffect(() => {
        if (id) {
            const url = `${API[`GET_PROCESS_DATA`]}/?pid=${id}`
            fetchData(url, 'GET')
        }
    }, [id])

    useEffect(() => {
        setProcessData(data)
        if (data) {
            setLoadingProcessData(false)
        }
    }, [data])

    const [userData, setUserData] = useState({
        userFirstName: '',
        userAdditionalFirstName: '',
        userLastName: '',
        userAdditionalLastName: '',
        userEmail: '',
        userPhoneNumber: '',
        userAdditionalPhone: '',
        userNip: '',
        companyAdditionalData: '',
        userPesel: '',
        userStreet: '',
        userHouse: '',
        userLocal: '',
        userCountry: locale === 'pl' ? 'PL' : 'none',
        userCity: '',
        userPostCode: '',
        companyName: '',
        companyStreet: '',
        companyHouse: '',
        companyLocal: '',
        companyPostCode: '',
        companyCity: '',
        companyPhone: '',
        companyEmail: '',
        companyMarketing: false,
        userCheckedWantToCreateAccount: false,
        userAllowedKRD: false,
        userAllowedTerms: false,
        profiling: false,
        withoutProfiling: false,
        telemarketing: false,
        clientType: 'business',
        isOtherDriver: false,
        companyId: ''
    })

    const initialValues = {
        userFirstName: userData.userFirstName || '',
        userAdditionalFirstName: userData.userAdditionalFirstName || '',
        userLastName: userData.userLastName || '',
        userAdditionalLastName: userData.userAdditionalLastName || '',
        userEmail: userData.userEmail || '',
        userPhoneNumber: userData.userPhoneNumber || '',
        userAdditionalPhone: userData.userAdditionalPhone || '',
        userNip: userData.userNip || '',
        companyAdditionalData: userData.companyAdditionalData || '',
        userPesel: userData.userPesel || '',
        userCountry: userData.userCountry || '',
        userStreet: userData.userStreet || '',
        userHouse: userData.userHouse || '',
        userCity: userData.userCity || '',
        userLocal: userData.userLocal || '',
        userPostCode: userData.userPostCode || '',
        companyName: userData.companyName || '',
        companyStreet: userData.companyStreet || '',
        companyHouse: userData.companyHouse || '',
        companyLocal: userData.companyLocal || '',
        companyPostCode: userData.companyPostCode || '',
        companyCity: userData.companyCity || '',
        companyPhone: userData.companyPhone || '',
        companyEmail: userData.companyEmail || '',
        companyMarketing: userData.companyMarketing,
        userAllowedKRD: userData.userAllowedKRD || false,
        userAllowedTerms: userData.userAllowedTerms || false,
        profiling: userData.profiling || false,
        withoutProfiling: userData.withoutProfiling || false,
        telemarketing: userData.telemarketing || false,
        clientType: userData.clientType || 'business',
        userCheckedWantToCreateAccount: userData.userCheckedWantToCreateAccount || false,
        isOtherDriver: userData.isOtherDriver || false,
        companyId: userData.companyId || ''
    }

    useEffect(() => {
        if (processData?.variables?.['client_first_name']) {
            setUserData(prevState => ({
                ...prevState,
                userFirstName: processData.variables['client_first_name'],
            }))
        }
        if (processData?.variables?.['client_last_name']) {
            setUserData(prevState => ({
                ...prevState,
                userLastName: processData.variables['client_last_name'],
            }))
        }
        if (processData?.variables?.['client_email']) {
            setUserData(prevState => ({
                ...prevState,
                userEmail: processData.variables['client_email'],
            }))
        }
        if (processData?.variables?.['client_phone_number']) {
            setUserData(prevState => ({
                ...prevState,
                userPhoneNumber: processData.variables['client_phone_number'],
            }))
        }
        if (processData?.variables?.['client_country']) {
            setUserData(prevState => ({
                ...prevState,
                userCountry: processData.variables['client_country'],
            }))
        }
        if (processData?.variables?.['client_street']) {
            setUserData(prevState => ({
                ...prevState,
                userStreet: processData.variables['client_street'],
            }))
        }
        if (processData?.variables?.['client_house']) {
            setUserData(prevState => ({
                ...prevState,
                userHouse: processData.variables['client_house'],
            }))
        }
        if (processData?.variables?.['client_local']) {
            setUserData(prevState => ({
                ...prevState,
                userLocal: processData.variables['client_local'],
            }))
        }
        if (processData?.variables?.['PESEL']) {
            setUserData(prevState => ({
                ...prevState,
                userPesel: processData.variables['PESEL'],
            }))
        }
        if (processData?.variables?.['client_type']) {
            if (processData.variables['client_type'] === 'ind') {
                setClientType('ind')
            }
            setUserData(prevState => ({
                ...prevState,
                clientType: processData.variables['client_type'],
            }))
        }
        if (processData?.variables?.['second_first_name']) {
            setIsOtherDriver(true)
            handleUserDataChange('isOtherDriver', true)
            setUserData(prevState => ({
                ...prevState,
                userAdditionalFirstName: processData.variables['second_first_name'],
            }))
        }
        if (processData?.variables?.['second_last_name']) {
            setUserData(prevState => ({
                ...prevState,
                userAdditionalLastName: processData.variables['second_last_name'],
            }))
        }
        if (processData?.variables?.['second_phone_number']) {
            setUserData(prevState => ({
                ...prevState,
                userAdditionalPhone: processData.variables['second_phone_number'],
            }))
        }
        if (processData?.variables?.['company_nip']) {
            setUserData(prevState => ({
                ...prevState,
                userNip: processData.variables['company_nip'],
            }))
        }
        if (processData?.variables?.['company_name']) {
            setUserData(prevState => ({
                ...prevState,
                companyName: processData.variables['company_name'],
            }))
        }
        if (processData?.variables?.['company_street']) {
            setUserData(prevState => ({
                ...prevState,
                companyStreet: processData.variables['company_street'],
            }))
        }
        if (processData?.variables?.['company_house']) {
            setUserData(prevState => ({
                ...prevState,
                companyHouse: processData.variables['company_house'],
            }))
        }
        if (processData?.variables?.['company_local']) {
            setUserData(prevState => ({
                ...prevState,
                companyLocal: processData.variables['company_local'],
            }))
        }
        if (processData?.variables?.['company_postal_code']) {
            setUserData(prevState => ({
                ...prevState,
                companyPostCode: processData.variables['company_postal_code'],
            }))
        }
        if (processData?.variables?.['company_city']) {
            setUserData(prevState => ({
                ...prevState,
                companyCity: processData.variables['company_city'],
            }))
        }
    }, [processData])

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    const handleUserDataChange = (name, value) => {
        setUserData(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }

    function disableActionButton(isValid) {
        if (!isValid)
            return false
        else if(userData.clientType === 'business') {
            if(userData.userAllowedTerms) {
                return true
            } else {
                return false
            }
        } else if (userData.clientType === 'ind') {
            if(userData.userAllowedKRD && userData.userAllowedTerms) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const handleResetCompanyData = () => {
        setUserData(prevState => ({
            ...prevState,
            companyName: '',
            companyStreet: '',
            companyHouse: '',
            companyLocal: '',
            companyPostCode: '',
            companyCity: '',
        }))
    }

    const handleSubmit = async values => {
        let data
        if (clientType === 'business') {
            data = {
                client_first_name: values.userFirstName,
                client_last_name: values.userLastName,
                client_phone_number: (locale === 'pl' && values.userCountry === 'PL' && !isLogged) ? `+48${values.userPhoneNumber}` : values.userPhoneNumber,
                client_email: values.userEmail,
                client_want_to_create_account: values.userCheckedWantToCreateAccount,
                company_name: values.companyName,
                company_street: values.companyStreet,
                company_house: values.companyHouse,
                company_local: values.companyLocal,
                company_postal_code: values.companyPostCode,
                company_city: values.companyCity,
                company_country: values.userCountry,
                company_phone: values.companyPhone,
                company_email: values.companyEmail,
                company_marketing: values.companyMarketing,
                terms_accepted: values.userAllowedTerms,
                profiling: values.profiling,
                without_profiling: values.withoutProfiling,
                telemarketing: values.telemarketing,
                contact_language: locale,
                client_type: 'business',
            }
            if(isLogged && userDataInSystem) {
                data = {
                    ...data,
                    company_id: values.companyId
                }
            }
            if (values.userCountry !== 'PL') {
                data = {
                    ...data,
                    companyAdditionalData: values.companyAdditionalData,
                }
            } else {
                data = {
                    ...data,
                    company_nip: values.userNip,
                }
            }
        }

        if (clientType === 'ind') {
            data = {
                client_first_name: values.userFirstName,
                client_last_name: values.userLastName,
                client_phone_number: (locale === 'pl' && values.userCountry === 'PL' && !isLogged) ? `+48${values.userPhoneNumber}` : values.userPhoneNumber,
                client_email: values.userEmail,
                client_street: values.userStreet,
                client_house: values.userHouse,
                client_local: values.userLocal,
                client_country: values.userCountry,
                client_city: values.userCity,
                client_postal_code: values.userPostCode,
                client_want_to_create_account: values.userCheckedWantToCreateAccount,
                krd_verification: values.userAllowedKRD,
                terms_accepted: values.userAllowedTerms,
                profiling: values.profiling,
                without_profiling: values.withoutProfiling,
                telemarketing: values.telemarketing,
                contact_language: locale,
                client_type: 'ind',
            }

            if (values.userCountry === 'PL') {
                data = {
                    ...data,
                    PESEL: values.userPesel,
                }
            }
        }

        if (isOtherDriver) {
            data = {
                ...data,
                second_first_name: values.userAdditionalFirstName,
                second_last_name: values.userAdditionalLastName,
                second_phone_number: values.userAdditionalPhone,
            }
        }

        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
            console.log('submit data ', data)
        }

        const response = await fetchData(`${API['POST_NEW_DATA']}&pid=${id}`, 'POST', data, locale)
    }

    async function prevStep() {
        const response = await fetchData(`${API['GET_PREVIOUS_STEP']}offer_presentation&pid=${id}`, 'GET')
        const url =
            locale === 'pl'
                ? `/${locale}/auto-dla-firmy/wynajem-elastyczny/krok-3/${id}`
                : `/${locale}/car-for-company/flexible-rental/step-3/${id}`
        if (response) {
            navigate(url)
        }
    }


    const checkIsLogged = async () => {
        try {
            const response = await fetch(API2['USER_IS_LOGGED'])
            if (response.ok) {
                const res = await response.json()
                setIsLogged(res.logged)
            } else {
                console.error('Error fetching data')
            }
        } catch (error) {
            console.error('Error fetching data', error)
        }
    }

    useEffect(() => {
        checkIsLogged()
    }, [])

    const getUserDataInSystem = async email => {
        setUserDataInSystem(null)
        try {
            const response = await fetch(`${API2['USER_GET_DATA']}`, {
                method: 'GET',
            })
            if (response.ok) {
                const res = await response.json()
                setUserDataInSystem(res)
                handleUserDataChange('userFirstName', res?.firstName)
                handleUserDataChange('userLastName', res?.lastName)
                handleUserDataChange('userPhoneNumber', res?.phoneNo)
                handleUserDataChange('userEmail', res?.email)
                handleUserDataChange('companyId', res?.companies?.[0]?.id ? res?.companies?.[0]?.id : '')
                if(res?.companies) {
                    handleUserDataChange('companyName', res?.companies?.[0]?.companyName ? res?.companies?.[0]?.companyName : '')
                    handleUserDataChange('companyStreet', res?.companies?.[0]?.address?.street ? res?.companies?.[0]?.address?.street : '')
                    handleUserDataChange('companyHouse', res?.companies?.[0]?.address?.house ? res?.companies?.[0]?.address?.house : '')
                    handleUserDataChange('companyLocal', res?.companies?.[0]?.address?.local ? res?.companies?.[0]?.address?.local : '')
                    handleUserDataChange('companyPostCode', res?.companies?.[0]?.address?.postCode ? res?.companies?.[0]?.address?.postCode : '')
                    handleUserDataChange('companyCity', res?.companies?.[0]?.address?.city ? res?.companies?.[0]?.address?.city : '')
                    handleUserDataChange('companyEmail', res?.companies?.[0]?.email ? res?.companies?.[0]?.email : '')
                    handleUserDataChange('companyPhone', res?.companies?.[0]?.phoneNo ? res?.companies?.[0]?.phoneNo : '')
                    handleUserDataChange('userNip', res?.companies?.[0]?.nip ? res?.companies?.[0]?.nip : '')
                }
                if(res.pesel != null) {
                    handleUserDataChange('userPesel', res?.pesel)
                }
            }
        } catch (error) {
            console.error('Error fetching data', error)
        }
    }


    useEffect(() => {
        isLogged && getUserDataInSystem()
    }, [isLogged])

    function showPopup(formType) {
        if(formType === 'ind') {
            if(!countryChangedInd) {
                setShowDialog(true);
                setCountryChangedInd(true);
            }
        } else {
            if(!countryChangedBusiness) {
                setShowDialog(true);
                setCountryChangedBusiness(true);
            }
        }
    }

    if (loading) {
        return <Spinner />
    }

    return (
        <div className="container mx-auto px-8 my-6">
            <BackButtonInForm handleClick={prevStep}>{t('btn.back')}</BackButtonInForm>
            <StepperLac activeStep={4} />

            <Box my={4}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={translatedValidationSchema}
                    validateOnMount={true}
                >
                    {({ errors, touched, values, isSubmitting, setFieldValue, isValid }) => (
                        <Form>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={8} mt={4}>
                                    <Box my={5}>
                                        <Typography
                                            variant="h2"
                                            textAlign="left"
                                            fontWeight="bold"
                                            fontSize={{ xs: 20, lg: 32 }}
                                            mb={3}
                                        >
                                            {t('LAC.client_data.heading_1')}
                                        </Typography>

                                        <div className="flex justify-center gap-4">
                                            <button
                                                onClick={() => {
                                                    setClientType('business')
                                                    handleUserDataChange('clientType', 'business')
                                                    handleUserDataChange('userAllowedTerms', false)
                                                    handleUserDataChange('userAllowedKRD', false)
                                                }}
                                                type="button"
                                                className={`px-5 rounded-full py-3 transition border font-semibold cursor-pointer flex items-center justify-center ${clientType === 'business' ? 'text-orange-500 border-orange-500' : 'text-[#0b4185] border-[#0b4185]'} `}
                                            >
                                                {t('LAC.client_data.button_1')}
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setClientType('ind')
                                                    handleUserDataChange('clientType', 'ind')
                                                    handleUserDataChange('userAllowedTerms', false)
                                                    handleUserDataChange('userAllowedKRD', false)
                                                }}
                                                type="button"
                                                className={`px-5 rounded-full py-3 transition border font-semibold cursor-pointer flex items-center justify-center ${clientType === 'ind' ? 'text-orange-500 border-orange-500' : 'text-[#0b4185] border-[#0b4185]'} `}
                                            >
                                                {t('LAC.client_data.button_2')}
                                            </button>
                                        </div>
                                    </Box>

                                    <Box display="flex" gap={4} alignItems="center">
                                        <Typography
                                            variant="h2"
                                            textAlign="left"
                                            fontWeight="bold"
                                            fontSize={{ xs: 15, lg: 18 }}
                                        >
                                            {t('LAC.client_data.heading_2')}
                                        </Typography>
                                        {!isLogged ? 
                                            <Typography
                                            className="cursor-pointer"
                                            variant="span"
                                            textAlign="left"
                                            fontSize={{ xs: 14, lg: 16 }}
                                            color="#0b4185"
                                            onClick={e => {
                                                e.preventDefault()
                                                setShowModal(true)
                                            }}
                                            >
                                                {t('LAC.client_data.have_acc')} &gt;
                                            </Typography>
                                        : null}
                                    </Box>
                                    {clientType === 'business' ? (
                                        <FormBusiness
                                            values={values}
                                            userData={userData}
                                            touched={touched}
                                            errors={errors}
                                            handleUserDataChange={handleUserDataChange}
                                            isOtherDriver={isOtherDriver}
                                            setIsOtherDriver={setIsOtherDriver}
                                            handleResetCompanyData={handleResetCompanyData}
                                            isLogged={isLogged}
                                            userDataInSystem={userDataInSystem}
                                            showPopup={showPopup}
                                        />
                                    ) : (
                                        <FormInd
                                            userData={userData}
                                            touched={touched}
                                            errors={errors}
                                            handleUserDataChange={handleUserDataChange}
                                            setIsOtherDriver={setIsOtherDriver}
                                            isLogged={isLogged}
                                            userDataInSystem={userDataInSystem}
                                            showPopup={showPopup}
                                        />
                                    )}
                                    {/* <BackButtonInForm handleClick={prevStep}>{t('btn.back')}</BackButtonInForm> */}
                                    {/* <Box sx={{display: {xs: 'none', md: 'block'}}}>
                                        <div className="flex justify-center">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    prevStep()
                                                }}
                                                className="btn-link"
                                            >
                                                {t('btn.back')}
                                            </button>
                                        </div>
                                    </Box> */}
                                </Grid>
                                {/* PODSUMOWANIE */}
                                <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                                    <Typography
                                        variant="h3"
                                        textAlign="left"
                                        fontWeight="bold"
                                        fontSize={{ xs: 18, lg: 24 }}
                                        mb="6px"
                                    >
                                        {t('LAC.client_data.heading_3')}
                                    </Typography>
                                    {processData && (
                                        <SummaryStep4
                                            showButton={disableActionButton(isValid)}
                                            processData={processData?.variables}
                                            carNumber={processData?.variables?.car_number || 1}
                                            priceNetto={parseFloat(processData?.variables?.offer_rent_rent_price)}
                                            priceBrutto={parseFloat(processData?.variables?.offer_rent_rentgross)}
                                            insurancePackagePriceNetto={parseFloat(
                                                processData?.variables?.insurance_package_price
                                            )}
                                            insurancePackagePriceBrutto={parseFloat(
                                                processData?.variables?.insurance_package_price_gross
                                            )}
                                            gpsPriceNetto={
                                                processData?.variables?.services_gps_price
                                                    ? parseFloat(processData?.variables?.services_gps_price)
                                                    : 0
                                            }
                                            gpsPriceBrutto={
                                                processData?.variables?.services_gps_price_gross
                                                    ? parseFloat(processData?.variables?.services_gps_price_gross)
                                                    : 0
                                            }
                                            fuelCardPriceNetto={
                                                processData?.variables?.services_fuel_card_price
                                                    ? parseFloat(processData?.variables?.services_fuel_card_price)
                                                    : 0
                                            }
                                            fuelCardPriceBrutto={
                                                processData?.variables?.services_fuel_card_price_gross
                                                    ? parseFloat(processData?.variables?.services_fuel_card_price_gross)
                                                    : 0
                                            }
                                            isSubmitting={isSubmitting}
                                            prevStep={prevStep}
                                        />
                                    )}
                                        <Box mt={4} sx={{display: {xs: 'block', md: 'none'}}}>
                                            <div className="flex justify-center">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        prevStep()
                                                    }}
                                                    className="btn-link"
                                                >
                                                    {t('btn.back')}
                                                </button>
                                            </div>
                                        </Box>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
                {userData?.userCountry !== 'PL' && showDialog && 
                    <DialogWindow isOpen={showDialog} setIsOpen={setShowDialog}>
                        <IconButton
                            aria-label="close"
                            onClick={() => setShowDialog(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: theme => theme.palette.grey[500],
                            }}
                            >
                            <CloseIcon />
                        </IconButton>
                        {t('LAC.client_data.popup_text')}
                    </DialogWindow>
                }
                {showModal &&
                    <PopupLogin
                        showModal={showModal}
                        setShowModal={setShowModal}
                        loginUrl={setLoginLink()}
                        registerUrl={environment.registrationPage[lang]}
                        title={t('login_popup_title')}
                    />
                }
            </Box>
        </div>
    )
}

export default Step4Lac
