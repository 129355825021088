import React, { useEffect } from 'react'
import { environment } from '../../environment.js'
import TextLinks from '../../components/TextLinks.js'
import Image from '../../components/Image'

function CommentsBoxItem(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('CommentsBoxs Item')
        console.log(params)
    }

    let Comment = params.block.hasOwnProperty('Comment') ? params.block['Comment'] : '',
        Author = params.block.hasOwnProperty('Author') ? params.block['Author'] : '',
        Position = params.block.hasOwnProperty('Position') ? params.block['Position'] : '',
        AltText = params.block.hasOwnProperty('AltText') 
                ? (params.block['AltText'] === ''
                    ? (Author === '' ? 'Express Comment Image' : Author)
                    : params.block['AltText'])
                : (Author === '' ? 'Express Comment Image' : Author);

    let noImage = false;
    if (params.block.hasOwnProperty('Image')) {
        if (!params.block['Image'].hasOwnProperty('link')) {
            noImage = true
        }
    }
    else {
        noImage = true
    }
    let img = params.block.hasOwnProperty('Image')
            ? params.block['Image'].hasOwnProperty('link')
                ? params.block['Image']['link']
                : environment.defaultImage
            : environment.defaultImage;
    let imgUrl = img;

    if (img !== undefined && img !== '') {
        if (img.includes(environment.siteSites)) {
            img = img.split(environment.siteSites)
            img = img[1]
            if (params.line === 'offline') {
                imgUrl = window.origin + img
            } else if (window.origin.includes(environment.localhost)) {
                imgUrl = environment.siteUrlDev + img
            } else {
                imgUrl = window.origin + img
            }
        } else if (window.origin.includes(environment.localhost)) {
            imgUrl = environment.siteUrlDev + img
        } else {
            imgUrl = img
        }
    } else {
        imgUrl = environment.defaultImage
    }
    let ImageUrl = imgUrl;


    function Initials() {
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
        let initials = [...Author.matchAll(rgx)] || [];
        initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
        ).toUpperCase();

        return (
            <div className='initials'>{initials}</div>
        )
    }

    return (
        <div className="comments-item grid-cell grid-33">
            <div className="boxes-inner d-flex">
                <div className="boxes-text c-color5">
                    {!(Comment === '') && 
                        <div className="content-text mb-30">
                            {<TextLinks text={Comment} line={params.line} />}
                        </div>
                    }
                </div>
                <div className="boxes-about d-flex ai-center c-color5">
                    <div className={'image-box img-'+!noImage}>
                        {!noImage && <Image className="h-[149px] w-auto" src={ImageUrl} alt={AltText} />}
                        {noImage && <Initials />}
                    </div>
                    <div className="author-box">
                        {!(Author === '') && 
                            <div className="author-text mb-30">
                                <h4>{Author}</h4>
                            </div>
                        }
                        {!(Position === '') && 
                            <div className="position-text mb-30">
                                <p>{Position}</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommentsBoxItem
