import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { Box, Typography } from '@mui/material'
import NumberSelector from '../../components/NumberSelector'
import Spinner from '../../components/Spinner'

const OptionItem = ({ option, initialOptionsValues, selectedOptions, setSelectedOptions }) => {
    const { t, i18n } = useTranslation()
    const { locale } = useParams()
    const { setFieldValue } = useFormikContext()

    const { id, name, nameEn, perDayPrice, unitPrice, min, max } = option

    const [isActive, setIsActive] = useState(false)
    const [value, setvalue] = useState(0)
    const [initialValue, setInitialValue] = useState(0)
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        if (
            (initialOptionsValues && initialOptionsValues.length > 0) ||
            (selectedOptions && selectedOptions.length > 0)
        ) {
            const index = initialOptionsValues.findIndex(obj => obj.id === option.id)

            index !== -1 && setInitialValue(initialOptionsValues[index].count)

            index !== -1 && setvalue(initialOptionsValues[index].count)

            setIsReady(true)

            countSelected()
        }
    }, [initialOptionsValues])

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        value > 0 ? setIsActive(true) : setIsActive(false)

        countSelected()
    }, [value])

    useEffect(() => {
        setFieldValue('options', selectedOptions)
    }, [selectedOptions])

    const countSelected = async () => {
        // Sprawdź, czy istnieje obiekt o podanym id
        const index = selectedOptions.findIndex(obj => obj.id === option.id)

        if (index !== -1) {
            // Jeśli obiekt o podanym id istnieje, zaktualizuj jego wartość
            const newArray = [...selectedOptions]
            selectedOptions[index].count = value
            selectedOptions[index].name = name
            setSelectedOptions(newArray)
        } else {
            // Jeśli obiekt o podanym id nie istnieje, dodaj nowy obiekt do tablicy
            setSelectedOptions([...selectedOptions, { id: option.id, name: name, count: value }])
        }
    }

    const handleUpdateValue = newValue => {
        setvalue(newValue)
    }

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
            width="100%"
            // maxWidth={{ xs: '100%', lg: '33.33%' }}
            px={{ xs: 2, lg: 4 }}
            py={{ xs: 2, lg: 2 }}
            my={2}
            sx={{
                transition: 'border-color 0.5s',
                border: isActive ? '1px solid #f07d14 ' : '1px solid #ccc',
                borderRadius: '10px',
            }}
        >
            <Box>
                <Typography fontSize={{ xs: 14, lg: 18 }} whiteSpace="pre-wrap">
                    {locale === 'pl' ? name.replace(/ - /g, '\n') : nameEn}
                </Typography>
                {name === 'Zgoda na wyjazd za granicę' && (
                    <Typography fontSize={{ xs: 10, lg: 12 }} pb={0.5} whiteSpace="pre-wrap">
                        {t('RAC.step_three.limit')}
                    </Typography>
                )}
                {name === 'Dodatkowy kierowca' && (
                    <Typography fontSize={{ xs: 10, lg: 12 }} pb={0.5} whiteSpace="pre-wrap">
                        {t('RAC.step_three.extra_driver')}
                    </Typography>
                )}

                {perDayPrice ? (
                    <Typography fontSize={{ xs: 14, lg: 18 }}>
                        <b>{perDayPrice} zł</b> / {t('RAC.step_two.price.per_day')}
                    </Typography>
                ) : (
                    unitPrice && (
                        <Typography fontSize={{ xs: 14, lg: 18 }}>
                            <b>{unitPrice} zł</b>
                        </Typography>
                    )
                )}
            </Box>
            {isReady ? (
                <NumberSelector initialValue={initialValue} min={min} max={max} handleClick={handleUpdateValue} />
            ) : (
                <Spinner size={20} />
            )}
        </Box>
    )
}

export default OptionItem
