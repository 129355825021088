import Axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { environment } from '../../environment'

function NavLangOffMob(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('NavLang')
    }

    const lang = window.location.pathname.split('/')[1].length > 2 || window.location.pathname.split('/')[1].length === 0 ? 'pl' : window.location.pathname.split('/')[1]
    const langMeta = document.documentElement.lang;
    let [navData, setNavData] = useState([]);
    useParams()
    let [jsonStatus, setJsonStatus] = useState(null)

    useEffect(() => {
        let fetchNavs = async () => {
            let url = null
            if (window.location.origin.includes(environment.cmsName)) {
                url = window.location.origin + environment.sitemapJson
            } else if (window.location.origin.includes(environment.localhost)) {
                url = environment.siteUrlDev + environment.sitemapJson
            } else {
                url = window.location.origin + environment.sitemapJson
            }
            let response = ''
            try {
                response = await Axios(url)
                setJsonStatus(true)
            } catch (err) {
                setJsonStatus(false)
                response = 'error'
            }
            if (response.hasOwnProperty('data')) {
                if (response.data) {
                    setNavData(response.data)
                    
                } else {
                    setJsonStatus(false)
                }
            } else {
                setJsonStatus(false)
            }
        }
        fetchNavs()
    }, [])
   
    function setSitemap(lang) {
        let paths = [];
        let url = window.location.pathname;
        if (url.includes(environment.lacRentUrl[lang])) {
            paths = [url.replace(environment.lacRentUrl['en'], environment.lacRentUrl['pl']), url.replace(environment.lacRentUrl['pl'], environment.lacRentUrl['en'])];
        }
        else if (url.includes(environment.racRentUrl[lang])) {
            paths = [url.replace(environment.racRentUrl['en'], environment.racRentUrl['pl']), url.replace(environment.racRentUrl['pl'], environment.racRentUrl['en'])];
        }
        else if (url.includes(environment.lacRentFlexUrl[lang])) {
            let par = window.location.search
            paths = [url.replace(environment.lacRentFlexUrl['en'], environment.lacRentFlexUrl['pl']) + par, url.replace(environment.lacRentFlexUrl['pl'], environment.lacRentFlexUrl['en']) + par];
        }
        else {
            navData.map((nav, i) => {
                let url = window.location.pathname.indexOf('index.html') > 0 ? window.location.pathname : window.location.pathname + environment.page
                if (nav[lang] === url) {
                    paths[0] = navData[i]['pl'].replace('index.html', '') //PL
                    paths[1] = navData[i]['en'].replace('index.html', '') //EN
                    //paths[2] = navData[i]['ua'].replace('index.html', '') //UA
                    return false;
                }
                return null
            })
        }
        if (paths.length < 1) paths = ['/','/en/'/*,[/ua/] */]
        return paths
    }

    function activeNavCMS(lan) {
        let status = ''
        if (lan === lang) status = ' text-orange-500'
        return status
    }

    switch (jsonStatus) {
        case null:
            return (
                <div
                    className="origin-top-right absolute right-0 mt-2 min-w-max rounded-md  bg-white bg-opacity-85 border border-white"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                >
                    <div className="py-1" role="none">
                        <button
                            className={'block px-4 py-2 text-sm text-navy-500 hover:text-orange-500 w-full text-left font-medium' + activeNavCMS('pl')}
                            role="menuitem"
                            onClick={() => {
                                window.location.href = '/';  
                            }}
                        >
                            PL
                        </button>
                        <button
                            className={'block px-4 py-2 text-sm text-navy-500 hover:text-orange-500 w-full text-left font-medium' + activeNavCMS('en')}
                            role="menuitem"
                            onClick={() => {
                                window.location.href = '/en/'; 
                            }}
                        >
                            EN
                        </button>
                    </div>
                </div>
            )
        case false:
            return (
                <div
                    className="origin-top-right absolute right-0 mt-2 min-w-max rounded-md  bg-white bg-opacity-85 border border-white"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                >
                    <div className="py-1" role="none">
                        <button
                            className={'block px-4 py-2 text-sm text-navy-500 hover:text-orange-500 w-full text-left font-medium' + activeNavCMS('pl')}
                            role="menuitem"
                            onClick={() => {
                                window.location.href = '/';  
                            }}
                        >
                            PL
                        </button>
                        <button
                            className={'block px-4 py-2 text-sm text-navy-500 hover:text-orange-500 w-full text-left font-medium' + activeNavCMS('en')}
                            role="menuitem"
                            onClick={() => {
                                window.location.href = '/en/'; 
                            }}
                        >
                            EN
                        </button>
                    </div>
                </div>
            )
        case true:
            return (
                <div
                    className="origin-top-right absolute right-0 mt-2 min-w-max rounded-md  bg-white bg-opacity-85 border border-white"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                >
                    <div className="py-1" role="none">
                        <button
                            className={'block px-4 py-2 text-sm text-navy-500 hover:text-orange-500 w-full text-left font-medium' + activeNavCMS('pl')}
                            role="menuitem"
                            onClick={() => {
                                window.location.href = setSitemap(lang)[0];  
                            }}
                        >
                            PL
                        </button>
                        <button
                            className={'block px-4 py-2 text-sm text-navy-500 hover:text-orange-500 w-full text-left font-medium' + activeNavCMS('en')}
                            role="menuitem"
                            onClick={() => {
                                window.location.href = setSitemap(lang)[1]; 
                            }}
                        >
                            EN
                        </button>
                    </div>
                </div>
            )
        default:
            break
    }
    return <></>
}
export default NavLangOffMob
