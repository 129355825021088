import React from 'react'
import { Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'

const BackButton = ({ handleClick, sx, ...props }) => {
    return props?.closeIcon ? (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <IconButton
                aria-label="close"
                onClick={handleClick}
                sx={{
                    color: theme => theme.palette.grey[500],
                    m: 1,
                    ...sx,
                }}
            >
                <CloseIcon />
            </IconButton>
        </Box>
    ) : (
        <IconButton color="primary" aria-label="close" onClick={handleClick} style={{ ...sx }}>
            <ArrowBackIosNewRoundedIcon />
        </IconButton>
    )
}
export default BackButton
