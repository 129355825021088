import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

export default function Spinner({ sx, size }) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5, ...sx }}>
            <CircularProgress size={size} />
        </Box>
    )
}
