import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { List, ListItem, ListItemButton } from '@mui/material'
import theme from '../../../styles/MUI-theme'

const FilterClass = ({ onFilterSelect }) => {
    const { locale } = useParams()

    const FILTERS = {
        Wszystkie: ['B', 'C', 'C+', 'D', 'D+', 'E', 'M', 'R', 'SUV', 'P'],
        Małe: ['B'],
        Średnie: ['C', 'C+'],
        Duże: ['D', 'D+', 'E'],
        'BUSy i Vany (7-9 os.)': ['M', 'R'],
        SUV: ['SUV'],
        Dostawcze: ['P'],
    }

    const FILTER_LABELS = Object.keys(FILTERS)
    const FILTER_LABELS_EN = ['All', 'Small', 'Medium', 'Large', 'BUSes and Vans (7-9 pers.)', 'SUV', 'Delivery']

    const [chosenFilter, setChosenFilter] = useState(FILTER_LABELS[0])

    const handleClick = label => {
        setChosenFilter(label)
        onFilterSelect(FILTERS[label])
    }

    return (
        <>
            <List sx={{ display: 'flex', overflowX: { xs: 'scroll', lg: 'auto' }, mb: 2 }}>
                {FILTER_LABELS.map((label, i) => (
                    <ListItem
                        key={`ListItemButton-${label}`}
                        sx={{
                            display: 'inline-block',
                            width: 'auto',
                            margin: 0,
                            marginRight: { xs: 1, lg: 2 },
                            padding: 0,
                        }}
                    >
                        <ListItemButton
                            sx={{
                                border: `1px solid ${theme.palette.primary.main}`,
                                color: label === chosenFilter ? 'white' : theme.palette.primary.main,
                                borderRadius: '5px',
                                fontSize: { xs: 12, lg: 16 },
                                whiteSpace: 'nowrap',
                                background: label === chosenFilter ? theme.palette.primary.main : 'white',
                                '&:hover': {
                                    background: label === chosenFilter && theme.palette.primary.main,
                                },
                            }}
                            onClick={() => handleClick(label)}
                        >
                            {locale === 'pl' ? label : FILTER_LABELS_EN[i]}
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </>
    )
}

export default FilterClass
