import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconTire() {
    return (
        <SvgIcon>
            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15 9C15 6.5 13.6562 4.21875 11.5 2.96875C9.3125 1.6875 6.65625 1.6875 4.5 2.96875C2.3125 4.21875 1 6.5 1 9C1 11.5312 2.3125 13.8125 4.5 15.0625C6.65625 16.3438 9.3125 16.3438 11.5 15.0625C13.6562 13.8125 15 11.5312 15 9ZM0 9C0 6.15625 1.5 3.53125 4 2.09375C6.46875 0.65625 9.5 0.65625 12 2.09375C14.4688 3.53125 16 6.15625 16 9C16 11.875 14.4688 14.5 12 15.9375C9.5 17.375 6.46875 17.375 4 15.9375C1.5 14.5 0 11.875 0 9ZM9.96875 9.125L11.9062 9.78125C11.9688 9.53125 12 9.28125 12 9C12 7.875 11.5 6.8125 10.7188 6.09375L9.53125 7.75C9.8125 8.09375 10 8.53125 10 9C10 9.0625 10 9.09375 9.96875 9.125ZM9.6875 10.0938C9.40625 10.5 8.96875 10.8125 8.5 10.9375V12.9688C9.875 12.8125 11.0312 11.9375 11.5938 10.7188L9.65625 10.0938H9.6875ZM7.5 12.9688V10.9375C7 10.8125 6.5625 10.5 6.3125 10.0938L4.375 10.7188C4.9375 11.9375 6.09375 12.8125 7.5 12.9688ZM4.0625 9.78125L6 9.125C6 9.09375 6 9.0625 6 9C6 8.53125 6.15625 8.09375 6.4375 7.75L6.125 7.3125L5.25 6.09375C4.46875 6.84375 4 7.875 4 9C4 9.28125 4 9.53125 4.0625 9.78125ZM7.25 7.15625C7.46875 7.0625 7.71875 7 8 7C8.25 7 8.5 7.0625 8.71875 7.15625L9.90625 5.5C9.34375 5.1875 8.6875 5 8 5C7.28125 5 6.625 5.1875 6.0625 5.5L6.9375 6.71875L7.25 7.15625ZM9 9C9 8.46875 8.53125 8 8 8C7.4375 8 7 8.46875 7 9C7 9.5625 7.4375 10 8 10C8.53125 10 9 9.5625 9 9ZM8 4C9.78125 4 11.4062 4.96875 12.3125 6.5C13.2188 8.0625 13.2188 9.96875 12.3125 11.5C11.4062 13.0625 9.78125 14 8 14C6.1875 14 4.5625 13.0625 3.65625 11.5C2.75 9.96875 2.75 8.0625 3.65625 6.5C4.5625 4.96875 6.1875 4 8 4Z"
                    fill="#202020"
                />
            </svg>
        </SvgIcon>
    )
}
