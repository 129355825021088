import React from 'react'
import { FormControlLabel, Checkbox, Box } from '@mui/material'
import { ErrorMessage, Field } from 'formik'

const MuiCheckbox = ({ name, label, touched, errors, mb = 2, ...props }) => (
    <Box mb={mb}>
        <Field
            type="checkbox"
            name={name}
            as={FormControlLabel}
            control={<Checkbox style={{ color: errors && touched ? '#ff0000' : undefined }} />}
            label={label}
            // sx={{ alignItems: props.aligntop ? 'flex-start' : 'center' }}
            {...props}
        />
        <ErrorMessage name={name} component="div" className="error" />
    </Box>
)

export default MuiCheckbox
