import Axios from 'axios'
import React, { useState, useEffect } from 'react'
import { environment } from '../../environment.js'
import NotLogged from './NotLogged.js'
import Logged from './Logged.js'

function LogBox(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('LogBox')
        console.log(params)
    }

    let [isLogged, setIsLogged] = useState([null, '']);

    useEffect(() => {
        let fetchIsLogged = async () => {
            let url = (window.origin.includes(environment.localhost) || window.origin.includes(environment.cmsName)) ? environment.siteUrlDev + environment.isLogged : environment.isLogged
            let response = ''
            try {
                response = await Axios(url)
                if (response.hasOwnProperty('data')) {
                    if (response.data && response.data.hasOwnProperty('logged')) {
                        if (response.data.logged) {
                            if (response.data.hasOwnProperty('name')) {
                                setIsLogged([response.data.logged, response.data.name])
                            }
                            else {
                                setIsLogged([response.data.logged, 'User'])
                            }
                        }
                        else {
                            setIsLogged([response.data.logged, ''])
                        }
                    } else {
                        setIsLogged([false, ''])
                    }
                } else {
                    setIsLogged([false, ''])
                }
            } catch (err) {
                setIsLogged([false, ''])
                response = 'error: ' + err
            }
        }
        fetchIsLogged()
    }, [window.location.pathname])

    switch (isLogged[0]) {
        case null:
            return (
                <NotLogged type={params.type} />
            )
        case false:
            return (
                <NotLogged type={params.type} />
            )
        case true:
            return (
                <Logged name={isLogged[1]} type={params.type} />
            )
        default:
            break
    }
}
export default LogBox
