import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import useFetch from '../../hooks/useFetch'
import Spinner from '../../components/Spinner'
import { environment } from '../../../environment'
import ErrorBox from '../ErrorBox'
import { API, FORM_KEY } from '../../api'
import theme from '../../../styles/MUI-theme'
import IconSearchResult from '../../components/IconSearchResult'
import useFormattedPrice from '../../hooks/useFormattedPrice'

const ReservationDetailsCar = ({ processData }) => {
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()
    const { loading, error, fetchData } = useFetch()

    const formattedRentPriceFullCalc = useFormattedPrice(processData?.full_calculation_price_rent)

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        console.log(processData)
    }, [])

    const handleClickEditStep2 = () => {
        fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=${FORM_KEY['step2']}`, 'GET')
    }

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return (
        <Box
            width="100%"
            p={{ xs: 1, lg: 2 }}
            display="flex"
            justifyContent="space-between"
            borderBottom={`0.5px solid ${theme.palette.secondary.main}`}
            bgcolor="white"
        >
            <>
                <Box display={'flex'}>
                    <IconSearchResult icon="Car" />
                    {processData ? (
                        <Box>
                            <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'bold'}>
                                {processData?.model?.split(' ').slice(0, 2).join(' ') || processData?.model || ''}
                            </Typography>
                            <Typography fontSize={{ xs: 10, lg: 14 }} fontWeight={'regular'}>
                                {t('RAC.step_two.others')}
                            </Typography>
                            <Typography fontSize={{ xs: 10, lg: 14 }} fontWeight={'regular'}>
                                {processData?.transmission}
                                , {processData?.seats} {t('RAC.step_two.car_equipment.seats')}
                                {/* , {processData?.fuelType} */}
                            </Typography>
                        </Box>
                    ) : (
                        <Spinner size={20} sx={{ m: 0 }} />
                    )}
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                    <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'regular'} textAlign={'right'}>
                        {formattedRentPriceFullCalc}
                    </Typography>
                    <Box
                        mt={1}
                        role="button"
                        type="submit"
                        color={theme.palette.primary.main}
                        onClick={handleClickEditStep2}
                        sx={{ color: theme.palette.primary.main, fontSize: { xs: 12, lg: 16 } }}
                    >
                        {t('btn.edit')}
                    </Box>
                </Box>
            </>
        </Box>
    )
}

export default ReservationDetailsCar
