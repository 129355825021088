const { REACT_APP_API_URL } = window.location.href.includes('cms') ? 'https://dev-express.qrqs.eu' : process.env

let API = {
    GET_RELEASE_PLACES: `/api/rac/releasePlaces`,
    GET_CAR_CLASSES: `/api/rac/carClasses`,
    GET_EXTRAS: `/api/rac/extras`,
    GET_DICT_FUEL_TYPES: `/api/dict/fuelTypes.json`,
    GET_DICT_TRANSMISSION_TYPES: `/api/dict/transmissionTypes.json`,
    GET_DICT_COUNTRIES: `/api/dict/country.json`,
    GET_DATA: `/ajax/Rac/processData/`,
    SAVE_DATA: `/ajax/Rac/step/`,
    REGISTER: `/ajax/Rac/register/`,
    PROMO_CODE: `/ajax/Rac/step/?navigation=promo_code`,
    KRD_VERYFICATION: `/ajax/Rac/step/?navigation=krd_verification`,
    ACCOUNT_EXISTS: `/ajax/User/accountExists/`,
    GET_COMPANY_DATA: `/ajax/User/getCompanyData/`,
    USER_IS_LOGGED: `/ajax/User/isLogged/`,
    USER_GET_DATA: `/ajax/User/getData/`,
    GET_FILE_REGULATIONS: `/api/rac/getReportDocument/Regulamin`,
    GET_FILE_REGULATIONS_EN: `/api/rac/getReportDocument/Regulamin_EN`,
    GET_FILE_LEASE_AGREEMENT: `/api/rac/getReportDocument/UmowaNajmuPojazdu`,
    GET_FILE_LEASE_AGREEMENT_EN: `/api/rac/getReportDocument/UmowaNajmuPojazdu_EN`,
    GET_PAYMENT_STATUS: `/ajax/Payments/check/`,
}

if (window.location.host.includes('localhost')) {
    API = {
        GET_RELEASE_PLACES: `https://dev-express.qrqs.eu/api/rac/releasePlaces`,
        GET_CAR_CLASSES: `https://dev-express.qrqs.eu/api/rac/carClasses`,
        GET_EXTRAS: `https://dev-express.qrqs.eu/api/rac/extras`,
        GET_DICT_FUEL_TYPES: `https://dev-express.qrqs.eu/api/dict/fuelTypes.json`,
        GET_DICT_TRANSMISSION_TYPES: `https://dev-express.qrqs.eu/api/dict/transmissionTypes.json`,
        GET_DICT_COUNTRIES: `https://dev-express.qrqs.eu/api/dict/country.json`,
        GET_DATA: `https://dev-express.qrqs.eu/ajax/Rac/processData/`,
        SAVE_DATA: `https://dev-express.qrqs.eu/ajax/Rac/step/`,
        REGISTER: `https://dev-express.qrqs.eu/ajax/Rac/register/`,
        PROMO_CODE: `https://dev-express.qrqs.eu/ajax/Rac/step/?navigation=promo_code`,
        KRD_VERYFICATION: `https://dev-express.qrqs.eu/ajax/Rac/step/?navigation=krd_verification`,
        ACCOUNT_EXISTS: `https://dev-express.qrqs.eu/ajax/User/accountExists/`,
        GET_COMPANY_DATA: `https://dev-express.qrqs.eu/ajax/User/getCompanyData/`,
        USER_IS_LOGGED: `https://dev-express.qrqs.eu/ajax/User/isLogged/`,
        USER_GET_DATA: `https://dev-express.qrqs.eu/ajax/User/getData/`,
        GET_FILE_REGULATIONS: `https://dev-express.qrqs.eu/api/rac/getReportDocument/Regulamin`,
        GET_FILE_REGULATIONS_EN: `https://dev-express.qrqs.eu/api/rac/getReportDocument/Regulamin_EN`,
        GET_FILE_LEASE_AGREEMENT: `https://dev-express.qrqs.eu/api/rac/getReportDocument/UmowaNajmuPojazdu`,
        GET_FILE_LEASE_AGREEMENT_EN: `https://dev-express.qrqs.eu/api/rac/getReportDocument/UmowaNajmuPojazdu_EN`,
        GET_PAYMENT_STATUS: `https://dev-express.qrqs.eu/ajax/Payments/check/`,
    }
}

export { API }

export const FORM_KEY = {
    step1: 'date_locations_selection',
    step2: 'offer_presentation',
    step3: 'packages_equipment_selection',
    step4: 'client_data_invoice_krd',
    step5: 'payment_initialization',
    summary: 'summary_payment',
    payByLink: 'bank_payment',
    reservationSummary: 'reservation_summary',
    krdNegativeVerification: 'krd_negative_verification',
}
