import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Box, Typography, Link, Alert, Divider, Button } from '@mui/material'
import useFormattedPrice from '../../hooks/useFormattedPrice'
import ChooseButton from '../../components/ChooseButton'
import Spinner from '../../components/Spinner'
import Price from '../Step3/Price'

import theme from '../../../styles/MUI-theme'

const SummaryStep4 = ({
    carNumber = 1,
    processData,
    priceNetto,
    priceBrutto,
    insurancePackagePriceNetto = 0,
    insurancePackagePriceBrutto = 0,
    gpsPriceNetto = 0,
    gpsPriceBrutto = 0,
    fuelCardPriceNetto = 0,
    fuelCardPriceBrutto = 0,
    errorPickupLocation,
    serviceName0,
    serviceName1,
    isSubmitting,
    prevStep,
    showButton = true
}) => {
    const { t } = useTranslation()
    const { locale } = useParams()

    const fullPriceNetto = useFormattedPrice(
        parseFloat(carNumber * (priceNetto + insurancePackagePriceNetto + gpsPriceNetto + fuelCardPriceNetto)).toFixed(
            2
        )
    )
    const fullPriceBrutto = useFormattedPrice(
        parseFloat(
            carNumber * (priceBrutto + insurancePackagePriceBrutto + gpsPriceBrutto + fuelCardPriceBrutto)
        ).toFixed(2)
    )

    return (
        <Box>
            <Box p={2} border="1px solid #e0e0e0" borderRadius="10px 10px 0 0 ">
                <Box>
                    <Typography fontSize={14} mb={0.2}>
                        {t('LAC.offer_presentation.summary_selected_model')}
                    </Typography>
                    <Typography fontSize={20} fontWeight="bold">
                        {processData?.car_name}
                    </Typography>
                    <Price
                        priceNetto={processData?.offer_rent_rent_price}
                        priceBrutto={processData?.offer_rent_rentgross}
                    />
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box>
                    <Typography fontSize={14}>{t('LAC.offer_presentation.summary_limit')}</Typography>
                    <Typography fontSize={20} fontWeight="bold">
                        {processData?.monthly_km_limit === '0' ? 'PPM (Pay-Per-Mile)' : processData?.monthly_km_limit}
                    </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box>
                    <Typography fontSize={14}>{t('LAC.offer_presentation.summary_rental_period')}</Typography>
                    <Typography fontSize={20} fontWeight="bold">
                        {processData?.rental_period}
                    </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box>
                    <Typography fontSize={14}>{t('LAC.offer_presentation.summary_cars_number')}</Typography>
                    <Typography fontSize={20} fontWeight="bold">
                        {processData?.car_number}
                    </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box>
                    <Typography fontSize={14}>{t('LAC.offer_presentation.summary_pickup_datetime')}</Typography>
                    <Typography fontSize={20} fontWeight="bold">
                        {locale === 'pl'
                            ? dayjs(processData?.pickup_date).format('DD.MM.YYYY, HH:mm')
                            : dayjs(processData?.pickup_date).format('MMMM D, YYYY, h:mm A')}
                    </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box>
                    <Typography fontSize={14}>{t('LAC.offer_presentation.summary_pickup_place')}</Typography>
                    <Typography fontSize={20} fontWeight="bold">
                        {processData?.summary_pickup_place}
                    </Typography>
                    <Price
                        unitPrice
                        priceNetto={processData?.quote_calculation_pickupFee || ''}
                        priceBrutto={processData?.quote_calculation_pickupFee_gross || ''}
                    />
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box>
                    <Typography fontSize={14} mb={1}>
                        {t('LAC.offer_presentation.summary_package')}
                    </Typography>
                    {processData?.insurance_package_price && processData?.insurance_package_price !== '0' ? (
                        <>
                            <Typography fontSize={20} fontWeight="bold">
                                {processData?.insurance_package_name}
                            </Typography>
                            <Price
                                priceNetto={processData?.insurance_package_price}
                                priceBrutto={processData?.insurance_package_price_gross}
                            />
                        </>
                    ) : (
                        <Alert severity="info" sx={{ mb: 2 }}>
                            {t('LAC.offer_presentation.alert_package')}
                            <Button
                                fontWeight="bold"
                                fontSize={{ xs: 12, lg: 14 }}
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontSize: { xs: 12, lg: 14 },
                                    lineHeight: { xs: '12px', lg: '14px' },
                                    fontWeight: 'bold',
                                    mx: 1,
                                    my: 0,
                                }}
                                onClick={() => {
                                    prevStep()
                                }}
                            >
                                {t('btn.edit')}
                            </Button>
                        </Alert>
                    )}
                </Box>
                <>
                    {/* <Box>
                        {processData?.services_with_fuel_card !== 'false' ? (
                            <>
                                <Typography fontSize={20} fontWeight="bold">
                                    {t('LAC.offer_presentation.fuel_card')}
                                </Typography>
                                <Price priceNetto={fuelCardPriceNetto} priceBrutto={fuelCardPriceBrutto} />
                                {processData?.services_fuel_card_price && processData?.services_fuel_card_price && (
                                    <Box mb={2} />
                                )}
                            </>
                        ) : (
                            <Alert severity="info">
                                {t('LAC.offer_presentation.alert_fuel_card')}
                                <Button
                                    fontWeight="bold"
                                    fontSize={{ xs: 12, lg: 14 }}
                                    sx={{
                                        color: theme.palette.primary.main,
                                        fontSize: { xs: 12, lg: 14 },
                                        lineHeight: { xs: '12px', lg: '14px' },
                                        fontWeight: 'bold',
                                        mx: 1,
                                        my: 0,
                                    }}
                                    onClick={() => {
                                        prevStep()
                                    }}
                                >
                                    {t('btn.edit')}
                                </Button>
                            </Alert>
                        )}
                    </Box> */}
                    {processData?.services_with_gps !== 'false' && (
                        <>
                            <Divider sx={{ my: 1 }} />
                            <Typography fontSize={14} mb={1}>
                                {t('LAC.offer_presentation.summary_extras')}
                            </Typography>
                            <Typography fontSize={20} fontWeight="bold">
                                {t('LAC.offer_presentation.monitoring')}
                            </Typography>
                            <Price priceNetto={gpsPriceNetto} priceBrutto={gpsPriceBrutto} />
                        </>
                    )}
                </>
            </Box>
            <Box backgroundColor={theme.palette.primary.main} borderRadius="0 0 10px 10px" color="#FAFAFA" p={2}>
                <Typography fontSize={14} color="#FAFAFA">
                    {t('LAC.offer_presentation.summary_cost')}
                </Typography>
                <Typography fontSize={40} fontWeight="bold" color="#FAFAFA">
                    {fullPriceNetto} netto
                </Typography>
                <Typography fontSize={20} color="#FAFAFA">
                    {fullPriceBrutto} {t('gross')}
                </Typography>

                {!errorPickupLocation && (
                    <ChooseButton id="L4" disabled={isSubmitting || !showButton} sx={{ mt: 2, width: '100%' }} type="submit" showButton={showButton}>
                        {!isSubmitting ? (
                            t('LAC.client_data.button_4')
                        ) : (
                            <Spinner
                                size={23}
                                sx={{
                                    margin: 0,
                                }}
                            />
                        )}
                    </ChooseButton>
                )}
            </Box>
        </Box>
    )
}

export default SummaryStep4
