import React from 'react'
import Container from '../../components/Container'
import WhyUsItem from './whyUsItem'
import { environment } from './../../environment'

const WhyUs = params => {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('WhyUs')
        console.log(params)
    }
    let TitleColor = params.block[params.locale].hasOwnProperty('TitleColor') ? 'c-'+params.block[params.locale]['TitleColor'] : 'c-color1';

    return (
        <section className="whyUs">
            <Container className="my-10">
                {params.block[params.locale]['Title'] && <h2 className={TitleColor}>{params.block[params.locale]['Title']}</h2>}
                {params.block[params.locale]['Subtitle'] && <h3>{params.block[params.locale]['Subtitle']}</h3>}
                <div className="sm:grid grid-cols-3 grid-flow-col gap-10 mt-16">
                    {params.block[params.locale]['Item'].map((item, i) => (
                        <WhyUsItem
                            key={`item-${i}`}
                            block={item}
                            headline={item['Headline']}
                            description={item['Description']}
                        />
                    ))}
                </div>
            </Container>
        </section>
    )
}

export default WhyUs
