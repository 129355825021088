import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { API } from '../../api'
import Spinner from '../../components/Spinner'
import CarClassItem from './CarClassItem'
import { environment } from '../../../environment'
import ErrorBox from '../ErrorBox'

const CarClasses = ({
    offer,
    filters,
    anyFuelFilterChecked,
    anyTransmissionFilterChecked,
    anySeatsFilterChecked,
    fuelTypes,
    transmissionTypes,
}) => {
    const { t, i18n } = useTranslation()
    const { locale } = useParams()

    const [data, setData] = useState([])
    const [updatedData, setUpdatedData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [dataIsUpdated, setDataIsUpdated] = useState(false)
    const [dataIsFiltering, setDataIsFiltering] = useState(true)

    const [filteredCars, setFilteredCars] = useState([])

    const fetchData = async url => {
        const response = await fetch(url)
        if (!response.ok) {
            throw new Error(t('errors.try_again'))
        }
        return response.json()
    }

    const fetchDataAsync = async () => {
        setLoading(true)
        try {
            const url = `${API[`GET_CAR_CLASSES`]}`
            const result = await fetchData(url)
            setData(result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        fetchDataAsync()
    }, [])

    useEffect(() => {
        if (!loading) {
            const newData = data?.map(item => ({
                ...item,
                // fuelType: fuelTypes[item.fuelType],
                // transmissionType: transmissionTypes[item.transmissionType],
            }))

            setUpdatedData(newData)
        }
    }, [data, loading])

    useEffect(() => {
        updatedData.length > 0 && setDataIsUpdated(true)
    }, [updatedData])

    useEffect(() => {
        setDataIsFiltering(true)
        dataIsUpdated &&
            setFilteredCars(
                updatedData
                    .filter(car => {
                        if (!anyTransmissionFilterChecked) return true; // Jeśli żaden filtr nie jest zaznaczony, zwróć wszystkie samochody
                        
                        // Rozbij transmissionType na części
                        const transmissions = car.transmissionType?.split('/') || [];
                        
                        // Sprawdź, czy którakolwiek część jest zaznaczona w filtrach
                        return transmissions.some(transmission => filters.filterTransmission[transmission]);
                    })
                    .filter(car =>
                        anySeatsFilterChecked
                            ? (filters.filterSeats.two && car.seats === 2) ||
                              (filters.filterSeats.three && car.seats === 3) ||
                              (filters.filterSeats.five && car.seats === 5) ||
                              (filters.filterSeats.seven && car.seats === 7) ||
                              (filters.filterSeats.nine && car.seats === 9) ||
                              car.seats === 'Unspecified'
                            : filters.filterSeats
                    )
                    .filter(car => (anyFuelFilterChecked ? filters.filterFuel[car.fuelType] : filters.filterFuel))
                    .filter(car => {
                        return (
                            (filters.filterSmallBags && parseInt(filters.filterSmallBags) <= parseInt(car.smallBags)) ||
                            car.smallBags === null
                        )
                    })
                    .filter(car => {
                        return (
                            (filters.filterLargeBags && parseInt(filters.filterLargeBags) <= parseInt(car.largeBags)) ||
                            car.largeBags === null
                        )
                    })
                    .filter(car => {
                        return (
                            (filters.filterGroupClasess.length > 0 && filters.filterGroupClasess.includes(car.name)) ||
                            filters.filterGroupClasess.length === 0
                        )
                    })
            )
    }, [updatedData, filters, dataIsUpdated])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('NEW CarClasses:', data)
    }, [data])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('filteredCars:', filteredCars)

        setDataIsFiltering(false)
    }, [filteredCars])

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return dataIsUpdated ? (
        <Box mb={1}>
            {filteredCars?.length === 0
                ? !dataIsFiltering && <Typography>{t('RAC.step_two.no_offers')}</Typography>
                : filteredCars?.map(carClass => (
                      <Box key={carClass.id} width="100%">
                          {carClass.exampleCars.map(
                              (example, i) =>
                                  // tylko pierwszy reprezentant z listy
                                  i === 0 && (
                                      <CarClassItem
                                          key={example.id}
                                          carClass={carClass}
                                          example={example}
                                          offer={offer}
                                          fuelTypes={fuelTypes}
                                          transmissionTypes={transmissionTypes}
                                      />
                                  )
                          )}
                      </Box>
                      //   <Box key={carClass.id} width="100%">
                      //       {carClass.exampleCars.map(example => (
                      //           <CarClassItem
                      //               key={example.id}
                      //               carClass={carClass}
                      //               example={example}
                      //               offer={offer}
                      //               fuelTypes={fuelTypes}
                      //           />
                      //       ))}
                      //   </Box>
                  ))}
        </Box>
    ) : (
        <Spinner />
    )
}

export default CarClasses
