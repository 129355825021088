import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { environment } from '../../environment'
import { API } from '../../forms/api'
import { API as API2 } from '../../forms/LAC/api'
import Spinner from '../../forms/components/Spinner'
import LocationDetails from './LocationDetails'
import Image from '../../components/Image'
import { addTargeoScript } from '../../addTargeoScript'
import FormStep1 from '../../forms/RAC/FormStep1'
import FormStep1Lac from '../../forms/LAC/FormStep1'
import FormStep1cms from '../../forms/RAC/FormStep1cms'
import LocationDetailsLac from './LocationDetailsLac'
import FormStep1CMS from '../../forms/LAC/FormStep1CMS'
import { FormProvider } from '../../forms/LAC/FormContext'
import TextLinks from '../../components/TextLinks'

export default function PickupBox(params) {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const isCmsInUrl = window.location.href.includes('cms')

    const portletType = params?.block?.[params.locale]?.['Type'] ? params?.block?.[params.locale]?.['Type'] : null
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('PickupBox')
        console.log(params)
    }
    let Text = params.block[params.locale].hasOwnProperty('Text') ? params.block[params.locale]['Text'] : '';
    const { t, i18n } = useTranslation()
    useEffect(() => {
        i18n.changeLanguage(params.locale)
    }, [params.locale])

    useEffect(() => {
        addTargeoScript('Two')
        getReleasePlaces()
    }, [])

    const getReleasePlaces = async () => {
        const id = params?.block?.[params.locale]?.['ID'] ? parseInt(params.block[params.locale]['ID']) : null
        setIsLoading(true)
        if (id) {
            const apiUrl =
                params?.block?.[params.locale]?.['Type'] === 'lac' ? API2['GET_LOCATIONS'] : API['GET_RELEASE_PLACES']
            try {
                const response = await fetch(apiUrl, {
                    method: 'GET',
                })
                if (response.ok) {
                    const res = await response.json()
                    // return
                    const filteredItems =
                        params?.block?.[params.locale]?.['Type'] === 'lac'
                            ? res.filter(item => item.id === id)
                            : res.releasePlaces.filter(item => item.id === id)
                    const [item] = filteredItems || []
                    setData(item)
                    setIsLoading(false)
                } else {
                    console.error('Error fetching data')
                    setIsLoading(false)
                }
            } catch (error) {
                console.error('Error fetching data', error)
                setError(error)
                setIsLoading(false)
            }
        }
    }

    let img = params.block[params.locale].hasOwnProperty('Image')
        ? params.block[params.locale]['Image'].hasOwnProperty('link')
            ? params.block[params.locale]['Image']['link']
            : environment.defaultImage
        : environment.defaultImage
    let imgUrl = img

    if (img !== undefined && img !== '') {
        if (img.includes(environment.siteSites)) {
            img = img.split(environment.siteSites)
            img = img[1]
            if (params.line === 'offline') {
                imgUrl = window.origin + img
            } else if (window.origin.includes(environment.localhost)) {
                imgUrl = environment.siteUrlDev + img
            } else {
                imgUrl = window.origin + img
            }
        } else if (window.origin.includes(environment.localhost)) {
            imgUrl = environment.siteUrlDev + img
        } else {
            imgUrl = img
        }
    } else {
        imgUrl = environment.defaultImage
    }
    let ImageUrl = imgUrl

    if (isLoading) {
        return (
            <section>
                <Spinner />
            </section>
        )
    }
    let locationName = portletType === 'lac' && data.name ? data.name.split(',')[0] : null
    return (
        <section className={'pickup-box'} style={{ borderTop: '1px solid #e4e4e4' }} >
            <div className="wrapper">
                <div className={'container mx-auto px-8 mt-10 mb-20'}>
                    {params.block[params.locale]['Title'] && (
                        <h3 className="c-color1 font-bold text-4xl lg:text-[44px] leading-tight mb-2 mt-2">
                            {params.block[params.locale]['Title']} {locationName ? locationName : data.city}
                        </h3>
                    )}
                    {data?.name ? (
                        <>
                            {portletType === 'lac' ? (
                                <LocationDetailsLac place={data} />
                            ) : (
                                <LocationDetails place={data} />
                            )}
                        </>
                    ) : null}
                    <div className="flex justify-center mt-8">
                        <Image className="w-auto" src={ImageUrl} alt="Express Car Rental" />
                    </div>
                    {Text !== '' && (
                        <div className="mt-6 ul-custom">
                            <TextLinks text={Text} line={params.line} />
                        </div>
                    )}
                    {data?.city && portletType !== 'lac' ? (
                        <div className="max-w-[800px] mx-auto">
                            {isCmsInUrl ? (
                                <FormStep1cms locale={params.locale} />
                            ) : (
                                <FormStep1 line={params.line} locale={params.locale} id={data.id} name={data.name} />
                            )}
                        </div>
                    ) : null}
                    {portletType === 'lac' ? (
                        <FormProvider params={params}>
                            <div className="max-w-[800px] mx-auto mt-10">
                                <h2 className='c-color1 font-bold text-4xl lg:text-[44px] leading-tight mb-0'>{t('LAC.step_one.additional_title')}</h2>
                                {isCmsInUrl ? (
                                    <FormStep1CMS isStepper={false} params={params} line={params.line} />
                                ) : (
                                    <FormStep1Lac isStepper={false} params={params} line={params.line} />
                                )}
                            </div>
                        </FormProvider>
                    ) : null}
                </div>
            </div>
        </section>
    )
}
