import Axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { environment } from '../../environment.js'
import Loaders from '../../Loaders.js'
import Svg from '../Svg.js'

function useOutsideAlerter(ref, changeState) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                changeState(false)
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])
}

const Redirect = {
    'aktualnosci/': 'aktualnosci/',
}
const OuterRedirect = {
    'outer/': 'https://outer.com/',
}

function Nav(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Build Nav')
    }
    let [navData, setNavData] = useState([])
    let [listEleId, setListEleId] = useState('')
    let [isSubOpened, setIsSubOpened] = useState(false)
    const lang = window.location.pathname.split('/')[1].length > 2 || window.location.pathname.split('/')[1].length === 0 ? 'pl' : window.location.pathname.split('/')[1]
    const menuRef = useRef(null)
    useOutsideAlerter(menuRef, setIsSubOpened)
    useParams()
    let [jsonStatus, setJsonStatus] = useState(null)
    useEffect(() => {
        let fetchNavs = async () => {
            let url = null
            if (window.location.origin.includes(environment.cmsName)) {
                url = window.location.origin + environment.navJson
            } else if (window.location.origin.includes(environment.localhost)) {
                url = environment.siteUrlDev + environment.navJson
            } else {
                url = window.location.origin + environment.navJson
            }
            let response = ''
            try {
                response = await Axios(url)
                setJsonStatus(true)
            } catch (err) {
                setJsonStatus(false)
                response = 'error'
                console.log('error get nav: ' + err)
            }
            if (response.hasOwnProperty('data')) {
                if (response.data.length > 0) {
                    if (lang === 'pl') {
                        let navs = response.data;
                        response.data.map((nav, i) => {
                            const path = nav.hasOwnProperty('path') ? nav.path : '';
                            if (path === 'en/' || path === 'ua/') delete navs[i];
                            
                        })
                        setNavData(navs)
                    }
                    else {
                        response.data.map((nav, i) => {
                            const path = nav.hasOwnProperty('path') ? nav.path : '/';
                            if (path === lang + '/') setNavData(response.data[i].children)
                            return null
                        })
                    }
                } else {
                    setJsonStatus(false)
                }
            } else {
                setJsonStatus(false)
            }
        }
        fetchNavs()
    }, [])
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log(navData)
    }
    function RedirectLink(item) {
        if (item.line === 'online') {
            if (Redirect.hasOwnProperty(item.link)) {
                return (
                    <Link
                        to={Redirect[item.link]}
                        className="menu-item-href btn-link"
                        title={item.text}
                        onClick={e => {
                            setNavActive(item.link)
                            setIsSubOpened(false)
                        }}
                    >
                        {item.text}
                    </Link>
                )
            } else {
                return (
                    <Link
                        to={item.link}
                        className="menu-item-href btn-link"
                        title={item.text}
                        onClick={e => {
                            setNavActive(item.link)
                            setIsSubOpened(false)
                        }}
                    >
                        {item.text}
                    </Link>
                )
            }
        } else {
            if (Redirect.hasOwnProperty(item.link)) {
                return (
                    <a href={Redirect[item.link]} title={item.text} className="menu-item-href btn-link">
                        {item.text}
                    </a>
                )
            } else {
                return (
                    <a href={item.link} title={item.text} className="menu-item-href btn-link">
                        {item.text}
                    </a>
                )
            }
        }
    }
    let [navActive, setNavActive] = useState('')
    useEffect(() => {
        setNavActive(window.location.pathname)
    }, [window.location.pathname])
    function activeNav(nav) {
        let status = ''
        let url = window.location.pathname.includes('index.html')
            ? window.location.pathname.replace('index.html', '')
            : window.location.pathname
        if (url.includes(nav) || (url === '/' && nav === environment.mainPage.substring(1))) {
            status = ' active'
        }
        return status
    }
    let mainSlide = ''
    function activeMainNav(nav, slide) {
        let status = ''
        let url = window.location.pathname.includes('index.html')
            ? window.location.pathname.replace('index.html', '')
            : window.location.pathname
        if (url.includes(nav)) {
            removeSlideBodyActive()
            status = 'active'
            mainSlide = slide
            if (!document.body.classList.contains(mainSlide + '-active')) {
                document.body.classList.add(mainSlide + '-active')
            }
        }
    }
    function removeSlideBody() {
        if (document.body.classList.contains('dropdownup')) {
            document.body.classList.remove('dropdownup')
        }
        if (document.body.classList.contains('slideup')) {
            document.body.classList.remove('slideup')
        }
    }
    function removeSlideBodyActive() {
        if (document.body.classList.contains('dropdown-active')) {
            document.body.classList.remove('dropdown-active')
        }
        if (document.body.classList.contains('slideup-active')) {
            document.body.classList.remove('slideup-active')
        }
    }
    function slideUp(type) {
        if (document.body.classList.contains('dropdownup')) {
            document.body.classList.remove('dropdownup')
        }
        if (document.body.classList.contains('slideup')) {
            document.body.classList.remove('slideup')
        }
        if (type === 'slideup') {
            if (!document.body.classList.contains('slideup')) {
                document.body.classList.add('slideup')
            }
            if (document.body.classList.contains('dropdownup')) {
                document.body.classList.remove('dropdownup')
            }
        } else {
            if (document.body.classList.contains('slideup')) {
                document.body.classList.remove('slideup')
            }
            if (!document.body.classList.contains('dropdownup')) {
                document.body.classList.add('dropdownup')
            }
        }
    }
    function RenderNavs() {
        return params.line === 'offline'
            ? navData.map((item, i) => {
                  return (
                      <li
                          id={`menu-item-` + i}
                          key={`menu-item-` + i + '_' + i}
                          data-active={navActive}
                          className={
                              (item.children && item.children.length > 0 ? 'dropdown' : 'slideup') +
                              activeNav(item.path) +
                              ' ' +
                              activeMainNav(
                                  item.path,
                                  item.children && item.children.length > 0 ? 'dropdown' : 'slideup'
                              ) +
                              (item.hasOwnProperty('navShow') ? (item.navShow === 'false' ? ' hidden' : '') : '')
                          }
                      >
                          <RedirectLink link={'/'+item.path} text={item.text} line="offline" />
                          {item.children && item.children.length > 0 && (
                              <div className="submenu b-green m-auto">
                                  <div className="container">
                                      <ul className="b-green d-flex jc-between">
                                          {item.children.map((subitem, ii) => {
                                              if (OuterRedirect.hasOwnProperty(subitem.path)) {
                                                  return (
                                                      <li
                                                          id={`menu-item-` + i + '_' + ii}
                                                          key={`menu-item-` + i + '_' + ii}
                                                          className={
                                                              `dropdown` +
                                                              activeNav(subitem.path) +
                                                              (subitem.hasOwnProperty('navShow')
                                                                  ? subitem.navShow === 'false'
                                                                      ? ' hidden'
                                                                      : ''
                                                                  : '')
                                                          }
                                                      >
                                                          <a
                                                              href={OuterRedirect[subitem.path]}
                                                              target="_blank"
                                                              rel="noreferrer"
                                                              className="menu-item-href btn-link"
                                                              title={subitem.text}
                                                          >
                                                              {subitem.text}
                                                          </a>
                                                          {subitem.children && subitem.children.length > 0 && (
                                                              <div className="submenu b-green m-auto">
                                                                  <div className="container">
                                                                      <ul className="b-green d-flex jc-between">
                                                                          {subitem.children.map((subsubitem, iii) => {
                                                                              if (
                                                                                  OuterRedirect.hasOwnProperty(
                                                                                      subsubitem.path
                                                                                  )
                                                                              ) {
                                                                                  return (
                                                                                      <li
                                                                                          id={
                                                                                              `menu-item-` +
                                                                                              i +
                                                                                              '_' +
                                                                                              ii +
                                                                                              '_' +
                                                                                              iii
                                                                                          }
                                                                                          key={
                                                                                              `menu-item-` +
                                                                                              i +
                                                                                              '_' +
                                                                                              ii +
                                                                                              '_' +
                                                                                              iii
                                                                                          }
                                                                                          className={
                                                                                              `dropdown` +
                                                                                              activeNav(
                                                                                                  subsubitem.path
                                                                                              ) +
                                                                                              (subsubitem.hasOwnProperty(
                                                                                                  'navShow'
                                                                                              )
                                                                                                  ? subsubitem.navShow ===
                                                                                                    'false'
                                                                                                      ? ' hidden'
                                                                                                      : ''
                                                                                                  : '')
                                                                                          }
                                                                                      >
                                                                                          <a
                                                                                              href={
                                                                                                  OuterRedirect[
                                                                                                      subsubitem.path
                                                                                                  ]
                                                                                              }
                                                                                              target="_blank"
                                                                                              rel="noreferrer"
                                                                                              className="menu-item-href btn-link"
                                                                                              title={subsubitem.text}
                                                                                          >
                                                                                              {subsubitem.text}
                                                                                              {subsubitem.hasOwnProperty(
                                                                                                  'navMoreText'
                                                                                              ) ? (
                                                                                                  <span>
                                                                                                      {
                                                                                                          subsubitem.navMoreText
                                                                                                      }
                                                                                                  </span>
                                                                                              ) : (
                                                                                                  ''
                                                                                              )}
                                                                                          </a>
                                                                                      </li>
                                                                                  )
                                                                              } else {
                                                                                  return (
                                                                                      <li
                                                                                          id={
                                                                                              `menu-item-` +
                                                                                              i +
                                                                                              '_' +
                                                                                              ii +
                                                                                              '_' +
                                                                                              iii
                                                                                          }
                                                                                          key={
                                                                                              `menu-item-` +
                                                                                              i +
                                                                                              '_' +
                                                                                              ii +
                                                                                              '_' +
                                                                                              iii
                                                                                          }
                                                                                          className={
                                                                                              `dropdown` +
                                                                                              activeNav(
                                                                                                  subsubitem.path
                                                                                              ) +
                                                                                              (subsubitem.hasOwnProperty(
                                                                                                  'navShow'
                                                                                              )
                                                                                                  ? subsubitem.navShow ===
                                                                                                    'false'
                                                                                                      ? ' hidden'
                                                                                                      : ''
                                                                                                  : '')
                                                                                          }
                                                                                      >
                                                                                          <a
                                                                                              href={
                                                                                                  '/' + subsubitem.path
                                                                                              }
                                                                                              className="menu-item-href btn-link"
                                                                                              title={subsubitem.text}
                                                                                              onClick={e => {
                                                                                                  setNavActive(
                                                                                                      subsubitem.path
                                                                                                  )
                                                                                                  setIsSubOpened(false)
                                                                                              }}
                                                                                          >
                                                                                              {subsubitem.text}
                                                                                              {subsubitem.hasOwnProperty(
                                                                                                  'navMoreText'
                                                                                              ) ? (
                                                                                                  <span>
                                                                                                      {
                                                                                                          subsubitem.navMoreText
                                                                                                      }
                                                                                                  </span>
                                                                                              ) : (
                                                                                                  ''
                                                                                              )}
                                                                                          </a>
                                                                                      </li>
                                                                                  )
                                                                              }
                                                                          })}
                                                                      </ul>
                                                                  </div>
                                                              </div>
                                                          )}
                                                      </li>
                                                  )
                                              } else {
                                                  return (
                                                      <li
                                                          id={`menu-item-` + i + '_' + ii}
                                                          key={`menu-item-` + i + '_' + ii}
                                                          className={
                                                              `dropdown ${listEleId === 'menu-item-' + i + '_' + ii ? (isSubOpened ? 'opened' : '') : ''} ` +
                                                              activeNav(subitem.path) +
                                                              (subitem.hasOwnProperty('navShow')
                                                                  ? subitem.navShow === 'false'
                                                                      ? ' hidden'
                                                                      : ''
                                                                  : '')
                                                          }
                                                      >
                                                          <span
                                                              className="menu-item-href btn-link"
                                                              title={subitem.text}
                                                              onClick={e => {
                                                                  setNavActive(subitem.path)
                                                                  setListEleId(`menu-item-${i}_${ii}`)
                                                                  if (e.target.getAttribute('data-id') === listEleId)
                                                                      setIsSubOpened(prev => !prev)
                                                                  if (e.target.getAttribute('data-id') !== listEleId)
                                                                      setIsSubOpened(true)
                                                              }}
                                                          >
                                                              {subitem.text}
                                                          </span>
                                                          {subitem.children && subitem.children.length > 0 && (
                                                              <div className="submenu b-green m-auto">
                                                                  <div className="container">
                                                                      <ul className="b-green d-flex jc-between">
                                                                          {subitem.children.map((subsubitem, iii) => {
                                                                              if (
                                                                                  OuterRedirect.hasOwnProperty(
                                                                                      subsubitem.path
                                                                                  )
                                                                              ) {
                                                                                  return (
                                                                                      <li
                                                                                          id={
                                                                                              `menu-item-` +
                                                                                              i +
                                                                                              '_' +
                                                                                              ii +
                                                                                              '_' +
                                                                                              iii
                                                                                          }
                                                                                          key={
                                                                                              `menu-item-` +
                                                                                              i +
                                                                                              '_' +
                                                                                              ii +
                                                                                              '_' +
                                                                                              iii
                                                                                          }
                                                                                          className={
                                                                                              `dropdown` +
                                                                                              activeNav(
                                                                                                  subsubitem.path
                                                                                              ) +
                                                                                              (subsubitem.hasOwnProperty(
                                                                                                  'navShow'
                                                                                              )
                                                                                                  ? subsubitem.navShow ===
                                                                                                    'false'
                                                                                                      ? ' hidden'
                                                                                                      : ''
                                                                                                  : '')
                                                                                          }
                                                                                      >
                                                                                          <a
                                                                                              href={
                                                                                                  OuterRedirect[
                                                                                                      subsubitem.path
                                                                                                  ]
                                                                                              }
                                                                                              target="_blank"
                                                                                              rel="noreferrer"
                                                                                              title={subsubitem.text}
                                                                                              className="menu-item-href btn-link"
                                                                                          >
                                                                                              {subsubitem.text}
                                                                                              {subsubitem.hasOwnProperty(
                                                                                                  'navMoreText'
                                                                                              ) ? (
                                                                                                  <span>
                                                                                                      {
                                                                                                          subsubitem.navMoreText
                                                                                                      }
                                                                                                  </span>
                                                                                              ) : (
                                                                                                  ''
                                                                                              )}
                                                                                          </a>
                                                                                      </li>
                                                                                  )
                                                                              } else {
                                                                                  return (
                                                                                      <li
                                                                                          id={
                                                                                              `menu-item-` +
                                                                                              i +
                                                                                              '_' +
                                                                                              ii +
                                                                                              '_' +
                                                                                              iii
                                                                                          }
                                                                                          key={
                                                                                              `menu-item-` +
                                                                                              i +
                                                                                              '_' +
                                                                                              ii +
                                                                                              '_' +
                                                                                              iii
                                                                                          }
                                                                                          className={
                                                                                              `dropdown` +
                                                                                              activeNav(
                                                                                                  subsubitem.path
                                                                                              ) +
                                                                                              (subsubitem.hasOwnProperty(
                                                                                                  'navShow'
                                                                                              )
                                                                                                  ? subsubitem.navShow ===
                                                                                                    'false'
                                                                                                      ? ' hidden'
                                                                                                      : ''
                                                                                                  : '')
                                                                                          }
                                                                                      >
                                                                                          <a
                                                                                              href={
                                                                                                  '/' + subsubitem.path
                                                                                              }
                                                                                              className="menu-item-href btn-link"
                                                                                              title={subsubitem.text}
                                                                                              onClick={e => {
                                                                                                  setNavActive(
                                                                                                      subsubitem.path
                                                                                                  )
                                                                                                  setIsSubOpened(false)
                                                                                              }}
                                                                                          >
                                                                                              {subsubitem.text}
                                                                                              {subsubitem.hasOwnProperty(
                                                                                                  'navMoreText'
                                                                                              ) ? (
                                                                                                  <span>
                                                                                                      {
                                                                                                          subsubitem.navMoreText
                                                                                                      }
                                                                                                  </span>
                                                                                              ) : (
                                                                                                  ''
                                                                                              )}
                                                                                          </a>
                                                                                      </li>
                                                                                  )
                                                                              }
                                                                          })}
                                                                      </ul>
                                                                  </div>
                                                              </div>
                                                          )}
                                                      </li>
                                                  )
                                              }
                                          })}
                                      </ul>
                                  </div>
                              </div>
                          )}
                      </li>
                  )
              })
            : navData.map((item, i) => {
                  return (
                      <li
                          id={`menu-item-` + i}
                          key={`menu-item-` + i + '_' + i}
                          data-active={navActive}
                          className={
                              (item.children && item.children.length > 0 ? 'dropdown' : 'slideup') +
                              activeNav(item.path) +
                              ' ' +
                              activeMainNav(
                                  item.path,
                                  item.children && item.children.length > 0 ? 'dropdown' : 'slideup'
                              ) +
                              (item.hasOwnProperty('navShow') ? (item.navShow === 'false' ? ' hidden' : '') : '')
                          }
                      >
                          <RedirectLink link={'/'+item.path} text={item.text} line="online" />
                          {item.children && item.children.length > 0 && (
                              <div className="submenu b-green m-auto">
                                  <div className="container">
                                      <ul className="b-green d-flex jc-between">
                                          {item.children.map((subitem, ii) => {
                                              if (OuterRedirect.hasOwnProperty(subitem.path)) {
                                                  return (
                                                      <li
                                                          id={`menu-item-` + i + '_' + ii}
                                                          key={`menu-item-` + i + '_' + ii}
                                                          className={
                                                              `dropdown` +
                                                              activeNav(subitem.path) +
                                                              (subitem.hasOwnProperty('navShow')
                                                                  ? subitem.navShow === 'false'
                                                                      ? ' hidden'
                                                                      : ''
                                                                  : '')
                                                          }
                                                      >
                                                          <span
                                                              className="menu-item-href btn-link"
                                                              title={subitem.text}
                                                          >
                                                              {subitem.text}
                                                          </span>
                                                          {subitem.children && subitem.children.length > 0 && (
                                                              <div className="submenu b-green m-auto">
                                                                  <div className="container">
                                                                      <ul className="b-green d-flex jc-between">
                                                                          {subitem.children.map((subsubitem, iii) => {
                                                                              if (
                                                                                  OuterRedirect.hasOwnProperty(
                                                                                      subsubitem.path
                                                                                  )
                                                                              ) {
                                                                                  return (
                                                                                      <li
                                                                                          id={
                                                                                              `menu-item-` +
                                                                                              i +
                                                                                              '_' +
                                                                                              ii +
                                                                                              '_' +
                                                                                              iii
                                                                                          }
                                                                                          key={
                                                                                              `menu-item-` +
                                                                                              i +
                                                                                              '_' +
                                                                                              ii +
                                                                                              '_' +
                                                                                              iii
                                                                                          }
                                                                                          className={
                                                                                              `dropdown` +
                                                                                              activeNav(
                                                                                                  subsubitem.path
                                                                                              ) +
                                                                                              (subsubitem.hasOwnProperty(
                                                                                                  'navShow'
                                                                                              )
                                                                                                  ? subsubitem.navShow ===
                                                                                                    'false'
                                                                                                      ? ' hidden'
                                                                                                      : ''
                                                                                                  : '')
                                                                                          }
                                                                                      >
                                                                                          <a
                                                                                              href={
                                                                                                  OuterRedirect[
                                                                                                      subsubitem.path
                                                                                                  ]
                                                                                              }
                                                                                              target="_blank"
                                                                                              rel="noreferrer"
                                                                                              className="menu-item-href btn-link"
                                                                                              title={subsubitem.text}
                                                                                          >
                                                                                              {subsubitem.text}
                                                                                              {subsubitem.hasOwnProperty(
                                                                                                  'navMoreText'
                                                                                              ) ? (
                                                                                                  <span>
                                                                                                      {
                                                                                                          subsubitem.navMoreText
                                                                                                      }
                                                                                                  </span>
                                                                                              ) : (
                                                                                                  ''
                                                                                              )}
                                                                                          </a>
                                                                                      </li>
                                                                                  )
                                                                              } else {
                                                                                  return (
                                                                                      <li
                                                                                          id={
                                                                                              `menu-item-` +
                                                                                              i +
                                                                                              '_' +
                                                                                              ii +
                                                                                              '_' +
                                                                                              iii
                                                                                          }
                                                                                          key={
                                                                                              `menu-item-` +
                                                                                              i +
                                                                                              '_' +
                                                                                              ii +
                                                                                              '_' +
                                                                                              iii
                                                                                          }
                                                                                          className={
                                                                                              `dropdown` +
                                                                                              activeNav(
                                                                                                  subsubitem.path
                                                                                              ) +
                                                                                              (subsubitem.hasOwnProperty(
                                                                                                  'navShow'
                                                                                              )
                                                                                                  ? subsubitem.navShow ===
                                                                                                    'false'
                                                                                                      ? ' hidden'
                                                                                                      : ''
                                                                                                  : '')
                                                                                          }
                                                                                      >
                                                                                          <Link
                                                                                              to={subsubitem.path}
                                                                                              className="menu-item-href btn-link"
                                                                                              title={subsubitem.text}
                                                                                              onClick={e => {
                                                                                                  setNavActive(
                                                                                                      subsubitem.path
                                                                                                  )
                                                                                              }}
                                                                                          >
                                                                                              {subsubitem.text}
                                                                                              {subsubitem.hasOwnProperty(
                                                                                                  'navMoreText'
                                                                                              ) ? (
                                                                                                  <span>
                                                                                                      {
                                                                                                          subsubitem.navMoreText
                                                                                                      }
                                                                                                  </span>
                                                                                              ) : (
                                                                                                  ''
                                                                                              )}
                                                                                          </Link>
                                                                                      </li>
                                                                                  )
                                                                              }
                                                                          })}
                                                                      </ul>
                                                                  </div>
                                                              </div>
                                                          )}
                                                      </li>
                                                  )
                                              } else {
                                                  return (
                                                      <li
                                                          id={`menu-item-` + i + '_' + ii}
                                                          key={`menu-item-` + i + '_' + ii}
                                                          className={
                                                              `dropdown ${listEleId === 'menu-item-' + i + '_' + ii ? (isSubOpened ? 'opened' : '') : ''} ` +
                                                              activeNav(subitem.path) +
                                                              (subitem.hasOwnProperty('navShow')
                                                                  ? subitem.navShow === 'false'
                                                                      ? ' hidden'
                                                                      : ''
                                                                  : '')
                                                          }
                                                      >
                                                          <span
                                                              data-id={`menu-item-` + i + '_' + ii}
                                                              className="menu-item-href btn-link"
                                                              title={subitem.text}
                                                              onClick={e => {
                                                                  setNavActive(subitem.path)
                                                                  setListEleId(`menu-item-${i}_${ii}`)
                                                                  if (e.target.getAttribute('data-id') === listEleId)
                                                                      setIsSubOpened(prev => !prev)
                                                                  if (e.target.getAttribute('data-id') !== listEleId)
                                                                      setIsSubOpened(true)
                                                              }}
                                                          >
                                                              {subitem.text}
                                                          </span>
                                                          {subitem.children && subitem.children.length > 0 && (
                                                              <div className="submenu b-green m-auto">
                                                                  <div className="container">
                                                                      <ul className="b-green d-flex jc-between">
                                                                          {subitem.children.map((subsubitem, iii) => {
                                                                              if (
                                                                                  OuterRedirect.hasOwnProperty(
                                                                                      subsubitem.path
                                                                                  )
                                                                              ) {
                                                                                  return (
                                                                                      <li
                                                                                          id={
                                                                                              `menu-item-` +
                                                                                              i +
                                                                                              '_' +
                                                                                              ii +
                                                                                              '_' +
                                                                                              iii
                                                                                          }
                                                                                          key={
                                                                                              `menu-item-` +
                                                                                              i +
                                                                                              '_' +
                                                                                              ii +
                                                                                              '_' +
                                                                                              iii
                                                                                          }
                                                                                          className={
                                                                                              `dropdown` +
                                                                                              activeNav(
                                                                                                  subsubitem.path
                                                                                              ) +
                                                                                              (subsubitem.hasOwnProperty(
                                                                                                  'navShow'
                                                                                              )
                                                                                                  ? subsubitem.navShow ===
                                                                                                    'false'
                                                                                                      ? ' hidden'
                                                                                                      : ''
                                                                                                  : '')
                                                                                          }
                                                                                      >
                                                                                          <a
                                                                                              href={
                                                                                                  OuterRedirect[
                                                                                                      subsubitem.path
                                                                                                  ]
                                                                                              }
                                                                                              target="_blank"
                                                                                              rel="noreferrer"
                                                                                              title={subsubitem.text}
                                                                                              className="menu-item-href btn-link"
                                                                                          >
                                                                                              {subsubitem.text}
                                                                                              {subsubitem.hasOwnProperty(
                                                                                                  'navMoreText'
                                                                                              ) ? (
                                                                                                  <span>
                                                                                                      {
                                                                                                          subsubitem.navMoreText
                                                                                                      }
                                                                                                  </span>
                                                                                              ) : (
                                                                                                  ''
                                                                                              )}
                                                                                          </a>
                                                                                      </li>
                                                                                  )
                                                                              } else {
                                                                                  return (
                                                                                      <li
                                                                                          id={
                                                                                              `menu-item-` +
                                                                                              i +
                                                                                              '_' +
                                                                                              ii +
                                                                                              '_' +
                                                                                              iii
                                                                                          }
                                                                                          key={
                                                                                              `menu-item-` +
                                                                                              i +
                                                                                              '_' +
                                                                                              ii +
                                                                                              '_' +
                                                                                              iii
                                                                                          }
                                                                                          className={
                                                                                              `dropdown` +
                                                                                              activeNav(
                                                                                                  subsubitem.path
                                                                                              ) +
                                                                                              (subsubitem.hasOwnProperty(
                                                                                                  'navShow'
                                                                                              )
                                                                                                  ? subsubitem.navShow ===
                                                                                                    'false'
                                                                                                      ? ' hidden'
                                                                                                      : ''
                                                                                                  : '')
                                                                                          }
                                                                                      >
                                                                                          <Link
                                                                                              to={subsubitem.path}
                                                                                              className="menu-item-href btn-link"
                                                                                              title={subsubitem.text}
                                                                                              onClick={e => {
                                                                                                  setNavActive(
                                                                                                      subsubitem.path
                                                                                                  )
                                                                                                  setIsSubOpened(false)
                                                                                              }}
                                                                                          >
                                                                                              {subsubitem.text}
                                                                                              {subsubitem.hasOwnProperty(
                                                                                                  'navMoreText'
                                                                                              ) ? (
                                                                                                  <span>
                                                                                                      {
                                                                                                          subsubitem.navMoreText
                                                                                                      }
                                                                                                  </span>
                                                                                              ) : (
                                                                                                  ''
                                                                                              )}
                                                                                          </Link>
                                                                                      </li>
                                                                                  )
                                                                              }
                                                                          })}
                                                                      </ul>
                                                                  </div>
                                                              </div>
                                                          )}
                                                      </li>
                                                  )
                                              }
                                          })}
                                      </ul>
                                  </div>
                              </div>
                          )}
                      </li>
                  )
              })
    }

    switch (jsonStatus) {
        case null:
            return (
                <ul className="menu">
                    <li className="Loading-nav b-white ml-10">
                        <Loaders type="dots" color="b-color1" />
                    </li>
                </ul>
            )
        case false:
            return (
                <ul className="menu">
                    <li className="Loading-nav b-white">
                        <a href="/" className="menu-item-href btn-link">
                            Strona Główna
                        </a>
                    </li>
                </ul>
            )
        case true:
            return (
                <>
                    <ul className="menu" ref={menuRef}>
                        <RenderNavs />
                    </ul>
                </>
            )
        default:
            break
    }
}
export default Nav
