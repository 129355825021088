import React, { useEffect } from 'react'
import Links from '../../components/Links.js'
import { environment } from '../../environment.js'
import TextLinks from '../../components/TextLinks.js'
import Image from '../../components/Image.js'

function InfoboxItem(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Infoboxs Item')
        console.log(params)
    }

    let Title = params.block.hasOwnProperty('Title') ? params.block['Title'] : '',
        Text = params.block.hasOwnProperty('Text') ? params.block['Text'] : '',
        Link = params.block.hasOwnProperty('ButtonLink') ? params.block['ButtonLink'] : '',
        LinkName = params.block.hasOwnProperty('ButtonName') ? params.block['ButtonName'] : '',
        LinkType = params.block.hasOwnProperty('ButtonType') ? params.block['ButtonType'] : 'color1',
        InNewOpen = params.block.hasOwnProperty('InNewOpen')
            ? params.block['InNewOpen'] === true
                ? true
                : false
            : false

    let img = params.block.hasOwnProperty('Image')
        ? params.block['Image'].hasOwnProperty('link')
            ? params.block['Image']['link']
            : environment.defaultImage
        : environment.defaultImage
    let imgUrl = img

    if (img !== undefined && img !== '') {
        if (img.includes(environment.siteSites)) {
            img = img.split(environment.siteSites)
            img = img[1]
            if (params.line === 'offline') {
                imgUrl = window.origin + img
            } else if (window.origin.includes(environment.localhost)) {
                imgUrl = environment.siteUrlDev + img
            } else {
                imgUrl = window.origin + img
            }
        } else if (window.origin.includes(environment.localhost)) {
            imgUrl = environment.siteUrlDev + img
        } else {
            imgUrl = img
        }
    } else {
        imgUrl = environment.defaultImage
    }
    let ImageUrl = 'url(' + imgUrl + ')'

    if (params.noBgImage) {
        return (
            <div className='bg-white p-8 rounded-3xl'>
                <Image className="max-w-[70px] lg:max-w-[none]" src={params.block['Image']['link']} alt={'Express Car Rental'} />
                {!(Title === '') && <h3 className=' mt-4 mb-2 text-2xl text-left c-color3 font-bold'>{Title}</h3>}
                {!(Text === '') && <TextLinks text={Text} line={params.line} />}
                {!(Link === '') && !(LinkName === '') && (
                    <div className="infobox-item grid-cell grid-50">
                        <Links
                            class={'btn-link b-' + LinkType}
                            url={Link}
                            target={InNewOpen}
                            name={LinkName}
                            line={params.line}
                        />
                    </div>
                )}
            </div>
            
        )
    }

    return (
        <div className="infobox-item grid-cell grid-50">
            <div className="square square-100" style={{ backgroundImage: ImageUrl }}></div>
            <div className="boxes-text">
                {!(Title === '') && (
                    <div className="boxes-inner">
                        <div className="main-text mb-30">
                            <h4>{Title}</h4>
                        </div>
                    </div>
                )}
                {!(Text === '') && (
                    <div className="content-text mb-30">{<TextLinks text={Text} line={params.line} />}</div>
                )}
            </div>
            {!(Link === '') && !(LinkName === '') && (
                <Links
                    class={'btn-link b-' + LinkType}
                    url={Link}
                    target={InNewOpen}
                    name={LinkName}
                    line={params.line}
                />
            )}
        </div>
    )
}

export default InfoboxItem
