import React, { useEffect } from 'react'
import { environment } from '../../environment.js'
import TextLinks from '../../components/TextLinks'
import { useTranslation } from 'react-i18next'
import axios from 'axios';

function CarsReservationsBoxesItem(params) {
    let regBaseUrl = '/'
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        //console.log('CarsReservationsBoxes Item')
        //console.log(params)
        regBaseUrl = environment.siteUrlDev
    }

    const { t, i18n } = useTranslation()
    useEffect(() => {
        i18n.changeLanguage(params.locale)
    }, [params.locale])

    let Title = params.block.hasOwnProperty('Title') ? params.block['Title'] : '',
        Text = params.block.hasOwnProperty('Text') ? params.block['Text'] : '',
        AfterText = params.block.hasOwnProperty('AfterText') ? params.block['AfterText'] : '',
        Link = params.block.hasOwnProperty('ButtonLink') ? params.block['ButtonLink'] : '',
        LinkName = params.block.hasOwnProperty('ButtonName') ? params.block['ButtonName'] : '',
        InNewOpen = params.block.hasOwnProperty('InNewOpen')
            ? params.block['InNewOpen'] === true
                ? true
                : false
            : false;
    let Class = params.block.hasOwnProperty('Class') ? params.block['Class'] : '';

    /*let Promotion = params.block.hasOwnProperty('Promotion') ? params.block['Promotion'] : '',
        Price = params.block.hasOwnProperty('Price') ? params.block['Price'] : '',
        Days = params.block.hasOwnProperty('Days') ? params.block['Days'] : '',
        LowPrice = params.block.hasOwnProperty('Lowest_price') ? params.block['Lowest_price'] : '',
        LowDays = params.block.hasOwnProperty('Lowest_days') ? params.block['Lowest_days'] : '';*/
    let img = params.block.hasOwnProperty('Image')
            ? params.block['Image'].hasOwnProperty('link')
                ? params.block['Image']['link']
                : environment.defaultImage
            : environment.defaultImage;
    let imgUrl = img;

    if (img !== undefined && img !== '') {
        if (img.includes(environment.siteSites)) {
            img = img.split(environment.siteSites)
            img = img[1]
            if (params.line === 'offline') {
                imgUrl = window.origin + img
            } else if (window.origin.includes(environment.localhost)) {
                imgUrl = environment.siteUrlDev + img
            } else {
                imgUrl = window.origin + img
            }
        } else if (window.origin.includes(environment.localhost)) {
            imgUrl = environment.siteUrlDev + img
        } else {
            imgUrl = img
        }
    } else {
        imgUrl = environment.defaultImage
    }
    let ImageUrl = 'url(' + imgUrl + ')';
    function redirectFunc(e) {
        if (e[0]) {
            window.location.href = '/'+params.locale+environment.processRAC+e[1]
        }
        else {
            window.location.href = '/'+params.locale+e[2]
        }
    }

    const handleProcess = async (Class, link) => {
        try {
            const response = await axios.create({
                baseURL: regBaseUrl
            }).post(environment.startProcessRAC,
                JSON.stringify({ "lp_car_class": Class, "gRecaptchaResponse": params.recaptchaToken }), {
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                }
            );
            if (response.hasOwnProperty('data')) {
                if (response.data && response.data.hasOwnProperty('status')) {
                    if (response.data.status === 'success') {
                        //setSuccess([true, response.data?.id, link]);
                        redirectFunc([true, response.data?.id, link])
                       
                    } else {
                        redirectFunc([false, response.data?.id, '/'])
                    }
                } else {
                    redirectFunc([false, response.data?.id, '/'])
                }
            }
            else {
                redirectFunc([false, response.data?.id, '/'])
            }
        } catch (err) {
            params.setErrorMsg(t('errors.try_again'))
            if (!err?.response) {
                console.log(err);
                // redirectFunc([false, '', '/']) 
            } else {
                // redirectFunc([false, '', '/'])
            }
        }
    }

    return (
        <div className="cars-res-boxes-item grid-cell grid-33">
            <div className="square square-100" style={{ backgroundImage: ImageUrl }}>
                <div className="boxes-inner">
                    <div className="main-text mb-30">
                        {!(Title === '') && <h3>{Title}</h3>}
                    </div>
                </div>
            </div>
            <div className="boxes-text">
                {!(Text === '') && 
                    <div className="content-text mb-30">
                        {<TextLinks text={Text} line={params.line} />}
                        {/*Promotion !== '' && <div className='promo-box'><b>{t('portlets.car_rent.promo')} {Promotion}</b></div>}
                        {Price !== '' && Days !== '' && <div className='price-box'>OD <b>{Price} <sup>ZŁ</sup></b><div className='sd-box'> / {Days}</div></div>}
                        {LowPrice !== '' && LowDays !== '' && <div className='low-price-box'>
                            Najniższa cena<br />z 30 dni przed obniżką<br />
                            <div className='bi-box'><b>OD {LowPrice} ZŁ</b> / {LowDays}</div>
                        </div>*/}
                    </div>
                }
                {!(AfterText === '') && 
                    <div className="after-text">
                        <TextLinks text={AfterText} line={params.line} />
                    </div>
                }
            </div>
            <a  href="#" className={'btn-link b-' +params.button}
                onClick={e => {
                    e.preventDefault();
                    handleProcess(Class, Link)
                }}
            >
                {LinkName}
            </a>
        </div>
    )
}

export default CarsReservationsBoxesItem