import React from 'react'
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import SpeedIcon from '@mui/icons-material/Speed';
import { useTranslation } from 'react-i18next'

export default function CarDetails({
    numberDoors,
    numberSeats,
    fuel,
    transmission,
    isElastic,
    isPromo,
    isSmall = false,
    fuelTypes,
    transmissionArray,
    mileageMin,
    mileageMax,
    isMultiOffer
}) {
    const { t } = useTranslation()

    if (isSmall) {
        const locale = window.location.href.includes('/en/') ? 'en' : 'pl'
        return (
            <>
                {fuel && fuelTypes ? (
                    <div className="border text-[10px] text-[#919191] border-[#c7c7c7] rounded-full px-2 py-1 flex items-center gap-x-1.5">
                        <LocalGasStationOutlinedIcon fontSize="small" />
                        <span>{fuelTypes[locale][fuel]}</span>
                    </div>
                ): null}
                {transmission && transmissionArray ? (
                    <div className="border text-[10px] text-[#919191] border-[#c7c7c7] rounded-full px-2 py-1 flex items-center gap-x-1.5">
                        <SettingsOutlinedIcon fontSize="small" />
                        <span>{transmissionArray[locale][transmission]}</span>
                    </div>
                ): null}
                {numberDoors ? (
                    <div className="border text-[10px] text-[#919191] border-[#c7c7c7] rounded-full px-2 py-1 flex items-center gap-x-1.5">
                        <DirectionsCarFilledOutlinedIcon fontSize="small" />
                        <span>
                            {numberDoors} {t('LAC.offer_list.doors')}
                        </span>
                    </div>
                ): null}
                {numberSeats ? (
                    <div className="border text-[10px] text-[#919191] border-[#c7c7c7] rounded-full px-2 py-1 flex items-center gap-x-1.5">
                        <PersonOutlineOutlinedIcon fontSize="small" />
                        <span>
                            {numberSeats} {t('LAC.offer_list.number_of_sits')}
                        </span>
                    </div>
                ): null}
                {isElastic ? (
                    <div className="border text-[10px] text-[#919191] border-[#c7c7c7] rounded-full px-2 py-1 flex items-center gap-x-1.5">
                        <SaveAsOutlinedIcon fontSize="small" />
                        <span>{t('LAC.offer_list.car_details.elastic_pill')}</span>
                    </div>
                ): null}
                {isPromo ? (
                    <div className="border text-[10px] text-[#919191] border-[#c7c7c7] rounded-full px-2 py-1 flex items-center gap-x-1.5">
                        <LocalOfferOutlinedIcon fontSize="small" />
                        <span>{t('LAC.offer_list.car_details.promo_price_pill')}</span>
                    </div>
                ): null}
                {isMultiOffer ? 
                    <>
                        {mileageMax && mileageMin &&
                            <div className="border text-[10px] text-[#919191] border-[#c7c7c7] rounded-full px-2 py-1 flex items-center gap-x-1.5">
                                <SpeedIcon fontSize='small' />
                                <span>{t('LAC.offer_list.offers_list_detail.mileage_label')}: {mileageMin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} km - {mileageMax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} km</span>
                            </div>
                        }
                    </> : 
                    <>
                        {mileageMin && 
                            <div className="border text-[10px] text-[#919191] border-[#c7c7c7] rounded-full px-2 py-1 flex items-center gap-x-1.5">
                                <SpeedIcon fontSize='small' />
                                <span>{t('LAC.offer_list.offers_list_detail.mileage_label')}: {mileageMin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} km</span>
                            </div>
                        }
                    </>
                }
            </>
        )
    }

    return (
        <>
            {fuel && fuelTypes ? (
                <div className="border text-[#919191] border-[#c7c7c7] rounded-full px-4 py-2 flex items-center gap-x-2">
                    <LocalGasStationOutlinedIcon />
                    {fuelTypes[fuel]}
                </div>
            ): null}
            {transmission && transmissionArray ? (
                <div className="border text-[#919191] border-[#c7c7c7] rounded-full px-4 py-2 flex items-center gap-x-2">
                    <SettingsOutlinedIcon />
                    {transmissionArray[transmission]}
                </div>
            ): null}
            {numberDoors ? (
                <div className="border text-[#919191] border-[#c7c7c7] rounded-full px-4 py-2 flex items-center gap-x-2">
                    <DirectionsCarFilledOutlinedIcon />
                    <span>
                        {numberDoors} {t('LAC.offer_list.doors')}
                    </span>
                </div>
            ): null}
            {numberSeats ? (
                <div className="border text-[#919191] border-[#c7c7c7] rounded-full px-4 py-2 flex items-center gap-x-2">
                    <PersonOutlineOutlinedIcon />
                    <span>
                        {numberSeats} {t('LAC.offer_list.number_of_sits')}
                    </span>
                </div>
            ) : null}
            {isElastic ? (
                <div className="border text-[#919191] border-[#c7c7c7] rounded-full px-4 py-2 flex items-center gap-x-2">
                    <SaveAsOutlinedIcon />
                    <span>{t('LAC.offer_list.car_details.elastic_pill')}</span>
                </div>
            ): null}
            {isPromo ? (
                <div className="border text-[#919191] border-[#c7c7c7] rounded-full px-4 py-2 flex items-center gap-x-2">
                    <LocalOfferOutlinedIcon />
                    <span>{t('LAC.offer_list.car_details.promo_price_pill')}</span>
                </div>
            ): null}
            {isMultiOffer ? 
                    <>
                        {mileageMax && mileageMin &&
                            <div className="border text-[#919191] border-[#c7c7c7] rounded-full px-4 py-2 flex items-center gap-x-2">
                                <SpeedIcon fontSize='small' />
                                <span>{t('LAC.offer_list.offers_list_detail.mileage_label')}: {mileageMin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} km - {mileageMax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} km</span>
                            </div>
                        }
                    </> : 
                    <>
                        {mileageMin && 
                            <div className="border text-[#919191] border-[#c7c7c7] rounded-full px-4 py-2 flex items-center gap-x-2">
                                <SpeedIcon fontSize='small' />
                                <span>{t('LAC.offer_list.offers_list_detail.mileage_label')}: {mileageMin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} km</span>
                            </div>
                        }
                    </>
                }
        </>
    )
}
