import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { useFormikContext } from 'formik'

import { Alert, Box, Stack } from '@mui/material'

import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'

import { REACT_APP_AUTOMAPA_API_KEY, environment } from '../../../environment'
import ErrorBox from '../../../components/ErrorBox'

import { DialogContext } from '../../RAC/Step1/SearchField'
import GeolocationTargeo from '../../RAC/Step1/GeolocationTargeo'

import '../../../styles/targeo-search.css'

const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ''

const TargeoSearch = ({ ...props }) => {
    const [chosenTargeoItem, setChosenTargeoItem] = useState(null)
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [scriptIsAdded, setScriptIsAdded] = useState(false)
    const [inputValue, setInputValue] = useState("")
    const [showAlertAddressNotFound, setShowAlertAddressNotFound] = useState("")
    const [showAlertAddressWithStreet, setShowAlertAddressWithStreet] = useState("")

    const { t } = useTranslation()

    const { setFieldValue, setFieldTouched, values } = useFormikContext()

    const { handleCloseDialog, name } = useContext(DialogContext)

    const handleTargeoResponse = () => {
        const open = XMLHttpRequest.prototype.open
        const send = XMLHttpRequest.prototype.send

        XMLHttpRequest.prototype.open = function (method, url, async, user, pass) {
            this._url = url
            open.call(this, method, url, async, user, pass)
        }

        XMLHttpRequest.prototype.send = function (data) {
            this.addEventListener(
                'readystatechange',
                () => {
                    if (
                        this._url &&
                        (this._url.startsWith('https://m20.targeo.pl/service.html') ||
                            this._url.startsWith('https://m40.targeo.pl/service.html') ||
                            this._url.startsWith('https://m50.targeo.pl/service.html'))
                    ) {
                        if (this.readyState === 4 && this.status === 200) {
                            if (this.responseType === '' || this.responseType === 'text') {
                                try {
                                    const jsonResponse = JSON.parse(this.responseText)
                                    if (
                                        window.origin.includes(environment.localhost) ||
                                        window.origin.includes(environment.devName)
                                    )
                                        console.log('Targeo Search JSON:', jsonResponse)
                                    setData(jsonResponse)
                                } catch (e) {
                                    if (
                                        window.origin.includes(environment.localhost) ||
                                        window.origin.includes(environment.devName)
                                    )
                                        console.error('Targeo Search: Could not parse JSON response', e)
                                    setError(t('errors.try_again'))
                                }
                            }
                        }
                    }
                },
                false
            )

            send.call(this, data)
        }
    }

    useEffect(() => {
        // -------- LocationsMap
        const targeoInitializeScript = document.getElementById("targeoInitializeScript");
        const targeoSuggesterInitializeScript = document.getElementById("targeoSuggesterInitializeScript");
        if (!targeoInitializeScript && !targeoSuggesterInitializeScript) {
            const targeoScript = document.createElement('script');
            targeoScript.setAttribute('id','targeoSuggesterInitializeScript');
            targeoScript.src = `https://mapa.targeo.pl/Targeo.html?vn=3_0&v=full&rv=form&ext=suggest&k=${REACT_APP_AUTOMAPA_API_KEY}&e=TargeoSuggesterInitialize&f=SuggesterInitialize`
            targeoScript.async = true
    
            const handleScriptLoad2 = () => {
                document.dispatchEvent(new Event('DOMContentLoaded'))
            }
            targeoScript.addEventListener('load', handleScriptLoad2)
            document.body.appendChild(targeoScript)
        }
        // -------- LocationsMap

        setScriptIsAdded(true)
    }, [])

    useEffect(() => {
        handleTargeoResponse()
    }, [setScriptIsAdded])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('!!! ', data)

        const inputElement = document.getElementById('ftsform-query')
        if (inputElement) {
            inputElement.setAttribute('placeholder', t('RAC.step_one.placeholder_on_geo'))
            inputElement.setAttribute('autocomplete', 'on')
            setInputValue(inputElement.value)
        } 

        const street = data.target?.item?.street || ''
        const number = data.target?.item?.number || ''
        const place = data.target?.item?.place || ''
        const province = data.target?.item?.province || ''
        const postalcode = data.target?.item?.pna_viewable[0] || data.target?.item?.pna_viewable || ''

        const label = data.target?.item
            ? capitalize(
                  `${street || ''} ${number || ''}${street && `, `}${place !== street ? `${postalcode} ${place},` : ''} ${province}`
              ).trim()
            : '-----'

            const chosenPlace = {
                // ...data.target?.item,
                lon: data.target?.item?.xy?.x || '',
                lat: data.target?.item?.xy?.y || '',
                label: label,
                number: data.target?.item?.number || '',
                // objecttype: data.target?.item?.objecttype || '',
            }
    
            setFieldValue(name || 'pickupTargeoAddress', label)
            setFieldValue(name ? `${name}Place` : 'pickupTargeoPlace', chosenPlace)
    
            values.theSamePlace && setFieldValue(`endLocation`, label)
            values.theSamePlace && setFieldValue(`endLocationPlace`, chosenPlace)
    
            setTimeout(() => setFieldTouched(name, true))
    
            setChosenTargeoItem(data.target?.item || null)
        }, [data])

    useEffect(() => {

        setShowAlertAddressWithStreet(false) 
        
        if (chosenTargeoItem) {
            if (chosenTargeoItem.number === "") {
                setShowAlertAddressWithStreet(true) 
            } else {
                props?.LAC ? props?.handleCloseDialogLAC() : handleCloseDialog()
            }
    }

    }, [chosenTargeoItem])

    useEffect(() => {
        const searchButton = document.getElementById('search') 
        showAlertAddressWithStreet ? searchButton?.classList.add('hide') : searchButton?.classList.remove('hide')
    }, [showAlertAddressWithStreet])

    useEffect(() => {
        if (inputValue.length > 3) {
            const hasDigit = /\d/.test(inputValue);
            !hasDigit ? setShowAlertAddressNotFound(true) : setShowAlertAddressNotFound(false)
        }
    }, [inputValue])

    return (
        scriptIsAdded && <>
                <Helmet>
                    <script type="text/javascript" id="TargeoSuggesterInitialize">
                        {`  
                                function checkForQueryDiv() {
                                    const queryDiv = document.getElementById('query');
                                    if (queryDiv) {
                                        clearInterval(window.queryDivInterval);
                                        window.queryDivInterval = null;
                                        handleQueryDivAdded();
                                    }
                                }

                                if (!window.queryDivInterval) {
                                window.queryDivInterval = setInterval(checkForQueryDiv, 200);
                                }

                                function handleQueryDivAdded() {         
                                    var suggest = new Targeo.FTSSuggest('query', {objectTypes: {places: true, poi: false}, icons: true, button: document.getElementById('search')});
                                    document.getElementById('query').addEventListener('searchResult', function(ev) { 
                                    console.log('searchResult', ev.detail);
                                });
                                                    
                                }
                            `}
                    </script>
                </Helmet>
                <div className="searchField">
                    <div id="container">
                        <div id="query" />
                        <label id="targeoSearchLabel">{t('RAC.step_one.label_search_address')}</label>
                        <button id="search" className={data.status === 1 ? '' : 'hidden'}>
                            OK
                        </button>
                    </div>
                    {data?.length !== 0 && data?.status !== 1 && (
                        <GeolocationTargeo
                            name={name}
                            LAC={props?.LAC || false}
                            handleCloseDialog={props.LAC ? props?.handleCloseDialogLAC : handleCloseDialog}
                        />
                    )}

                    <Stack spacing={1} mt={2}>
                        {(showAlertAddressWithStreet) && (
                            <Alert severity="error">{t('RAC.step_one.label_search_address')}</Alert>
                        )}

                        {/* jeżeli coś jest wpisane w inpucie, powyżej 3 znaków i nie ma tam numeru  */}
                        {/* {(data.items?.length === 0 || showAlertAddressNotFound) && (
                            <Alert severity="warning">{t('RAC.step_one.warning_address_not_found')}</Alert>
                        )} */}

                        {!data.target?.item && (
                            <Alert severity="info" icon={<LocalOfferOutlinedIcon />}>
                                {t('RAC.step_one.price_info')}
                            </Alert>
                        )}

                        {error && <ErrorBox error={t('errors.try_again')} />}
                    </Stack>

                    {(window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) &&
                        data && <pre>{JSON.stringify(data, null, 2)}</pre>}
                </div>
            </>
    )
}

export default TargeoSearch
