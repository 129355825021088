import { REACT_APP_AUTOMAPA_API_KEY } from "./environment"

export function addTargeoScript(id = '') {
    const targeoScript = document.createElement('script')
    targeoScript.src = `https://mapa.targeo.pl/Targeo.html?vn=3_0&v=full&rv=form&ext=suggest&k=${REACT_APP_AUTOMAPA_API_KEY}&e=TargeoSuggesterInitialize&f=SuggesterInitialize` 
    // targeoScript.src = `https://mapa.targeo.pl/Targeo.html?vn=3_0&k=${REACT_APP_AUTOMAPA_API_KEY}&f=TargeoMapInitialize&e=TargeoMapContainer${id ? id : ''}&ln=pl`
    targeoScript.async = true
    targeoScript.setAttribute('id','targeoInitializeScript');

    const handleScriptLoad = () => {
        document.dispatchEvent(new Event('DOMContentLoaded'))
    }

    targeoScript.addEventListener('load', handleScriptLoad)

    document.body.appendChild(targeoScript)
}
