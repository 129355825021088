import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconMoneyBill() {
    return (
        <SvgIcon>
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.125 1.875C16.9375 1.6875 16.9375 1.34375 17.125 1.15625C17.3125 0.96875 17.6562 0.96875 17.8438 1.15625L19.8438 3.15625C19.9375 3.25 20 3.375 20 3.5C20 3.65625 19.9375 3.78125 19.8438 3.875L17.8438 5.875C17.6562 6.0625 17.3125 6.0625 17.125 5.875C16.9375 5.6875 16.9375 5.34375 17.125 5.15625L18.2812 4H16.875H16.375H5C5 5.65625 3.65625 7 2 7V10.9062L1.4375 11.4375L1 11.9062V5C1 3.90625 1.875 3 3 3H11.9688H12H16.375H16.875H18.2812L17.125 1.875ZM4 4H3C2.4375 4 2 4.46875 2 5V6C3.09375 6 4 5.125 4 4ZM1.6875 15L2.84375 16.1562C3.03125 16.3438 3.03125 16.6875 2.84375 16.875C2.65625 17.0625 2.3125 17.0625 2.125 16.875L0.125 14.875C0.03125 14.7812 0 14.6562 0 14.5C0 14.375 0.03125 14.25 0.125 14.1562L2.125 12.1562C2.3125 11.9688 2.65625 11.9688 2.84375 12.1562C3.03125 12.3438 3.03125 12.6875 2.84375 12.875L1.6875 14H8H15C15 12.3438 16.3438 11 18 11V7.125L18.5312 6.5625L19 6.125V13C19 14.125 18.0938 15 17 15H3.09375H1.6875ZM18 12C16.875 12 16 12.9062 16 14H17C17.5312 14 18 13.5625 18 13V12ZM8 9C8 9.71875 8.375 10.375 9 10.75C9.59375 11.0938 10.375 11.0938 11 10.75C11.5938 10.375 12 9.71875 12 9C12 8.3125 11.5938 7.65625 11 7.28125C10.375 6.9375 9.59375 6.9375 9 7.28125C8.375 7.65625 8 8.3125 8 9ZM13 9C13 10.0938 12.4062 11.0625 11.5 11.625C10.5625 12.1562 9.40625 12.1562 8.5 11.625C7.5625 11.0625 7 10.0938 7 9C7 7.9375 7.5625 6.96875 8.5 6.40625C9.40625 5.875 10.5625 5.875 11.5 6.40625C12.4062 6.96875 13 7.9375 13 9Z"
                    fill="#202020"
                />
            </svg>
        </SvgIcon>
    )
}
