import {
    Box,
    Checkbox,
    InputAdornment,
    TextField,
    Typography,
    Button,
    Select,
    MenuItem,
    FormControlLabel,
    CircularProgress,
    Alert,
    FormControl,
    InputLabel,
    Tooltip,
    Zoom,
} from '@mui/material'
import { Field, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import { useTranslation } from 'react-i18next'
import CountrySelect from '../components/CountrySelect'
import { API } from '../api'
import { API as API2 } from '../../api'
import Spinner from '../../components/Spinner'
import PopupEmailExist from '../../RAC/Step4/PopupEmailExist'
import { useParams } from 'react-router-dom'
import { environment } from '../../../environment'
import LinkText from '../../components/LinkText'

export default function FormBusiness({
    userData,
    values,
    handleUserDataChange,
    touched,
    errors,
    setIsOtherDriver,
    isOtherDriver,
    handleResetCompanyData,
    userDataInSystem,
    isLogged,
    showPopup,
}) {
    const [isActive, setIsActive] = useState(false)
    const [isOtherUser, setIsOtherUser] = useState(false)
    const [companyData, setCompanyData] = useState(null)
    const [companyDataIsLoading, setCompanyDataIsLoading] = useState(false)
    const [loadingCheckIfAccountExist, setLoadingCheckIfAccountExist] = useState(false)
    // const [isLogged, setIsLogged] = useState(false)
    const [accountExists, setAccountExists] = useState(false)
    const [showDialogAccountExist, setShowDialogAccountExist] = useState(false)
    const [errorCompanyData, setErrorCompanyData] = useState(null)
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()

    const { handleBlur } = useFormikContext()

    const handleClickGUS = nip => {
        if (isActive) {
            setIsActive(false)
        }
        getCompanyData(nip)
    }

    const getCompanyData = async nip => {
        setCompanyDataIsLoading(true)
        setErrorCompanyData('')
        try {
            const response = await fetch(`${API['GET_COMPANY_DATA']}?nip=${nip}`, {
                method: 'GET',
            })
            if (response.ok) {
                const res = await response.json()
                setCompanyData(res)
                handleUserDataChange('companyName', res?.name)
                handleUserDataChange('companyStreet', res?.address?.street)
                handleUserDataChange('companyHouse', res?.address?.house)
                handleUserDataChange('companyLocal', res?.address?.local)
                handleUserDataChange('companyPostCode', res?.address?.postalCode)
                handleUserDataChange('companyCity', res?.address?.place)
                setCompanyDataIsLoading(false)
                setErrorCompanyData(null)

                if (res.status === 404) setErrorCompanyData(t('errors.gus_company_data_not_found'))
            } else {
                console.error('Error fetching data')
            }
        } catch (error) {
            console.error('Error fetching data', error)
        }
    }

    const handleCheckIfAccountExists = async email => {
        try {
            setLoadingCheckIfAccountExist(true)
            const response = await fetch(`${API2['ACCOUNT_EXISTS']}?email=${email}`)
            if (response.ok) {
                const res = await response.json()
                setAccountExists(res.accountExists)
                setLoadingCheckIfAccountExist(false)
            } else {
                console.error('Error fetching data')
            }
        } catch (error) {
            console.error('Error fetching data', error)
        }
    }

    useEffect(() => {
        if (userData.userCountry !== 'PL') {
            setIsActive(true)
        }
    }, [userData.userCountry])

    useEffect(() => {
        accountExists && setShowDialogAccountExist(true)
    }, [accountExists])

    useEffect(() => {
        if (isOtherDriver) {
            setIsOtherUser(true)
        }
    }, [isOtherDriver])

    useEffect(() => {
        if (isLogged && userDataInSystem && userDataInSystem?.companies) {
            setIsActive(true)
        }
    }, [userData.userNip])

    return (
        <div className="mt-8 ">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
                <Field
                    disabled={isLogged && userData.userFirstName}
                    as={TextField}
                    value={userData.userFirstName}
                    name="userFirstName"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.userFirstName && Boolean(errors.userFirstName)}
                    helperText={touched.userFirstName && errors.userFirstName}
                    fullWidth
                    placeholder={t('LAC.client_data.name')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonOutlineOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Field
                    disabled={isLogged && userData.userLastName}
                    as={TextField}
                    value={userData.userLastName}
                    name="userLastName"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.userLastName && Boolean(errors.userLastName)}
                    helperText={touched.userLastName && errors.userLastName}
                    fullWidth
                    placeholder={t('LAC.client_data.surname')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonOutlineOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Field
                    disabled={isLogged && userData.userEmail}
                    as={TextField}
                    value={userData.userEmail}
                    name="userEmail"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.userEmail && Boolean(errors.userEmail)}
                    helperText={touched.userEmail && errors.userEmail}
                    fullWidth
                    placeholder={t('LAC.client_data.email')}
                    onBlur={e => {
                        handleBlur(e)
                        handleCheckIfAccountExists(e.target.value)
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlineIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {loadingCheckIfAccountExist && <CircularProgress size={20} />}
                            </InputAdornment>
                        ),
                    }}
                />
                <Tooltip TransitionComponent={Zoom} title={t('LAC.client_data.tooltip_text_2')}>
                    <div>
                        <Field
                            disabled={isLogged && userData.userPhoneNumber}
                            as={TextField}
                            value={userData.userPhoneNumber}
                            name="userPhoneNumber"
                            onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                            error={touched.userPhoneNumber && Boolean(errors.userPhoneNumber)}
                            helperText={touched.userPhoneNumber && errors.userPhoneNumber}
                            fullWidth
                            placeholder={t('LAC.client_data.phone')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PhoneInTalkOutlinedIcon />
                                        {locale === 'pl' && userData.userCountry === 'PL' && !isLogged && '+48'}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </Tooltip>
            </div>
            <div>
                {!isLogged ? (
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={e =>
                                        handleUserDataChange('userCheckedWantToCreateAccount', e.target.checked)
                                    }
                                    color="primary"
                                />
                            }
                            label={
                                <div className="text-[11px] leading-3 flex flex-col">
                                    <span>{t('LAC.client_data.want_acc')}</span>
                                    <span>{t('LAC.client_data.sign_up_text')}</span>
                                </div>
                            }
                        />
                    </Box>
                ) : null}
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                required
                                onChange={e => handleUserDataChange('userAllowedTerms', e.target.checked)}
                                color="primary"
                            />
                        }
                        label={
                            <span className="text-[11px] leading-3">
                                {t('LAC.client_data.consent_1')} <LinkText href={locale === 'pl' ? API['GET_FILE_REGULATIONS_USER_TERMS_AND_CONDITIONS'] : API['GET_FILE_REGULATIONS_USER_TERMS_AND_CONDITIONS_EN']}>{t('LAC.client_data.consent_2')}</LinkText>{' '}
                                {t('LAC.client_data.consent_3')} <LinkText href={environment.privacyPolicy[locale]}>{t('LAC.client_data.consent_4')}</LinkText>
                            </span>
                        }
                    />
                </Box>
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={e => {
                                    handleUserDataChange('profiling', e.target.checked)
                                    handleUserDataChange('withoutProfiling', e.target.checked)
                                    handleUserDataChange('telemarketing', e.target.checked)
                                }}
                                color="primary"
                            />
                        }
                        label={
                            <div className="text-[11px] leading-3 flex flex-col">
                                <span>{t('LAC.client_data.profiling_text')}</span>
                            </div>
                        }
                    />
                </Box>
                {userData.profiling ? (
                    <>
                        <Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={e => handleUserDataChange('withoutProfiling', e.target.checked)}
                                        color="primary"
                                        checked={userData.withoutProfiling}
                                    />
                                }
                                label={
                                    <div className="text-[11px] leading-3 flex flex-col">
                                        <span>{t('LAC.client_data.without_profiling_text')}</span>
                                    </div>
                                }
                            />
                        </Box>
                        <Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={e => handleUserDataChange('telemarketing', e.target.checked)}
                                        color="primary"
                                        checked={userData.telemarketing}
                                    />
                                }
                                label={
                                    <div className="text-[11px] leading-3 flex flex-col">
                                        <span>{t('LAC.client_data.telemarketing_text')}</span>
                                    </div>
                                }
                            />
                        </Box>
                    </>
                ) : null}
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isOtherUser}
                                onChange={event => {
                                    setIsOtherUser(event.target.checked)
                                    setIsOtherDriver(event.target.checked)
                                    handleUserDataChange('isOtherDriver', event.target.checked)
                                }}
                                color="primary"
                            />
                        }
                        label={<span className="text-[11px] leading-3">{t('LAC.client_data.other_driver')}</span>}
                    />
                </Box>
                {isOtherUser ? (
                    <div>
                        <Box my={3} mb={4}>
                            <Typography variant="h2" textAlign="left" fontWeight="bold" fontSize={{ xs: 15, lg: 18 }}>
                                {t('LAC.client_data.heading_4')}
                            </Typography>
                        </Box>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <Field
                                as={TextField}
                                value={userData.userAdditionalFirstName}
                                name="userAdditionalFirstName"
                                onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                                error={touched.userAdditionalFirstName && Boolean(errors.userAdditionalFirstName)}
                                helperText={touched.userAdditionalFirstName && errors.userAdditionalFirstName}
                                fullWidth
                                placeholder={t('LAC.client_data.name')}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonOutlineOutlinedIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Field
                                as={TextField}
                                value={userData.userAdditionalLastName}
                                name="userAdditionalLastName"
                                onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                                error={touched.userAdditionalLastName && Boolean(errors.userAdditionalLastName)}
                                helperText={touched.userAdditionalLastName && errors.userAdditionalLastName}
                                fullWidth
                                placeholder={t('LAC.client_data.surname')}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonOutlineOutlinedIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Field
                                as={TextField}
                                value={userData.userAdditionalPhone}
                                name="userAdditionalPhone"
                                onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                                error={touched.userAdditionalPhone && Boolean(errors.userAdditionalPhone)}
                                helperText={touched.userAdditionalPhone && errors.userAdditionalPhone}
                                fullWidth
                                placeholder={t('LAC.client_data.phone')}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneInTalkOutlinedIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="mt-6">
                <Box mb={4}>
                    <Typography variant="h2" textAlign="left" fontWeight="bold" fontSize={{ xs: 15, lg: 18 }}>
                        {t('LAC.client_data.heading_5')}
                    </Typography>
                </Box>
                {isLogged && userDataInSystem?.companies && (
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="company-select-label">{t('LAC.client_data.company_name')}</InputLabel>
                            <Select
                                name="companyId"
                                id="company-select"
                                label="company-select"
                                value={values.companyId}
                                placeholder={t('LAC.client_data.company_name')}
                                onChange={e => {
                                    handleUserDataChange('companyId', e.target.value)
                                    setIsActive(true)
                                }}
                            >
                                {userDataInSystem.companies?.map((company, index) => (
                                    <MenuItem
                                        key={`company-${company.id}`}
                                        value={company.id}
                                        onClick={() => {
                                            setErrorCompanyData(false)
                                            setCompanyData(company)
                                            handleUserDataChange(
                                                'companyName',
                                                company?.companyName ? company?.companyName : ''
                                            )
                                            handleUserDataChange(
                                                'companyStreet',
                                                company?.address?.street ? company?.address?.street : ''
                                            )
                                            handleUserDataChange(
                                                'companyHouse',
                                                company?.address?.house ? company?.address?.house : ''
                                            )
                                            handleUserDataChange(
                                                'companyLocal',
                                                company?.address?.local ? company?.address?.local : ''
                                            )
                                            handleUserDataChange(
                                                'companyPostCode',
                                                company?.address?.postCode ? company?.address?.postCode : ''
                                            )
                                            handleUserDataChange(
                                                'companyCity',
                                                company?.address?.city ? company?.address?.city : ''
                                            )
                                            handleUserDataChange('companyEmail', company?.email ? company?.email : '')
                                            handleUserDataChange(
                                                'companyPhone',
                                                company?.phoneNo ? company?.phoneNo : ''
                                            )
                                            handleUserDataChange('userNip', company?.nip ? company?.nip : '')
                                        }}
                                    >
                                        {company.companyName}
                                    </MenuItem>
                                ))}
                                <MenuItem
                                    key={`company-new`}
                                    value="new"
                                    onClick={() => {
                                        setCompanyData('')
                                        handleUserDataChange('companyName', '')
                                        handleUserDataChange('companyStreet', '')
                                        handleUserDataChange('companyHouse', '')
                                        handleUserDataChange('companyLocal', '')
                                        handleUserDataChange('companyPostCode', '')
                                        handleUserDataChange('companyCity', '')
                                        handleUserDataChange('companyEmail', '')
                                        handleUserDataChange('companyPhone', '')
                                        handleUserDataChange('userNip', '')
                                    }}
                                >
                                    Nowa firma
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                )}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <CountrySelect userData={userData} handleUserDataChange={handleUserDataChange} showPopup={showPopup} formType='business' />
                    {userData?.userCountry === 'PL' ? (
                        <Field
                            as={TextField}
                            value={userData.userNip}
                            name="userNip"
                            onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                            error={touched.userNip && Boolean(errors.userNip)}
                            helperText={touched.userNip && errors.userNip}
                            fullWidth
                            placeholder={t('LAC.client_data.nip')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <RemoveCircleOutlineOutlinedIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <>
                                        {userData.userCountry === 'PL' ? (
                                            <Button
                                                variant="text"
                                                sx={{
                                                    fontSize: 12,
                                                    fontWeight: 'bold',
                                                    lineHeight: 1.5,
                                                }}
                                                onClick={() => handleClickGUS(values.userNip)}
                                                disabled={userData.userNip === '' || userData.userNip.length < 10}
                                            >
                                                Wczytaj dane z&nbsp;GUS
                                            </Button>
                                        ) : null}
                                    </>
                                ),
                            }}
                        />
                    ) : (
                        <Field
                            as={TextField}
                            value={userData.companyAdditionalData}
                            name="companyAdditionalData"
                            onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                            error={touched.companyAdditionalData && Boolean(errors.companyAdditionalData)}
                            helperText={touched.companyAdditionalData && errors.companyAdditionalData}
                            fullWidth
                            placeholder={t('LAC.client_data.nip_2')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <RemoveCircleOutlineOutlinedIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                </div>
                {companyDataIsLoading && <Spinner />}
                {errorCompanyData ? (
                    <Alert severity="error" sx={{ mb: 2, mt: 2 }}>
                        {errorCompanyData}
                    </Alert>
                ) : null}
                {companyData && errorCompanyData == null && (
                    <Box
                        my={2}
                        sx={{
                            backgroundColor: '#f4f4f3',
                            p: 2,
                        }}
                    >
                        <Typography fontWeight="bold">{companyData?.name}</Typography>
                        <Typography fontWeight="bold">{companyData?.companyName}</Typography>
                        <Typography>NIP: {companyData?.nip}</Typography>
                        {companyData?.regon && <Typography>REGON: {companyData?.regon}</Typography>}
                        <Typography>
                            {companyData?.address?.street} {companyData?.address?.house}
                            {companyData?.address?.local && '/'}
                            {companyData?.address?.local}
                        </Typography>
                        {companyData?.address?.place && (
                            <Typography>
                                {companyData?.address?.place} {companyData?.address?.postalCode}
                            </Typography>
                        )}
                        {companyData?.address?.city && (
                            <Typography>
                                {companyData?.address?.city} {companyData?.address?.postCode}
                            </Typography>
                        )}
                    </Box>
                )}
                <div>
                    <Box mb={4} mt={6}>
                        {((errors.companyName && touched.companyName) ||
                            (errors.companyStreet && touched.companyStreet) ||
                            (errors.companyHouse && touched.companyHouse) ||
                            (errors.companyPostCode && touched.companyPostCode) ||
                            (errors.companyPhone && touched.companyPhone)) &&
                        !isActive ? (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                Uzupełnij dane firmowe
                            </Alert>
                        ) : null}
                        <Box textAlign="center" mb={2}>
                            <Button
                                onClick={() => {
                                    setIsActive(!isActive)
                                    setCompanyData(null)
                                    // handleResetCompanyData()
                                }}
                                sx={{ fontWeight: 'bold', textTransform: 'none' }}
                            >
                                {isActive ? (
                                    <>
                                        {t('LAC.client_data.button_3')} <KeyboardArrowUpOutlinedIcon />
                                    </>
                                ) : (
                                    <>
                                        {t('LAC.client_data.button_3')} <KeyboardArrowDownOutlinedIcon />
                                    </>
                                )}
                            </Button>
                        </Box>

                        {isActive && (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <Field
                                    as={TextField}
                                    value={userData.companyName}
                                    name="companyName"
                                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                                    error={touched.companyName && Boolean(errors.companyName)}
                                    helperText={touched.companyName && errors.companyName}
                                    fullWidth
                                    placeholder={t('LAC.client_data.company_name')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonOutlineOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Field
                                    as={TextField}
                                    value={userData.companyStreet}
                                    name="companyStreet"
                                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                                    error={touched.companyStreet && Boolean(errors.companyStreet)}
                                    helperText={touched.companyStreet && errors.companyStreet}
                                    fullWidth
                                    placeholder={t('LAC.client_data.street')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Field
                                    as={TextField}
                                    value={userData.companyHouse}
                                    name="companyHouse"
                                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                                    error={touched.companyHouse && Boolean(errors.companyHouse)}
                                    helperText={touched.companyHouse && errors.companyHouse}
                                    fullWidth
                                    placeholder={t('LAC.client_data.house')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Field
                                    as={TextField}
                                    value={userData.companyLocal}
                                    name="companyLocal"
                                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                                    fullWidth
                                    placeholder={t('LAC.client_data.local')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Field
                                    as={TextField}
                                    value={userData.companyPostCode}
                                    name="companyPostCode"
                                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                                    error={touched.companyPostCode && Boolean(errors.companyPostCode)}
                                    helperText={touched.companyPostCode && errors.companyPostCode}
                                    fullWidth
                                    placeholder={t('LAC.client_data.postcode')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Field
                                    as={TextField}
                                    value={userData.companyCity}
                                    name="companyCity"
                                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                                    error={touched.companyCity && Boolean(errors.companyCity)}
                                    helperText={touched.companyCity && errors.companyCity}
                                    fullWidth
                                    placeholder={t('LAC.client_data.city')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        )}
                    </Box>
                </div>
                <div>
                    <Box mb={4}>
                        <Typography variant="h2" textAlign="left" fontWeight="bold" fontSize={{ xs: 15, lg: 18 }}>
                            {t('LAC.client_data.heading_6')}
                        </Typography>
                    </Box>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                        <Field
                            as={TextField}
                            value={userData.companyEmail}
                            name="companyEmail"
                            onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                            error={touched.companyEmail && Boolean(errors.companyEmail)}
                            helperText={touched.companyEmail && errors.companyEmail}
                            fullWidth
                            placeholder={t('LAC.client_data.email')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MailOutlineIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Field
                            as={TextField}
                            value={userData.companyPhone}
                            name="companyPhone"
                            onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                            error={touched.companyPhone && Boolean(errors.companyPhone)}
                            helperText={touched.companyPhone && errors.companyPhone}
                            fullWidth
                            placeholder={t('LAC.client_data.phone')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PhoneInTalkOutlinedIcon />
                                        <span className="ml-2">
                                            {locale === 'pl' && userData.userCountry === 'PL' && !isLogged && '+48'}
                                        </span>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={e => handleUserDataChange('companyMarketing', e.target.checked)}
                                    color="primary"
                                />
                            }
                            label={
                                <div className="text-[11px] leading-3 flex flex-col">
                                    <span>{t('LAC.client_data.company_marketing_text')}</span>
                                </div>
                            }
                        />
                    </Box>
                </div>
            </div>
            {showDialogAccountExist === true && (
                <PopupEmailExist
                    showAlert={showDialogAccountExist}
                    setShowAlert={setShowDialogAccountExist}
                    dialogTitle="Masz już konto w Express.pl?"
                />
            )}
        </div>
    )
}
