import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, useFormikContext } from 'formik'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import theme from '../../../styles/MUI-theme'

const FieldOptions = ({ name, label, optionId, children, processData }) => {
    const { t } = useTranslation()
    const { setFieldValue } = useFormikContext()

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setFieldValue(name, isActive)
        isActive ? setFieldValue(`${name}Id`, optionId.toString()) : setFieldValue(`${name}Id`, '-1')
    }, [isActive])

    useEffect(() => {
        processData &&
            processData?.services_with_gps === 'true' &&
            optionId === parseInt(processData?.services_gps_id) &&
            setIsActive(true)
        processData &&
            processData?.services_with_fuel_card === 'true' &&
            optionId === parseInt(processData?.services_fuel_card_id) &&
            setIsActive(true)
    }, [processData])

    const handleClick = () => {
        setIsActive(!isActive)
    }

    return (
        <Box
            type="button"
            border={`1px solid ${isActive ? 'orange' : '#d1d1d1'}`}
            p={4}
            mb={2}
            borderRadius="10px"
            sx={{
                opacity: isActive ? 1 : 0.7,
                transition: 'opacity 0.5s',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Field
                name={name}
                as={FormControlLabel}
                sx={{
                    display: 'block',
                }}
                control={
                    <>
                        <Box display="flex" alignItems="center" pb={1}>
                            {isActive ? (
                                <CheckBoxIcon color="secondary" />
                            ) : (
                                <CheckBoxOutlineBlankIcon color="secondary" />
                            )}
                            <Typography
                                sx={{
                                    fontSize: { xs: 16, lg: 20 },
                                    color: theme.palette.primary.main,
                                    fontWeight: 'bold',
                                    pl: { xs: 1, lg: 2 },
                                }}
                            >
                                {label}
                            </Typography>
                        </Box>

                        <Box>{children}</Box>
                    </>
                }
                value={isActive}
                onClick={handleClick}
                // sx={{ alignItems: props.aligntop ? 'flex-start' : 'center' }}
                // {...props}
            />
        </Box>
    )
}

export default FieldOptions
