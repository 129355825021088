import React, { useEffect } from 'react'
import { environment } from './../../environment'
import TextLinks from '../../components/TextLinks'
import { useTranslation } from 'react-i18next'
import '../../styles/text-box.css'

function TextBox(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('TextBox')
        console.log(params)
    }
    const { t, i18n } = useTranslation()
    useEffect(() => {
        i18n.changeLanguage(params.locale)
    }, [params.locale])
    
    const hide = params.block[params.locale].hasOwnProperty('Hide') ? params.block[params.locale]['Hide'] === true ? true : false : false;
    const [isOpen, setOpen] = React.useState(!hide)
    let LinkType = params.block[params.locale].hasOwnProperty('ButtonType') ? params.block[params.locale]['ButtonType'] : 'color1';

    return (
        <section className={'text-box'}>
            <div className="wrapper">
                <div className={'container mx-auto px-8 my-20'}>
                    {params.block[params.locale]['Title'] && <h2 className={'c-'+params.block[params.locale]['TitleColor']}>{params.block[params.locale]['Title']}</h2>}
                    {params.block[params.locale]['Teaser'] && (
                        <div className="teaser">
                            <TextLinks text={params.block[params.locale]['Teaser']} line={params.line} />
                        </div>
                    )}
                    {params.block[params.locale]['Text'] && (
                        <div 
                            aria-hidden={!isOpen}
                            role="region"
                            tabIndex="-1"
                            className={`grid text-slate-600 overflow-hidden transition-all duration-300 ease-in-out ${
                                isOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
                            }`}
                        >
                            <div
                                className={`overflow-hidden`}
                            >
                                <TextLinks text={params.block[params.locale]['Text']} line={params.line} />
                            </div>
                        </div>
                    )}
                    
                    {hide && <div className={'button-box text-center mt-5' +(isOpen ? ' open' : '')}
                            aria-expanded={isOpen}
                    >
                        <a href="#"
                            onClick={e => {
                                e.preventDefault()
                                setOpen(!isOpen)
                            }}
                            title={t('portlets.text_box.expand')} 
                            className={'btn-link b-' +LinkType}>
                                {!isOpen ? t('portlets.text_box.expand') : t('portlets.text_box.collapse')}
                        </a>
                    </div>}
                    
                </div>
            </div>
        </section>
    )
}

export default TextBox
