import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

import theme from '../../../styles/MUI-theme'

const PricesPackages = ({ totalPrice, dayCount }) => {
    const { t } = useTranslation()

    return (
        <Box
            px={{ xs: 2, lg: 4 }}
            py={1}
            sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderRadius: '0 0 10px 10px',
            }}
        >
            <Box display="flex" alignItems="center">
                <Box>
                    <Typography fontSize={{ xs: 12, lg: 14 }} fontWeight="bold">
                        {t('RAC.step_three.total_cost')}
                    </Typography>
                    <Typography fontSize={{ xs: 16, lg: 20 }} fontWeight="bold">
                        {totalPrice}
                        <Box
                            component="span"
                            sx={{ color: '#CECECE', fontWeight: 'normal', fontSize: { xs: 12, lg: 14 } }}
                        >
                            {' '}
                            / {dayCount} {t('RAC.step_two.price.days')}
                        </Box>
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default PricesPackages
