import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined'
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined'
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const EquipmentList = ({ fuel, transmission, seats, largeBags, smallBags, fuelTypes, transmissionTypes }) => {
    const { t } = useTranslation()
    const { locale } = useParams()

    return (
        <Box mt={2}>
            <List>
                <ListItem sx={{ p: 0 }}>
                    <PersonOutlineOutlinedIcon />
                    <ListItemText py={0}>
                        <Typography fontSize={{ xs: 12, lg: 16 }} ml={1}>
                            {seats}
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                    <DirectionsCarOutlinedIcon />
                    <ListItemText>
                        <Typography fontSize={{ xs: 12, lg: 16 }} ml={1}>
                            {transmission.split('/').map(item => transmissionTypes[item]).join(' / ')}
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                    <LocalGasStationOutlinedIcon />
                    <ListItemText>
                        <Typography fontSize={{ xs: 12, lg: 16 }} ml={1}>
                            {fuel.split('/').map(item => fuelTypes[item]).join(' / ')}
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                    <LuggageOutlinedIcon />
                    <ListItemText>
                        <Typography fontSize={{ xs: 12, lg: 16 }} ml={1}>
                            {smallBags} {smallBags === 1 && locale === 'pl' ? 'mała' : t('RAC.step_two.car_equipment.small_bags')} {t('and')} {largeBags}{' '}
                            {largeBags === 1 && locale === 'pl' ? 'duża' : t('RAC.step_two.car_equipment.large_bags')}
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                    <InfoOutlinedIcon />
                    <ListItemText>
                        <Typography fontSize={{ xs: 12, lg: 16 }} ml={1}>
                            {t('RAC.step_two.no_limit')}
                        </Typography>
                    </ListItemText>
                </ListItem>
            </List>
            <Typography fontSize={{ xs: 10, lg: 12 }}>{t('RAC.step_two.info_text_fuel_level')}</Typography>
            <Typography fontSize={{ xs: 10, lg: 12 }} mb={1}>
                {t('RAC.step_two.info_text_limit_abroad')}
            </Typography>
        </Box>
    )
}

export default EquipmentList
