import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import theme from '../../../styles/MUI-theme'
import IconSearchResult from '../../components/IconSearchResult'
import useFetch from '../../hooks/useFetch'
import { API, FORM_KEY } from '../../api'
import Spinner from '../../components/Spinner'

const ReservationDetialsLocation = ({
    isExpress,
    icon,
    address,
    additionalInfo,
    activeStep,
    priceInitial,
    priceFullCalc,
    locationName,
    ...props
}) => {
    const { fetchData } = useFetch()

    const { id } = useParams()

    const { t } = useTranslation()

    const handleClickEditStep1 = () => {
        fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=${FORM_KEY['step1']}`, 'GET')
    }

    return (
        <Box
            width="100%"
            p={{ xs: 1, lg: 2 }}
            display="flex"
            justifyContent="space-between"
            borderBottom={`0.5px solid ${theme.palette.secondary.main}`}
            bgcolor="white"
        >
            <>
                <Box display={'flex'}>
                    <IconSearchResult icon={icon} />
                    {address ? (
                        <Box>
                            <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'bold'}>
                                {locationName || address?.split(',')[0]}
                            </Typography>
                            <Typography fontSize={{ xs: 10, lg: 14 }} fontWeight={'regular'}>
                                {locationName
                                    ? `${address?.split(',')[0]}` || ''
                                    : `${address?.split(',')[1]}, ${address?.split(',')[2] || ''}`}
                            </Typography>
                            <Typography fontSize={{ xs: 10, lg: 14 }} fontWeight={'regular'}>
                                {additionalInfo}: {props.pickupDate}
                                {props.pickupDate && props.returnDate && ' — '}
                                {props.returnDate}
                            </Typography>
                        </Box>
                    ) : (
                        <Spinner size={20} sx={{ m: 0 }} />
                    )}
                </Box>
                <Box textAlign="right">
                    <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'regular'} textAlign={'right'}>
                        {activeStep > 2 ? priceFullCalc : <>{/* {t('from')} {priceInitial} */}</>}
                    </Typography>
                    <Box
                        mt={activeStep === 2 ? 0 : 1}
                        role="button"
                        type="submit"
                        color={theme.palette.primary.main}
                        onClick={handleClickEditStep1}
                        sx={{ color: theme.palette.primary.main, fontSize: { xs: 12, lg: 16 } }}
                    >
                        {t('btn.edit')}
                    </Box>
                    {activeStep === 2 && (
                        <Typography
                            sx={{
                                maxWidth: { md: '220px' },
                                display: { xs: 'none', md: 'block' },
                                fontSize: { xs: 12, lg: 14 },
                                fontWeight: 'regular',
                                textAlign: 'right',
                            }}
                        >
                            {t('RAC.step_two.additional_price_message')}
                        </Typography>
                    )}
                </Box>
            </>
        </Box>
    )
}

export default ReservationDetialsLocation
