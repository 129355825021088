import React, { useEffect } from 'react'
import { useState, useContext } from 'react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    Input,
    InputAdornment,
    MenuItem,
    Select,
    Slider,
    styled,
} from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TuneIcon from '@mui/icons-material/Tune'

import Spinner from '../components/Spinner'
import Stepper from '../components/Stepper'
import Loader from '../components/Loader'

import { LocaleContext } from './Form'

import '../../styles/form.css'
import DialogFilters from './DialogFilters'
import { useFormContext } from './FormContext'

const color = '#f07d14'
const CustomSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-track': {
        backgroundColor: 'white', //color of thumbs
        border: '1px solid #c7c7c7',
    },
    '& .MuiSlider-thumb': {
        backgroundColor: color, //color of thumbs
        height: '12px',
        width: '12px',
    },
    '& .MuiSlider-rail': {
        color: 'white', ////color of the slider outside  teh area between thumbs
        border: '1px solid #c7c7c7',
        opacity: '1',
    },
}))

const FormStep1CMS = params => {
    const {
        brands,
        models,
        fuelTypes,
        showUrl,
        transmission,
        drive,
        localization,
        details,
        filteredOffers,
        rangeValues,
        lowerValue,
        higherValue,
        isDailyCar,
        setIsDailyCar,
        mark,
        setMark,
        model,
        engineType,
        isLoading,
        handleSliderChange,
        handleLowerInputChange,
        handleHigherInputChange,
        handleBrandChange,
        handleChangeModel,
        handleChangeEngineType,
        minSliderPortlet,
        maxSliderPortlet,
    } = useFormContext()
    const { t } = useTranslation()
    const { locale } = useContext(LocaleContext)

    const [error, setError] = useState('')
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleShowOffers = e => {
        e.preventDefault()
        const url = window.location.href.includes('/en/') ? `/en/car-for-company/flexible-rental/${showUrl}` : `/auto-dla-firmy/wynajem-elastyczny/${showUrl}`
        window.open(url, "_self")
    }

    function wordVariation(word) {
        switch (word) {
            case 'ofert':
                if (filteredOffers.length === 1) {
                    return `${word}ę`
                } else if (filteredOffers.length === 2 || filteredOffers.length === 3 || filteredOffers.length === 4) {
                    return `${word}y`
                } else {
                    return word
                }

            default:
                if (filteredOffers.length === 1) {
                    return `${word}a`
                } else if (filteredOffers.length === 2 || filteredOffers.length === 3 || filteredOffers.length === 4) {
                    return `${word}y`
                } else {
                    return word
                }
        }
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <Container disableGutters>
            <Formik>
                {({ isSubmitting, values, errors, touched }) => (
                    <Form noValidate className="h-full">
                        <div className="p-5 lg:p-10 lg:pb-14 relative h-full">
                        {!params.edit && params.params.block[locale]?.LACTitle ? (
                                <h2 className="c-color1 font-bold text-4xl lg:text-[44px] leading-tight mb-10">
                                    {params.params.block[locale].LACTitle}
                                </h2>
                            ) : (
                                <>
                                    {params.isStepper ? 
                                        <Box mb={4}>
                                            <Stepper activeStep={1} /> 
                                        </Box> : null
                                        
                                    }
                                </>
                            )}

                            <Box
                                sx={{
                                    height: '56px',
                                    borderRadius: '50px',
                                    mb: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <button
                                    onClick={e => {
                                        e.preventDefault()
                                        setIsDailyCar('Car')
                                    }}
                                    className={`transition border rounded-l-[50px] font-bold h-full cursor-pointer flex items-center justify-center w-[50%] ${isDailyCar === 'Car' || isDailyCar === 'All' ? 'text-orange-500 border-orange-500' : 'border-[#d1d1d1] c-color1'}`}
                                >
                                    <DirectionsCarFilledOutlinedIcon sx={{ marginRight: '5px' }} />
                                    {t('LAC.step_one.label_first_button')}
                                </button>
                                <button
                                    onClick={e => {
                                        e.preventDefault()
                                        setIsDailyCar('Truck')
                                    }}
                                    className={`transition border rounded-r-[50px] font-bold h-full cursor-pointer flex items-center justify-center w-[50%] ${isDailyCar === 'Truck' ? 'text-orange-500 border-orange-500' : 'border-[#d1d1d1] c-color1'}`}
                                >
                                    <LocalShippingOutlinedIcon sx={{ marginRight: '5px' }} />
                                    {t('LAC.step_one.label_second_button')}
                                </button>
                            </Box>

                            <Box
                                sx={{
                                    height: '56px',
                                    border: '1px solid #d1d1d1',
                                    borderRadius: '50px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '10px 20px',
                                    mb: '20px',
                                    gap: '15px',
                                }}
                            >
                                <FormControl fullWidth>
                                    <Select
                                        IconComponent={ExpandMoreIcon}
                                        displayEmpty
                                        value={mark}
                                        onChange={e => {
                                            handleBrandChange(e.target.value)
                                            setMark(e.target.value)
                                        }}
                                        variant="standard"
                                        disableUnderline
                                    >
                                        <MenuItem value="">{t('LAC.step_one.label_select_brand')}</MenuItem>
                                        {Object.entries(brands).map(([id, name]) => (
                                            <MenuItem key={id} value={id}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Divider orientation="vertical" />
                                <FormControl fullWidth>
                                    <Select
                                        IconComponent={ExpandMoreIcon}
                                        displayEmpty
                                        value={model}
                                        onChange={handleChangeModel}
                                        disableUnderline
                                        variant="standard"
                                    >
                                        <MenuItem value="">{t('LAC.step_one.label_select_model')}</MenuItem>
                                        {models.hasOwnProperty('pl') || models.hasOwnProperty('en')
                                            ? Object.entries(models['pl']).map(([id, name]) => (
                                                  <MenuItem key={id} value={id}>
                                                      {name}
                                                  </MenuItem>
                                              ))
                                            : null}
                                    </Select>
                                </FormControl>
                                <Divider orientation="vertical" />
                                <FormControl fullWidth>
                                    <Select
                                        IconComponent={ExpandMoreIcon}
                                        disableUnderline
                                        displayEmpty
                                        value={engineType}
                                        onChange={handleChangeEngineType}
                                        variant="standard"
                                    >
                                        <MenuItem value="">{t('LAC.step_one.label_select_fuel')}</MenuItem>
                                        {Object.entries(fuelTypes).map(([id, name]) => (
                                            <MenuItem key={id} value={id}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box mt={5}>
                                <span className="font-bold">{t('LAC.step_one.range_title')}</span>
                                <CustomSlider
                                    value={rangeValues}
                                    onChange={handleSliderChange}
                                    valueLabelDisplay="auto"
                                    min={minSliderPortlet}
                                    max={maxSliderPortlet}
                                    step={100}
                                />
                            </Box>

                            <Box
                                sx={{
                                    height: '56px',
                                    border: '1px solid #d1d1d1',
                                    borderRadius: '50px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '10px 20px',
                                    gap: '15px',
                                    mb: '20px',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Input
                                    disableUnderline
                                    value={lowerValue}
                                    margin="dense"
                                    onChange={handleLowerInputChange}
                                    inputProps={{
                                        step: 100,
                                        min: 500,
                                        max: higherValue - 1,
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',
                                    }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            {t('LAC.step_one.label_price_from')}{' '}
                                        </InputAdornment>
                                    }
                                    endAdornment={<InputAdornment position="start"> zł</InputAdornment>}
                                />
                                <Divider orientation="vertical" />
                                <Input
                                    disableUnderline
                                    value={higherValue}
                                    margin="dense"
                                    onChange={handleHigherInputChange}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            {t('LAC.step_one.label_price_to')}{' '}
                                        </InputAdornment>
                                    }
                                    endAdornment={<InputAdornment position="start"> zł</InputAdornment>}
                                    inputProps={{
                                        step: 100,
                                        min: lowerValue,
                                        max: 10000,
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',
                                    }}
                                />
                            </Box>
                            <Box>
                                <Button
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: '16px',
                                        letterSpacing: '0',
                                    }}
                                    onClick={handleClickOpen}
                                >
                                    <TuneIcon sx={{ marginRight: '10px' }} />
                                    {t('LAC.step_one.show_more')}
                                </Button>
                            </Box>

                            {isSubmitting && <Spinner sx={{ mt: 2 }} />}

                            {error && (
                                <Box my={1} className="error text-center">
                                    {error}
                                </Box>
                            )}

                            <button
                                onClick={handleShowOffers}
                                // disabled={isSubmitting}
                                className="btn-link absolute bottom-[-35px] left-1/2 translate-x-[-50%] b-color1"
                            >
                                <SearchOutlinedIcon style={{ marginRight: '8px' }} />
                                {t('LAC.step_one.show_offers_button')}{' '}
                                <span className="font-bold">{filteredOffers.length?.toLocaleString()}</span>{' '}
                                {wordVariation(t('LAC.step_one.label_offer'))}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
            <DialogFilters open={open} handleClose={handleClose} />
        </Container>
    )
}

export default FormStep1CMS
