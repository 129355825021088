import React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Box, Stack } from '@mui/material'
import { useFormikContext } from 'formik'
import dayjs from 'dayjs'

import Datepicker from '../../../components/Datepicker'
import MuiTimepicker from '../../../components/MuiTimePicker'

import 'react-datepicker/dist/react-datepicker.css'
import '../../../styles/form.css'

const PickupDateTime = ({
    processData,
    pickupDate,
    setPickupDate,
    pickupTime,
    setPickupTime,
    pickupDateTime,
    minPickupDate,
    maxPickupDate,
}) => {
    const { locale } = useParams()
    const { setFieldValue, errors, touched } = useFormikContext()

    useEffect(() => {
        // przekaz do formik
        setFieldValue('pickupDateTime', pickupDateTime)
    }, [pickupDateTime])

    return (
        processData && (
            <>
                <Box
                    sx={{
                        height: '56px',
                        border: '1px solid #d1d1d1',
                        borderRadius: '50px',
                        padding: '10px 20px',
                        mb: '20px',
                    }}
                >
                    <Stack direction="row">
                        <Box width="50%">
                            <Datepicker
                                name="pickupDate"
                                selected={pickupDate}
                                onChange={date => setPickupDate(date)}
                                selectsStart
                                pickupDate={pickupDate}
                                minDate={minPickupDate}
                                maxDate={maxPickupDate}
                                locale={locale}
                            />
                        </Box>
                        <Box width="50%" borderLeft="1px solid #d1d1d1" pl={2}>
                            <MuiTimepicker
                                name="pickupTime"
                                defaultValue={pickupTime}
                                value={pickupTime}
                                onChange={time => setPickupTime(time)}
                                required
                            />
                        </Box>
                    </Stack>
                </Box>

                {touched.pickupDateTime && errors.pickupDateTime && (
                    <Alert severity="error">{errors.pickupDateTime}</Alert>
                )}
            </>
        )
    )
}

export default PickupDateTime
