import React from 'react'
import { Link } from 'react-router-dom'
import Svg from '../Svg.js'
import { environment } from '../../environment.js'


function NavItem(param) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Build Mob Nav Item')
        console.log(param)
    }

    const [isOpen, setOpen] = React.useState(false)
    const tab1 = {
        'pl' : environment.mainPage,
        'en' : environment.enMainPage
    }
    const tab2 = {
        'pl' : environment.secondMainPage,
        'en' : environment.secondEnMainPage
    }
    function CloseMenu() {
        const sidemenu = document.getElementById('sidemenu')
        if (sidemenu.classList.contains('open')) {
            sidemenu.classList.remove('open')
        }
    }
    
    function RedirectLink(item) {
        if (item.line === 'online') {
            return (
                <Link to={item.link} className="menu-text"
                    onClick={() => CloseMenu()}
                >
                    <p>{item.text}</p>
                </Link>
            )
        } else {
            return (
                <a href={item.link} className="menu-text"
                    onClick={() => CloseMenu()}
                >
                    <p>{item.text}</p>
                </a>
            )
        }
    }
    
    function tabName(link) {
        let tabName = ' gh'
        if (tab1[param.locale] === '/'+link) {
            tabName = ' tab-1'
        }
        if (tab2[param.locale] === '/'+link) {
            tabName = ' tab-2'
        }
        return tabName
    }
    function Dropdown(param) {
        return (
            param.items.items.children.map((subitem, ii) => {
                if (subitem.hasOwnProperty('navShow') && subitem.navShow !== 'false') {
                        return (
                            <SubItem items={subitem} i={`` + ii} sub={`sub-`} line={param.line} type="1" key={ii} />
                        )
                }
                return null
            })
        )
    }
    function SubItem(param) {
        let type = param;
        if (type.hasOwnProperty('param')) {
            type = type.param
        }
        let drop = (type.hasOwnProperty('items') && type.items.hasOwnProperty('children') && type.items.children && type.items.children.length > 0) ? ' dropdown' : '';
        
        return (
            <div
                id={type.sub+`menu-item-` + type.i}
                key={type.sub+`menu-item-` + type.i}
                className={type.sub+`menu-item overflow-hidden` + drop + tabName(type.items.path)}
            >
                <span className="menu-icon menu-icon-empty"></span>
                <span className="menu-text">
                    <RedirectLink link={'/'+type.items.path} text={type.items.text} line={type.line} />
                </span>
                {/* {type.items.children && type.items.children.length > 0 &&
                    <>
                        <div className={'childs-icon ' +(isOpen ? ' open' : '')}
                            onClick={() => setOpen(!isOpen)}
                            aria-expanded={isOpen}
                        >
                            <Svg name="MenuArrowLeft" />
                        </div>
                        <div 
                            aria-hidden={!isOpen}
                            role="region"
                            tabIndex="-1"
                            className={`sub-menu-item grid text-slate-600 overflow-hidden transition-all duration-300 ease-in-out ${
                                isOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
                            }`}
                        >
                            <Dropdown items={type} line={type.line}></Dropdown>
                        </div>
                    </>
                } */}
            </div>
        )
    }
    return (
        <SubItem param={param} type="2"/>
    )
}

export default NavItem
