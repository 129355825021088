import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import TargeoSearch from '../../components/TargeoSuggester/TargeoSearch'
import GetReleasePlaces from './GetReleasePlaces'
import LocationDetails from './LocationDetails'
import BackButton from '../../components/BackButton'
import { DialogContext } from './SearchField'
import { Typography } from '@mui/material'
import IconSearchResult from '../../components/IconSearchResult'

const CustomTabPanel = props => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ py: 3, px: 1 }}>{children}</Box>}
        </div>
    )
}

const TabsSearchLocation = () => {
    const [value, setValue] = useState(0)

    const [showMoreInfo, setShowMoreInfo] = useState(false)

    const [clickedPlace, setClickedPlace] = useState({})

    const { handleCloseDialog, setAutoHeight } = useContext(DialogContext)

    const { t } = useTranslation()

    useEffect(() => {
        setAutoHeight(false)
    }, [value])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleClickBackButton = () => {
        showMoreInfo ? setShowMoreInfo(false) : handleCloseDialog()
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    return (
        <Box pb={2} sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center' }}>
                <BackButton handleClick={handleClickBackButton} sx={{ marginRight: '20px' }} />
                {!showMoreInfo ? (
                    <>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label={t('RAC.step_one.search_tab.0')} {...a11yProps(0)} />
                            <Tab label={t('RAC.step_one.search_tab.1')} {...a11yProps(1)} />
                        </Tabs>
                    </>
                ) : (
                    <>
                        <IconSearchResult
                            icon={
                                clickedPlace.name.includes('PKS')
                                    ? 'Bus'
                                    : clickedPlace.name.includes('Prom')
                                      ? 'Ferry'
                                      : clickedPlace.type
                            }
                            my={1}
                        />
                        <Box>
                            <Typography>{clickedPlace.name.split(',')[0]}</Typography>
                            <Typography>
                                {clickedPlace.name
                                    .split(',')[1]
                                    .replace(' - 24h', '')
                                    .replace(' - 24h', '')
                                    .replace(' 24h', '')
                                    .replace(' - 24 h', '')}
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
            <Box py={2} px={{ xs: 2, lg: 4 }}>
                <CustomTabPanel value={value} index={0}>
                    {!showMoreInfo ? (
                        <GetReleasePlaces setShowMoreInfo={setShowMoreInfo} setClickedPlace={setClickedPlace} />
                    ) : (
                        <LocationDetails place={clickedPlace} />
                    )}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <TargeoSearch />
                </CustomTabPanel>
            </Box>
        </Box>
    )
}

export default TabsSearchLocation
