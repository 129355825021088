import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Box } from '@mui/material'
import useFormattedPrice from '../../hooks/useFormattedPrice'

import theme from '../../../styles/MUI-theme'

const Price = ({ priceNetto, priceBrutto, unitPrice = false }) => {
    const { t } = useTranslation()

    const formattedPriceNetto = useFormattedPrice(parseFloat(priceNetto).toFixed(2)).replace('.', ',')
    const formattedPriceBrutto = useFormattedPrice(parseFloat(priceBrutto).toFixed(2)).replace('.', ',')

    return (
        <Box mb={2}>
            <Typography>
                <span
                    style={{
                        fontWeight: 'bold',
                        fontSize: 24,
                        color: theme.palette.secondary.main,
                    }}
                >
                    {priceNetto ? formattedPriceNetto : ""}
                </span>{' '}
                {!unitPrice && <span style={{ fontSize: 14 }}>{t('nett_month')}</span>}
            </Typography>
            <Typography>
                <span
                    style={{
                        fontWeight: 'bold',
                        fontSize: 16,
                    }}
                >
                    {priceBrutto ? formattedPriceBrutto : ""}
                </span>{' '}
                {!unitPrice && <span style={{ fontSize: 14 }}>{t('gross_month')}</span>}
            </Typography>
        </Box>
    )
}

export default Price
