import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import EquipmentList from './EquipmentList'
import Prices from './Prices'
import theme from '../../../styles/MUI-theme'

const CarClassItem = ({ carClass, example, offer, fuelTypes, transmissionTypes }) => {
    const { t } = useTranslation()

    return (
        <>
            {offer.map(
                calculation =>
                    calculation.classId === carClass.id.toString() && (
                        <Box
                            key={carClass.id}
                            pt={{ xs: 2, lg: 4 }}
                            sx={{ border: '1px solid #ccc', borderRadius: '10px', bgcolor: 'white' }}
                            mb={2}
                        >
                            <Box
                                px={{ xs: 2, lg: 4 }}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: 2,
                                }}
                            >
                                <Box textAlign={'left'}>
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 14, lg: 24 },
                                            color: theme.palette.primary.main,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {example?.model.split(' ').slice(0, 2).join(' ') || example?.model}
                                    </Typography>{' '}
                                    <Typography sx={{ fontSize: { xs: 10, lg: 14 } }}>
                                        {t('RAC.step_two.others')}
                                    </Typography>
                                </Box>
                                <Typography variant="h2" fontSize={20} textAlign={'right'}>
                                    {t('RAC.step_two.class')} {carClass.name}
                                </Typography>
                            </Box>

                            <Box
                                px={{ xs: 2, lg: 4 }}
                                pb={{ xs: 2, lg: 4 }}
                                pt={0}
                                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}
                            >
                                <Box width={'50%'}>
                                    <EquipmentList
                                        fuel={carClass.fuelType}
                                        transmission={carClass.transmissionType}
                                        seats={carClass.seats}
                                        largeBags={carClass.largeBags}
                                        smallBags={carClass.smallBags}
                                        fuelTypes={fuelTypes}
                                        transmissionTypes={transmissionTypes}
                                    />
                                </Box>

                                <Box width={'50%'} height={'auto'}>
                                    {example.imagePaths[0] && (
                                        <Box
                                            component="img"
                                            alt={example.model}
                                            src={example.imagePaths[0]}
                                            width={'100%'}
                                            maxWidth={'350px'}
                                            height={'auto'}
                                            sx={{ borderRadius: '10px' }}
                                        />
                                    )}
                                </Box>
                            </Box>

                            {offer.map(
                                calculation =>
                                    calculation.classId === carClass.id.toString() && (
                                        <Prices
                                            key={calculation.classId}
                                            item={calculation}
                                            carClass={carClass}
                                            model={example.model}
                                        />
                                    )
                            )}
                        </Box>
                    )
            )}
        </>
    )
}

export default CarClassItem
