import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import useFetch from '../../hooks/useFetch'
import Spinner from '../../components/Spinner'
import ErrorBox from '../ErrorBox'
import { API, FORM_KEY } from '../../api'
import theme from '../../../styles/MUI-theme'
import IconSearchResult from '../../components/IconSearchResult'
import useFormattedPrice from '../../hooks/useFormattedPrice'

const ReservationDetailsPackage = ({ packagePrice, packageDepositPrice, packageIsSilver, activeStep }) => {
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()
    const { loading, error, fetchData } = useFetch()

    const formattedPackagePrice = useFormattedPrice(parseFloat(packagePrice).toFixed(2)).replace('.', ',') || '0 zł'

    const formattedPackageDepositPrice =
        useFormattedPrice(parseFloat(packageDepositPrice).toFixed(2)).replace('.', ',') || ''

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    const handleClickEditStep3 = () => {
        fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=${FORM_KEY['step3']}`, 'GET')
    }

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return (
        <Box
            width="100%"
            p={{ xs: 1, lg: 2 }}
            display="flex"
            justifyContent="space-between"
            borderBottom={`0.5px solid ${theme.palette.secondary.main}`}
            bgcolor="white"
        >
            <>
                <Box display={'flex'}>
                    <IconSearchResult icon="Package" />
                    <Box>
                        <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'bold'}>
                            {packageIsSilver
                                ? t('RAC.step_three.car_insurance.1')
                                : t('RAC.step_three.car_insurance.0')}
                        </Typography>
                        <Typography fontSize={{ xs: 10, lg: 14 }} fontWeight={'regular'}>
                            {t('deposit')}: {formattedPackageDepositPrice}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                    <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'regular'} textAlign={'right'}>
                        {formattedPackagePrice}
                    </Typography>
                    {activeStep !== 3 && (
                        <Box
                            mt={1}
                            role="button"
                            type="submit"
                            color={theme.palette.primary.main}
                            onClick={handleClickEditStep3}
                            sx={{ color: theme.palette.primary.main, fontSize: { xs: 12, lg: 16 } }}
                        >
                            {t('btn.edit')}
                        </Box>
                    )}
                </Box>
            </>
        </Box>
    )
}

export default ReservationDetailsPackage
