import { Box, Checkbox, InputAdornment, TextField, FormControlLabel, CircularProgress, Tooltip, Zoom } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import { useTranslation } from 'react-i18next'
import CountrySelect from '../components/CountrySelect'
import PopupEmailExist from '../../RAC/Step4/PopupEmailExist'
import { API } from '../api'
import { API as API2 } from '../../api'
import { useParams } from 'react-router-dom'
import LinkText from '../../components/LinkText'
import { environment } from '../../../environment'

export default function FormInd({ userData, handleUserDataChange, touched, errors, userDataInSystem, isLogged, showPopup }) {
    // const [isActive, setIsActive] = useState(false)
    // const [isOtherUser, setIsOtherUser] = useState(false)
    const { t, i18n } = useTranslation()
    const [loadingCheckIfAccountExist, setLoadingCheckIfAccountExist] = useState(false)
    const [accountExists, setAccountExists] = useState(false)
    const [showDialogAccountExist, setShowDialogAccountExist] = useState(false)
    const { locale } = useParams()
    const { handleBlur } = useFormikContext()

    const handleCheckIfAccountExists = async email => {
        try {
            setLoadingCheckIfAccountExist(true)
            const response = await fetch(`${API2['ACCOUNT_EXISTS']}?email=${email}`)
            if (response.ok) {
                const res = await response.json()
                setAccountExists(res.accountExists)
                setLoadingCheckIfAccountExist(false)
            } else {
                console.error('Error fetching data')
            }
        } catch (error) {
            console.error('Error fetching data', error)
        }
    }

    useEffect(() => {
        accountExists && setShowDialogAccountExist(true)
    }, [accountExists])

    return (
        <div className="mt-8 ">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
                <Field
                    disabled={isLogged && userData.userFirstName}
                    as={TextField}
                    value={userData.userFirstName}
                    name="userFirstName"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.userFirstName && Boolean(errors.userFirstName)}
                    helperText={touched.userFirstName && errors.userFirstName}
                    fullWidth
                    placeholder={t('LAC.client_data.name')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonOutlineOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Field
                    disabled={isLogged && userData.userLastName}
                    as={TextField}
                    value={userData.userLastName}
                    name="userLastName"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.userLastName && Boolean(errors.userLastName)}
                    helperText={touched.userLastName && errors.userLastName}
                    fullWidth
                    placeholder={t('LAC.client_data.surname')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonOutlineOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <CountrySelect userData={userData} handleUserDataChange={handleUserDataChange} showPopup={showPopup} formType='ind' />
                {userData?.userCountry == 'PL' ? (
                    <Field
                        disabled={isLogged && userData.userPesel}
                        as={TextField}
                        value={userData.userPesel}
                        name="userPesel"
                        onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                        error={touched.userPesel && Boolean(errors.userPesel)}
                        helperText={touched.userPesel && errors.userPesel}
                        fullWidth
                        placeholder={t('LAC.client_data.PESEL')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <RemoveCircleOutlineOutlinedIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                ) : null}
                <Field
                    disabled={isLogged && userData.userEmail}
                    as={TextField}
                    value={userData.userEmail}
                    name="userEmail"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.userEmail && Boolean(errors.userEmail)}
                    helperText={touched.userEmail && errors.userEmail}
                    fullWidth
                    placeholder={t('LAC.client_data.email')}
                    onBlur={e => {
                        handleBlur(e)
                        handleCheckIfAccountExists(e.target.value)
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlineIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {loadingCheckIfAccountExist && <CircularProgress size={20} />}
                            </InputAdornment>
                        ),
                    }}
                />
                <Tooltip TransitionComponent={Zoom} title={t('LAC.client_data.tooltip_text_2')}>
                    <div>
                        <Field
                            disabled={isLogged && userData.userPhoneNumber}
                            as={TextField}
                            value={userData.userPhoneNumber}
                            name="userPhoneNumber"
                            onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                            error={touched.userPhoneNumber && Boolean(errors.userPhoneNumber)}
                            helperText={touched.userPhoneNumber && errors.userPhoneNumber}
                            fullWidth
                            placeholder={t('LAC.client_data.phone')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PhoneInTalkOutlinedIcon />
                                        <span className="ml-2">
                                            {locale === 'pl' && userData.userCountry === 'PL' && !isLogged && '+48'}
                                        </span>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </Tooltip>
                {/* <Field
                    
                    as={TextField}
                    value={userData.userAddress}
                    name="userAddress"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    // error={touched.userLastName && Boolean(errors.userLastName)}
                    fullWidth
                    placeholder="Adres"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FmdGoodOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                /> */}
                <Field
                    as={TextField}
                    value={userData.userStreet}
                    name="userStreet"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.userStreet && Boolean(errors.userStreet)}
                    helperText={touched.userStreet && errors.userStreet}
                    fullWidth
                    placeholder={t('LAC.client_data.street')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlineIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Field
                    as={TextField}
                    value={userData.userHouse}
                    name="userHouse"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.userHouse && Boolean(errors.userHouse)}
                    helperText={touched.userHouse && errors.userHouse}
                    fullWidth
                    placeholder={t('LAC.client_data.house')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlineIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Field
                    as={TextField}
                    value={userData.userLocal}
                    name="userLocal"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    fullWidth
                    placeholder={t('LAC.client_data.local')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlineIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Field
                    as={TextField}
                    value={userData.userCity}
                    name="userCity"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.userCity && Boolean(errors.userCity)}
                    helperText={touched.userCity && errors.userCity}
                    fullWidth
                    placeholder={t('LAC.client_data.city')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlineIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Field
                    as={TextField}
                    value={userData.userPostCode}
                    name="userPostCode"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.userPostCode && Boolean(errors.userPostCode)}
                    helperText={touched.userPostCode && errors.userPostCode}
                    fullWidth
                    placeholder={t('LAC.client_data.postcode')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlineIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            {!isLogged ? (
                <div>
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={e =>
                                        handleUserDataChange('userCheckedWantToCreateAccount', e.target.checked)
                                    }
                                    color="primary"
                                />
                            }
                            label={
                                <div className="text-[11px] leading-3 flex flex-col">
                                    <span>{t('LAC.client_data.want_acc')}</span>
                                    <span>{t('LAC.client_data.sign_up_text')}</span>
                                </div>
                            }
                        />
                    </Box>
                </div>
            ) : null}
            <div>
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={e => {
                                    handleUserDataChange('profiling', e.target.checked)
                                    handleUserDataChange('withoutProfiling', e.target.checked)
                                    handleUserDataChange('telemarketing', e.target.checked)
                                }}
                                color="primary"
                            />
                        }
                        label={
                            <div className="text-[11px] leading-3 flex flex-col">
                                <span>{t('LAC.client_data.profiling_text')}</span>
                            </div>
                        }
                    />
                </Box>
            </div>
            {userData.profiling ? (
                <>
                    <div>
                        <Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={e => handleUserDataChange('withoutProfiling', e.target.checked)}
                                        color="primary"
                                        checked={userData.withoutProfiling}
                                    />
                                }
                                label={
                                    <div className="text-[11px] leading-3 flex flex-col">
                                        <span>{t('LAC.client_data.without_profiling_text')}</span>
                                    </div>
                                }
                            />
                        </Box>
                    </div>
                    <div>
                        <Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={e => handleUserDataChange('telemarketing', e.target.checked)}
                                        color="primary"
                                        checked={userData.telemarketing}
                                    />
                                }
                                label={
                                    <div className="text-[11px] leading-3 flex flex-col">
                                        <span>{t('LAC.client_data.telemarketing_text')}</span>
                                    </div>
                                }
                            />
                        </Box>
                    </div>
                </>
            ) : null}

            <div>
                <div className="flex flex-col">
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={e => handleUserDataChange('userAllowedKRD', e.target.checked)}
                                color="primary"
                                required
                            />
                        }
                        label={
                            <span className="text-[11px] leading-3">
                                {t('LAC.client_data.krd_consent')} <LinkText href="mailto:handlowy@express.pl">handlowy@express.pl</LinkText>
                            </span>}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={e => handleUserDataChange('userAllowedTerms', e.target.checked)}
                                color="primary"
                                required
                            />
                        }
                        label={
                            <span className="text-[11px] leading-3">
                                {t('LAC.client_data.consent_1')} <LinkText href={locale === 'pl' ? API['GET_FILE_REGULATIONS_USER_TERMS_AND_CONDITIONS'] : API['GET_FILE_REGULATIONS_USER_TERMS_AND_CONDITIONS_EN']}>{t('LAC.client_data.consent_2')}</LinkText>{' '}
                                {t('LAC.client_data.consent_3')} <LinkText href={environment.privacyPolicy[locale]}>{t('LAC.client_data.consent_4')}</LinkText>
                            </span>
                        }
                    />
                </div>
            </div>
            {showDialogAccountExist === true && (
                <PopupEmailExist
                    showAlert={showDialogAccountExist}
                    setShowAlert={setShowDialogAccountExist}
                    dialogTitle="Masz już konto w Express.pl?"
                />
            )}
        </div>
    )
}
