import Axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { environment } from '../../environment.js'
import NavItem from '../../components/Nav/NavItem.js'
import NavLangOff from '../../components/Nav/NavLangOff.js'
import Loaders from '../../Loaders.js'
import Svg from '../Svg.js'
import { useTranslation } from 'react-i18next'
import LogBox from '../../components/Nav/LogBox.js'

function NavMob(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Build Mob Nav')
        console.log(params)
    }
    const lang = window.location.pathname.split('/')[1].length > 2 || window.location.pathname.split('/')[1].length === 0 ? 'pl' : window.location.pathname.split('/')[1]
    const { t, i18n } = useTranslation()
    useEffect(() => {
        i18n.changeLanguage(lang)
    }, [lang])
    let [navData, setNavData] = useState([])
    useParams()
    let [jsonStatus, setJsonStatus] = useState(null)
    let [sideNav, setSideNav] = useState([])
    const [tabOpen, setTabOpen] = React.useState(true)

    useEffect(() => {
        let fetchNavs = async () => {
            let url = null
            if (window.location.origin.includes(environment.cmsName)) {
                url = window.location.origin + environment.navJson
            } else if (window.location.origin.includes(environment.localhost)) {
                url = environment.siteUrlDev + environment.navJson
            } else {
                url = window.location.origin + environment.navJson
            }
            let response = ''
            try {
                response = await Axios(url)
                setJsonStatus(true)
            } catch (err) {
                setJsonStatus(false)
                response = 'error'
            }
            if (response.hasOwnProperty('data')) {
                if (response.data.length > 0) {
                    if (lang === 'pl') {
                        let navs = response.data;
                        response.data.map((nav, i) => {
                            const path = nav.hasOwnProperty('path') ? nav.path : '';
                            if (path === 'en/' || path === 'ua/') delete navs[i];
                            
                        })
                        setNavData(navs)
                    }
                    else {
                        response.data.map((nav, i) => {
                            const path = nav.hasOwnProperty('path') ? nav.path : '/';
                            if (path === lang + '/') setNavData(response.data[i].children)
                            return null
                        })
                    }
                } else {
                    setJsonStatus(false)
                }
            } else {
                setJsonStatus(false)
            }
        }
        fetchNavs()
    }, [])

    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('navData')
        console.log(navData)
    }

    const setNavPath = path => {
        let sideArray = []
        let index = 0
        if (navData) {
            navData.map((item, i) => {
                if (item.path === path && item.hasOwnProperty('navShow') && item.navShow !== 'false') {
                    if (navData[i].hasOwnProperty('children')) {
                        navData[i].children.map((subitem, ii) => {
                            if (subitem.hasOwnProperty('navShow') && subitem.navShow !== 'false') {
                                sideArray.push(subitem)
                                index = ii
                            }
                            return null
                        })
                        sideArray[0].index = index
                    }
                }
                return null
            })
        }
        setSideNav(sideArray)
    }

    function CloseMenu() {
        const sidemenu = document.getElementById('sidemenu')
        if (sidemenu.classList.contains('open')) {
            sidemenu.classList.remove('open')
        }
    }
    function RenderNavs() {
        return (
            <nav>
             {/* <nav className={'menu-transform-items ' +(tabOpen ? 'first' : 'second')} id="sidenav"> */}
                {/* <div className='nav-tabs d-flex ai-center jc-between'>
                    <a href="#" title={t('header.nav.first_page')}
                        className={'btn-link secondary mr-2 ' +(tabOpen ? ' active' : '')}
                        onClick={e => {
                            e.preventDefault()
                            setTabOpen(!tabOpen)
                        }}
                    >
                        {t('header.nav.first_page')}
                    </a>
                    <a href="#" title={t('header.nav.second_page')}
                        className={'btn-link secondary ml-2 ' +(!tabOpen ? ' active' : '')}
                         onClick={e => {
                            e.preventDefault()
                            setTabOpen(!tabOpen)
                        }}
                    >
                        {t('header.nav.second_page')}
                    </a>
                </div> */}
                <ul className="menu-items">
                    <RenderMainNav />
                </ul> 
            </nav>
        )
    }
 
    function RenderMainNav() {
        return (
            navData &&
            navData.map((item, i) => {
                 if (item.hasOwnProperty('navShow') && item.navShow !== 'false') {
                    return (
                        <NavItem items={item} i={`` + i} sub={``} line={params.line} key={i} locale={lang}/>
                    )
                }
                return null
            })
        )
    }

    function RedirectLink(item) {
        if (item.line === 'online') {
            return (
                <Link to={item.link} className="menu-text">
                    <p>{item.text}</p>
                </Link>
            )
        } else {
            return (
                <a href={item.link} className="menu-text">
                    <p>{item.text}</p>
                </a>
            )
        }
    }

    switch (jsonStatus) {
        case null:
            return (
                <ul className="menu">
                    <li className="Loading-nav b-red ml-10">
                        <Loaders type="dots" color="b-white" />
                    </li>
                </ul>
            )
        case false:
            return (
                <nav className="menu-transform-items b-white" id="sidenav">
                    <ul className="menu-items">
                        <li className="menu-item current">
                            <Svg name="BackArrow" class="menu-icon" />
                            <span className="menu-text">
                                <p>Express</p>
                            </span>
                            <div className="childs-icon"></div>
                        </li>
                    </ul>
                </nav>
            )
        case true:
            return <RenderNavs />
        default:
            break
    }
}
export default NavMob
