import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconTruck() {
    return (
        <SvgIcon>
            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3 2V5.5C3 6.34375 2.3125 7 1.5 7C0.65625 7 0 6.34375 0 5.5V5C0 4.75 0.21875 4.5 0.5 4.5C0.75 4.5 1 4.75 1 5V5.5C1 5.78125 1.21875 6 1.5 6C1.75 6 2 5.78125 2 5.5V1C2 0.65625 2.1875 0.3125 2.5 0.15625C2.625 0.0625 2.8125 0 3 0H3.09375H4.53125C4.8125 0 5.09375 0.125 5.28125 0.34375L12 7.875V4.5C12 3.6875 12.6562 3 13.5 3H15.25C15.875 3 16.4375 3.3125 16.8438 3.78125L19.6562 7.4375C19.875 7.6875 19.9688 8.03125 19.9688 8.34375V11.5C19.9688 12.3438 19.3125 13 18.4688 13H18C18 14.6562 16.6562 16 15 16C13.3438 16 12 14.6562 12 13H8C8 14.6562 6.65625 16 5 16C3.34375 16 2 14.6562 2 13H1.5C0.65625 13 0 12.3438 0 11.5V9.5C0 8.6875 0.65625 8 1.5 8H6L3 2ZM7.8125 12H12.1562C12.5625 10.8438 13.6875 10 15 10C16.2812 10 17.4062 10.8438 17.8125 12H18.5C18.75 12 19 11.7812 19 11.5V9H13H12H11.6562H6.5H1.5C1.21875 9 1 9.25 1 9.5V11.5C1 11.7812 1.21875 12 1.5 12H2.15625C2.5625 10.8438 3.6875 10 5 10C6.28125 10 7.40625 10.8438 7.8125 12ZM13 13C13 13.7188 13.375 14.375 14 14.75C14.5938 15.0938 15.375 15.0938 16 14.75C16.5938 14.375 17 13.7188 17 13C17 12.3125 16.5938 11.6562 16 11.2812C15.375 10.9375 14.5938 10.9375 14 11.2812C13.375 11.6562 13 12.3125 13 13ZM16.0312 4.40625C15.8438 4.15625 15.5625 4 15.25 4H13.5C13.2188 4 13 4.25 13 4.5V8H18.8438L16.0312 4.40625ZM4.53125 1H3.59375L7.09375 8H10.75L4.53125 1ZM7 13C7 12.3125 6.59375 11.6562 6 11.2812C5.375 10.9375 4.59375 10.9375 4 11.2812C3.375 11.6562 3 12.3125 3 13C3 13.7188 3.375 14.375 4 14.75C4.59375 15.0938 5.375 15.0938 6 14.75C6.59375 14.375 7 13.7188 7 13Z"
                    fill="#202020"
                />
            </svg>
        </SvgIcon>
    )
}
