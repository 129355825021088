import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, useFormikContext, useField } from 'formik'
import { Grid, FormControlLabel, RadioGroup } from '@mui/material'
import RadioPickupLocation from './RadioPickupLocation'

const FieldPickupLocation = ({ locations, setPickupLocationState, setErrorPickupLocation, setErrorPickupLocationWithoutStreet }) => {
    const { t } = useTranslation()
    const { values, setFieldValue } = useFormikContext()
    const [field] = useField('pickupLocationExpress')

    useEffect(() => {
        values?.pickupLocation === 'pickupInAnyLocation' && !values?.pickupTargeoAddress
            ? setErrorPickupLocation(true)
            : setErrorPickupLocation(false)

            values?.pickupLocation === 'pickupInAnyLocation' && values.pickupTargeoPlace.number === ""
            ? setErrorPickupLocationWithoutStreet(true)
            : setErrorPickupLocationWithoutStreet(false)
    }, [values])

    const handleClick = value => {
        setFieldValue('pickupLocation', value)
        setPickupLocationState(value)
    }

    return (
        <Field
            type="button"
            name="pickupLocation"
            as={FormControlLabel}
            sx={{
                margin: 0,
                display: 'flex',
            }}
            control={
                <RadioGroup row name="pickupLocation" value={values.pickupLocation} sx={{ width: '100%' }}>
                    <Grid container spacing={2} width="100%">
                        <Grid item xs={12} md={6}>
                            <RadioPickupLocation
                                locations={locations}
                                label={t('LAC.offer_presentation.free_of_charge')}
                                text={
                                    field?.value.openingHours?.[0]?.timeFrom
                                        ? `${t('LAC.offer_presentation.in_hours')} ${field?.value.openingHours?.[0]?.timeFrom?.slice(0, 5)} – ${field?.value.openingHours?.[0].timeTo.slice(0, 5)}`
                                        : ``
                                }
                                value="pickupInExpress"
                                values={values}
                                handleClick={value => handleClick(value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RadioPickupLocation
                                locations={locations}
                                label={t('LAC.offer_presentation.any_location')}
                                text={t('LAC.offer_presentation.additional_costs')}
                                value="pickupInAnyLocation"
                                values={values}
                                handleClick={value => handleClick(value)}
                            />
                        </Grid>
                    </Grid>
                </RadioGroup>
            }
        />
    )
}

export default FieldPickupLocation
