import React from 'react'
import LinkButton from './LinkButton'
import setLoginLink from '../RAC/setLoginLink'
import { useTranslation } from 'react-i18next'

const LoginButton = ({ ...props }) => {
    const { t } = useTranslation()
    return (
        <LinkButton
            onClick={e => {
                e.preventDefault()
                window.location.href = setLoginLink()
            }}
            align={props.align || 'center'}
            {...props}
        >
            {t('btn.login')}
        </LinkButton>
    )
}

export default LoginButton
