import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, IconButton } from '@mui/material'
import theme from '../../../styles/MUI-theme'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'

const FilterCarsByEquipment = ({ handleShowDialogWindow }) => {
    const { t } = useTranslation()
    return (
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="h1" fontSize={{ xs: 16, lg: 20 }} fontWeight={'bold'}>
                {t('RAC.step_two.heading')}
            </Typography>
            <Box
                role="button"
                color={theme.palette.primary.main}
                onClick={handleShowDialogWindow}
                sx={{ color: theme.palette.primary.main, fontSize: { xs: 12, lg: 16 } }}
            >
                {t('RAC.step_two.show_all_options')}
                <IconButton>
                    <FilterAltOutlinedIcon sx={{ color: theme.palette.primary.main }} />
                </IconButton>
            </Box>
        </Box>
    )
}

export default FilterCarsByEquipment
