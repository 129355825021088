import React, { useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Alert, Box, Button, Container } from '@mui/material'

import ErrorBox from './ErrorBox'
import ReservationDetials from './ReservationDetails'

import { API, FORM_KEY } from '../api'
import { environment } from '../../environment'
import Spinner from '../components/Spinner'
import Stepper from '../components/Stepper'
import useFetch from '../hooks/useFetch'

import '../../styles/form.css'
import OfferPresentation from './Step2/OfferPresentation'
import BackButtonInForm from '../components/BackButtonInForm'

const FormStep2 = () => {
    const { t, i18n } = useTranslation()
    const { data, loading, error, fetchData } = useFetch()
    const { id, locale } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        const url = `${API[`GET_DATA`]}?pid=${id}`
        fetchData(url, 'GET')
    }, [id])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('PROCESS DATA:', data)
    }, [data])

    const handleClickEditStep1 = () => {
        fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=${FORM_KEY['step1']}`, 'GET')
    }

    return (
        <Container>
            <Box py={1}>
                <BackButtonInForm handleClick={handleClickEditStep1}>{t('btn.back')}</BackButtonInForm>
                <Stepper activeStep={2} />
                <ReservationDetials activeStep={2} />
                {data ? (
                    <>
                        {data?.variables?.lp_car_class && data?.variables?.lp_car_class !== '' && (
                            <Alert severity="error" sx={{ my: 2 }}>
                                {t('errors.car_class_no_exist')}
                            </Alert>
                        )}
                        <OfferPresentation offer={data.variables.initial_calculation} />
                    </>
                ) : (
                    <>
                        {loading && <Spinner />}
                        {error && <ErrorBox error={error} />}
                    </>
                )}

                {/* <BackButtonInForm handleClick={handleClickEditStep1}>{t('btn.back')}</BackButtonInForm> */}
            </Box>
        </Container>
    )
}

export default FormStep2
