import React, { useEffect, useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const Recaptcha = ({ setRecaptchaToken }) => {
    const { executeRecaptcha } = useGoogleReCaptcha()

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available')
            return
        }

        const token = await executeRecaptcha('yourAction')
        setRecaptchaToken(token)
    }, [executeRecaptcha, setRecaptchaToken])

    useEffect(() => {
        handleReCaptchaVerify()
    }, [handleReCaptchaVerify])

    return null
}

export default Recaptcha
