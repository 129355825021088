import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Typography, Box, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import DialogWindow from '../../components/DialogWindow'
import LoginButton from '../../components/LoginButton'

const PopupEmailExist = ({ showAlert, setShowAlert, dialogTitle, locale }) => {
    const { t, i18n } = useTranslation()

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    return (
        <DialogWindow isOpen={showAlert} setIsOpen={setShowAlert} dialogTitle={dialogTitle}>
            <IconButton
                aria-label="close"
                onClick={() => setShowAlert(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <Typography variant="h3" mb={4}>
                {dialogTitle}
            </Typography>

            <Typography mb={2}>{t('text_to_encourage_to_log_in')}</Typography>

            <LoginButton />
            <Box textAlign="center" mt={2}>
                <Button sx={{ fontWeight: 'bold' }} onClick={() => setShowAlert(false)}>
                    {t('continue_as_guest')} <ChevronRightIcon />
                </Button>
            </Box>
        </DialogWindow>
    )
}

export default PopupEmailExist
