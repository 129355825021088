import React from 'react'
import { Button, Box } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const LinkButton = ({ onClick, children, sx, ...props }) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Box textAlign={props.align || 'center'}>
            <Button
                onClick={onClick}
                disabled={props?.disabled}
                fullWidth={fullScreen}
                variant="contained"
                disableElevation
                sx={{
                    px: { xs: 2, md: 3 },
                    py: { xs: 1, md: 2 },
                    my: 2,
                    color: theme.palette.primary.main,
                    borderRadius: '50px',
                    backgroundColor: '#67cff0 !important',
                    ':hover': {
                        backgroundColor: '#0b4185 !important',
                        color: 'white',
                    },
                    ...sx,
                }}
                {...props}
            >
                {children}
            </Button>
        </Box>
    )
}

export default LinkButton
