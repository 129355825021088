import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconSplotch() {
    return (
        <SvgIcon>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.90625 13.0312C5.9375 13 5.96875 13 6 13C6.875 13 7.71875 13.3125 8.40625 13.8438L9.5625 14.7812C9.75 14.9375 9.96875 15.0312 10.1875 15.0312C10.8125 15.0312 11.3125 14.4688 11.25 13.8438L11.1562 13.1562C11.0312 11.8438 11.5625 10.5625 12.625 9.78125L14.5312 8.34375C14.8125 8.125 14.9688 7.8125 14.9688 7.46875C14.9688 7 14.6875 6.59375 14.2812 6.4375L13.25 6.0625C11.9375 5.5625 10.9375 4.5 10.5 3.1875L10.0312 1.78125C9.875 1.3125 9.4375 1.03125 8.96875 1.03125C8.65625 1.03125 8.34375 1.15625 8.125 1.40625L7.4375 2.25C6.46875 3.40625 4.96875 3.9375 3.5 3.6875L2.15625 3.4375C1.5625 3.3125 1 3.78125 1 4.40625C1 4.625 1.03125 4.8125 1.15625 4.96875L2.40625 6.90625C2.75 7.4375 2.9375 8.03125 2.96875 8.65625C2.96875 9.4375 2.75 10.1562 2.28125 10.7812L1.71875 11.5625C1.5625 11.75 1.5 11.9688 1.5 12.2188C1.5 12.875 2.03125 13.375 2.6875 13.3125L5.90625 13.0312ZM1.59375 7.4375L0.3125 5.5C0.09375 5.1875 0 4.8125 0 4.40625C0 3.15625 1.125 2.21875 2.34375 2.4375L3.6875 2.6875C4.8125 2.875 5.9375 2.46875 6.65625 1.59375L7.34375 0.78125C7.75 0.28125 8.34375 0 8.96875 0C9.875 0 10.6875 0.59375 10.9688 1.4375L11.4375 2.875C11.7812 3.90625 12.5938 4.75 13.625 5.125L14.625 5.5C15.4375 5.8125 16 6.59375 16 7.46875C16 8.125 15.6875 8.75 15.1562 9.15625L13.2188 10.5938C12.4375 11.1562 12.0625 12.0938 12.1562 13.0312L12.2188 13.75C12.375 14.9688 11.4062 16 10.1875 16C9.75 16 9.28125 15.8438 8.9375 15.5625L7.78125 14.625C7.25 14.2188 6.625 14 6 14L2.78125 14.3125C1.53125 14.4062 0.5 13.4375 0.5 12.2188C0.5 11.75 0.625 11.3125 0.90625 10.9375L1.5 10.1562C1.8125 9.75 1.96875 9.21875 1.96875 8.6875C1.96875 8.25 1.8125 7.8125 1.59375 7.4375Z"
                    fill="#202020"
                />
            </svg>
        </SvgIcon>
    )
}
