import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconNinja() {
    return (
        <SvgIcon>
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 4C10 2.9375 9.40625 1.96875 8.5 1.40625C7.5625 0.875 6.40625 0.875 5.5 1.40625C4.5625 1.96875 4 2.9375 4 4C4 5.09375 4.5625 6.0625 5.5 6.625C6.40625 7.15625 7.5625 7.15625 8.5 6.625C9.40625 6.0625 10 5.09375 10 4ZM11 4C11 6.21875 9.1875 8 7 8C5 8 3.34375 6.5625 3.03125 4.65625L1.84375 5.875C1.65625 6.0625 1.3125 6.0625 1.125 5.875C0.9375 5.6875 0.9375 5.34375 1.125 5.15625L2.78125 3.5L1.125 1.875C0.9375 1.6875 0.9375 1.34375 1.125 1.15625C1.3125 0.96875 1.65625 0.96875 1.84375 1.15625L3.25 2.5625C3.84375 1.0625 5.28125 0 7 0C9.1875 0 11 1.8125 11 4ZM6.59375 14.1562L4 10.7812C2.25 11.4062 1.03125 13.0625 1 15H7.21875L6.59375 14.1875C6.59375 14.1875 6.59375 14.1875 6.59375 14.1562ZM7.625 13.875L8.46875 15H13C12.9688 13.0625 11.7188 11.4062 9.96875 10.7812L7.625 13.875ZM13.0625 16H8.25H8.21875H0.90625C0.40625 16 0 15.5938 0 15.0938C0 12.5 1.75 10.3125 4.125 9.6875C4.3125 9.65625 4.53125 9.71875 4.625 9.875L6.59375 12.4688C6.78125 12.75 7.1875 12.75 7.375 12.4688L9.34375 9.875C9.46875 9.71875 9.65625 9.65625 9.84375 9.6875C12.2188 10.3125 14 12.5 14 15.0938C14 15.5938 13.5625 16 13.0625 16ZM5.5 3H8.5C8.75 3 9 3.25 9 3.5C9 3.78125 8.75 4 8.5 4H5.5C5.21875 4 5 3.78125 5 3.5C5 3.25 5.21875 3 5.5 3Z"
                    fill="#202020"
                />
            </svg>
        </SvgIcon>
    )
}
