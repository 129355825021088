import React from 'react'

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import ChooseButton from '../../components/ChooseButton'
import Spinner from '../../components/Spinner'
import BackButtonInForm from '../../components/BackButtonInForm'
import ErrorBox from '../ErrorBox'
import Extras1 from './Extras1'
import Extras2 from './Extras2'
import Extras3 from './Extras3'
import useFetch from '../../hooks/useFetch'
import { API, FORM_KEY } from '../../api'

const ChooseExtras = ({ extras, processData, initialValues }) => {
    const { t } = useTranslation()
    const { id, locale } = useParams()

    const { fetchData } = useFetch()

    const submitValues = async values => {
        const extras_equipment = values.equipment.filter(el => el.count > 0)
        const extras_options = values.options.filter(el => el.count > 0)

        const data = {
            extras_equipment_count: String(extras_equipment.length),
            extras_options_count: String(extras_options.length),

            extras_packages_count: 0,

            offer_selected_package: values.offer_selected_package_id === 1 ? 'brown' : 'silver',

            extra_drivers_count: Number(values.options.filter(el => el.name === 'Dodatkowy kierowca')[0]?.count || 0),
        }

        let counter = 0
        values.equipment.forEach(item => {
            if (item.count > 0) {
                counter++
                data[`extras_equipment_${counter}`] = String(item.id)
                data[`extras_equipment_quantity_${counter}`] = String(item.count)
            }
        })

        counter = 0
        values.options.forEach(item => {
            if (item.count > 0) {
                counter++
                data[`extras_options_${counter}`] = String(item.id)
                data[`extras_options_quantity_${counter}`] = String(item.count)
            }
        })

        fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=next`, 'POST', data, locale)
    }

    const handleClickEditStep2 = () => {
        fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=${FORM_KEY['step2']}`, 'GET')
    }
    return processData ? (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={values => {
                submitValues(values)
            }}
        >
            {({ isSubmitting, values, errors }) =>
                errors.lenght > 0 ? (
                    errors?.map((err, i) => <ErrorBox key={`error-${i}`}>{err}</ErrorBox>)
                ) : isSubmitting ? (
                    <Spinner />
                ) : (
                    <Form noValidate className="h-full">
                        {/* nie dajemy tych ktorych nie mozemy modyfikowac bo min i max = 1 */}
                        <Extras3 processData={processData} />
                        <Extras1
                            processData={processData}
                            options={extras?.options.filter(item => !(item.min === 1 && item.max === 1))}
                            initialOptionsValues={initialValues && initialValues.options}
                        />
                        <Extras2
                            processData={processData}
                            equipment={extras?.equipment.filter(item => !(item.min === 1 && item.max === 1))}
                            initialEquipmentValues={initialValues && initialValues.equipment}
                        />

                        <ChooseButton id="R3" sx={{ my: 5}} type="submit" disabled={isSubmitting}>
                            {t('btn.go_further')}
                        </ChooseButton>

                        {/* <BackButtonInForm handleClick={handleClickEditStep2}>{t('btn.back')}</BackButtonInForm> */}

                        {/* <Box
                    mt={5}
                    sx={{ wordBreak: 'normal', background: 'white' }}
                    position="fixed"
                    top={0}
                    right={0}
                >
                    <pre>{JSON.stringify(values, null, '\t')}</pre>
                </Box> */}
                    </Form>
                )
            }
        </Formik>
    ) : (
        <Spinner />
    )
}

export default ChooseExtras
