import React from 'react'
import Image from '../../components/Image'
import ColorBox from '../../components/ColorBox'

const WhyUsItem = ({ block, headline, description }) => {
    return (
        <div className="max-w-xs md:max-w-none mx-auto">
            <Image
                className="w-full aspect-square object-cover rounded-2xl mb-10"
                src={block['Gallery']['link']}
                alt="Item"
            />
            <ColorBox width="100px" height="5px" className="my-3" />
            <div className="xl:flex justify-between align-top my-9">
                <div className="xl:w-1/3 mr-6">
                    <h4 className="text-2xl font-bold mb-2">{headline}</h4>
                </div>
                <div className="xl:w-2/3 text-lg leading-[1.2] ul-custom" dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>
        </div>
    )
}

export default WhyUsItem
