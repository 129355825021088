import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

const DetailsInsurancePackagesTable = ({ package1, package2, package3, withText }) => {
    const { t } = useTranslation()
    return (
        <Box
            display="inline-grid"
            gridTemplateColumns="repeat(3, 1fr)"
            alignItems="center"
            border="1px solid #ccc"
            width="100%"
            mt={1}
            mb={2}
        >
            <Box borderRight="1px solid #ccc" textAlign="center" py={1}>
                {package1 ? <CheckCircleIcon sx={{ color: 'green' }} /> : <CancelIcon sx={{ color: '#E92020' }} />}
                {withText && (
                    <Typography fontSize={14} mt={1}>
                        1 {t('LAC.offer_presentation.damage')}
                    </Typography>
                )}
            </Box>
            <Box borderRight="1px solid #ccc" textAlign="center" py={1}>
                {package2 ? <CheckCircleIcon sx={{ color: 'green' }} /> : <CancelIcon sx={{ color: '#E92020' }} />}
                {withText && (
                    <Typography fontSize={14} mt={1}>
                        {t('LAC.offer_presentation.damage_no_limit')}
                    </Typography>
                )}
            </Box>
            <Box textAlign="center">
                {package3 ? <CheckCircleIcon sx={{ color: 'green' }} /> : <CancelIcon sx={{ color: '#E92020' }} />}
                {withText && (
                    <Typography fontSize={14} mt={1}>
                        {t('LAC.offer_presentation.damage_no_limit')}
                    </Typography>
                )}
            </Box>
        </Box>
    )
}

export default DetailsInsurancePackagesTable
