import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Alert } from '@mui/material'
import Container from '../../components/Container'

const Notify = ({ formKey = '' }) => {
    const { t, i18n } = useTranslation()
    const { locale } = useParams()

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    return (
        <Container className="my-0">
            {formKey === 'error' && <Alert severity="error">{t('errors.try_again')}</Alert>}

            {(formKey === 'krd_negative_verification' || 'krd_reject') && (
                <Alert severity="error">{t('errors.krd_negative_verification')}</Alert>
            )}

            {formKey === 'reject_out_of_date' && <Alert severity="error">{t('errors.reject_out_of_date')}</Alert>}

            {formKey === 'reservation_confirmation' && (
                <Alert severity="success">{t('reservation_confirmation')}</Alert>
            )}

            {/* TO DO: ZEBRANIE LEADA */}
            {formKey === 'lead_collecting' && <Alert severity="success">{t('reservation_confirmation')}</Alert>}

            {/* TO DO: number_of_vehicles_selection
             */}
            {formKey === 'number_of_vehicles_selection' && (
                <Alert severity="error">{t('errors.car_class_no_exist')}</Alert>
            )}
        </Container>
    )
}
export default Notify
