import React, { createContext, useState, useContext, useEffect } from 'react'

const MultistepContext = createContext({
    currentStep: 1,
    setCurrentStep: () => {},
    registeredSteps: [],
    registerStep: () => {},
})

const MultistepForm = ({ children }) => {
    const [currentStep, setCurrentStep] = useState(1)
    const [registeredSteps, setRegisteredSteps] = useState([])
    const registerStep = step => {
        if (registeredSteps.includes(step)) {
            return
        }
        setRegisteredSteps([...registeredSteps, step])
    }
    const contextValue = {
        currentStep,
        setCurrentStep,
        registeredSteps,
        registerStep,
    }
    return <MultistepContext.Provider value={contextValue}>{children}</MultistepContext.Provider>
}

const Step = ({ children, step }) => {
    const { currentStep, registerStep } = useContext(MultistepContext)
    useEffect(() => {
        registerStep(step)
    })
    return step === currentStep ? children : null
}

const Buttons = () => {
    const { currentStep, setCurrentStep, registeredSteps } = useContext(MultistepContext)
    const isLastStep = currentStep === registeredSteps.length
    const isFirstStep = currentStep === 1
    return (
        <div>
            {!isFirstStep && (
                <button onClick={!isFirstStep ? () => setCurrentStep(currentStep - 1) : null} disabled={isFirstStep}>
                    Wstecz
                </button>
            )}
            {!isLastStep && (
                <button onClick={!isLastStep ? () => setCurrentStep(currentStep + 1) : null} disabled={isLastStep}>
                    Dalej
                </button>
            )}
            {isLastStep && <button role="none">Zatwierdź</button>}
        </div>
    )
}

// const ProgressBar = () => {
//   const { currentStep, registeredSteps } = useContext(MultistepContext)
//   const value = Math.round((currentStep / registeredSteps.length) * 100)
//   const isCompleted = value >= 100
//   return (
//     <progress value={value} max="100">
//       {currentStep} / {registeredSteps.length}
//     </progress>
//   )
// }

export { MultistepForm, Step, Buttons }
