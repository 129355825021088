import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
    TextField,
    Typography,
    Box,
    FormControlLabel,
    Radio,
    RadioGroup,
    Alert,
    Divider,
    InputAdornment,
    Button,
} from '@mui/material'
import { Formik, Form, Field } from 'formik'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import PercentIcon from '@mui/icons-material/Percent'
import useFormattedPrice from '../../hooks/useFormattedPrice'
import ChooseButton from '../../components/ChooseButton'
import { API, FORM_KEY } from '../../api'
import { useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner'
import useFetch from '../../hooks/useFetch'
import BackButtonInForm from '../../components/BackButtonInForm'
import ErrorBox from '../../../components/ErrorBox'

const FormStep5 = ({ processData, promoCodeIsAdded, setPromoCodeIsAdded, promoCode, setPromoCode }) => {
    const { t } = useTranslation()
    const { id, locale } = useParams()

    const { fetchData } = useFetch()

    const depositPrice = useFormattedPrice(processData?.variables.full_calculation_deposit)
    const fullPrice = useFormattedPrice(processData?.variables.full_calculation_price)

    const depositPriceBeforePromo = useFormattedPrice(processData?.variables.full_calculation_without_promo_deposit)
    const fullPriceBeforePromo = useFormattedPrice(processData?.variables.full_calculation_without_promo_price)
    const priceIntallment =
        useFormattedPrice(parseFloat(processData?.variables.full_calculation_required_instalment).toFixed(2)) || ''

    const [loadingCheckPromoCode, setLoadingCheckPromoCode] = useState(false)
    const [promocodeIsChecked, serPromoCodeIsChecked] = useState(false)

    const [alertReservationNotCreated, setAlertReservationNotCreated] = useState(false)

    const [hideButton, setHideButton] = useState(false)

    const [errorPaymentMsg, setErrorPaymentMsg] = useState("")

    const initialValues = {
        promoCode: '',
        payOnlyAdvance: 'false',
        paymentMethod: 'payByTransfer',
        // blik: '',
        // bank_id: '25',
    }

    // useEffect(() => {
    // if (processData?.variables?.bank_token) {
    // navigate(`/${locale || 'pl'}/RAC/summary/${processData?.variables?.pid}`)
    // }
    // }, [processData])

    const handleCheckPromoCode = async promoCode => {
        setLoadingCheckPromoCode(true)

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ promo_code: promoCode }),
        }

        fetch(`${API['PROMO_CODE']}&pid=${id}`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error(t('errors.try_again'))
                }
                return response.json()
            })
            .then(data => {
                if (
                    data.variables?.full_calculation_voucher_used === 'yes' ||
                    data.variables?.full_calculation_promotional_code_used === 'yes'
                ) {
                    setPromoCodeIsAdded(true)
                } else {
                    setPromoCodeIsAdded(false)
                }
                // if (data.variables.navigation === 'promo_code') {
                //     setPromoCodeIsAdded(true)
                // } else {
                //     setPromoCodeIsAdded(false)
                // }
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error)
                setPromoCodeIsAdded(false)
            })
            .finally(
                setLoadingCheckPromoCode(false),
                serPromoCodeIsChecked(true)
            )

        return true
    }

    const handleSubmit = async values => {
        setHideButton(true)
        setErrorPaymentMsg("")

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ payment_advance: values.payOnlyAdvance === 'true' ? 'yes' : 'no' }),
        }

        fetch(`${API['SAVE_DATA']}?pid=${id}&navigation=${FORM_KEY['payByLink']}`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error(t('errors.try_again'))
                }
                return response.json()
            })
            .then(data => {
                if (data?.varaibles?.reservation_created === 'no') {
                    setAlertReservationNotCreated(true)
                } else if (data?.variables?.bank_token) {
                    const token = data.variables.bank_token
                    const redirectUrl = `${process.env.REACT_APP_P24_URL}${token}`
                    // const redirectUrl = `https://sandbox.przelewy24.pl/trnRequest/${token}?lang=${locale}`
                    // const redirectUrl = `https://secure.przelewy24.pl/trnRequest/${token}?lang=${locale}`
                    window.location.href = redirectUrl
                }
            })
            .catch(error => {
                console.error('Error:', error)
                setErrorPaymentMsg(t('errors.try_again'))
            })
        // }
    }

    const handleClickEditStep2 = () => {
        fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=${FORM_KEY['step2']}`, 'GET')
    }

    return processData ? (
        alertReservationNotCreated ? (
            <>
                <Alert severity="error" sx={{ mb: 4 }}>
                    {t('errors.failed_create_reservation')}
                </Alert>
                <BackButtonInForm handleClick={handleClickEditStep2}>
                    {locale === 'pl' ? 'Wybierz inne auto' : 'Select a different car'}
                </BackButtonInForm>
            </>
        ) : (
            <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
                {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                    <Form>
                        <section id="promoCode">
                            {/* -------------------- Podmumowanie + Kod rabatowy -------------------- */}
                            <>
                                {loadingCheckPromoCode && (
                                    <Box textAlign="ceneter">
                                        <Spinner />
                                    </Box>
                                )}
                                {promocodeIsChecked && promoCodeIsAdded && processData?.variables?.full_calculation_promotional_code_used === 'no' && (
                                        <Alert severity="error" sx={{ mb: 4 }}>
                                            <Typography>
                                                {promoCode}
                                            </Typography>
                                        </Alert>
                                )}
                                {!promoCodeIsAdded ? (
                                    <Box mb={4}>
                                        <Typography
                                            variant="h2"
                                            fontSize={20}
                                            fontWeight="bold"
                                            textAlign="left"
                                            mb={2}
                                        >
                                            {t('RAC.step_five.discount_code')}
                                        </Typography>
                                        <Box mb={2}>
                                            <Field
                                                as={TextField}
                                                name="promoCode"
                                                label={`${t('RAC.step_five.discount_code')} (${t('RAC.step_five.optional')})`}
                                                error={touched.promoCode && Boolean(errors.promoCode)}
                                                helperText={touched.promoCode && errors.promoCode}
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <PercentIcon />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {promoCode?.length > 0 && (
                                                                <Button
                                                                    variant="text"
                                                                    sx={{
                                                                        fontSize: 18,
                                                                        fontWeight: 'bold',
                                                                        color: '#f07d14',
                                                                    }}
                                                                    onClick={async e => {
                                                                        const promoCode = values.promoCode

                                                                        if (promoCode.length > 0) {
                                                                            const promoCodeExists =
                                                                                await handleCheckPromoCode(promoCode)
                                                                        }
                                                                    }}
                                                                >
                                                                    {t('verify_code')}
                                                                </Button>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={e => {
                                                    setFieldValue('promoCode', e.target.value)
                                                    setPromoCode(e.target.value)
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                ) : ( 
                                    !loadingCheckPromoCode && (
                                        <Alert severity="success" sx={{ mb: 4 }}>
                                            <Typography>
                                                {t('code_added')}: {promoCode}
                                            </Typography>
                                        </Alert>
                                    )
                                )}
                                
                                <Box mb={4}>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography>{t('RAC.step_five.total_cost')}</Typography>
                                        {fullPrice ? (
                                            <>
                                                {fullPriceBeforePromo ? <Typography></Typography> : ''}
                                                <Typography fontWeight="bold" textAlign="right">
                                                    {fullPrice}{' '}
                                                    {promoCodeIsAdded && fullPriceBeforePromo && (
                                                        <span style={{ fontSize: '12px' }}>
                                                            ({t('price_before_promocode')}: {fullPriceBeforePromo})
                                                        </span>
                                                    )}
                                                </Typography>
                                            </>
                                        ) : (
                                            <Spinner size={20} sx={{ m: 0 }} />
                                        )}
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Box display="flex" justifyContent="space-between">
                                        <Box>
                                            <Typography>{t('deposit')}</Typography>
                                            <Typography fontSize={10}>
                                                {' '}
                                                <span style={{ textWrap: 'nowrap' }}>{depositPrice}</span>{' '}
                                                {t('RAC.step_five.payment')}
                                            </Typography>
                                        </Box>
                                        {depositPrice ? (
                                            <Typography fontWeight="bold" textAlign="right">
                                                <span style={{ textWrap: 'nowrap' }}>{depositPrice}</span>{' '}
                                                {promoCodeIsAdded && depositPriceBeforePromo && (
                                                    <span style={{ fontSize: '12px' }}>
                                                        ({t('price_before_promocode')}: {depositPriceBeforePromo})
                                                    </span>
                                                )}
                                            </Typography>
                                        ) : (
                                            <Spinner size={20} sx={{ m: 0 }} />
                                        )}
                                    </Box>
                                </Box>
                            </>
                        </section>
                        <section id="payment">
                            {/* -------------------- Kwota do zapłaty -------------------- */}
                            {/* - możliwość zapłaty zaliczki tylko jeśli powyżej 72h od godziny odbioru - */}
                            {/* {dayjs(processData?.variables?.pickup_date).diff(dayjs(), 'hour') > 72 && ( */}
                            {processData?.variables?.advance_payment_possibility === "yes" && (
                                <Box mb={4}>
                                    <Box mb={2}>
                                        <Typography variant="h2" fontSize={20} fontWeight="bold" textAlign="left">
                                            {t('RAC.step_five.price.0')}
                                        </Typography>
                                    </Box>
                                    <Box width="100%">
                                        <Field
                                            type="radio"
                                            name="payOnlyAdvance"
                                            as={FormControlLabel}
                                            style={{ width: '100%', marginLeft: 0, marginRight: 0 }}
                                            control={
                                                <RadioGroup
                                                    row
                                                    name="payOnlyAdvance"
                                                    value={values.payOnlyAdvance}
                                                    sx={{ width: '100%' }}
                                                >
                                                    <Box
                                                        width="100%"
                                                        display="flex"
                                                        gap={2}
                                                        justifyContent="space-between"
                                                    >
                                                        <Box
                                                            border="1px solid #c4c4c4"
                                                            p={2}
                                                            borderRadius="4px"
                                                            width="50%"
                                                        >
                                                            <FormControlLabel
                                                                value="false"
                                                                control={<Radio />}
                                                                label={t('RAC.step_five.price.1')}
                                                            />
                                                            <Typography pl={4} fontSize={20} fontWeight="bold">
                                                                {fullPrice}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            border="1px solid #c4c4c4"
                                                            p={2}
                                                            borderRadius="4px"
                                                            width="50%"
                                                        >
                                                            <FormControlLabel
                                                                value="true"
                                                                control={<Radio />}
                                                                label={t('RAC.step_five.price.2')}
                                                            />
                                                            <Typography pl={4} fontSize={20} fontWeight="bold">
                                                                {priceIntallment}
                                                                {/* 100 zł */}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </RadioGroup>
                                            }
                                        />
                                        {values.payOnlyAdvance === 'true' && (
                                            <Alert severity="info" icon={<InfoOutlinedIcon />} sx={{ mt: 2 }}>
                                                {t('info_about_paying_full_price.0')} <b>3 {t('days')}</b>{' '}
                                                {t('info_about_paying_full_price.1')}
                                            </Alert>
                                        )}
                                    </Box>
                                </Box>
                            )}
                            {!hideButton ? (
                                <>
                                    <Alert severity="info" sx={{ mb: 2 }}>
                                        {t('RAC.step_five.obligatory_payment')}
                                    </Alert>
                                    <ChooseButton id="R5" sx={{ my: 2 }} type="submit" disabled={isSubmitting}>
                                        {t('RAC.step_five.submit_btn')}
                                    </ChooseButton>
                                </>
                            ) : (
                                <>
                                    {errorPaymentMsg ? <ErrorBox error={errorPaymentMsg} /> : <Spinner />} 
                                </>
                            )}
                        </section>
                    </Form>
                )}
            </Formik>
        )
    ) : (
        <Spinner />
    )
}

export default FormStep5
