import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import TabsSearchLocation from './TabsSearchLocation'
import { DialogContext } from './SearchField'

const DialogLocation = () => {
    const { openDialog, handleCloseDialog, autoHeight } = useContext(DialogContext)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const { t } = useTranslation()

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{
                style: {
                    width: '100%',
                    minHeight: autoHeight ? 'auto' : `calc(100% - 64px)`,
                    '@media (minWidth: 700px)': {
                        width: '700px',
                    },
                },
            }}
        >
            <DialogTitle id="responsive-dialog-title" className="hidden">
                {t('RAC.step_one.search_tab_title')}
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <TabsSearchLocation />
            </DialogContent>
        </Dialog>
    )
}

export default DialogLocation
