import React, { useEffect, useState } from 'react'
import { API } from '../../../forms/LAC/api';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import AddRoadOutlinedIcon from '@mui/icons-material/AddRoadOutlined';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import Spinner from '../../../forms/components/Spinner';

export default function TagsItems({car, tagsData, isList = false}) {
const iconMap = {
  1: <ErrorOutlineOutlinedIcon fontSize="small" />,
  2: <LocalOfferOutlinedIcon fontSize="small" />,
  3: <LocalOfferOutlinedIcon fontSize="small" />,
  4: <CardMembershipOutlinedIcon fontSize="small" />,
  5: <AddRoadOutlinedIcon fontSize="small" />,
  6: <AdsClickOutlinedIcon fontSize="small" />,
  7: <LocalShippingIcon fontSize="small" />,
  8: <ElectricCarIcon fontSize="small" />,
};

if(!tagsData) {
  return(
    <Spinner />
  )
}
  return (
      <>
      {car?.tagIds.map((id) => {

        if(car.fuel === 'Electric' && id === 8) {

        } else {
          return (
            <div className={`${isList ? 'border text-[#919191] border-[#c7c7c7] rounded-full px-4 py-2 flex items-center gap-x-2' : 'border text-[10px] text-[#919191] border-[#c7c7c7] rounded-full px-2 py-1 flex items-center gap-x-1.5'}`} key={id}>
              {iconMap[id] || <LocalOfferOutlinedIcon />} {tagsData?.['pl'][id]}
            </div>
          )
        }
      })}
    </>
  )
}
