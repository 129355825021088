import React, { useState, useEffect, useRef } from 'react'
import Axios from 'axios'
import { useParams } from 'react-router-dom'
import { environment } from './../../environment'
import NewsTeaser from '../../portlets/news/NewsTeaser'
import Loaders from '../../Loaders.js'
import Links from '../../components/Links'

const TeaserComponents = {
    'news-details': NewsTeaser,
}

function NewsList(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('NewsList')
        console.log(params)
    }
    const [setCat, setSetCat] = useState('')
    let newsList = [],
        setFine = false,
        minified = params.block.hasOwnProperty('Minified') ? params.block['Minified'] : false,
        listCats = getCat(params.block.hasOwnProperty('Category') ? params.block['Category'] : ''),
        linkToMain = environment.newsMainLink + '#' + listCats

    if (params.block.hasOwnProperty('list')) {
        //setPending(false);
        //setData(params.block.list);
        newsList = params.block.list
        setFine = true
    } else {
        //setPending(false);
        newsList = []
        setFine = true
    }
    if (!setFine) {
        //setPending(false)
        newsList = []
    }
    function getCat(tag) {
        if (tag !== '') {
            const splitTags = tag.split(','),
                tagLast = splitTags[0].split('/')
            return tagLast[tagLast.length - 2]
        } else {
            return ''
        }
    }
    let sortParams = newsList.sort(function (a, b) {
        if (a['en']['Date'] && a['en']['Date'] !== '' && b['en']['Date'] && b['en']['Date'] !== '') {
            return new Date(b['en']['Date']) - new Date(a['en']['Date'])
        }
        return null
    })

    const closeIcon = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 682.66669 682.66669" style="enable-background:new 0 0 512 512" xml:space="preserve"><g><path xmlns="http://www.w3.org/2000/svg" d="m460.175781 215.175781-104.800781 104.800781 104.800781 104.796876-35.351562 35.359374-104.800781-104.800781-104.796876 104.800781-35.359374-35.359374 104.800781-104.796876-104.800781-104.800781 35.359374-35.351562 104.796876 104.800781 104.800781-104.800781zm179.824219 104.824219c0 85.472656-33.285156 165.835938-93.726562 226.273438-60.4375 60.441406-140.800782 93.726562-226.273438 93.726562s-165.835938-33.285156-226.273438-93.726562c-60.441406-60.4375-93.726562-140.800782-93.726562-226.273438s33.285156-165.835938 93.726562-226.273438c60.4375-60.441406 140.800782-93.726562 226.273438-93.726562s165.835938 33.285156 226.273438 93.726562c60.441406 60.4375 93.726562 140.800782 93.726562 226.273438zm-50 0c0-148.875-121.125-270-270-270s-270 121.125-270 270 121.125 270 270 270 270-121.125 270-270zm0 0" fill="#0d3174" data-original="#000000" /></g></svg>`
    let [data, setData] = useState(null),
        [catData, setCatData] = useState([]),
        [catDataShort, setCatDataShort] = useState([])
    useParams()

    useEffect(() => {
        let fetchCats = async () => {
            let url = null
            if (window.location.origin.includes(environment.cmsName)) {
                url = window.location.origin + environment.categoriesJson
            } else if (window.location.origin.includes(environment.localhost)) {
                url = environment.siteUrlDev + environment.categoriesJson
            } else {
                url = window.location.origin + environment.categoriesJson
            }
            let response = ''
            try {
                response = await Axios(url)
            } catch (err) {
                response = 'error'
            }

            if (response.hasOwnProperty('data')) {
                let mainCat = []
                Object.keys(response.data).filter(function (key) {
                    if (key.includes('tagi-glowne')) {
                        let tagLast = key.split('/'),
                            last = tagLast[tagLast.length - 2]
                        mainCat[last] = response.data[key]
                    }
                    return null
                })
                setCatData(mainCat)
                let shortCat = {}
                Object.keys(response.data).map(function (key) {
                    let tagLast = key.split('/'),
                        last = tagLast[tagLast.length - 2],
                        name = response.data[key]
                    if (name === '') name = last

                    shortCat[last] = name
                    return null
                })
                setCatDataShort(shortCat)
            }
        }
        fetchCats()
    }, [])
    useEffect(() => {
        setChoosenTagsList([])
        setSortTag('all')
        setData(sortParams)
    }, [])
    useEffect(() => {
        const setCats = cat => {
            let setTag = document.getElementById('add-' + cat)
            if (setTag) {
                setTag.click()
            }
        }
        setCats(setCat)
    }, [setCat])

    let activeClickOne = event => {
        if (event.target.classList.contains('active')) {
            event.target.classList.remove('active')
        } else {
            event.target.classList.add('active')
        }
    }

    const [choosenTagsList, setChoosenTagsList] = useState([])
    let tagsList = []
    function Tags() {
        sortParams.map((teaser, i) => {
            if (teaser['en']['Tags'] !== undefined && teaser['en']['Tags'] !== '') {
                let tags = teaser['en']['Tags'].split(',')
                tags.map(tag => {
                    if (!Object.values(tagsList).includes(tag) && tag.includes('tagi-glowne')) {
                        tagsList.push(tag)
                    }
                    return null
                })
            }
            return null
        })
        if (tagsList.length === 0) {
            return (
                <div>
                    <span className="tag-item">NIE ZNALEZIONO TAGÓW</span>
                </div>
            )
        } else {
            return tagsList.map(tag => {
                let tagLast = tag.split('/'),
                    nameTag = tagLast[tagLast.length - 2]
                if (nameTag in catDataShort) {
                    let name = catDataShort[nameTag],
                        href = '#' + nameTag,
                        addHide = ''

                    if (name === undefined || name === '') name = nameTag
                    if (!(nameTag in catData)) {
                        addHide = ' hidden'
                    }
                    if (Object.values(choosenTagsList).includes(tagLast[tagLast.length - 2])) {
                        return (
                            <a
                                key={href}
                                href={href}
                                className={`button tag-type b-color1 c-white active mr-10` + addHide}
                                data-tags={tagLast[tagLast.length - 2]}
                                onClick={e => {
                                    e.preventDefault()
                                }}
                            >
                                {name}
                            </a>
                        )
                    } else if (window.location.hash && nameTag === window.location.hash.substring(1)) {
                        setTimeout(() => setSetCat(window.location.hash.substring(1)), 0)
                        return (
                            <a
                                key={href}
                                href={href}
                                id={`add-` + nameTag}
                                className={`button tag-type mr-10` + addHide}
                                data-tags={tagLast[tagLast.length - 2]}
                                onClick={e => {
                                    e.preventDefault()
                                    activeClickOne(e)
                                    setSortTag(e.target.attributes['data-tags'].value)
                                }}
                            >
                                {name}
                            </a>
                        )
                    } else {
                        return (
                            <a
                                key={href}
                                href={href}
                                id={`add-` + nameTag}
                                className={`button tag-type mr-10` + addHide}
                                data-tags={tagLast[tagLast.length - 2]}
                                onClick={e => {
                                    e.preventDefault()
                                    activeClickOne(e)
                                    setSortTag(e.target.attributes['data-tags'].value)
                                }}
                            >
                                {name}
                            </a>
                        )
                    }
                } else {
                    return ''
                }
            })
        }
    }
    const [sortTag, setSortTag] = useState('all')
    useEffect(() => {
        const sortTagType = tag => {
            const newList = []
            if (tag !== 'all') {
                if (!Object.values(choosenTagsList).includes(tag)) {
                    choosenTagsList.push(tag)
                }
                sortParams.map((teaser, i) => {
                    if (teaser['en']['Tags'] !== undefined && teaser['en']['Tags'] !== '') {
                        let added = false
                        choosenTagsList.map((tags, i) => {
                            if (teaser['en']['Tags'] !== undefined && teaser['en']['Tags'] !== '') {
                                let tagg = teaser['en']['Tags'].split(',')
                                tagg.map((tag, i) => {
                                    let thisTag = tag.split('/')
                                    let lastTagg = thisTag[thisTag.length - 2]
                                    if (lastTagg.includes(tags)) {
                                        if (!added) {
                                            newList.push(teaser)
                                            added = true
                                        }
                                    }
                                    return null
                                })
                            }
                            return null
                        })
                    }
                    return null
                })
                setData(newList)
            }
        }
        sortTagType(sortTag)
    }, [sortTag])

    function ChooseTags() {
        return choosenTagsList.map(tag => {
            let href = '#' + tag
            let idTag = 'tag-' + tag
            let name = catDataShort[tag]
            return (
                <div className="chosen-tag d-flex ai-center mb-20" key={tag} id={idTag}>
                    <a
                        href={href}
                        className="all-link"
                        data-tag={tag}
                        onClick={e => {
                            e.preventDefault()
                            RemoveTags(e.target.attributes['data-tag'].value)
                        }}
                    >
                        {href}
                    </a>
                    <div className="close-tag">
                        <span className="close-icon" dangerouslySetInnerHTML={{ __html: closeIcon }}></span>
                    </div>
                    <p className="c-color1">{name}</p>
                </div>
            )
        })
    }
    function RemoveTags(e) {
        choosenTagsList.splice(choosenTagsList.indexOf(e), 1)
        setChoosenTagsList(choosenTagsList)
        setSortTag('all')
        if (choosenTagsList.length === 0) {
            setData(sortParams)
        } else {
            const newsLists = []
            sortParams.map((teaser, i) => {
                if (teaser['en']['Tags'] !== undefined && teaser['en']['Tags'] !== '') {
                    let added = false
                    choosenTagsList.map(tag => {
                        if (teaser['en']['Tags'].includes(tag)) {
                            if (!added) {
                                newsLists.push(teaser)
                                added = true
                            }
                        }
                        return null
                    })
                }
                return null
            })
            setData(newsLists)
        }
    }
    function CategoriesBox() {
        return (
            <div className="tags-box pb-20 pt-20">
                <div className="chosen-tags d-flex ai-center f-wrap">
                    <ChooseTags />
                </div>
                <div className="tags-item d-flex ai-center f-wrap">
                    <h5>Tagi: &nbsp;</h5>
                    <Tags />
                </div>
            </div>
        )
    }

    let arrayNewsPosts = []
    const Items = () => {
        const [newsToShow, setNewsToShow] = useState('empty'),
            newsPerPage = minified ? 4 : 3,
            ref = useRef(newsPerPage),
            loopWithSlice = (start, end) => {
                const slicedNews = data.slice(start, end)
                arrayNewsPosts = arrayNewsPosts.concat(slicedNews)
                setNewsToShow(arrayNewsPosts)
            }
        useEffect(() => {
            loopWithSlice(0, newsPerPage)
        }, [])
        const handleShowMorePosts = () => {
            loopWithSlice(ref.current, ref.current + newsPerPage)
            ref.current += newsPerPage
        }
        function LoadButton() {
            if (minified) {
                return (
                    <div className="ta-center">
                        <Links line={params.line} url={linkToMain} class="button b-color1" name="POKAŻ WIĘCEJ" />
                    </div>
                )
            } else {
                if (newsToShow !== 'empty' && newsToShow.length === data.length) {
                    return <div className="ta-center mt-20"></div>
                } else {
                    return (
                        <div className="ta-center mt-20">
                            <a
                                href="#loadmore"
                                className="button b-color1 mt-20"
                                onClick={e => {
                                    e.preventDefault()
                                    handleShowMorePosts()
                                }}
                            >
                                POKAŻ WIĘCEJ
                            </a>
                        </div>
                    )
                }
            }
        }
        return (
            <div>
                <ul className="list-entries d-flex f-wrap">
                    {newsToShow &&
                        newsToShow !== 'empty' &&
                        newsToShow.length > 0 &&
                        newsToShow.map((teaser, i) => {
                            let type = teaser.attributes.type
                            if (TeaserComponents.hasOwnProperty(type)) {
                                let key = teaser.path.split('/').pop()
                                key = key.substring(0, key.length - 4)
                                return (
                                    <li className="list-entry" key={key + '_' + i}>
                                        {React.createElement(TeaserComponents[type], {
                                            key: key + '_' + i,
                                            block: teaser,
                                            line: params.line,
                                            min: minified,
                                        })}
                                    </li>
                                )
                            }
                            return null
                        })}
                    {newsToShow && newsToShow.length === 0 && (
                        <div>
                            <h3>NIE ZNALEZIONO AKTUALNOŚCI Z WYBRANEJ KATEGORII</h3>
                        </div>
                    )}
                    {newsToShow && newsToShow === 'empty' && (
                        <li className="list-entry">
                            <div>
                                <h3 className="c-color1">
                                    <Loaders type="dots" color="b-color1" />
                                </h3>
                            </div>
                        </li>
                    )}
                </ul>
                <LoadButton />
            </div>
        )
    }
    function TopBox() {
        if (params.block['Category'] !== '') {
            return (
                <div className="top-banner-title container-wide title-box">
                    <div className="tags-container">
                        <CategoriesBox />
                    </div>
                </div>
            )
        } else {
            return (
                <div className="top-banner-title container-wide title-box">
                    <div className="tags-container">
                        <CategoriesBox />
                    </div>
                </div>
            )
        }
    }
    if (data !== null) {
        return (
            <div className="container news-list list-box list-dynamic list-paginate">
                {params.block['Title'] && <h2>{params.block['Title']}</h2>}
                <TopBox />
                <Items />
                <div className="list-pagination"></div>
            </div>
        )
    }
}

export default NewsList
