import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import useFetch from '../../hooks/useFetch'
import Spinner from '../../components/Spinner'
import ErrorBox from '../ErrorBox'
import theme from '../../../styles/MUI-theme'
import IconSearchResult from '../../components/IconSearchResult'
import useFormattedPrice from '../../hooks/useFormattedPrice'

const ReservationDetailsPriceRelocation = ({ processData }) => {
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()
    const { loading, error, fetchData } = useFetch()

    const formattedPrice =
        useFormattedPrice(parseFloat(processData?.full_calculation_price_transport_relocation).toFixed(2)).replace(
            '.',
            ','
        ) || ''

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return (
        <Box
            width="100%"
            p={{ xs: 1, lg: 2 }}
            display="flex"
            justifyContent="space-between"
            borderBottom={`0.5px solid ${theme.palette.secondary.main}`}
            bgcolor="white"
        >
            <>
                <Box display={'flex'}>
                    <IconSearchResult icon="NotListedLocationOutlinedIcon" />
                    <Box>
                        <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'bold'}>
                            {t('fee_additional')}
                        </Typography>
                        <Typography fontSize={{ xs: 10, lg: 14 }} fontWeight={'regular'}>
                            {t('fee_relocation')}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ textAlign: 'right', minWidth: '100px' }}>
                    <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'regular'} textAlign={'right'}>
                        {formattedPrice}
                    </Typography>
                </Box>
            </>
        </Box>
    )
}

export default ReservationDetailsPriceRelocation
