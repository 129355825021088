import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Box, Dialog, DialogTitle, DialogContent, Button, Typography } from '@mui/material'
import useFetch from '../hooks/useFetch'
import Spinner from '../components/Spinner'
import ErrorBox from '../../components/ErrorBox'
import { API } from '../api'

const CheckPaymentStatusRAC = ({ setAfterPayment, setRetryToPay }) => {
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()
    const { data, loading, error, fetchData } = useFetch()

    const [status, setStatus] = useState('pending')
    const [timer, setTimer] = useState(0)
    const [open, setOpen] = useState(false)
    const [checkingIsStopped, setCheckingIsStopped] = useState(false)

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        status === 'success' && setCheckingIsStopped(true)
    }, [status])

    useEffect(() => {
        const interval = setInterval(() => {
            if (timer >= 60) {
                setOpen(true)
                setCheckingIsStopped(true)
                clearInterval(interval) 
                return
            }

            !checkingIsStopped &&
                fetch(`${API[`GET_PAYMENT_STATUS`]}?pid=${id}`)
                    .then(response => response.json())
                    .then(data => {
                        const { status } = data
                        setStatus(status)

                        if (status === 'success') {
                            clearInterval(interval)
                            fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=next`)
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching payment status:', error)
                        clearInterval(interval)
                    })

            setTimer(prev => prev + 5)
        }, 2000)

        return () => clearInterval(interval)
    }, [id, timer, checkingIsStopped])

    const handleClose = () => {
        setOpen(false)
        setAfterPayment(false)
        setRetryToPay(true)
    }

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return !loading ? (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                my: 8,
            }}
        >
            {status === 'pending' && (
                <>
                    <CircularProgress />
                    <Typography my={2}>{t('payment_verification')}</Typography>
                </>
            )}
           <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t('errors.payment_error_title')}</DialogTitle>
                <DialogContent>{t('errors.payment_error_message')}</DialogContent>
                <Button onClick={handleClose}>{t('btn.close')}</Button>
            </Dialog>
        </Box>
    ) : (
        <Spinner />
    )
}

export default CheckPaymentStatusRAC
