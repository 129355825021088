import Axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { environment } from '../../environment'

function NavLangOff(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('NavLang')
    }

    const lang = window.location.pathname.split('/')[1].length > 2 || window.location.pathname.split('/')[1].length === 0 ? 'pl' : window.location.pathname.split('/')[1]
    const langMeta = document.documentElement.lang;
    let [navData, setNavData] = useState([]);
    useParams()
    let [jsonStatus, setJsonStatus] = useState(null)

    useEffect(() => {
        let fetchNavs = async () => {
            let url = null
            if (window.location.origin.includes(environment.cmsName)) {
                url = window.location.origin + environment.sitemapJson
            } else if (window.location.origin.includes(environment.localhost)) {
                url = environment.siteUrlDev + environment.sitemapJson
            } else {
                url = window.location.origin + environment.sitemapJson
            }
            let response = ''
            try {
                response = await Axios(url)
                setJsonStatus(true)
            } catch (err) {
                setJsonStatus(false)
                response = 'error'
            }
            if (response.hasOwnProperty('data')) {
                if (response.data) {
                    setNavData(response.data)
                    
                } else {
                    setJsonStatus(false)
                }
            } else {
                setJsonStatus(false)
            }
        }
        fetchNavs()
    }, [])
   
    function setSitemap(lang) {
        let paths = [];
        let url = window.location.pathname;
        if (url.includes(environment.lacRentUrl[lang])) {
            paths = [url.replace(environment.lacRentUrl['en'], environment.lacRentUrl['pl']), url.replace(environment.lacRentUrl['pl'], environment.lacRentUrl['en'])];
        }
        else if (url.includes(environment.racRentUrl[lang])) {
            paths = [url.replace(environment.racRentUrl['en'], environment.racRentUrl['pl']), url.replace(environment.racRentUrl['pl'], environment.racRentUrl['en'])];
        }
        else if (url.includes(environment.lacRentFlexUrl[lang])) {
            let par = window.location.search
            paths = [url.replace(environment.lacRentFlexUrl['en'], environment.lacRentFlexUrl['pl']) + par, url.replace(environment.lacRentFlexUrl['pl'], environment.lacRentFlexUrl['en']) + par];
        }
        else {
            navData.map((nav, i) => {
                let url = window.location.pathname.indexOf('index.html') > 0 ? window.location.pathname : window.location.pathname + environment.page
                if (nav[lang] === url) {
                    paths[0] = navData[i]['pl'].replace('index.html', '') //PL
                    paths[1] = navData[i]['en'].replace('index.html', '') //EN
                    //paths[2] = navData[i]['ua'].replace('index.html', '') //UA
                    return false;
                }
                return null
            })
        }
        if (paths.length < 1) paths = ['/','/en/'/*,[/ua/] */]
        return paths
    }

    function activeNavCMS(lan) {
        let status = ''
        if (lan === lang) status = ' active'
        return status
    }

    switch (jsonStatus) {
        case null:
            return (
                <div className="lang-menu d-flex ai-center">
                    <a
                        href={'/'}
                        title="Polski"
                        className={`link-button` + activeNavCMS('pl')}
                    >
                        PL
                    </a>
                    <a
                        href={'/en/'}
                        title="English"
                        className={`link-button` + activeNavCMS('en')}
                    >
                        EN
                    </a>
                </div>
            )
        case false:
            return (
                <div className="lang-menu d-flex ai-center">
                    <a
                        href={'/'}
                        title="Polski"
                        className={`link-button` + activeNavCMS('pl')}
                    >
                        PL
                    </a>
                    <a
                        href={'/en/'}
                        title="English"
                        className={`link-button` + activeNavCMS('en')}
                    >
                        EN
                    </a>
                </div>
            )
        case true:
            return (
                <div className="lang-menu d-flex ai-center">
                    <a
                        href={setSitemap(lang)[0]}
                        title="Polski"
                        className={`link-button` + activeNavCMS('pl')}
                    >
                        PL
                    </a>
                    <a
                        href={setSitemap(lang)[1]}
                        title="English"
                        className={`link-button` + activeNavCMS('en')}
                    >
                        EN
                    </a>
                </div>
            )
        default:
            break
    }
    return <></>
}
export default NavLangOff
