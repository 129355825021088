import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import useFormattedPrice from '../../hooks/useFormattedPrice'
import theme from '../../../styles/MUI-theme'
import IconSearchResult from '../../components/IconSearchResult'

const ReservationDetailsFixedFeePrice = ({ processData }) => {
    const { t, i18n } = useTranslation()
    const { locale } = useParams()

    const priceFixedFee = useFormattedPrice(parseFloat(processData?.summary_car_preparing_price).toFixed(2)) || ''

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    return (
        <Box
            width="100%"
            p={{ xs: 1, lg: 2 }}
            display="flex"
            justifyContent="space-between"
            borderBottom={`0.5px solid ${theme.palette.secondary.main}`}
            bgcolor="white"
        >
            <>
                <Box display={'flex'}>
                    <IconSearchResult icon="Inventory" />
                    <Box>
                        <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'bold'}>
                            {t('RAC.step_three.fixed_fee')}
                        </Typography>
                        <Typography fontSize={{ xs: 10, lg: 14 }} fontWeight={'regular'}>
                            {t('RAC.step_three.prepering')}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'regular'} textAlign={'right'}>
                        {priceFixedFee}
                    </Typography>
                </Box>
            </>
        </Box>
    )
}

export default ReservationDetailsFixedFeePrice
