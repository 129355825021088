import React, { useRef, useState, useEffect, useCallback } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { environment } from '../../environment'
import Image from '../../components/Image.js'
import Links from '../../components/Links.js'
import { useTranslation } from 'react-i18next'
import '../../styles/registration-form.css'
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { Button, Typography, Box, Tooltip, Zoom } from '@mui/material'
import { API } from '../../forms/api'

const USER_REGEX = /^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ][A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ ]{1,79}$/;
const USERS_REGEX = /^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ][A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ,_\s]{1,79}$/;
//const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const MAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const PHONE_REGEX = /^\+[\d- ()]{7,15}$/;
const REGISTER_URL = environment.regCreate;

function RegistrationForm(params) {
    let regBaseUrl = '/'
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('RegistrationForm')
        console.log(params)
        regBaseUrl = environment.siteUrlDev
    }
    const { t, i18n } = useTranslation()

    const { executeRecaptcha } = useGoogleReCaptcha()

    useEffect(() => {
        i18n.changeLanguage(params.locale)
    }, [params.locale])
    
    let ButtonName = params.block[params.locale].hasOwnProperty('ButtonName') ? params.block[params.locale]['ButtonName'] : '',
        LinkType = params.block[params.locale].hasOwnProperty('ButtonType') ? params.block[params.locale]['ButtonType'] : 'color1',
        Title = params.block[params.locale].hasOwnProperty('Title') ? params.block[params.locale]['Title'] : '',
        Statute = params.block[params.locale].hasOwnProperty('LabelStatute') ? params.block[params.locale]['LabelStatute'] : t('portlets.register_box.label_statute'),
        IOD = params.block[params.locale].hasOwnProperty('LabelIOD') ? params.block[params.locale]['LabelIOD'] : t('portlets.register_box.label_iod'),
        Newsletter = params.block[params.locale].hasOwnProperty('LabelNewsletter') ? params.block[params.locale]['LabelNewsletter'] : t('portlets.register_box.label_newsletter');
    let img = params.block[params.locale].hasOwnProperty('Image')
        ? params.block[params.locale]['Image'].hasOwnProperty('link')
            ? params.block[params.locale]['Image']['link']
            : environment.defaultImage
        : environment.defaultImage;
    let imgUrl = img;
    let mainPage = params.locale === 'pl' ? environment.mainPage : environment.enMainPage;
    let alreadyAccount = '<p>'+t('portlets.register_box.already_have')+'<b><a href="/authcallback/?return='+mainPage+'" class="c-color1 bold"> '+t('portlets.register_box.to_login')+'</a></b></p>'
    if (img !== undefined && img !== '') {
    if (img.includes(environment.siteSites)) {
        img = img.split(environment.siteSites)
        img = img[1]
        if (params.line === 'offline') {
            imgUrl = window.origin + img
        } else if (window.origin.includes(environment.localhost)) {
            imgUrl = environment.siteUrlDev + img
        } else {
            imgUrl = window.origin + img
        }
    } else if (window.origin.includes(environment.localhost)) {
        imgUrl = environment.siteUrlDev + img
    } else {
        imgUrl = img
    }
    } else {
        imgUrl = environment.defaultImage
    } 

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [userS, setUserS] = useState('');
    const [validNameS, setValidNameS] = useState(false);
    const [userFocusS, setUserFocusS] = useState(false);

    const [mail, setMail] = useState('');
    const [validMail, setValidMail] = useState(false);
    const [mailFocus, setMailFocus] = useState(false);

    const [phone, setPhone] = useState('');
    const [validPhone, setValidPhone] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);

    const [statute, setStatute] = useState(false);
    const [validStatute, setValidStatute] = useState(false);

    const [iod, setIod] = useState(false);
    const [validIod, setValidIod] = useState(false);
    const [showAllConsentInformationClause, setShowAllConsentInformationClause] = useState(false)

    const [newsletter, setNewsletter] = useState(false);

    const [onemail, setOnEmail] = useState(false);
    const [onphone, setOnPhone] = useState(false);

    /*const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);*/

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [recaptchaToken, setRecaptchaToken] = useState() 
    
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available')
            return
        } 
        const token = await executeRecaptcha('yourAction') 
        setRecaptchaToken(token)
    }, [executeRecaptcha, setRecaptchaToken])
 
    useEffect(() => { 
        handleReCaptchaVerify()
    }, [handleReCaptchaVerify]) 
    
    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])
    useEffect(() => {
        setValidNameS(USERS_REGEX.test(userS));
    }, [userS])

    useEffect(() => {
        setValidMail(MAIL_REGEX.test(mail));
    }, [mail])
    useEffect(() => {
        setValidPhone(PHONE_REGEX.test(phone));
    }, [phone])
    
    /*useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])*/

    useEffect(() => {
        setErrMsg('');
    }, [user, userS, mail, phone, statute, iod])

    const [checkedItems, setCheckedItems] = useState({})

    const handleChange = option => {
        setCheckedItems(prevCheckedItems => ({
            ...prevCheckedItems,
            [option]: !prevCheckedItems[option],
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
       
        // if button enabled 
        const v1 = USER_REGEX.test(user);
        const v1S = USERS_REGEX.test(userS);
        //const v2 = PWD_REGEX.test(pwd);
        const v3 = MAIL_REGEX.test(mail);
        const v4 = PHONE_REGEX.test(phone);
        const v5 = statute;
        const v6 = iod;
        if (!v1 || !v1S || !v3 || !v4 || !v5 || !v6) {
            setErrMsg(t('portlets.register_box.invalid_entry'));
            return;
        } 
        if (!recaptchaToken) {
            setErrMsg(t('errors.try_again'));
            return;
        } 

        try {
            const response = await axios.create({
                baseURL: regBaseUrl
            }).post(REGISTER_URL,
                JSON.stringify({ gRecaptchaResponse: recaptchaToken, firstName: user, lastName: userS, email: mail, phoneNo: phone, statute: statute, iod: iod, newsletter: newsletter, onEmail: onemail, onPhone: onphone }), {
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                }
            );
            if (response.hasOwnProperty('data')) {
                if (response.data && response.data.hasOwnProperty('message')) {
                    if (response.data.message === 'success') {
                        setSuccess(true);
                        //clear state and controlled inputs
                        setUser('');
                        setUserS('');
                        //setPwd('');
                        setMail('');
                        setPhone('');
                        setStatute(false);
                        setIod(false);
                        setNewsletter(false);
                        setOnEmail(false);
                        setOnPhone(false);
                        //setMatchPwd('');
                    } else {
                        setErrMsg(t('portlets.register_box.registration_failed'))
                        errRef.current.focus();
                    }
                } else {
                    setErrMsg(t('portlets.register_box.registration_failed'))
                    errRef.current.focus();
                }
            }
            else {
                setErrMsg(t('portlets.register_box.registration_failed'))
                errRef.current.focus();
            }
        } catch (err) {
            if (!err?.response) {
                setErrMsg(t('portlets.register_box.no_server_response'));
            } else {
                setErrMsg(t('portlets.register_box.registration_failed'))
            }
            errRef.current.focus();
        }
    }

    return (
        <GoogleReCaptchaProvider reCaptchaKey={environment.REACT_APP_RECAPTCHA}>
        <section className={'registration-form'}>
            <div className="wrapper">
                <div className={'container d-flex mx-auto'}>
                    <div className="img-wrapper">
                        <Image
                            src={imgUrl}
                            alt={Title !== '' ? Title : 'Express Rent Car Registration'}
                        />
                    </div>
                    <>
                        {success ? (
                            <div className="form-wrapper">
                                {params.block[params.locale]['Title'] && <h3 className={'text-left c-'+params.block[params.locale]['TitleColor']}>{params.block[params.locale]['Title']}</h3>}
                                <p className={"succmsg mb-8"} aria-live="assertive">{t('portlets.register_box.registration_success')}</p>
                                <Links
                                    class={'btn-link b-color1'}
                                    url={mainPage}
                                    target={false}
                                    name={t('portlets.register_box.back_to_main')}
                                    line={params.line}
                                />
                            </div>
                        ) : (
                            <div className="form-wrapper">
                                {params.block[params.locale]['Title'] && <h3 className={'text-left c-'+params.block[params.locale]['TitleColor']}>{params.block[params.locale]['Title']}</h3>}
                                <div
                                    className={`text-box mb-8`}
                                >
                                    <div dangerouslySetInnerHTML={{__html: alreadyAccount}} />
                                </div>
                                <p ref={errRef} className={errMsg ? "errmsg mb-8" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                <p className={success ? "succmsg mb-8" : "offscreen"} aria-live="assertive">{t('portlets.register_box.registration_success')}</p>
                                <form onSubmit={handleSubmit}>

                                    <div class="fieldset">
                                        <label
                                            htmlFor="name"
                                        >
                                            {t('portlets.register_box.name')}: <i>* </i>
                                            <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                                            <FontAwesomeIcon icon={faTimes} className={validName || !user ? "hide" : "invalid"} />
                                        </label>
                                        <div class="fieldset-box">
                                            <input  
                                                type="text"
                                                id="name"
                                                ref={userRef}
                                                autoComplete="off"
                                                onChange={(e) => setUser(e.target.value)}
                                                value={user}
                                                required
                                                aria-invalid={validName ? "false" : "true"}
                                                aria-describedby="uidnote"
                                                onFocus={() => setUserFocus(true)}
                                                onBlur={() => setUserFocus(false)} 
                                            />
                                            <fieldset aria-hidden="true">
                                                <legend>
                                                    <span>
                                                        {t('portlets.register_box.name')}: <i>* </i>
                                                        <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                                                        <FontAwesomeIcon icon={faTimes} className={validName || !user ? "hide" : "invalid"} />
                                                    </span>
                                                </legend>
                                            </fieldset>
                                        </div>
                                        <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                            {t('portlets.register_box.name_val')}
                                        </p>
                                    </div>

                                    
                                    <div class="fieldset">
                                        <label htmlFor="second">
                                            {t('portlets.register_box.second_name')}: <i>* </i>
                                            <FontAwesomeIcon icon={faCheck} className={validNameS ? "valid" : "hide"} />
                                            <FontAwesomeIcon icon={faTimes} className={validNameS || !userS ? "hide" : "invalid"} />
                                        </label>
                                        <div class="fieldset-box">
                                            <input
                                                type="text"
                                                id="second"
                                                ref={userRef}
                                                autoComplete="off"
                                                onChange={(e) => setUserS(e.target.value)}
                                                value={userS}
                                                required
                                                aria-invalid={validNameS ? "false" : "true"}
                                                aria-describedby="uidnoteS"
                                                onFocus={() => setUserFocusS(true)}
                                                onBlur={() => setUserFocusS(false)}
                                            />
                                            <fieldset aria-hidden="true">
                                                <legend>
                                                    <span>
                                                        {t('portlets.register_box.second_name')}: <i>* </i>
                                                        <FontAwesomeIcon icon={faCheck} className={validNameS ? "valid" : "hide"} />
                                                        <FontAwesomeIcon icon={faTimes} className={validNameS || !userS ? "hide" : "invalid"} />
                                                    </span>
                                                </legend>
                                            </fieldset>
                                        </div>
                                        <p id="uidnoteS" className={userFocusS && userS && !validNameS ? "instructions" : "offscreen"}>
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                            {t('portlets.register_box.second_name_val')}
                                        </p>
                                    </div>
                                    
                                    <div class="fieldset">
                                        <label htmlFor="mail">
                                            {t('portlets.register_box.email')}: <i>* </i>
                                            <FontAwesomeIcon icon={faCheck} className={validMail ? "valid" : "hide"} />
                                            <FontAwesomeIcon icon={faTimes} className={validMail || !mail ? "hide" : "invalid"} />
                                        </label>
                                        <div class="fieldset-box">
                                            <input
                                                type="text"
                                                id="mail"
                                                ref={userRef}
                                                autoComplete="off"
                                                onChange={(e) => setMail(e.target.value)}
                                                value={mail}
                                                required
                                                aria-invalid={validMail ? "false" : "true"}
                                                aria-describedby="uidnoteM"
                                                onFocus={() => setMailFocus(true)}
                                                onBlur={() => setMailFocus(false)}
                                            />
                                            <fieldset aria-hidden="true">
                                                <legend>
                                                    <span>
                                                        {t('portlets.register_box.email')}: <i>* </i>
                                                        <FontAwesomeIcon icon={faCheck} className={validMail ? "valid" : "hide"} />
                                                        <FontAwesomeIcon icon={faTimes} className={validMail || !mail ? "hide" : "invalid"} />
                                                    </span>
                                                </legend>
                                            </fieldset>
                                        </div>
                                        <p id="uidnoteM" className={mailFocus && mail && !validMail ? "instructions" : "offscreen"}>
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                            {t('portlets.register_box.email_val')}
                                        </p>
                                    </div>

                                    <div class="fieldset">
                                        <label htmlFor="phone">
                                            {t('portlets.register_box.phone')}: <i>* </i>
                                            <FontAwesomeIcon icon={faCheck} className={validPhone ? "valid" : "hide"} />
                                            <FontAwesomeIcon icon={faTimes} className={validPhone || !phone ? "hide" : "invalid"} />
                                        </label>
                                        <Tooltip className='fieldset-box' TransitionComponent={Zoom} title={t('LAC.client_data.tooltip_text_2')}>
                                            <div class="fieldset-box">
                                                <input
                                                    type="text"
                                                    id="phone"
                                                    ref={userRef}
                                                    autoComplete="off"
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    value={phone}
                                                    required
                                                    aria-invalid={validPhone ? "false" : "true"}
                                                    aria-describedby="uidnotePhone"
                                                    onFocus={() => setPhoneFocus(true)}
                                                    onBlur={() => setPhoneFocus(false)}
                                                />
                                                <fieldset aria-hidden="true">
                                                    <legend>
                                                        <span>
                                                            {t('portlets.register_box.phone')}: <i>* </i>
                                                            <FontAwesomeIcon icon={faCheck} className={validPhone ? "valid" : "hide"} />
                                                            <FontAwesomeIcon icon={faTimes} className={validPhone || !phone ? "hide" : "invalid"} />
                                                        </span>
                                                    </legend>
                                                </fieldset>
                                            </div>
                                        </Tooltip>
                                        <p id="uidnotePhone" className={phoneFocus && phone && !validPhone ? "instructions" : "offscreen"}>
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                            {t('portlets.register_box.phone_val')}
                                        </p>
                                    </div>
                                    <FormControlLabel
                                        key={'statute'}
                                        className="label-checkbox"
                                        control={
                                            <Checkbox
                                                name={'statute'}
                                                checked={checkedItems['statute'] || false}
                                                onChange={(e) => {handleChange('statute'); setStatute(e.target.checked)}}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                className="not-req-ast"
                                            />
                                        }
                                        label={
                                            <>
                                                <span style={{ color: 'red' }}>* </span>
                                                <span>{t('consents.accept_regulations.0')} </span>
                                                <a
                                                    href={
                                                        params.locale === 'pl'
                                                            ? API['GET_FILE_REGULATIONS']
                                                            : API['GET_FILE_REGULATIONS_EN']
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                        fontWeight: 'bold',
                                                        color: '#0b4185',
                                                    }}
                                                >
                                                    {t('consents.accept_regulations.1')}
                                                </a>
                                                <span> {t('consents.accept_regulations.2')} </span>
                                            </>
                                        }
                                    />
                                    <FormControlLabel
                                        key={'iod'}
                                        className="label-checkbox"
                                        control={
                                            <Checkbox
                                                name={'iod'}
                                                checked={checkedItems['iod'] || false}
                                                onChange={(e) => {handleChange('iod'); setIod(e.target.checked)}}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                className="req-ast"
                                            />
                                        }
                                        label={
                                            <div>
                                                <p>
                                                    {t('consents.information_clause_header')}
                                                </p>
                                                <Typography>
                                                    {t('consents.information_clause.0')}
                                                    <br />
                                                    {t('consents.information_clause.1')}
                                                </Typography>
                                                {showAllConsentInformationClause && (
                                                    <Typography>
                                                        {t('consents.information_clause.2')}
                                                        <br />
                                                        {t('consents.information_clause.3')}
                                                        <br />
                                                        {t('consents.information_clause.4')}
                                                        <br />
                                                        {t('consents.information_clause.5')}
                                                        <br />
                                                        {t('consents.information_clause.6')}
                                                    </Typography>
                                                )}
                                                {!showAllConsentInformationClause && <Box>...</Box>}
                                            </div>
                                        }
                                    />
                                    
                                    <Button
                                        sx={{ padding: 0, fontWeight: 'bold' }}
                                        onClick={() =>
                                            setShowAllConsentInformationClause(
                                                !showAllConsentInformationClause
                                            )
                                        }
                                    >
                                        {showAllConsentInformationClause
                                            ? t('btn.collapse')
                                            : t('btn.expand')}
                                    </Button>
                                    <FormControlLabel
                                        key={'newsletter'}
                                        className="label-checkbox"
                                        control={
                                            <Checkbox
                                                name={'newsletter'}
                                                checked={checkedItems['newsletter'] || false}
                                                onChange={(e) => {
                                                    handleChange('newsletter');
                                                    setNewsletter(e.target.checked)
                                                    if (e.target.checked) {
                                                        if (!onemail) {
                                                            handleChange('onemail');
                                                            setOnEmail(true)
                                                        }
                                                        if (!onphone) {
                                                            handleChange('onphone');
                                                            setOnPhone(true)
                                                        }
                                                    }
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                className="not-req-ast"
                                            />
                                        }
                                        label={t('consents.marketing')}
                                    />
                                    {newsletter && 
                                        <>
                                            <FormControlLabel
                                                key={'on_email'}
                                                className="label-checkbox"
                                                control={
                                                    <Checkbox
                                                        name={'on_email'}
                                                        checked={checkedItems['onemail'] || false}
                                                        onChange={(e) => {handleChange('onemail'); setOnEmail(e.target.checked)}}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        className="not-req-ast"
                                                    />
                                                }
                                                label={<div dangerouslySetInnerHTML={{__html: ' '+t('portlets.register_box.on_email')}} />}
                                            />
                                            <FormControlLabel
                                                key={'on_phone'}
                                                className="label-checkbox"
                                                control={
                                                    <Checkbox
                                                        name={'on_phone'}
                                                        checked={checkedItems['onphone'] || false}
                                                        onChange={(e) => {handleChange('onphone'); setOnPhone(e.target.checked)}}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        className="not-req-ast"
                                                    />
                                                }
                                                label={<div dangerouslySetInnerHTML={{__html: ' '+t('portlets.register_box.on_phone')}} />}
                                            />
                                        </>
                                    }
                                    {/*<div class="fieldset">
                                        <label htmlFor="password">
                                            Password:
                                            <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                                            <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                                        </label>
                                        <div class="fieldset-box">
                                            <input
                                                type="password"
                                                id="password"
                                                onChange={(e) => setPwd(e.target.value)}
                                                value={pwd}
                                                required
                                                aria-invalid={validPwd ? "false" : "true"}
                                                aria-describedby="pwdnote"
                                                onFocus={() => setPwdFocus(true)}
                                                onBlur={() => setPwdFocus(false)}
                                            />
                                            <fieldset aria-hidden="true">
                                                <legend>
                                                    <span>
                                                        Password:
                                                        <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                                                        <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                                                    </span>
                                                </legend>
                                            </fieldset>
                                        </div>
                                        <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                            8 to 24 characters.<br />
                                            Must include uppercase and lowercase letters, a number and a special character.<br />
                                            Allowed special characters: 
                                            <span aria-label="exclamation mark">!</span>
                                             <span aria-label="at symbol">@</span> 
                                             <span aria-label="hashtag">#</span>
                                              <span aria-label="dollar sign">$</span> 
                                              <span aria-label="percent">%</span>
                                        </p>
                                    </div>
                                    <div class="fieldset">
                                        <label htmlFor="confirm_pwd">
                                            Confirm Password:
                                            <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                                            <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                                        </label>
                                        <div class="fieldset-box">
                                            <input
                                                type="password"
                                                id="confirm_pwd"
                                                onChange={(e) => setMatchPwd(e.target.value)}
                                                value={matchPwd}
                                                required
                                                aria-invalid={validMatch ? "false" : "true"}
                                                aria-describedby="confirmnote"
                                                onFocus={() => setMatchFocus(true)}
                                                onBlur={() => setMatchFocus(false)}
                                            />
                                            <fieldset aria-hidden="true">
                                                <legend>
                                                    <span>
                                                        Confirm Password:
                                                        <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                                                        <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                                                    </span>
                                                </legend>
                                            </fieldset>
                                        </div>
                                        <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                            Must match the first password input field.
                                        </p>
                                    </div>*/}
                                    <button className={'btn-link b-' +LinkType} 
                                            disabled={!validNameS || !validName || !validMail || !validPhone || !statute || !iod}
                                    >
                                        {ButtonName} 
                                    </button>
                                </form>
                            </div>
                        )}
                    </>
                </div>
            </div>
        </section>
        </GoogleReCaptchaProvider>
    )
}

export default RegistrationForm
