import React from 'react'
import { Link } from 'react-router-dom'
import parse, { domToReact } from 'html-react-parser'
import { environment } from './../environment'

function TextLinks(params) {
    const options = {
        replace: ({ name, attribs, children }) => {
            if (name === 'a' && attribs.href) {
                if (
                    params.line === 'offline' ||
                    attribs.href.includes('http://') ||
                    attribs.href.includes('https://')
                ) {
                    let url = attribs.href
                    let target = attribs.target
                    if (url.includes('offline-')) {
                        url = url.split(environment.siteSites)
                        url = url[1]
                        return (
                            <a href={url} target={target} className={attribs.class}>
                                {domToReact(children)}
                            </a>
                        )
                    } else {
                        return (
                            <a href={attribs.href} target={target} className={attribs.class}>
                                {domToReact(children)}
                            </a>
                        )
                    }
                } else {
                    let url = attribs.href
                    if (
                        attribs.href.includes('/dokumenty/') ||
                        attribs.href.includes('/en/documents/') ||
                        attribs.href.includes('/pdf/') ||
                        attribs.href.includes('.pdf') ||
                        attribs.href.includes('.png') ||
                        attribs.href.includes('.webp') ||
                        attribs.href.includes('.jpg')
                    ) {
                        if (url.includes('offline-express')) {
                            url = url.split('/sites/express')
                            url = url[1]
                            return (
                                <a href={url} target="_blank" rel="noreferrer" className={attribs.class}>
                                    {domToReact(children)}
                                </a>
                            )
                        }
                        else if (url.includes('/sites/express')) {
                            url = url.split('/export/sites/express')
                            url = url[1]
                            return (
                                <a href={url} target="_blank" rel="noreferrer" className={attribs.class}>
                                    {domToReact(children)}
                                </a>
                            )
                        } else {
                            return (
                                <a href={attribs.href} target="_blank" className={attribs.class}>
                                    {domToReact(children)}
                                </a>
                            )
                        }
                    } else if (attribs.href.includes('mailto:')) {
                        if (url.includes(environment.siteSites)) {
                            url = url.split(environment.siteSites)
                            url = url[1]
                            return (
                                <a href={url} className={attribs.class}>
                                    {domToReact(children)}
                                </a>
                            )
                        } else {
                            return (
                                <a href={attribs.href} className={attribs.class}>
                                    {domToReact(children)}
                                </a>
                            )
                        }
                    } else {
                        if (url.includes('offline-')) {
                            url = url.split(environment.siteSites)
                            url = url[1]
                            let target = attribs.target
                            if (target && target == '_blank') {
                                return (
                                    <a href={url} target="_blank" className={attribs.class}>
                                        {domToReact(children)}
                                    </a>
                                )
                            } else {
                                return (
                                    <Link to={url} className={attribs.class}>
                                        {domToReact(children)}
                                    </Link>
                                )
                            }
                        } else {
                            let target = attribs.target
                            if (target && target == '_blank') {
                                return (
                                    <a href={attribs.href} target="_blank" className={attribs.class}>
                                        {domToReact(children)}
                                    </a>
                                )
                            } else {
                                return (
                                    <Link to={attribs.href} className={attribs.class}>
                                        {domToReact(children)}
                                    </Link>
                                )
                            }
                        }
                    }
                }
            }
            if (name === 'img' && attribs.src) {
                // change string attribs.style with dashes into object with camelcase
                let imageInlineStyles = attribs.style
                    ? attribs?.style
                          .replace(/-([a-z])/g, function (m, w) {
                              return w.toUpperCase()
                          })
                          .split(';')
                          .filter(item => !item.includes('width'))
                          .map(x => x.split(':').map(y => y.trim()))
                          .reduce((a, x) => {
                              a[x[0]] = x[1]
                              return a
                          }, {})
                    : { '': '' }

                let image = attribs.src
                if (image.includes(environment.siteSites) && image !== '') {
                    image = image.split(environment.siteSites)
                    image = image[1]
                }
                if (
                    window.location.origin.includes(environment.localhost) &&
                    !(image.includes('http://') || image.includes('https://'))
                ) {
                    image = environment.siteUrlDev + image
                }
                if (image.includes('/export/sites/express')) {
                    image = image.split('/export/sites/express')
                    image = image[1]
                }
                if (
                    window.location.origin.includes('cms-') &&
                    !(image.includes('http://') || image.includes('https://'))
                ) {
                    image = window.location.origin + image
                }
                return (
                    <img
                        src={image}
                        className={attribs.class}
                        style={imageInlineStyles}
                        loading="lazy"
                        alt={attribs.alt}
                    />
                )
            }
        },
    }

    return parse(params.text, options)
}

export default TextLinks
