import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconWallet() {
    return (
        <SvgIcon>
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.5 0H14.5C14.75 0 15 0.25 15 0.5C15 0.78125 14.75 1 14.5 1H2.5C1.65625 1 1 1.6875 1 2.5V11.5C1 12.3438 1.65625 13 2.5 13H13.5C14.3125 13 15 12.3438 15 11.5V4.5C15 3.6875 14.3125 3 13.5 3H3.5C3.21875 3 3 2.78125 3 2.5C3 2.25 3.21875 2 3.5 2H13.5C14.875 2 16 3.125 16 4.5V11.5C16 12.9062 14.875 14 13.5 14H2.5C1.09375 14 0 12.9062 0 11.5V2.5C0 1.125 1.09375 0 2.5 0ZM12 8.75C11.5625 8.75 11.25 8.4375 11.25 8C11.25 7.59375 11.5625 7.25 12 7.25C12.4062 7.25 12.75 7.59375 12.75 8C12.75 8.4375 12.4062 8.75 12 8.75Z"
                    fill="#202020"
                />
            </svg>
        </SvgIcon>
    )
}
