import React from 'react'
import Links from '../../components/Links.js'
import { environment } from '../../environment.js'

function FooterPickupsItem(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('FooterPickups Item')
        console.log(params)
    }
    return (
        <div className='pickup-wrapper'>
            {params.block &&
                Object.keys(params.block).map((item, i) => (
                    <div 
                        className='pickup-box'
                        key={'FooterPickups-' + item}
                    >   
                        {(params.block[item]['Link'] && params.block[item]['Name']) && <Links
                            class={'link c-color1'}
                            url={params.block[item]['Link']}
                            target={params.block[item]['InNewOpen']}
                            name={params.block[item]['Name']}
                            line={params.line}
                        />}
                    </div>
                ))
            }
        </div>
    )
}

export default FooterPickupsItem
