import React from 'react'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import ParkOutlinedIcon from '@mui/icons-material/ParkOutlined'
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined'
import AddRoadOutlinedIcon from '@mui/icons-material/AddRoadOutlined'
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined'
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';

export default function TagComp({item}) {
  const locale = window.location.href.includes('/en/') ? 'en' : 'pl'
  let setTagName = [];
  let choosen = [];
  const tagsName = {
    "pl": {
      "1": "Kilka sztuk",
      "2": "Oferta dnia",
      "3": "Nowość w ofercie",
      "4": "Autoabonament",
      "5": "Pay Per Mile",
      "6": "Clik&Drive",
      "7": "Ciężarowe",
      "8": "Elektryczne",
      "9": "Promocja",
    },
    "en": {
        "1": "A few cars",
        "2": "Offer of the day",
        "3": "New in the offer",
        "4": "Auto subscription",
        "5": "Pay Per Mile",
        "6": "Clik&Drive",
        "7": "Trucks",
        "8": "Electric",
        "9": "Special offer",
      }
};

  const tags = getTags(item)
    //get list of choosen id if so
    let resultItemsId = [];

    if (choosen.length > 0) {
        choosen.map((item) => {
            resultItemsId.push(item['ID'])
            setTagName.push(item['Tag'])
            return null
        })
    }
    resultItemsId = [...new Set(resultItemsId)];

    function getTagId(name) {
        return Object.keys(tagsName[locale]).filter(tag => tagsName[locale][tag] === name).toString();
    }
    function getTagName(id) {
        return tagsName[locale][id]
    }
    function getTags(item) {
        let id = '';
        let name = '';
        if (setTagName.length > 0) {
            if (item.hasOwnProperty('showTag') && item.showTag !== '') {
                id = getTagId(item.showTag)
                name = item.showTag
            }
        }
        else {
            if (item.tagIds.length > 0 ) {
                id = item.tagIds[0]
                name = getTagName(item.tagIds[0])
            }
            else {
                id = 0
                name = ''
            }
        }
       
        return [id,name,getColorTag(id)]
    }
    function getColorTag(tagId) {
        if (tagId === 8) return '7';
        return [4,2,3,1][Number(tagId) % 4];
    }

    function Tag() {
        if (tags && tags[0] !== '' && tags[1] !== '') {
            return (
                <span className={'super-item b-color' + tags[2]}>
                    <IconTag />
                    {tags[1]}
                </span>
            )
        } else {
            return ''
        }
    }
    function IconTag() {
        switch (tags[0]) {
            case 1:
                return <ErrorOutlineOutlinedIcon />
            case 2:
                return <LocalOfferOutlinedIcon />
            case 3:
                return <LocalOfferOutlinedIcon />
            case 4:
                return <CardMembershipOutlinedIcon />
            case 5:
                return <AddRoadOutlinedIcon />
            case 6:
                return <AdsClickOutlinedIcon />
            case 7:
                return <LocalShippingIcon />
            case 8:
                return <ElectricCarIcon />
            default:
                return <LocalOfferOutlinedIcon />
        }
    }


  return (
    <Tag />
  )
}
