import React from 'react'
import { environment } from '../../environment'
import PopupContactForm from '../../components/PopupContactForm'
import TextLinks from '../../components/TextLinks'
import { t } from 'i18next'
import '../../styles/contact-form.css'

export default function ContactForm(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('ContactForm')
        console.log(params)
    }

    return (
        <section className={'contact-form'}>
            <div className="wrapper">
                <div className={'container mx-auto px-5 md:px-8 my-20'}>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                        {params.block[params.locale].Text ? (
                            <div>
                                {params.block[params.locale].Title ? (
                                    <h3 className="opacity-50 text-left">
                                        {params.block[params.locale].Title}
                                    </h3>
                                ) : null}
                                <div className="ul-custom text-box-div">
                                    <TextLinks text={params.block[params.locale].Text} line={params.line} />
                                </div>
                            </div>
                        ) : null}
                        <div className='border-2 p-6 md:p-12 rounded-2xl'>
                            <PopupContactForm
                                dialogTitle={params?.block?.[params.locale]?.FormTitle ? params.block[params.locale].FormTitle : t("LAC.popup_contact.title")}
                                idOffer="2069"
                                noPopup={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
