import React from 'react'
import Image from '../../components/Image'
import Links from '../../components/Links'
import '../../styles/carousel3d.css'

const SaleItem = ({ item }) => {
    return (
        <>
            <>
                <div className="overflow-hidden w-full h-full rounded-[20px]">
                    <Image src={item.src} alt={item.alt} />
                </div>
                <div className="text">
                    <h4 className="text-orange-500 text-4xl lg:text-lg xl:text-4xl font-bold mb-5">{item.headline}</h4>
                    <h5 className="text-white text-xl lg:text-lg xl:text-xl font-regular">{item.subheading}</h5>
                </div>
            </>
            <Links
                class="btn-link absolute bottom-[-35px] left-[40px] z-10"
                url={item.link}
                target={item.inNewOpen}
                name={item.linkName}
                line={item.line}
            />
        </>
    )
}

export default SaleItem
