const groupOpeningHours = openingHours => {
    const groupedHours = {}

    openingHours.forEach(hour => {
        const key = `${hour.timeFrom}-${hour.timeTo}`

        if (groupedHours[key]) {
            groupedHours[key].push(hour.day)
        } else {
            groupedHours[key] = [hour.day]
        }
    })

    return Object.entries(groupedHours).map(([timeRange, days]) => {
        const [timeFrom, timeTo] = timeRange.split('-')
        return {
            timeFrom,
            timeTo,
            days,
        }
    })
}

export default groupOpeningHours
