export const largeBagsOptions = [
    {
        label: '1 duża walizka',
        value: 1,
    },
    {
        label: '2 duże walizki',
        value: 2,
    },
    {
        label: '3 duże walizki',
        value: 3,
    },
    {
        label: '4 duże walizki',
        value: 4,
    },
    {
        label: '5 dużych walizek',
        value: 5,
    },
    {
        label: '6 dużych walizek',
        value: 6,
    },
    {
        label: '7 dużych walizek',
        value: 7,
    },
    {
        label: '8 dużych walizek',
        value: 8,
    },
]

export const largeBagsOptionsEn = [
    {
        label: '1 large',
        value: 1,
    },
    {
        label: '2 large',
        value: 2,
    },
    {
        label: '3 large',
        value: 3,
    },
    {
        label: '4 large',
        value: 4,
    },
    {
        label: '5 large',
        value: 5,
    },
    {
        label: '6 large',
        value: 6,
    },
    {
        label: '7 large',
        value: 7,
    },
    {
        label: '8 large',
        value: 8,
    },
]
