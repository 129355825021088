import React from 'react'
import { ErrorMessage, Field, useField } from 'formik'
import DatePicker, { registerLocale } from 'react-datepicker'
import pl from 'date-fns/locale/pl'
import en from 'date-fns/locale/en-GB'

import 'react-datepicker/dist/react-datepicker.css'

const Datepicker = ({ name, locale = 'pl', ...props }) => {
    const [field, meta, helpers] = useField(name)

    const { value } = meta
    const { setValue } = helpers

    registerLocale('pl', pl)
    registerLocale('en', en)

    return (
        <>
            <DatePicker
                {...field}
                dateFormat="dd.MM.yyyy"
                selected={value}
                onChange={date => setValue(date)}
                locale={locale}
                popperProps={{ strategy: 'fixed' }}
                {...props}
            />
            <ErrorMessage name={name} component="div" className="error" />
        </>
    )
}
export default Datepicker
