import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, List, ListItem, Typography } from '@mui/material'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import LinkText from '../../components/LinkText'
import MapComponent from './MapComponent'
import { environment } from '../../../environment'
import groupOpeningHours from '../../hooks/useGroupedOpeningHours'
import { DialogContext } from './SearchField'

const LocationDetails = ({ place }) => {
    const { t } = useTranslation()
    const { locale, setAutoHeight } = useContext(DialogContext)

    const [groupedOpeningHours, setGroupedOpeningHours] = useState(null)

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('PLACE ', place)

        const openingHours = groupOpeningHours(place.openingHours)

        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('openingHours ', openingHours)

        setGroupedOpeningHours(openingHours[0])

        setAutoHeight(true)
    }, [place])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('groupedOpeningHours ', groupedOpeningHours)
    }, [groupedOpeningHours])
   
    return (
        <Box>
            {place?.gpsCoords !== null && (
                <Box
                    sx={{
                        width: { xs: `calc(100% + 48px)`, lg: `calc(100% + 80px)` },
                        height: '400px',
                        mx: {
                            xs: -3,
                            lg: -5,
                        },
                        mt: -5,
                        mb: 1
                    }}
                >
                    <MapComponent gpsCoords={place?.gpsCoords}/>
                    <a
                        href="https://mapa.targeo.pl/"
                        id="TargeoMapContainer"
                        style={{ position: 'relative', width: '100%', height: '400px' }}
                    >
                        Targeo
                    </a>
                </Box>
            )}

            <Box>
                {place?.priceIOH !== null && (
                    <Box mt={3} mb={2}>
                        <Typography fontWeight="bold" my={2}>
                            {t('RAC.step_one.price')}
                        </Typography>

                        <Box display={'flex'} alignItems="center" gap={2} mt={1}>
                            <Box width="80px">
                                <Typography fontWeight="bold">{place.priceIOH} zł</Typography>
                            </Box>
                            <Box>
                                {groupedOpeningHours && groupedOpeningHours.days.length > 0 ? (
                                    <>
                                        <Typography>
                                            {t('RAC.step_one.days_all.0')} -{' '}
                                            {groupedOpeningHours.days.length === 1 && t('RAC.step_one.days_all.0')}
                                            {groupedOpeningHours.days.length === 2 && t('RAC.step_one.days_all.1')}
                                            {groupedOpeningHours.days.length === 3 && t('RAC.step_one.days_all.2')}
                                            {groupedOpeningHours.days.length === 4 && t('RAC.step_one.days_all.3')}
                                            {groupedOpeningHours.days.length === 5 && t('RAC.step_one.days_all.4')}
                                            {groupedOpeningHours.days.length === 6 && t('RAC.step_one.days_all.5')}
                                            {groupedOpeningHours.days.length === 7 && t('RAC.step_one.days_all.6')}
                                        </Typography>
                                        <Typography>
                                            {groupedOpeningHours.timeFrom.substring(0, 5)} -
                                            {groupedOpeningHours.timeTo.substring(0, 5)}
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography>{place.openingHours.map(el => el.day)}</Typography>
                                )}
                            </Box>
                        </Box>
                        <Divider />

                        <Box display={'flex'} alignItems="center" gap={2} my={1}>
                            <Box width="80px">
                                <Typography fontWeight="bold">{place?.priceOOH || 0} zł</Typography>
                            </Box>
                            <Box>
                                <Typography>{t('RAC.step_one.ooh')}</Typography>
                            </Box>
                        </Box>
                        <Divider />
                    </Box>
                )}

                {(place?.phoneNumber || place?.email) && (
                    <>
                        <Typography fontWeight="bold">{t('RAC.step_one.contact')}</Typography>
                        <List>
                            {place?.phoneNumber && (
                                <ListItem sx={{ px: 0 }}>
                                    <PhoneOutlinedIcon color="primary" sx={{ mr: 1 }} />
                                    <LinkText href={`tel:${place?.phoneNumber}`}>{place?.phoneNumber}</LinkText>
                                </ListItem>
                            )}
                            {place?.email && (
                                <ListItem sx={{ px: 0 }}>
                                    <EmailOutlinedIcon color="primary" sx={{ mr: 1 }} />
                                    <LinkText href={`mailto:${place?.email}`}>{place?.email}</LinkText>
                                </ListItem>
                            )}
                        </List>
                    </>
                )}

                {place?.description && (
                    <>
                        <Divider />
                        <Typography fontWeight="bold" mt={2} mb={1}>
                            {t('RAC.step_one.comments')}
                        </Typography>
                        <Typography whiteSpace="pre-wrap">
                            {locale === 'pl'
                                ? place?.description.replace(/;/g, '\n\n')
                                : place?.descriptionEn.replace(/;/g, '\n\n')}
                        </Typography>
                    </>
                )}
            </Box>
        </Box>
    )
}
export default LocationDetails
