import React, { useState } from 'react'
import useApiData from '../hooks/useApiData'
import { API } from '../../../forms/LAC/api'
import { Box, Container } from '@mui/material'
import Spinner from '../../../forms/components/Spinner'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useTranslation } from 'react-i18next'

export default function DetailsTabs({ equipment }) {
    const [selectedTab, setSelectedTab] = useState('wyposazenie')
    const { data, loading, error } = useApiData(`${API.GET_EQUIPMENT}`)
    const locale = window.location.href.includes('/en/') ? 'en' : 'pl'
    const { t } = useTranslation()

    if (loading) {
        return (
            <Container>
                <Spinner />
            </Container>
        )
    }

    if (error) {
        return (
            <Container>
                <Box my={4}>Błąd</Box>
            </Container>
        )
    }

    return (
        <>
            {equipment?.length > 0 && data?.['pl'] && ( // TODO chwilowa zmiana - PL NA STAŁE
                <div className="mt-10">
                    <div className="flex flex-wrap flex-shrink-0 gap-4">
                        <button
                            className={`px-4 py-2 transition border rounded-[50px] font-semibold h-full cursor-pointer flex items-center justify-center text-[#0b4185] border-[#0b4185] ${
                                selectedTab === 'wyposazenie'
                                    ? 'text-orange-500 border-orange'
                                    : 'text-[#0b4185] border-[#0b4185]'
                            }`}
                            onClick={() => setSelectedTab('wyposazenie')}
                        >
                            {t('LAC.offer_list.details_tabs.equipment_pill')}
                        </button>
                        {/* <button
                    className={`px-4 py-2 transition border rounded-[50px] font-semibold h-full cursor-pointer flex items-center justify-center text-[#0b4185] border-[#0b4185] ${
                        selectedTab === 'bezpieczenstwo'
                        ? 'text-orange-500 border-orange'
                        : 'text-[#0b4185] border-[#0b4185]'
                    }`}
                    onClick={() => setSelectedTab('bezpieczenstwo')}
                    >
                    Bezpieczeństwo
                    </button>
                    <button
                    className={`px-4 py-2 transition border rounded-[50px] font-semibold h-full cursor-pointer flex items-center justify-center text-[#0b4185] border-[#0b4185] ${
                        selectedTab === 'technologia' ? 'text-orange-500 border-orange' : 'text-[#0b4185] border-[#0b4185]'
                    }`}
                    onClick={() => setSelectedTab('technologia')}
                    >
                    technologia
                    </button>
                    <button
                    className={`px-4 py-2 transition border rounded-[50px] font-semibold h-full cursor-pointer flex items-center justify-center text-[#0b4185] border-[#0b4185] ${
                        selectedTab === 'pozostale' ? 'text-orange-500 border-orange' : 'text-[#0b4185] border-[#0b4185]'
                    }`}
                    onClick={() => setSelectedTab('pozostale')}
                    >
                    Pozostałe cechy
                </button> */}
                    </div>

                    <div className="mt-10">
                        {selectedTab === 'wyposazenie' && data?.['pl'] && ( // TODO chwilowa zmiana - PL NA STAŁE
                            <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
                                {equipment?.map((id, index) => (
                                    <div className="text-[#0b4185] flex" key={index}>
                                        <KeyboardArrowRightIcon />
                                         <span className="text-[#919191] ml-2">{data?.['pl'][id]}</span> {/* TODO chwilowa zmiana - PL NA STAŁE */}
                                    </div>
                                ))}
                            </div>
                        )}
                        {/* {selectedTab === 'bezpieczenstwo' && <div>Bezpieczeństwo content</div>}
                {selectedTab === 'technologia' && <div>Technologia content</div>}
            {selectedTab === 'pozostale' && <div>Pozostałe cechy content</div>} */}
                    </div>
                </div>
            )}
        </>
    )
}
