import React from 'react'

const ColorBox = ({ className, width, height, style }) => {
    return (
        <div
            className={className}
            style={{
                width: width,
                height: height,
                backgroundColor: '#fece22',
                ...style,
            }}
        />
    )
}

export default ColorBox
