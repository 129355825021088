import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import BackButton from '../../components/BackButton'

const DialogPricing = ({ handleClose, open, children }) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open} 
            onClose={handleClose}
            maxWidth="100%"
        >
            <DialogContent sx={{ p: 0 }}>
                <Box pb={2} sx={{ width: '100%' }}>
                    <BackButton closeIcon handleClick={handleClose} sx={{ marginRight: '20px' }} />
                    <Box py={2} px={{ xs: 2, lg: 4 }}>
                        {children}
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default DialogPricing
