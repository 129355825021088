import React from 'react'
import Link from '@mui/material/Link'
import theme from '../../styles/MUI-theme'

const LinkText = ({ children, href, props }) => {
    return (
        <Link href={href} underline="hover" sx={{ color: theme.palette.primary.main, ...props }}>
            {children}
        </Link>
    )
}

export default LinkText
