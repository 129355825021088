import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share';
import theme from '../../../styles/MUI-theme'

const getMailtoLink = (subject, body) => {
 
    const encodedSubject = encodeURIComponent(subject)

    const signature = 'Express Car Rental'

    const encodedBody = encodeURIComponent(`${body}\n\n${signature}`)

    return `mailto:?subject=${encodedSubject}&body=${encodedBody}`
}

const ShareButton = ({ subject, body }) => {
    const { t } = useTranslation()

    const mailtoLink = getMailtoLink(subject, body)

    return (
        <Button variant="text" href={mailtoLink} sx={{ color: theme => theme.palette.grey[50], textDecoration: 'none', fontWeight: 'bold' }} startIcon={<ShareIcon sx={{ color: theme => theme.palette.grey[50] }}/>}>

            {t('LAC.offer_list.offers_list_detail.download_button')}
        </Button>
    )
}

export default ShareButton
