import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import { environment } from '../../../environment'
import Spinner from '../../components/Spinner'
import ErrorBox from '../ErrorBox'
import useFetch from '../../hooks/useFetch'
import { API, FORM_KEY } from '../../api'
import ChooseExtras from './ChooseExtras'

function isEmpty(obj) {
    return Object.keys(obj).length === 0
}

const transformDataToInitialValues = data => {
    const extras_equipment_count = parseInt(data?.extras_equipment_count, 10)
    const extras_options_count = parseInt(data?.extras_options_count, 10)

    let equipment = []
    let options = []

    for (let i = 1; i <= extras_equipment_count; i++) {
        const equipmentId = data ? parseInt(data[`extras_equipment_${i}`]) : 0
        const count = data ? parseInt(data[`extras_equipment_quantity_${i}`], 10) : 0
        equipment.push({ id: equipmentId, name: "", count: count })
    }

    for (let i = 1; i <= extras_options_count; i++) {
        const optionId = data ? parseInt(data[`extras_options_${i}`]) : 0
        const count = data ? parseInt(data[`extras_options_quantity_${i}`], 10) : 0
        options.push({ id: optionId, name: "", count: count })
    }

    return {
        equipment,
        options,
        offer_selected_package_id: data?.offer_selected_package === 'brown' ? 1 : 2,
    }
}

const GetProcessData = ({ extras }) => {
    const { id } = useParams()

    const { data, loading, error, fetchData } = useFetch()

    const [initialValues, setinitialValues] = useState([])

    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        const url = `${API[`GET_DATA`]}?pid=${id}`
        fetchData(url, 'GET')
    }, [id])

    useEffect(() => {
        data && data?.variables && setinitialValues(transformDataToInitialValues(data?.variables))
    }, [data])

    useEffect(() => {

        !isEmpty(initialValues) && setIsReady(true)
    }, [initialValues])

    return !loading && isReady ? (
        error ? (
            <ErrorBox error={error} />
        ) : (
            <ChooseExtras extras={extras} processData={data?.variables} initialValues={initialValues} />
        )
    ) : (
        <Spinner />
    )
}

export default GetProcessData
