import React from 'react'
import { environment } from './../../environment'
import Links from '../../components/Links'
import Image from '../../components/Image'
import TextLinks from '../../components/TextLinks'

const TextImage = params => {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Text Image')
        console.log(params)
    }
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window
        let device = 'desktop'
        if (width < 1024) device = 'tablet'
        if (width < 768) device = 'mobile'
        return {
            width,
            height,
            device,
        }
    }
    const { device, width } = getWindowDimensions()

    let Images = params.block['en'].hasOwnProperty('Image')
            ? params.block['en']['Image'].hasOwnProperty('link')
                ? params.block['en']['Image']['link']
                : environment.defaultImage
            : environment.defaultImage,
        Headline = params.block['en'].hasOwnProperty('Headline') ? params.block['en']['Headline'] : '',
        Title = params.block['en'].hasOwnProperty('Title') ? params.block['en']['Title'] : '',
        Text = params.block['en'].hasOwnProperty('Text') ? params.block['en']['Text'] : '',
        ImageSide = params.block['en'].hasOwnProperty('ImageSide') ? params.block['en']['ImageSide'] : 'f-row',
        Choice = params.block['en'].hasOwnProperty('Choice') ? params.block['en']['Choice'] : 'empty',
        ChoiceIs =
            Choice === 'empty'
                ? 'empty'
                : Choice.hasOwnProperty('Button')
                  ? 'button'
                  : Choice.hasOwnProperty('Link')
                    ? 'link'
                    : 'empty',
        ButtonLink =
            Choice === 'empty'
                ? ''
                : Choice.hasOwnProperty('Button')
                  ? Choice['Button'].hasOwnProperty('ButtonLink')
                      ? Choice['Button']['ButtonLink']
                      : ''
                  : '',
        ButtonName =
            Choice === 'empty'
                ? ''
                : Choice.hasOwnProperty('Button')
                  ? Choice['Button'].hasOwnProperty('ButtonName')
                      ? Choice['Button']['ButtonName']
                      : ''
                  : '',
        ButtonPosition =
            Choice === 'empty'
                ? 'left'
                : Choice.hasOwnProperty('Button')
                  ? Choice['Button'].hasOwnProperty('ButtonPosition')
                      ? Choice['Button']['ButtonPosition']
                      : 'left'
                  : 'left',
        ButtonType =
            Choice === 'empty'
                ? 'primary'
                : Choice.hasOwnProperty('Button')
                  ? Choice['Button'].hasOwnProperty('ButtonType')
                      ? Choice['Button']['ButtonType']
                      : 'primary'
                  : 'primary',
        ButtonNewOpen =
            Choice === 'empty'
                ? 'false'
                : Choice.hasOwnProperty('Button')
                  ? Choice['Button']['NewTab']
                      ? 'true'
                      : 'false'
                  : 'false',
        Link =
            Choice === 'empty'
                ? ''
                : Choice.hasOwnProperty('Link')
                  ? Choice['Link'].hasOwnProperty('Link')
                      ? Choice['Link']['Link']
                      : ''
                  : '',
        LinkName =
            Choice === 'empty'
                ? ''
                : Choice.hasOwnProperty('Link')
                  ? Choice['Link'].hasOwnProperty('LinkName')
                      ? Choice['Link']['LinkName']
                      : ''
                  : '',
        LinkPosition =
            Choice === 'empty'
                ? 'left'
                : Choice.hasOwnProperty('Link')
                  ? Choice['Link'].hasOwnProperty('LinkPosition')
                      ? Choice['Link']['LinkPosition']
                      : 'left'
                  : 'left',
        LinkNewOpen =
            Choice === 'empty'
                ? 'false'
                : Choice.hasOwnProperty('Link')
                  ? Choice['Link']['NewTab']
                      ? 'true'
                      : 'false'
                  : 'false'

    return (
        <section aria-label="section" className="text-image">
            <div className="wrapper">
                <div className={'container d-flex ai-center ' + ImageSide}>
                    <div className="image-box">
                        <Image
                            src={Images}
                            alt={Title ? Title : 'Express'}
                            width={device === 'mobile' ? width : '700'}
                        />
                    </div>
                    <div className="content-box">
                        {Headline && (
                            <div className="headline-box d-flex ai-center">
                                <span className="line b-color1 mr-15"></span>
                                <h5>{Headline}</h5>
                            </div>
                        )}
                        {Title && (
                            <div className="title-box">
                                <h2>{Title}</h2>
                            </div>
                        )}
                        {Text && (
                            <div className="text">
                                <TextLinks text={Text} line={params.line} />
                            </div>
                        )}
                        <div className="bottom-box">
                            {ChoiceIs === 'button' && (
                                <div class={'button-box w-100 ta-' + ButtonPosition}>
                                    <Links
                                        line={params.line}
                                        url={ButtonLink}
                                        class={'button ' + ButtonType}
                                        name={ButtonName}
                                        target={ButtonNewOpen}
                                    />
                                </div>
                            )}
                            {ChoiceIs === 'link' && (
                                <div class={'link-box w-100 ta-' + LinkPosition}>
                                    <Links
                                        line={params.line}
                                        url={Link}
                                        class="link-name"
                                        name={LinkName}
                                        target={LinkNewOpen}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TextImage
