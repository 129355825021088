import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { environment } from './../../environment'
import TextLinks from '../../components/TextLinks'
import Image from '../../components/Image'
import Links from '../../components/Links'
import Loaders from '../../Loaders.js'

function NewsTeaser(params) {
    console.log(params)
    let min = params.min ? 'min-' + params.min + 'm' : 'min-falsem'
    let classTeaser = 'news-teaser ' + min
    let currentTimestamp = new Date()

    if (params.block['en']['Date'] && params.block['en']['Date'] !== '')
        currentTimestamp = new Date(params.block['en']['Date'])
    let date = [
        currentTimestamp.getDate(),
        currentTimestamp.getMonth() + 1,
        currentTimestamp.getFullYear().toString().slice(-2),
    ]
    let ImagesB = params.block['en'].hasOwnProperty('ImageBanner')
            ? params.block['en']['ImageBanner'].hasOwnProperty('link')
                ? params.block['en']['ImageBanner']['link']
                : environment.defaultImage
            : environment.defaultImage,
        ImagesT = params.block['en'].hasOwnProperty('ImageTeaser')
            ? params.block['en']['ImageTeaser'].hasOwnProperty('link')
                ? params.block['en']['ImageTeaser']['link']
                : environment.defaultLogo
            : environment.defaultLogo,
        Tag = params.block['en'].hasOwnProperty('Tags') ? params.block['en']['Tags'] : [],
        Title = params.block['en'].hasOwnProperty('Title') ? params.block['en']['Title'] : '',
        Teaser = params.block['en'].hasOwnProperty('Teaser') ? params.block['en']['Teaser'] : ''

    let [catData, setCatData] = useState([])
    useEffect(() => {
        let fetchCats = async () => {
            let url = null
            if (window.location.origin.includes(environment.cmsName)) {
                url = window.location.origin + environment.categoriesJson
            } else if (window.location.origin.includes(environment.localhost)) {
                url = environment.siteUrlDev + environment.categoriesJson
            } else {
                url = window.location.origin + environment.categoriesJson
            }
            let response = ''
            try {
                response = await Axios(url)
            } catch (err) {
                response = 'error'
            }
            if (response.hasOwnProperty('data')) setCatData(response.data)
        }
        fetchCats()
    }, [])
    function Tags() {
        const splitTags = params.block['en'].hasOwnProperty('Tags') ? params.block['en']['Tags'].split(',') : []
        let checkSame = []
        return splitTags.map(tag => {
            let name = catData[tag],
                tagLast = tag.split('/'),
                nameTag = tagLast[tagLast.length - 2]
            let pathOriginList = window.location.pathname.split('item/')[0],
                href = pathOriginList + '#' + nameTag
            if (name === '' || name === undefined) name = nameTag
            if (!checkSame.includes(name)) {
                checkSame.push(name)
                if (params.line === 'online') {
                    return <Links key={href} url={href} class="button tag-type" name={name} />
                } else {
                    return (
                        <a key={href} href={href} className="button tag-type" data-tags={tagLast[tagLast.length - 2]}>
                            {name}
                        </a>
                    )
                }
            }
            return null
        })
    }

    if (params.min) {
        return (
            <div className={classTeaser}>
                <div className="wrapper">
                    <div className="news-item d-flex" title={Title}>
                        {params.block.link && (
                            <Links line={params.line} url={params.block.link} class="all-link" name={Title} />
                        )}
                        <div className="left-box d-flex">
                            <div className="image-box d-flex ai-center">
                                <>
                                    {ImagesT ? '' : <Loaders type="circle" color="b-color3" mode="true" />}
                                    <Image src={ImagesT} width="50" alt={Title} />
                                </>
                            </div>
                        </div>
                        <div className="right-box">
                            {params.block['en']['Date'] && (
                                <div className="date-box">
                                    <p className="c-color1">
                                        {date[0]}/{date[1]}/{date[2]}
                                    </p>
                                </div>
                            )}
                            <div className="text-box">
                                {Title && <h3>{Title}</h3>}
                                {Teaser && (
                                    <div className="text mt-10">
                                        <TextLinks text={Teaser} line={params.line} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={classTeaser}>
                <div className="wrapper">
                    <div className="news-item" title={Title}>
                        {params.block.link && (
                            <Links line={params.line} url={params.block.link} class="all-link" name={Title} />
                        )}
                        <div className="top-box">
                            <div className="image-box">
                                <>
                                    {ImagesB ? '' : <Loaders type="circle" color="b-color3" mode="true" />}
                                    <Image src={ImagesB} width="400" alt={Title} />
                                </>
                            </div>
                        </div>
                        <div className="bottom-box">
                            <div className="info-box d-flex ai-center jc-between">
                                <div className="date-box">
                                    <p className="c-color1">
                                        {date[0]}/{date[1]}/{date[2]}
                                    </p>
                                </div>
                                <div className="icon-box">
                                    <Image src={ImagesT} width="50" alt={Title} />
                                </div>
                            </div>
                            <div className="text-box">
                                {Title && <h3>{Title}</h3>}
                                {Teaser && (
                                    <div className="text mt-10">
                                        <TextLinks text={Teaser} line={params.line} />
                                    </div>
                                )}
                            </div>
                            <div className="button-box d-flex ai-center jc-end mt-10">{Tag && <Tags />}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsTeaser
