import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { List, TextField, InputAdornment, Divider } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { environment } from '../../../environment'
import Spinner from '../../components/Spinner'
import PlaceItem from './PlaceItem'

const replaceDiacritics = (text) => {
    const diacriticsMap = {
      'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 'ó': 'o', 'ś': 's', 'ź': 'z', 'ż': 'z'
    };
  
    return text
      .toLowerCase()  
      .replace(/[ąćęłńóśźż]/g, match => diacriticsMap[match] || match);
  };

const GetPlaces = ({ setClickedPlace, locations, handleCloseDialog }) => {
    const { t } = useTranslation()

    const [data, setData] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [searchResult, setSearchResult] = useState([])

    useEffect(() => {
        setData(locations)
    }, [locations])

    useEffect(() => {
        data.length > 0 && setSearchResult(data)
    }, [data])

    useEffect(() => {
        setSearchResult(data.filter(item => replaceDiacritics(item.name).includes(replaceDiacritics(searchQuery))))
    }, [searchQuery])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('releasePlaces data:', data)
    }, [data])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('releasePlaces searchResult:', searchResult)
    }, [searchResult])

    return (
        <>
            {data.length > 0 ? (
                <>
                    <div className="searchField">
                        <TextField
                            label={t('RAC.step_one.label_search')}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlinedIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={e => setSearchQuery(e.target.value)}
                            sx={{ width: '100%', mb: 1 }}
                        />
                    </div>
                    <List>
                        {searchResult
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .filter(place => place.pickupPriceIOH === 0)
                            .map(place => (
                                <PlaceItem
                                    key={place.id}
                                    place={place}
                                    setClickedPlace={setClickedPlace}
                                    handleCloseDialog={handleCloseDialog}
                                />
                            ))}

                        <Divider />

                        {searchResult
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .filter(place => place.pickupPriceIOH !== 0)
                            .map(place => (
                                <PlaceItem
                                    key={place.id}
                                    place={place}
                                    setClickedPlace={setClickedPlace}
                                    handleCloseDialog={handleCloseDialog}
                                />
                        ))}
                    </List>
                </>
            ) : (
                <Spinner />
            )}
        </>
    )
}

export default GetPlaces
