import React, { useState, useRef } from 'react'
import { environment } from './../../environment'
import Slider from 'react-slick'
import useWindowDimensions from '../../Device'
import TopBannerCarouselItem from './TopBannerCarouselItem'
import Form from '../../forms/RAC/Form'
import FormLAC from '../../forms/LAC/Form'
import '../../styles/slick-custom-banner.css'
import '../../styles/top-banner-carousel.css'
import Image from '../../components/Image'
import Links from '../../components/Links'

function TopBannerCarousel(params) {
    const { width ,device } = useWindowDimensions()

    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('TopBannerCarousell')
        console.log(params)
    }

    const nextArrow = `<svg id="Layer_1" enable-background="new 0 0 512 512" viewBox="0 0 128 128" x="0px" y="0px"xmlns="http://www.w3.org/2000/svg"><path fill="white" id="Right_Arrow_4_" d="m44 108c-1.023 0-2.047-.391-2.828-1.172-1.563-1.563-1.563-4.094 0-5.656l37.172-37.172-37.172-37.172c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l40 40c1.563 1.563 1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"/></svg>`
    const prevArrow = `
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <path fill="white" id="Right_Arrow_4_" d="M336,80c4.1,0,8.2,1.6,11.3,4.7c6.3,6.3,6.3,16.4,0,22.6L198.6,256l148.7,148.7
            c6.3,6.3,6.3,16.4,0,22.6c-6.3,6.2-16.4,6.3-22.6,0l-160-160c-6.3-6.3-6.3-16.4,0-22.6l160-160C327.8,81.6,331.9,80,336,80L336,80z"
            />
        </svg>
    `
    const [currentSilde, setCurrentSilde] = useState(0),
        autoTime = params.block[params.locale]['RotationTime']
            ? parseFloat(params.block[params.locale]['RotationTime'])
            : 5000,
        speedSliderTime = 1000,
        sliderRef = useRef(),
        progresRef = useRef()

    const changeSlideTo = index => {
        progresRef.current.style.animation = 'none'
        sliderRef.current.slickGoTo(index)
    }

    const NextArrow = props => {
        const { className, style, onClick } = props
        return (
            <div
                className={className}
                onClick={onClick}
                style={{
                    ...style,
                    display: 'block',
                    width: '30px',
                    height: '30px',
                }}
                dangerouslySetInnerHTML={{ __html: nextArrow }}
            ></div>
        )
    }

    const PrevArrow = props => {
        const { className, style, onClick } = props
        return (
            <div
                className={className}
                onClick={onClick}
                style={{
                    ...style,
                    display: 'block',
                    width: '30px',
                    height: '30px',
                }}
                dangerouslySetInnerHTML={{ __html: prevArrow }}
            ></div>
        )
    }
    let autoRotate = params.block.hasOwnProperty('AutoRotate') ? params.block['AutoRotate'] : true
    var settings = {
        dots: true,
        infinite: true,
        speed: speedSliderTime,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: autoRotate,
        autoplaySpeed: autoTime,
        pauseOnHover: false,
        vertical: true,
        verticalSwiping: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        afterChange: current => {
            setCurrentSilde(current)
        },
    }

    let colorBack = ''
    if (params.block[params.locale]['FontColor'] !== '' && params.block[params.locale].hasOwnProperty('FontColor')) {
        colorBack = 'b-' + params.block[params.locale]['FontColor']
    }
    let innerSection = 'top-banner-carousel'
    let innerContainer = `container-wide ${params.block[params.locale]?.FormType === 'none' ? '' : ''}`
    if (params.block[params.locale]['InnerBackground'] !== '') {
        innerSection = innerSection + ' ' + colorBack
    }
    let Label = params.block[params.locale].hasOwnProperty('Label') ? params.block[params.locale]['Label'] : '',
        Icon = params.block[params.locale].hasOwnProperty('Icon') ? params.block[params.locale]['Icon'] : '',
        LinkLabel = params.block[params.locale].hasOwnProperty('Link') ? params.block[params.locale]['Link'] : '',
        InNewOpen = params.block[params.locale].hasOwnProperty('InNewOpen')
            ? params.block[params.locale]['InNewOpen'] === true
                ? true
                : false
            : false;
    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    {params.block[params.locale]?.FormType === 'none' ? (
                        <>
                            {params.block[params.locale].hasOwnProperty('Slide') &&
                                Object.keys(params.block[params.locale]['Slide']).map((slider, i) => (
                                    <TopBannerCarouselItem
                                        key={'TopBannerCarouselItem-' + i}
                                        block={params.block[params.locale]['Slide'][slider]}
                                        line={params.line}
                                        device={device}
                                        width={width}
                                        noSlider={true}
                                    />
                                ))}
                        </>
                    ) : (
                        <Slider {...settings} ref={sliderRef}>
                            {params.block[params.locale].hasOwnProperty('Slide') &&
                                Object.keys(params.block[params.locale]['Slide']).map((slider, i) => (
                                    <TopBannerCarouselItem
                                        key={'TopBannerCarouselItem-' + i}
                                        block={params.block[params.locale]['Slide'][slider]}
                                        line={params.line}
                                        device={device}
                                        width={width}
                                    />
                                ))}
                        </Slider>
                    )}
                    {params.block[params.locale]?.FormType === 'lac' && (
                        <div className="container form-box">
                            <div className="form-size">
                                <FormLAC params={params} locale={params.locale} line={params.line} />
                            </div>
                        </div>
                    )}
                    {params.block[params.locale]?.FormType === 'rac' && (
                        <div className="container form-box">
                            <div className="form-size">
                                <Form locale={params.locale} line={params.line} />
                            </div>
                        </div>
                    )}
                    {Label !== '' && (
                        <>
                            <div className='label-box hover-box'>
                                <Image src={Icon} alt={Label} />
                            </div>
                            <div className='label-box over-box d-flex ai-center'>
                                {Label !== '' && LinkLabel !== '' && (
                                    <Links
                                        line={params.line}
                                        url={LinkLabel}
                                        target={InNewOpen}
                                        class={'all-link'}
                                        name={Label}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </section>
    )
}

export default TopBannerCarousel