import React from 'react'
import { Alert } from '@mui/material'

const ErrorBox = ({ error, sx }) => {
    return (
        <Alert severity="error" variant="standard" sx={{ ...sx }}>
            {error}
        </Alert>
    )
}

export default ErrorBox
