import React from 'react'
import { TextField, Box } from '@mui/material'
import { ErrorMessage, Field } from 'formik'

const MuiTextField = ({ ...props }) => (
    <Box mb={props.mb || '20px'} className={props.errors && 'error'}>
        <Field as={TextField} {...props} fullWidth />
        <ErrorMessage name={props.name} component="div" className="error" style={{ paddingLeft: '20px' }} />
    </Box>
)

export default MuiTextField
