import React from 'react'
import { environment } from '../../environment'
import Image from '../../components/Image'

export default function TextImageBox(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('TextImageBox')
        console.log(params)
    }

    return (
        <section className={'text-image-box'}>
            <div className="wrapper">
                <div className={'container mx-auto px-8 my-20'}>
                    {params.block[params.locale]['Title'] && (
                        <h3 className="opacity-50">{params.block[params.locale]['Title']}</h3>
                    )}
                    <div className='mt-12 flex flex-col gap-10'>
                        {params.block[params.locale].Item &&
                            params.block[params.locale].Item.map((item, index) => (
                                <div key={index} className={`flex items-center gap-8 ${index % 2 !== 0 ? 'flex-col lg:flex-row-reverse' : 'flex-col lg:flex-row'}`}>
                                    {item?.Image?.link && <div className='max-w-[500px] basis-1/3'><Image src={item.Image.link} alt={item.Title} /></div>}
                                    <div className='basis-2/3'>
                                        {item.Title && <h3 className='font-semibold c-color3 text-left'>{item.Title}</h3>}
                                        {item.Text && <div className='ul-custom' dangerouslySetInnerHTML={{__html: item.Text}} />}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </section>
    )
}
