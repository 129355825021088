import { Checkbox, FormControlLabel, InputAdornment, MenuItem, TextField } from '@mui/material'
import { t } from 'i18next'
import React, { useState } from 'react'

export default function AdditionalOptions({ data, userData, handleUserDataChange, isActive, setIsActive, touched, errors }) {
    const locale = window.location.href.includes('/en/') ? 'en' : 'pl'
    return (
        <div className="grid grid-cols-1 gap-1 mt-2">
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={e => {
                            setIsActive(prev => !prev)
                            if (!e.target.checked) {
                                handleUserDataChange('insurance', 'none')
                            }
                        }}
                        color="primary"
                    />
                }
                label={<span className="text-[14px] leading-3">{t("LAC.popup_contact.protection_package_title")}</span>}
            />
            {isActive ? (
                <TextField
                    name="insurance"
                    select
                    id="insurance-select"
                    value={userData.insurance}
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.insurance && Boolean(errors.insurance)}
                    helperText={touched.insurance && errors.insurance}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                >
                    <MenuItem disabled value="none">
                    {t("LAC.popup_contact.protection_package_select")}
                    </MenuItem>
                    {locale === 'pl'
                        ? data?.packages?.map(item => (
                              <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                              </MenuItem>
                          ))
                        : data?.packages?.map(item => (
                              <MenuItem key={item.id} value={item.id}>
                                  {item.nameEn}
                              </MenuItem>
                          ))}
                </TextField>
            ) : null}
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-0 sm:gap-6'>
                {data?.services?.map(item => (
                    <FormControlLabel
                        key={item.id}
                        control={
                            <Checkbox
                                onChange={e =>
                                    handleUserDataChange(
                                        item.name === 'Monitoring' ? 'gps' : 'fuelCard',
                                        e.target.checked,
                                        item.id
                                    )
                                }
                                color="primary"
                            />
                        }
                        label={
                            <span className="text-[14px] leading-3">{locale === 'pl' ? item.name : item.nameEn}</span>
                        }
                    />
                ))}
            </div>
        </div>
    )
}
