import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

import { environment } from '../../../environment'
import Spinner from '../../components/Spinner'
import { API } from '../api'
import StepperLac from '../components/StepperLac'
import ErrorBox from '../../../components/ErrorBox'
import GetProcessData from './GetProcessData'
import useFetchLac from '../../hooks/useFetchLac'

const Step3Lac = () => {
    const { t, i18n } = useTranslation()
    const { data, loading, error, fetchData } = useFetchLac()
    const { id, locale } = useParams()

    const [extras, setExtras] = useState([])

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        if (id) {
            const url = `${API[`GET_OFFER_OPTIONS`]}/?pid=${id}`
            fetchData(url, 'GET')
        }
    }, [id])

    useEffect(() => {
        setExtras(data)
    }, [data])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('Get offer:', extras)
    }, [data])

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return (
        <div className="container mx-auto px-8 my-6">
            <StepperLac activeStep={3} />
            <Box my={4}>
                {data && (
                    <GetProcessData
                        locations={data?.locations}
                        packages={data?.additionalOptions?.packages}
                        services={data?.additionalOptions?.services}
                    />
                )}
            </Box>
        </div>
    )
}

export default Step3Lac
