import { useState, useEffect } from 'react'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    let device = 'desktop'
    if (width < 1081) device = 'tablet'
    if (width < 769) device = 'mobile'
    return {
        width,
        height,
        device,
    }
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(() => getWindowDimensions())

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        handleResize()  
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowDimensions
}
