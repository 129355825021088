import React, { useState, useRef } from 'react'
import { environment } from '../../environment'
import CarsReservationsBoxesItem from './CarsReservationsBoxesItem'

function CarsReservationsBoxes(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('CarsReservationsBoxes')
        console.log(params)
    }
    /*const pseudojson = {
        "123123": {
          "promotion": "-10 %",
          "price": "356,10",
          "days": "5 dni",
          "lowest_price": "399,10",
          "lowest_days": "5 dni"
        },
        "22222": {
            "promotion": "-11 %",
            "price": "366,10",
            "days": "dobę",
            "lowest_price": "499,10",
            "lowest_days": "dobę"
        },
        "333": {
            "promotion": "-12 %",
            "price": "376,10",
            "days": "5 dni",
            "lowest_price": "599,10",
            "lowest_days": "5 dni"
        }
    }*/
    let block = params.block[params.locale]['Item'];

    /*const blockjson = block.map(object => {
        return {...object, 
            "Promotion": pseudojson[object.ID]["promotion"],
            "Price": pseudojson[object.ID]["price"],
            "Days": pseudojson[object.ID]["days"],
            "Lowest_price": pseudojson[object.ID]["lowest_price"],
            "Lowest_days": pseudojson[object.ID]["lowest_days"]
        };
    });*/

    return (
        <div className={'cars-res-boxes grid d-flex ai-center'}>
            {params.block[params.locale].hasOwnProperty('Item') &&
                Object.keys(block).map((item, i) => (
                    <CarsReservationsBoxesItem
                        key={'CarsReservationsBoxesItem-' + i}
                        block={block[item]}
                        line={params.line}
                        button={params.button}
                        locale={params.locale}
                        recaptchaToken={params.recaptchaToken}
                        setErrorMsg={params.setErrorMsg}
                    />
                ))
            }
        </div>
    )
}

export default CarsReservationsBoxes
