import React from 'react'
import Links from '../../components/Links.js'
import Svg from '../../components/Svg';
import { environment } from '../../environment.js'

function FooterSocialItem(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('FooterSocials Item')
        console.log(params)
    }

    return (
        <div className='socials-wrapper d-flex ai-center'>
            {params.block &&
                Object.keys(params.block).map((item, i) => (
                    <div 
                        className='social-box'
                        key={'FooterSocials-' + item}
                    >
                        {(params.block[item]['Link'] && params.block[item]['LinkName']) && <Links
                            class={'social-link c-color1'}
                            url={params.block[item]['Link']}
                            title={params.block[item]['LinkName']}
                            target={params.block[item]['InNewOpen']}
                            name={<Svg name={params.block[item]['LinkName']} />}
                            line={params.line}
                        />}
                    </div>
                ))
            }
        </div>
    )
}

export default FooterSocialItem
