import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconUmbrella() {
    return (
        <SvgIcon>
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.5 0.5V1.53125C13.8438 1.75 17.4062 4.875 17.9062 8.9375C17.9688 9.4375 17.375 9.71875 17.0625 9.34375C16.625 8.84375 15.75 8.5 15 8.5C13.7812 8.5 12.7812 9.375 12.5312 10.5312C12.4688 10.7812 12.25 11 12 11C11.7188 11 11.5 10.7812 11.4375 10.5312C11.2188 9.375 10.1875 8.5 9 8.5C7.78125 8.5 6.78125 9.375 6.53125 10.5312C6.46875 10.7812 6.25 11 6 11C5.71875 11 5.5 10.7812 5.4375 10.5312C5.21875 9.375 4.1875 8.5 3 8.5C2.21875 8.5 1.34375 8.84375 0.90625 9.34375C0.59375 9.71875 0 9.4375 0.0625 8.9375C0.5625 4.875 4.125 1.75 8.5 1.53125V0.5C8.5 0.25 8.71875 0 9 0C9.25 0 9.5 0.25 9.5 0.5ZM12 9.21875C12.5938 8.1875 13.7188 7.5 15 7.5C15.5312 7.5 16.125 7.65625 16.6562 7.875C15.6875 4.78125 12.6562 2.5 9 2.5C5.34375 2.5 2.28125 4.78125 1.3125 7.875C1.84375 7.65625 2.4375 7.5 3 7.5C4.25 7.5 5.375 8.1875 6 9.21875C6.59375 8.1875 7.71875 7.5 9 7.5C10.25 7.5 11.375 8.1875 12 9.21875ZM8.5 9.59375C8.65625 9.53125 8.8125 9.5 9 9.5C9.15625 9.5 9.34375 9.53125 9.5 9.59375V13.9688C9.5 15.0938 8.5625 16 7.4375 16C6.65625 16 5.96875 15.5625 5.625 14.875L5.53125 14.75C5.40625 14.5 5.53125 14.1875 5.75 14.0625C6 13.9375 6.3125 14.0312 6.4375 14.2812L6.5 14.4375C6.6875 14.7812 7.03125 15 7.4375 15C8.03125 15 8.5 14.5625 8.5 13.9688V9.59375Z"
                    fill="#202020"
                />
            </svg>
        </SvgIcon>
    )
}
