import React, { useState } from 'react'
import { Typography, Button, Box } from '@mui/material'
import DetailsInsurancePackagesTable from './DetailsInsurancePackagesTable'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import { useTranslation } from 'react-i18next'

const DetailsInsurancePackages = () => {
    const { t } = useTranslation()
    const [isActive, setIsActive] = useState(false)
    return (
        <Box mb={4}>
            <Box textAlign="center" mb={2}>
                <Button onClick={() => setIsActive(!isActive)} sx={{ fontWeight: 'bold', textTransform: 'none' }}>
                    {isActive ? (
                        <>
                            {t('LAC.offer_presentation.hide_details')} <KeyboardArrowUpOutlinedIcon />
                        </>
                    ) : (
                        <>
                            {t('LAC.offer_presentation.show_details')} <KeyboardArrowDownOutlinedIcon />
                        </>
                    )}
                </Button>
            </Box>

            {isActive && (
                <>
                    <Typography fontWeight="bold">{t('LAC.offer_presentation.packages_table_info.0')}</Typography>
                    <DetailsInsurancePackagesTable package1={false} package2={false} package3={true} />

                    <Typography fontWeight="bold">{t('LAC.offer_presentation.packages_table_info.1')}</Typography>
                    <DetailsInsurancePackagesTable package1={false} package2={true} package3={true} />

                    <Typography fontWeight="bold">{t('LAC.offer_presentation.packages_table_info.2')}</Typography>
                    <DetailsInsurancePackagesTable withText package1={true} package2={true} package3={true} />
                </>
            )}
        </Box>
    )
}

export default DetailsInsurancePackages
