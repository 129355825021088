import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

export default function TabsClientPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 4 }}>{children}</Box>}
        </div>
    )
}
