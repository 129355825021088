import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
    en: {
        translation: require('./locales/en.json'),
    },
    pl: {
        translation: require('./locales/pl.json'),
    },
}

i18n.use(initReactI18next).init({
    resources,
    lng: 'pl',
    fallbackLng: 'en',
})

export default i18n
