import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconCar() {
    return (
        <SvgIcon>
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.53125 2.09375L2.65625 5.03125C2.75 5.03125 2.875 5 3 5H13C13.0938 5 13.2188 5.03125 13.3125 5.03125L12.4375 2.09375C12.25 1.4375 11.6562 1 11 1H4.96875C4.3125 1 3.71875 1.4375 3.53125 2.09375ZM2.3125 6.125C1.53125 6.40625 1 7.15625 1 8V11H15V8C15 7.15625 14.4375 6.40625 13.6562 6.125C13.4375 6.0625 13.2188 6 12.9688 6H3C2.75 6 2.53125 6.0625 2.3125 6.125ZM1.5 5.40625V5.375L2.59375 1.78125C2.90625 0.75 3.875 0 4.96875 0H11C12.0938 0 13.0625 0.75 13.4062 1.78125L14.4688 5.375V5.40625C15.375 5.9375 16 6.90625 16 8V11V11.5V12V13.5C16 13.7812 15.75 14 15.5 14C15.2188 14 15 13.7812 15 13.5V12H1V13.5C1 13.7812 0.75 14 0.5 14C0.21875 14 0 13.7812 0 13.5V12V11.5V11V8C0 6.90625 0.59375 5.9375 1.5 5.40625ZM3.25 7.5C3.65625 7.5 4 7.84375 4 8.25C4 8.6875 3.65625 9 3.25 9C2.8125 9 2.5 8.6875 2.5 8.25C2.5 7.84375 2.8125 7.5 3.25 7.5ZM12 8.25C12 7.84375 12.3125 7.5 12.75 7.5C13.1562 7.5 13.5 7.84375 13.5 8.25C13.5 8.6875 13.1562 9 12.75 9C12.3125 9 12 8.6875 12 8.25Z"
                    fill="#202020"
                />
            </svg>
        </SvgIcon>
    )
}
