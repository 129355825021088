import React from 'react'
import { environment } from './../../environment'
import Container from '../../components/Container'
import AccordionItem from './AccordionItem'

const Accordion = params => {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Acordion')
        console.log(params)
    }
    const max = 5
    let TitleColor = params.block[params.locale].hasOwnProperty('TitleColor') ? 'c-'+params.block[params.locale]['TitleColor'] : 'c-color1';
    return (
        <section className="Accordion">
            <Container className="my-10 !px-4 sm:!px-8">
                {params.block[params.locale]['Title'] && (
                    <h2 className={TitleColor}>{params.block[params.locale]['Title']}</h2>
                )}
                {params.block[params.locale]['Subtitle'] && <h3>{params.block[params.locale]['Subtitle']}</h3>}
                <div className="mt-20">
                    {params.block[params.locale]['Item'] &&
                        params.block[params.locale]['Item'].map(
                            (item, i) =>
                                i < max && (
                                    <AccordionItem
                                        key={`item-${i}`}
                                        block={item}
                                        counter={i + 1}
                                        question={item['AccordionQuestion']}
                                        answer={item['AccordionAnswer']}
                                    />
                                )
                        )}
                </div>
            </Container>
        </section>
    )
}

export default Accordion
