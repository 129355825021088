import React from 'react'
import CarDetails from './components/CarDetails'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Links from '../../components/Links'
import useHostname from './hooks/useHostname'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import ParkOutlinedIcon from '@mui/icons-material/ParkOutlined'
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined'
import AddRoadOutlinedIcon from '@mui/icons-material/AddRoadOutlined'
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import TagsItems from './detailsView/TagsItems'
import Svg from '../../components/Svg.js'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

export default function OffersListLACItem({ item, listAsGrid, params, tags, fuelTypes, transmission, button, tagsData }) {
    const { t } = useTranslation()
    const origin = useHostname()
    let imgUrl = ''

    if (item.photos.length > 0) {
        imgUrl = item.photos[0].path
    } else {
        imgUrl = `${origin}/images/car-rent/test-car.jpg`
    }

    let urlToDetails =
        params?.locale === 'pl'
            ? `/auto-dla-firmy/wynajem-elastyczny/${item.makeName}/${item.modelName}/${item?.id}`
            : `/en/car-for-company/flexible-rental/${item.makeName}/${item.modelName}/${item?.id}`

    function Tag() {
        if (tags && tags[0] !== '' && tags[1] !== '') {
            return (
                <span className={'super-item b-color' + tags[2]}>
                    <IconTag />
                    {tags[1]}
                </span>
            )
        } else {
            return ''
        }
    }
    function IconTag() {
        switch (tags[0]) {
            case 1:
                return <Svg name="fewcars" />
            case 2:
                return <LocalOfferOutlinedIcon />
            case 3:
                return <LocalOfferOutlinedIcon />
            case 4:
                return <CardMembershipOutlinedIcon />
            case 5:
                return <AllInclusiveIcon/>
            case 6:
                return <AdsClickOutlinedIcon />
            case 7:
                return <LocalShippingIcon />
            case 8:
                return <ElectricCarIcon />
            default:
                return <LocalOfferOutlinedIcon />
        }
    }
    return (
        <div
            className={`relative border border-[#c7c7c7] rounded-[20px] overflow-hidden flex ${listAsGrid ? 'flex-col' : 'flex-col lg:flex-row'}`}
        >
            <Tag />
            <div className='relative'>
                <Links
                    url={urlToDetails}
                    target={false}
                    withChild={true}
                    line={params.line}
                >
                    <img
                        className={`${!listAsGrid ? 'lg:max-w-[488px]' : ''} ${item?.carsCount === 0 ? 'grayscale' : ''} aspect-video object-cover`}
                        src={imgUrl}
                    />
                </Links>
                {item.carsCount === 0 ? <div className='absolute top-[50%] left-0 right-0 translate-y-[-50%] text-center'><span className='bg-[#0d3174] px-4 py-2 text-white'><DoNotDisturbIcon className='mr-1' />{t("LAC.offer_list.temporarily_unavailable")}</span></div> : null}
            </div>
            <div
                className={`p-6 flex ${listAsGrid ? 'flex-col h-full' : 'gap-8 w-full justify-between flex-wrap lg:flex-nowrap'} ${item?.carsCount === 0 ? 'grayscale bg-[#ebebeb]' : ''}`}
            >
                <div>
                    <h4 className="c-color1 font-semibold text-2xl">{item?.name}</h4>
                    <span className="opacity-60">{item?.carNameDetails}</span>
                    <div className="flex flex-wrap flex-shrink-0 gap-4 mt-4">
                        <CarDetails
                            numberDoors={item.numberDoors}
                            numberSeats={item.numberSeats}
                            fuel={item.fuel}
                            transmission={item.transmission}
                            fuelTypes={fuelTypes}
                            transmissionArray={transmission}
                            mileageMin={item.mileageMin}
                            mileageMax={item.mileageMax}
                            isMultiOffer={item.tagIds.includes(1) ? true : false}
                        />
                        {tagsData ? 
                            <TagsItems tagsData={tagsData} car={item} isList={true} /> : null
                        }
                    </div>
                </div>
                <div className={`${listAsGrid ? 'mt-auto' : 'flex flex-col'}`}>
                    <div className="mt-4">
                        <span className="c-color3 text-3xl font-bold">
                            {item?.rents[0]?.rent.toLocaleString('pl-PL', { useGrouping: true })}
                            <small className="text-lg"> zł</small>
                        </span>
                        <span className="opacity-50 uppercase ml-2">{t('LAC.offer_list.label_netto_price')}</span>
                    </div>
                    <div>
                        <span className="text-[#919191] uppercase text-xs font-bold">
                            {item?.rents[0]?.rentGross.toLocaleString('pl-PL', { useGrouping: true })}
                            <small className="text-xs"> zł {t('LAC.offer_list.label_brutto_price')}</small>
                        </span>
                    </div>
                    <div className={`flex items-center justify-between flex-wrap ${listAsGrid ? 'mt-4' : 'flex-col mt-auto'}`}>
                        <FormGroup>
                            {false && //TODO ukrycie tymczasowe
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label={t('LAC.offer_list.offers_list_detail.add_compare_checkbox')}
                                />}
                        </FormGroup>
                        <Links
                            id="L1"
                            class={'btn-link b-' + button}
                            url={urlToDetails}
                            target={false}
                            name={item?.carsCount === 0 ? t("LAC.offer_list.temporarily_unavailable") : t('LAC.offer_list.offers_list_detail.button_text')}
                            line={params.line}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
