import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'

const useFormattedDate = originalDate => {
    const [formattedDate, setFormattedDate] = useState('')

    useEffect(() => {
        if (originalDate === undefined || originalDate === null) {
            setFormattedDate('')
            return
        }

        const formatted = dayjs(originalDate).format('DD-MM-YYYY HH:mm')
        setFormattedDate(formatted)
    }, [originalDate])

    return formattedDate
}

export default useFormattedDate
