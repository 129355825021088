import React from 'react'
import { environment } from '../../environment'
import OffersListLAC from '../offersList/OffersListLAC'
import { FormProvider } from '../../forms/LAC/FormContext'
import '../../styles/offers-box.css'

function OffersBox(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('offersBox')
        console.log(params)
    }
    let ButtonType = params.block[params.locale].hasOwnProperty('ButtonType') ? params.block[params.locale]['ButtonType'] : 'color1';

    return (
        <section className={'offersList offers-list-filters offers-box'}>
            <div className="wrapper">
                <div className={'container mx-auto px-8 my-20'}>
                    <FormProvider params={params} noApi={true}>
                        <OffersListLAC params={params} button={ButtonType} />
                    </FormProvider>
                </div>
            </div>
        </section>
    )
}

export default OffersBox
