import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Dialog, DialogTitle, DialogContent, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import BackButton from './BackButton'

const DialogWindow = (
    { isOpen, setIsOpen, dialogTitle, children },
    ...props
) => {
    const [openDialog] = useState(isOpen)

    const handleCloseDialog = () => {
        setIsOpen(false)
        // setMinimum25h()
        // setMax29Days()
    }
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const { t } = useTranslation()

    return (
        openDialog && (
            <Dialog
                disableEnforceFocus // FocusTrap.js:225 Uncaught RangeError: Maximum call stack size exceeded.
                fullScreen={fullScreen}
                fullWidth
                maxWidth="sm"
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" className="hidden">
                    {dialogTitle}
                </DialogTitle>
                {props.backButtonText && (
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <BackButton handleClick={handleCloseDialog} sx={{ marginRight: 2 }} />
                        <Typography fontWeight="bold" textAlign="center" width={`calc(100% - 80px)`}>
                            {props.backButtonText}
                        </Typography>
                    </Box>
                )}
                <DialogContent>
                    <Box p={3} mt={2}>
                        {children}
                    </Box>
                </DialogContent>
            </Dialog>
        )
    )
}

export default DialogWindow
