import React, { useState, useEffect } from 'react'
import { Box, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import CheckIcon from '@mui/icons-material/Check'

const NumberSelector = ({ initialValue, min, max, handleClick }) => {
    const [value, setValue] = useState(initialValue || min)

    const handleIncrement = () => {
        const newValue = value + 1
        if (value < max) {
            setValue(newValue)
            handleClick(newValue)
        }
    }

    const handleDecrement = () => {
        const newValue = value - 1
        if (value > min) {
            setValue(newValue)
            handleClick(newValue)
        }
    }

    return min === 0 && max === 1 ? (
        <Box
            width="48px"
            minWidth="48px"
            height="44px"
            borderRadius="4px"
            border="1px solid #ccc"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => (value === 1 ? handleDecrement() : handleIncrement())}
        >
            <CheckIcon
                sx={{ transition: 'all 0.3s' }}
                color={value === 1 ? 'secondary' : 'primary'}
                opacity={value === 1 ? 1 : 0}
            />
        </Box>
    ) : (
        <Box
            maxWidth="144px"
            height="48px"
            borderRadius="4px"
            bgcolor="#F2F2F2"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <IconButton color="primary" aria-label="minus" onClick={handleDecrement}>
                <RemoveIcon />
            </IconButton>
            <Box width="48px" height="44px" display="flex" alignItems="center" justifyContent="center" bgcolor="white">
                {value}
            </Box>
            <IconButton color="primary" aria-label="plus" onClick={handleIncrement}>
                <AddIcon />
            </IconButton>
        </Box>
    )
}

export default NumberSelector
