import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Typography, Divider } from '@mui/material'
import { API } from '../api'
import Spinner from '../../components/Spinner'
import useFetchLac from '../../hooks/useFetchLac'
import ErrorBox from '../../../components/ErrorBox'

const GetTransportPriceList = () => {
    const { t } = useTranslation()
    const { data, loading, error, fetchData } = useFetchLac()
    const { locale } = useParams()

    useEffect(() => {
        const url = `${API[`GET_TRANSPORT_PRICE_LIST`]}`
        fetchData(url, 'GET')
    }, [])

    const [transportPriceList, setTransportPriceList] = useState([])

    useEffect(() => {
        setTransportPriceList(data?.priceListItems)
    }, [data])

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return (
        transportPriceList &&
        transportPriceList.map(item => (
            <>
                <Typography fontWeight="bold">
                    {item?.maxKm === null ? `> ${item?.minKm} km` : `${item?.minKm} km - ${item?.maxKm} km`}
                </Typography>
                {item?.unitPrice && (
                    <Typography fontSize={12}>
                        {t('LAC.offer_presentation.fee_unit')} <br />
                        {`${String(item?.unitPrice.toFixed(2)).replace('.', ',')} zł (${String(item?.unitPriceGross?.toFixed(2)).replace('.', ',')} zł ${t('gross')})`}
                    </Typography>
                )}
                {item?.perKmPrice && (
                    <Typography fontSize={12}>
                        {t('LAC.offer_presentation.fee_km')} <br />{' '}
                        {`${String(item?.perKmPrice?.toFixed(2)).replace('.', ',')} zł/km (${String(item?.perKmPriceGross?.toFixed(2)).replace('.', ',')} zł/km ${t('gross')})`}
                    </Typography>
                )}
                <Divider sx={{ m: 1 }} />
            </>
        ))
    )
}

export default GetTransportPriceList
