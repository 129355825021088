import React, { useState, useEffect } from 'react'
import { environment } from '../../environment'
import { useFormContext } from '../../forms/LAC/FormContext'
import '../../styles/offers-list-news.css'
import OffersListLACItem from './OffersListLACItem'
import { useTranslation } from 'react-i18next'
import Loaders from '../../Loaders.js'

const DISPLAYED_OFFERS = 9

function OffersListNews(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('offersList News')
        console.log(params)
    }
    const { t, i18n } = useTranslation()
    const [listAsGrid, setListAsGrid] = useState(true)
    const [displayedOffers, setDisplayedOffers] = useState(DISPLAYED_OFFERS)
    const { filteredOffers, sortingLabel, handleSortingChange } = useFormContext()
    let data = null;
    if (filteredOffers.length > 0) {
        data = filteredOffers
    }
    let choosen = (params.params.block[params.params.locale].hasOwnProperty('Item') ? params.params.block[params.params.locale]['Item'] : []);
    //get list of choosen id if so
    let resultItemsId = [];
    let setTagName = [];
    if (choosen.length > 0) {
        choosen.map((item) => {
            resultItemsId.push(item['ID'])
            setTagName.push(item['Tag'])
            return null
        })
    }
    resultItemsId = [...new Set(resultItemsId)];

    //testing names fo tags
    const tagsName = {
        "pl": {
          "1": "Kilka sztuk",
          "2": "Oferta dnia",
          "3": "Nowość w ofercie",
          "4": "Autoabonament",
          "5": "Pay Per Mile",
          "6": "Clik&Drive",
          "7": "Ciężarowe",
          "8": "Elektryczne",
          "9": "Promocja",
        },
        "en": {
            "1": "A few cars",
            "2": "Offer of the day",
            "3": "New in the offer",
            "4": "Auto subscription",
            "5": "Pay Per Mile",
            "6": "Clik&Drive",
            "7": "Trucks",
            "8": "Electric",
            "9": "Special offer",
        }
    };
    function getTagId(name) {
        return Object.keys(tagsName[params.params.locale]).filter(tag => tagsName['pl'][tag] === name).toString();
    }
    function getTagName(id) {
        return tagsName[params.params.locale][id]
    }
    function getTags(item) {
        let id = '';
        let name = '';
        if (setTagName.length > 0) {
            if (item.hasOwnProperty('showTag') && item.showTag !== '') {
                id = getTagId(item.showTag)
                name = item.showTag
            }
            else {
                if (item.tagIds.length > 0 ) {
                    id = item.tagIds[0]
                    name = getTagName(item.tagIds[0])
                }
                else {
                    id = 0
                    name = ''
                }
            }
        }
        else {
            if (item.tagIds.length > 0 ) {
                id = item.tagIds[0]
                name = getTagName(item.tagIds[0])
            }
            else {
                id = 0
                name = ''
            }
        }
       
        return [id.toString(),name,getColorTag(id)]
    }
    function getColorTag(tagId) {
        if (tagId === 8) return '7' 
        return [4,2,3,1][Number(tagId) % 4];
    }
    if (data !== null) {
        //filter list of choosen tag if so
        //data = data.filter(offer => offer.tagIds.includes(2))

        if (resultItemsId.length > 0 && data.length > 0) {
            let result = [];
            data.map((item) => {
                if (resultItemsId.includes(item['id'].toString())) {
                    item["showTag"] = setTagName[resultItemsId.indexOf(item['id'].toString())];
                    result.push(item)
                }
                return null
            })
            data = [...new Set(result)];
        }
        if (!filteredOffers.length > 0 && !data.length > 0) {
            data = []
        }
    }
    useEffect(() => {
        i18n.changeLanguage(params.params.locale)
    }, [params.params.locale])
    return (
        <div className={'offers-list-days'}>
            {data === null && 
                <div className="Loading-nav b-white">
                    <Loaders type="dots" color="b-color1" />
                </div>
            }
            {data?.length <= 0 ? (
                <h3 className='text-center mt-10'>{t('portlets.offers_list.no_offers')}</h3>
            ) : (
                <div
                    className={`grid ${listAsGrid ? 'sm:grid-cols-[repeat(auto-fill,minmax(380px,1fr))] grid-cols-[repeat(auto-fill,minmax(300px,1fr))]' : 'grid-cols-1'} gap-6`}
                >
                    {data?.slice(0, displayedOffers)?.map((item, index) => (
                        <OffersListLACItem params={params.params} key={index} item={item} listAsGrid={listAsGrid} tags={getTags(item)} button={params.button} />
                    ))}
                </div>
            )}
        </div>
    )
}

export default OffersListNews
