import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { environment } from '../../../environment'
import useFetch from '../../hooks/useFetch'
import { API } from '../../api'
import theme from '../../../styles/MUI-theme'
import Spinner from '../../components/Spinner'
import ErrorBox from '../ErrorBox'
import useFormattedPrice from '../../hooks/useFormattedPrice'

const Prices = ({ item, carClass, model }) => {
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()
    const { loading, error, fetchData } = useFetch()
    const [offerId] = useState(item.classId)
    const [offerPrice] = useState(item.price)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const formattedPrice = useFormattedPrice(item.price)
    const formattedPricePerDay = useFormattedPrice(parseFloat(item.price / item.dayCount).toFixed(2))

    const handleSubmit = () => {
        setIsSubmitting(true)

        const data = {
            car_class_id: offerId,
            car_class_name: carClass.name,
            model: model,
            seats: carClass.seats.toString(),
            fuel: carClass.fuelType,
            transmission: carClass.transmissionType,
        }

        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('SUBMIT : ', data)

        fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=next`, 'POST', data, locale)
    }

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return (
        <Box
            key={item.classId}
            sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderRadius: '0 0 10px 10px',
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" px={{ xs: 2, lg: 4 }} py={3}>
                <Box>
                    <Typography fontSize={{ xs: 16, lg: 20 }} fontWeight="bold">
                        {formattedPrice}
                        <Box component="span" sx={{ color: '#CECECE', fontWeight: 'normal' }}>
                            {' '}
                            / {item.dayCount} {t('RAC.step_two.price.days')}
                        </Box>
                    </Typography>
                    <Typography>
                        {formattedPricePerDay} /{' '}
                        <Box component="span" sx={{ color: '#CECECE' }}>
                            {t('RAC.step_two.price.per_day')}
                        </Box>
                    </Typography>
                    <Typography
                        fontSize={{
                            xs: 10,
                            md: 12,
                        }}
                        pt={1}
                    >
                        {t('RAC.step_two.price_does_not_include_fees')}
                    </Typography>
                </Box>
                <Button
                    id="R2" 
                    disableElevation
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{
                        color: 'navy !important',
                        textTransfrom: 'uppercase',
                        fontWeight: 'bold',
                        background: 'white !important',
                        px: 2,
                        py: 1,
                        height: '50px',
                        ':hover': {
                            background: 'transparent !important',
                            color: 'white !important',
                        },
                        ':disabled': {
                            background: 'transparent !important',
                            color: 'white !important',
                            opacity: '0.3',
                        },
                    }}
                    onClick={handleSubmit}
                >
                    {t('btn.choose')}
                </Button>
            </Box>
        </Box>
    )
}

export default Prices
