import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, useFormikContext } from 'formik'
import { Grid, FormControlLabel, RadioGroup } from '@mui/material'
import RadioInsurancePackage from './RadioInsurancePackage'
import { useParams } from 'react-router-dom'

const FieldInsurancePackage = ({ packages, processData }) => {
    const { t } = useTranslation()
    const { locale } = useParams()
    const { values, setFieldValue } = useFormikContext()

    const [selectedPackageID, setSelectedPackageID] = useState(0)

    const handleClick = clickedPackageId => {
        setSelectedPackageID(clickedPackageId)

        if (clickedPackageId === selectedPackageID && selectedPackageID !== null)
            setFieldValue('insurancePackage', null)
        else
            setFieldValue(
                'insurancePackage',
                packages.find(insurance => insurance.id === clickedPackageId)
            )
    }

    return (
        <Field
            type="radio"
            name="insurancePackage"
            as={FormControlLabel}
            sx={{
                margin: 0,
                marginBottom: 2,
                display: 'flex',
            }}
            control={
                <RadioGroup row name="insurancePackage" value={values.insurancePackage?.id || 0} sx={{ width: '100%' }}>
                    <Grid container spacing={2} width="100%">
                        {packages.map(insurance => (
                            <Grid key={insurance.id} item xs={12} md={4} alignItems="strech">
                                <RadioInsurancePackage
                                    id={insurance.id}
                                    name={locale === 'pl' ? insurance.name : insurance.nameEn}
                                    text={locale === 'pl' ? insurance.text : insurance.textEn}
                                    description={locale === 'pl' ? insurance.description : insurance.descriptionEn}
                                    priceNetto={insurance.monthlyFee}
                                    priceBrutto={insurance.monthlyFeeGross}
                                    values={values}
                                    selectedPackageID={parseInt(processData?.insurance_package_id)}
                                    handleClick={value => handleClick(value)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </RadioGroup>
            }
        />
    )
}

export default FieldInsurancePackage
