import Axios from 'axios'
import React, { useState, useEffect } from 'react'
import { project, environment } from './environment.js'
import Head from './Head.js'
import Portlets from './Portlets.js'
import { useParams } from 'react-router-dom'
import Func from './Function.js'
import Loaders from './Loaders.js'
import RemoveLoader from './RemoveLoader.js'
import BigFooter from './BigFooter.js'

function App() {
    ;<Func line="online" />
    //const locale = window.location.pathname.split('/')[1].length > 2 ? 'pl' : window.location.pathname.split('/')[1];
    let [pageData, setPageData] = useState([])
    let [compList, setCompList] = useState([])
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Main')
        console.log(window.location.href)
        console.log(project.name)
        console.log(environment)
    }
    useParams()
    let [jsonStatus, setJsonStatus] = useState(null)

    useEffect(() => {
        let fetchPortlets = async () => {
            if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
                console.log('fetchPortlets')
            }
            let url = null,
                errUrl = null
            let winLoc = window.location.origin + window.location.pathname
            if (winLoc.endsWith('#')) winLoc = winLoc.replace('#', '')
            if (window.location.origin.includes(environment.cmsName)) {
                url = window.location.origin + environment.cmsPageUrl + window.location.pathname + '?content'
                errUrl = window.location.origin + environment.cmsPageUrl + environment.error404Page + '?content'
            } else if (winLoc.endsWith('/')) {
                url = winLoc + environment.pageJson
                errUrl = window.location.origin + environment.error404Json
            } else {
                url = winLoc + '.json'
                errUrl = window.location.origin + environment.error404Json
            }

            if (url.includes(environment.localhost)) {
                url = url.replace(window.location.origin, environment.siteUrlDev)
                errUrl = environment.siteUrlDev + environment.error404Json
            }

            if(url.includes('/wynajem-elastyczny/')) {
                url = url.split('/wynajem-elastyczny')[0] + "/wynajem-elastyczny/index.html.json"
            }

            if(url.includes('/flexible-rental/')) {
                url = url.split('/flexible-rental')[0] + "/flexible-rental/index.html.json"
            }

            let response = ''
            try {
                response = await Axios(url)
                setJsonStatus(true)
            } catch (err) {
                try {
                    response = await Axios(errUrl)
                    setJsonStatus(true)
                } catch (err) {
                    setJsonStatus(false)
                    console.log('error ' + err)
                    response = 'error ' + err
                }
            }
            if (response.hasOwnProperty('data')) {
                setPageData(response.data)
                if (response.data.hasOwnProperty('containers')) {
                    setCompList(response.data.containers)
                } else {
                    setJsonStatus(false)
                }
            } else {
                setJsonStatus(false)
            }
        }
        fetchPortlets()
    }, [window.location.href])

    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log(pageData)
        console.log(compList)
    }

    let listComp = []
    function RenderPortlets() {
        compList.map((cont, i) => {
            cont.elements.map((item, ii) => {
                const uuid = item.formatterKey
                const path = item.path
                const settings = item.settings
                listComp.push({ uuid, path, settings })
                return null
            })
            return null
        })
        return listComp.map((portlet, index, settings) => {
            if (pageData.hasOwnProperty('linkedContents') && pageData.linkedContents.hasOwnProperty(portlet.path)) {
                if (!(portlet.path.indexOf('/footer/') > 0)) {
                    return Portlets(
                        'online',
                        pageData.linkedContents[portlet.path],
                        portlet.path,
                        index,
                        pageData.properties.locale,
                        settings[index].settings
                    )
                }
            } else {
                let id = portlet.path.split('/').pop()
                id = id.substring(0, id.length - 4)
                return <div class="hidden b-red">Some bug with {id}</div>
            }
        })
    }
    function SetHead() {
        if (pageData.hasOwnProperty('properties')) {
            return <Head block={pageData.properties} />
        } else {
            return ''
        }
    }

    function SetBigFooter() {
        if (pageData.hasOwnProperty('properties')) {
            return <BigFooter block={pageData.properties} />
        } else {
            return ''
        }
    }

    switch (jsonStatus) {
        case null:
            return (
                <div className="Loading b-white ta-center d-flex ai-center">
                    <Loaders type="circle" />
                </div>
            )
        case false:
            return (
                <>
                    <div className="Error b-red ta-center d-flex ai-center">
                        <div className="container">
                            <p>
                                Przepraszamy, zawartość żądanej strony nie mogła zostać wyświetlona. <br />
                                Mogą to być przejściowe problemy - spróbuj odświeżyć widok w przeglądarce.
                            </p>
                        </div>
                    </div>
                    <RemoveLoader />
                </>
            )
        case true:
            return (
                <>
                    <SetHead />
                    <RemoveLoader />
                    <RenderPortlets />
                    <SetBigFooter />
                </>
            )
        default:
            break
    }
}
export default App
