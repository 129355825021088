import React from 'react'
import { MultistepForm, Step } from './MultistepForm'

import FormStep1 from './FormStep1'
import FormStep1cms from './FormStep1cms'
import FormStep2 from './FormStep2'
import FormStep3 from './FormStep3'
import FormStep4 from './FormStep4'
import FormStep5 from './FormStep5'

const Form = ({ ...props }) => {
    const isCmsInUrl = window.location.href.includes('cms')

    return (
        <MultistepForm>
            <Step step={1}>
                {isCmsInUrl ? (
                    <FormStep1cms locale={props.locale} />
                ) : (
                    <FormStep1 line={props.line} locale={props.locale} />
                )}
            </Step>
            <Step step={2}>
                <FormStep2 />
            </Step>
            <Step step={3}>
                <FormStep3 />
            </Step>
            <Step step={4}>
                <FormStep4 />
            </Step>
            <Step step={5}>
                <FormStep5 />
            </Step>
        </MultistepForm>
    )
}

export default Form
