import React, { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { environment } from './../../environment'
import Container from '../../components/Container'
import ColorBox from '../../components/ColorBox'
import useWindowDimensions from '../../Device'
import SaleItem from './saleItem'

import '../../styles/carousel3d.css'

const Carousel3d = ({ items }) => {
    const { device } = useWindowDimensions()

    const [[activeIndex, direction], setActiveIndex] = useState([0, 0])

    // we want the scope to be always to be in the scope of the array so that the carousel is endless
    const indexInArrayScope = ((activeIndex % items.length) + items.length) % items.length

    // so that the carousel is endless, we need to repeat the items twice
    // then, we slice the the array so that we only have 3 items visible at the same time
    const visibleItems =
        device === 'desktop'
            ? [...items, ...items].slice(indexInArrayScope, indexInArrayScope + 3)
            : [...items, ...items].slice(indexInArrayScope, indexInArrayScope + 1)

    const handleClick = newDirection => {
        setActiveIndex(prevIndex => [prevIndex[0] + newDirection, newDirection])
    }

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
            console.log('Sale')
            console.log(items)
        }
    }, [items])

    const variants = {
        enter: ({ direction }) => {
            return {
                scale: device === 'desktop' ? 0.2 : 1,
                x: direction < 1 ? 50 : -50,
                opacity: 0,
            }
        },
        center: ({ position, direction }) => {
            return {
                scale: device === 'desktop' ? (position() === 'center' ? 1.3 : 1) : 1,
                x: 0,
                zIndex: getZIndex({ position, direction }),
                opacity: 1,
            }
        },
        exit: ({ direction }) => {
            return {
                scale: device === 'desktop' ? 0.2 : 1,
                x: direction < 1 ? -50 : 50,
                opacity: 0,
            }
        },
    }

    return (
        <div className="main-wrapper">
            <div className="carousel-wrapper">
                {/*AnimatePresence is necessary to show the items after they are deleted because only max. 3 are shown*/}
                <AnimatePresence mode="popLayout" initial={false}>
                    {visibleItems.map(item => {
                        // The layout prop makes the elements change its position as soon as a new one is added
                        // The key tells framer-motion that the elements changed its position
                        return (
                            <motion.div
                                className={`card 
                                    ${device === 'desktop' ? item === visibleItems[1] && 'active' : 'active'}
                                `}
                                key={`CarouselMotion-${item.id}`}
                                layout
                                custom={{
                                    direction,
                                    position: () => {
                                        if (item === visibleItems[0]) {
                                            return 'left'
                                        } else if (item === visibleItems[1]) {
                                            return 'center'
                                        } else {
                                            return 'right'
                                        }
                                    },
                                }}
                                variants={variants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{
                                    duration: device === 'desktop' ? 0.7 : 0.3,
                                }}
                                onClick={() => {
                                    if (device !== 'desktop') handleClick(1)
                                    else {
                                        if (item === visibleItems[0]) {
                                            handleClick(-1)
                                        } else if (item === visibleItems[1]) {
                                            handleClick(0)
                                        } else {
                                            handleClick(1)
                                        }
                                    }
                                }}
                            >
                                <SaleItem item={item} />
                            </motion.div>
                        )
                    })}
                </AnimatePresence>
            </div>
            <div className="buttons">
                <motion.button whileTap={{ scale: 0.8 }} onClick={() => handleClick(-1)}>
                    {`<`}
                </motion.button>
                <motion.button whileTap={{ scale: 0.8 }} onClick={() => handleClick(1)}>
                    {`>`}
                </motion.button>
            </div>
        </div>
    )
}

function getZIndex({ position, direction }) {
    const indexes = {
        left: direction > 0 ? 2 : 1,
        center: 3,
        right: direction > 0 ? 1 : 2,
    }
    return indexes[position()]
}

const Sale = params => {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Sale')
        console.log(params)
    }
    let TitleColor = params.block[params.locale].hasOwnProperty('TitleColor') ? 'c-'+params.block[params.locale]['TitleColor'] : 'c-color4';

    const ITEMS = params.block[params.locale]['Item'].map((item, i) => {
        return {
            id: i,
            src: item.hasOwnProperty('Image')
                ? item['Image'].hasOwnProperty('link')
                    ? item['Image']['link']
                    : environment.defaultImage
                : environment.defaultImage,
            alt: item.hasOwnProperty('Headline') ? item['Headline'] : '',
            link: item.hasOwnProperty('Link') ? item['Link'] : '',
            linkName: item.hasOwnProperty('LinkName') ? item['LinkName'] : '',
            inNewOpen: item.hasOwnProperty('InNewOpen') ? (item['InNewOpen'] === true ? true : false) : false,
            headline: item.hasOwnProperty('Headline') ? item['Headline'] : '',
            subheading: item.hasOwnProperty('Subheading') ? item['Subheading'] : '',
            line: params.line,
        }
    })

    return (
        <section className="sale">
            <div className="wrapper">
                <Container className="bg-navyBg rounded-2xl px-0 pt-20 pb-20 lg:pb-40 lg:mb-40 relative my-5">
                    <h2 className={TitleColor}>{params.block[params.locale]['Title']}</h2>
                    <h3 className="text-white mb-5 md:mb-20">{params.block[params.locale]['Subtitle']}</h3>
                    <Carousel3d items={ITEMS} />
                    <ColorBox
                        width="100px"
                        height="100px"
                        className="hidden lg:block"
                        style={{
                            position: 'absolute',
                            right: '160px',
                            bottom: '-50px',
                        }}
                    />
                </Container>
            </div>
        </section>
    )
}

export default Sale
