import React, { useContext, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import BackButton from '../components/BackButton'
import Filters from './filters/Filters'

const DialogFilters = ({ handleClose, open }) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            aria-labelledby="responsive-dialog-title"
            onClose={handleClose}
            maxWidth="100%"
        >
            <DialogContent sx={{ p: 0 }}>
                <Box pb={2} sx={{ width: '100%' }}>
                    <BackButton closeIcon handleClick={handleClose} />
                    <Box py={2} px={{ xs: 2, lg: 4 }}>
                        <Filters handleClick={handleClose} />
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default DialogFilters
