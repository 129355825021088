import React from 'react'
import { environment } from '../../environment'
import CommentsBoxItems from './CommentsBoxItem'
import '../../styles/comments.css'
import Links from '../../components/Links.js'

function CommentsBox(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('comments')
        console.log(params)
    }
    let sett = params.hasOwnProperty('settings') ? params.settings : {},
        showItem = sett.hasOwnProperty('Count') ? sett['Count'] : 'all',
        setNumCount = showItem === 'all' ? 1000 : parseInt(isNumeric(showItem) ? showItem : 1000);

    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }
    let Link = params.block[params.locale].hasOwnProperty('ButtonLink') ? params.block[params.locale]['ButtonLink'] : '',
        LinkName = params.block[params.locale].hasOwnProperty('ButtonName') ? params.block[params.locale]['ButtonName'] : '',
        LinkType = params.block[params.locale].hasOwnProperty('ButtonType') ? params.block[params.locale]['ButtonType'] : 'color1',
        InNewOpen = params.block[params.locale].hasOwnProperty('InNewOpen')
            ? params.block[params.locale]['InNewOpen'] === true
                ? true
                : false
            : false;
    return (
        <section className={'comments-box'}>
            <div className="wrapper">
                <div className={'container mx-auto px-8 my-20'}>
                    {params.block[params.locale]['Title'] && <h2 className={'c-'+params.block[params.locale]['TitleColor']}>{params.block[params.locale]['Title']}</h2>}
                    {params.block[params.locale]['Subtitle'] && <h3 className='opacity-50'>{params.block[params.locale]['Subtitle']}</h3>}
                    <div className={'comment-boxes grid d-flex'}>
                        {params.block[params.locale].hasOwnProperty('Item') &&
                            Object.keys(params.block[params.locale]['Item']).map((item, i) => (
                                i <= (setNumCount-1) && <CommentsBoxItems
                                    key={'CommentsBoxItem-' + i}
                                    block={params.block[params.locale]['Item'][item]}
                                    line={params.line}
                                />
                            ))
                        }
                    </div>
                    {(!(Link === '') && !(LinkName === '')) && (showItem !== 'all') &&
                        <div className="button-box text-center mt-5">
                            <Links
                                class={'btn-link b-' +LinkType}
                                url={Link}
                                target={InNewOpen}
                                name={LinkName}
                                line={params.line}
                            />
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}

export default CommentsBox
