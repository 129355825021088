import React from 'react'
import Links from '../../components/Links.js'
import Image from '../../components/Image.js'
import { environment } from '../../environment.js'

function FooterBrandsItem(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('FooterBrands Item')
        console.log(params)
    }

    return (
        <div className='brands-wrapper grid d-flex'>
            {params.block &&
                Object.keys(params.block).map((item, i) => (
                    <div 
                        className='brand-box grid-cell grid-8'
                        key={'FooterBrands-' + item}
                    >   
                        <div className="square square-100">
                            {(params.block[item]['Link'] && params.block[item]['LinkName']) && <Links
                                class={'link c-color1 all-link'}
                                url={params.block[item]['Link']}
                                target={params.block[item]['InNewOpen']}
                                name={params.block[item]['LinkName']}
                                line={params.line}
                            />}

                            {(params.block[item]['Image'] &&
                                <div className="img-box">
                                    <Image
                                        src={
                                            params.block[item].hasOwnProperty('Image')
                                                ? params.block[item]['Image'].hasOwnProperty('link')
                                                    ? params.block[item]['Image']['link']
                                                    : environment.defaultImage
                                                : environment.defaultImage
                                        }
                                        alt={
                                            params.block[item].hasOwnProperty('LinkName')
                                                ? params.block[item]['LinkName']
                                                : 'Express Rent Car'
                                        }
                                    />
                                </div>
                            )}
                            {params.block[item]['LinkName'] && 
                                <div className="text-box">
                                    <h5>{params.block[item]['LinkName']}</h5>
                                </div>
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default FooterBrandsItem
