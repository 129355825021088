import React from 'react'
import { environment } from '../../environment.js'
import HistoryBoxItems from './HistoryBoxItem.js'
import TextLinks from '../../components/TextLinks.js'
import Image from '../../components/Image.js'
import '../../styles/history.css'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function HistoryBox(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('HistoryBox')
        console.log(params)
    }
    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }
    function ImageLink (item) {
        let img = item
            ?item.hasOwnProperty('link')
                ? item['link']
                : environment.defaultImage
            : environment.defaultImage;
        let imgUrl = img;

        if (img !== undefined && img !== '') {
            if (img.includes(environment.siteSites)) {
                img = img.split(environment.siteSites)
                img = img[1]
                if (params.line === 'offline') {
                    imgUrl = window.origin + img
                } else if (window.origin.includes(environment.localhost)) {
                    imgUrl = environment.siteUrlDev + img
                } else {
                    imgUrl = window.origin + img
                }
            } else if (window.origin.includes(environment.localhost)) {
                imgUrl = environment.siteUrlDev + img
            } else {
                imgUrl = img
            }
        } else {
            imgUrl = environment.defaultImage
        }
        let ImageUrl = imgUrl;
        return ImageUrl
    }
    return (
        <section className={'history-box'}>
            <div className="wrapper bg-[#f6f6f5] pt-12">
                <div className={'container mx-auto px-8 my-20'}>
                    {params.block[params.locale]['Title'] && <h2 className={'c-'+params.block[params.locale]['TitleColor']}>{params.block[params.locale]['Title']}</h2>}
                    {params.block[params.locale]['Subtitle'] && <h3 className='opacity-50'>{params.block[params.locale]['Subtitle']}</h3>}
                    <div className={'history-boxes grid d-flex'}>
                        <VerticalTimeline>
                            {params.block[params.locale].hasOwnProperty('Item') &&
                                Object.keys(params.block[params.locale]['Item']).map((item, i) => (
                                    <VerticalTimelineElement
                                        className="section-history"
                                        contentStyle={{ background: '#fff', color: '#505154' }}
                                        contentArrowStyle={{ borderRight: '7px solid #fff' }}
                                        date={params.block[params.locale]['Item'][item]?.['Year']}
                                        iconStyle={{ background: '#f4f4f3', color: '#bfbfbf', marginLeft: '-10px', marginTop: '20px', width: '20px', height: '20px' }}
                                        icon={''}
                                    >   
                                        <div className="d-flex top-box">
                                            <div className="image-box mr-20 mb-10 pb-20">
                                                <Image className="w-auto" src={ImageLink(params.block[params.locale]['Item'][item]?.['Image'])} alt={params.block[params.locale]['Item'][item]?.['AltText']} />
                                            </div>
                                            <h3 className="c-color3 mb-0">{params.block[params.locale]['Item'][item]?.['Year']}</h3>
                                        </div>
                                        <TextLinks text={params.block[params.locale]['Item'][item]?.['Text']} line={params.line} />
                                    </VerticalTimelineElement>
                                ))
                        }
                        </VerticalTimeline>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HistoryBox
