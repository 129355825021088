import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { object, string } from 'yup'
import useFetchLac from '../../hooks/useFetchLac'
import { Typography, TextField, InputAdornment, Box, Alert } from '@mui/material'
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined'
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'

import ErrorBox from '../../../components/ErrorBox'
import DialogWindow from '../../components/DialogWindow'
import ChooseButton from '../../components/ChooseButton'
import Spinner from '../../components/Spinner'
import LinkText from '../../components/LinkText'
import { API } from '../api'

const DialogAuthentication = ({ showAuthentication, setShowAuthentication }) => {
    const { t } = useTranslation()
    const { id, locale } = useParams()
    const { data, loading, error, fetchData } = useFetchLac()

    const [verifyIsSuccess, setVerifyIsSuccess] = useState(false)

    useEffect(() => {
        data && data?.status === 'success' && setVerifyIsSuccess(true)

        data && data?.status === 'error' && setVerifyIsSuccess(false)
    }, [data])

    useEffect(() => {
        verifyIsSuccess && setShowAuthentication(false)
    }, [verifyIsSuccess])

    const initialValues = {
        authEmail: '',
        authNIP: '',
    }

    const validationSchema = object().shape({
        authEmail: string().required(t('errors.schema.required')).email(t('errors.schema.invalid_email')).max(50),
        authNIP: string()
            .required(t('LAC.client_data.validation.nip_3'))
            .matches(/^\d{10}$/, t('LAC.client_data.validation.nip_1'))
            .test('isValidNIP', t('LAC.client_data.validation.nip_2'), value => {
                const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7]
                const digits = value.toString().split('').map(Number)
                const checksum =
                    digits.reduce((acc, digit, index) => {
                        if (index === 9) return acc
                        return acc + digit * weights[index]
                    }, 0) % 11
                return checksum === digits[9]
            }),
    })

    const handleSubmit = async values => {
        const submitValues = {
            auth_email: values.authEmail,
            auth_nip: values.authNIP,
        }

        await fetchData(`${API['VERIFY_PAYMENT_HANDING']}?pid=${id}`, 'POST', submitValues, locale)
    }

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return (
        !verifyIsSuccess && (
            <DialogWindow isOpen={showAuthentication} setIsOpen={() => {}}>
                <Typography variant="h2" mb={4} fontSize={{ xs: 24, md: 40 }}>
                    {t('LAC.payment_transfer.auth_heading')}
                </Typography>
                {data?.tries_left > 0 && (
                    <Alert severity="warning" sx={{ mb: 4 }}>
                        {t('LAC.payment_transfer.auth_incorrect_data_message')}: {data?.tries_left}
                    </Alert>
                )}
                {data?.tries_left === 0 && (
                    <Alert severity="error" sx={{ mb: 4 }}>
                        {t('LAC.reservation_payment.error_auth')}:{' '}
                        <LinkText href="mailto:handlowy@express.pl">handlowy@express.pl</LinkText>
                    </Alert>
                )}
                <>
                    <Typography mb={5}>{t('LAC.payment_transfer.auth_info_text')}</Typography>

                    <Formik
                        noValidate
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched, values, isSubmitting }) => (
                            <Form>
                                <Box mb={4}>
                                    <Field
                                        as={TextField}
                                        name="authEmail"
                                        label="Twój adres e-mail"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AlternateEmailOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <ErrorMessage name="authEmail" component="div" className="error" />
                                </Box>
                                <Box mb={4}>
                                    <Field
                                        as={TextField}
                                        name="authNIP"
                                        label="NIP"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CorporateFareOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <ErrorMessage name="authNIP" component="div" className="error" />
                                </Box>

                                {isSubmitting ? (
                                    <Spinner />
                                ) : (
                                    <ChooseButton disabled={isSubmitting} type="submit">
                                        {t('LAC.payment_transfer.auth_btn_submit')}
                                    </ChooseButton>
                                )}
                            </Form>
                        )}
                    </Formik>
                </>
            </DialogWindow>
        )
    )
}

export default DialogAuthentication
