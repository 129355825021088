import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { Box, Typography, Button, Divider } from '@mui/material'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import DialogPickupLocation from './DialogPickupLocation'
import GetPlaces from './GetPlaces'
import DialogPricing from './DialogPricing'
import GetTransportPriceList from './GetTransportPriceList'

import theme from '../../../styles/MUI-theme'
import TargeoSearch from '../../components/TargeoSuggester/TargeoSearch'

const RadioPickupLocation = ({ locations, label, text, value, values, handleClick }) => {
    const { t } = useTranslation()

    const [field] = useField('pickupLocation')

    const [isActive, setIsActive] = useState(false)

    const [dialogWindow, setDialogWindow] = useState(false)
    const [showMoreInfo, setShowMoreInfo] = useState(false)

    const [clickedPlace, setClickedPlace] = useState({})

    const [openedDialogPricing, setOpenedDialogPricing] = useState(false)

    const handleCloseDialogPricing = () => {
        setOpenedDialogPricing(false)
    }

    const handleShowDialogPricing = () => {
        setOpenedDialogPricing(true)
    }

    useEffect(() => {
        setIsActive(values.pickupLocation === value)
    }, [values, value])

    const handleShowDialogWindow = () => {
        setDialogWindow(!dialogWindow)
    }

    const handleCloseDialog = () => {
        setDialogWindow(false)
    }

    return (
        <>
            <DialogPickupLocation
                open={dialogWindow}
                handleClose={handleShowDialogWindow}
                children={
                    <>
                        {value === 'pickupInExpress' ? (
                            <GetPlaces
                                locations={locations}
                                setClickedPlace={setClickedPlace}
                                handleCloseDialog={handleCloseDialog}
                            />
                        ) : (
                            <TargeoSearch LAC={true} handleCloseDialogLAC={handleCloseDialog} />
                        )}
                    </>
                }
            />

            <Box
                type="radio"
                border={`1px solid ${isActive ? 'orange' : '#d1d1d1'}`}
                p={2}
                borderRadius="10px"
                sx={{
                    opacity: isActive ? 1 : 0.7,
                    transition: 'opacity 0.5s',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleClick(value)
                }}
            >
                <Box display="flex" alignItems="center" pb={1}>
                    {isActive ? (
                        <RadioButtonCheckedIcon color="secondary" />
                    ) : (
                        <RadioButtonUncheckedIcon color="secondary" />
                    )}
                    <Typography
                        sx={{
                            fontSize: { xs: 14, lg: 18 },
                            color: theme.palette.primary.main,
                            fontWeight: 'bold',
                            pl: { xs: 1, lg: 2 },
                        }}
                    >
                        {label}
                    </Typography>
                </Box>
                <Box display="flex" marginTop="auto" flexDirection="column">
                    <Box>
                        <Typography
                            fontWeight="regular"
                            fontSize={{ xs: 12, lg: 14 }}
                            sx={{
                                mb: value === 'pickupInExpress' ? 1 : 0,
                            }}
                        >
                            {text}{' '}
                            {value !== 'pickupInExpress' && (
                                <Button
                                    onClick={handleShowDialogPricing}
                                    sx={{
                                        textTransform: 'none',
                                        mb: '2px',
                                        mx: 1,
                                    }}
                                >
                                    {t('LAC.offer_presentation.price_list')}{' '}
                                    <DescriptionOutlinedIcon
                                        sx={{
                                            px: '2px',
                                            pb: '2px',
                                            fontSize: 18,
                                        }}
                                    />
                                </Button>
                            )}
                        </Typography>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box
                                type="button"
                                fontWeight="bold"
                                fontSize={{ xs: 12, lg: 14 }}
                                color={theme.palette.primary.main}
                                onClick={e => {
                                    if (e.target === e.currentTarget) handleShowDialogWindow()
                                }}
                            >
                                {value === 'pickupInExpress' && values?.pickupLocationExpressAddress}
                                {value === 'pickupInAnyLocation' &&
                                    (values?.pickupTargeoAddress || t('LAC.offer_presentation.choose_convenient'))}
                            </Box>

                            <Box
                                type="button"
                                disabled={!isActive}
                                fontWeight="bold"
                                fontSize={{ xs: 12, lg: 14 }}
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontSize: { xs: 12, lg: 14 },
                                    fontWeight: 'bold',
                                    mx: 1,
                                }}
                                onClick={e => {
                                    if (e.target === e.currentTarget) handleShowDialogWindow()
                                }}
                            >
                                {t('btn.edit')}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <DialogPricing open={openedDialogPricing} handleClose={handleCloseDialogPricing}>
                {values.pickupLocation === 'pickupInExpress' ? (
                    locations?.map(location => (
                        <Box key={`location${location.id}`}>
                            <Typography fontWeight="bold">{location.name} </Typography>
                            {location.pickupPriceIOH !== '' && (
                                <Typography fontSize={14}>
                                    {t('LAC.offer_presentation.during_working_hours')}:{' '}
                                    {location.pickupPriceIOH === 0 && 'od 0 zł'}
                                    {(location.pickupPriceIOH === null || location.pickupPriceIOH === '') &&
                                        'brak ceny'}
                                    {location.pickupPriceIOH !== null &&
                                        location.pickupPriceIOH !== '' &&
                                        location.pickupPriceIOH !== 0 &&
                                        `${String(location.pickupPriceIOH?.toFixed(2)).replace('.', ',')}zł (${String(location.pickupPriceIOH.toFixed(2)).replace('.', ',')}zł brutto)`}
                                </Typography>
                            )}
                            {location.pickupPriceOOH !== '' && (
                                <Typography fontSize={14}>
                                    {t('LAC.offer_presentation.outside_working_hours')}:{' '}
                                    {location.pickupPriceOOH === 0 && 'od 0 zł'}
                                    {(location.pickupPriceIOH === null || location.pickupPriceIOH === '') &&
                                        'brak ceny'}
                                    {location.pickupPriceOOH !== null &&
                                        location.pickupPriceOOH !== '' &&
                                        location.pickupPriceOOH !== 0 &&
                                        `${String(location.pickupPriceOOH?.toFixed(2)).replace('.', ',')}zł (${String(location.pickupPriceOOH.toFixed(2)).replace('.', ',')}zł brutto)`}
                                </Typography>
                            )}
                            <Divider sx={{ m: 1 }} />
                        </Box>
                    ))
                ) : (
                    <GetTransportPriceList />
                )}
            </DialogPricing>
        </>
    )
}

export default RadioPickupLocation
