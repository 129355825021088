import React from 'react'
import { createRoot } from 'react-dom/client'
import { environment } from './environment'
import TopBannerCarousel from './portlets/topBannerCarousel/TopBannerCarousel'
import TextBox from './portlets/textBox/TextBox'
import TextImage from './portlets/textImage/TextImage'
import ListBox from './portlets/listBox/ListBox'
import NewsTeaser from './portlets/news/NewsTeaser'
import NewsDetails from './portlets/news/NewsDetails'
import Galleries from './portlets/galleries/Galleries'
import Accordion from './portlets/accordion/Accordion'
import Infobox from './portlets/infobox/Infobox'
import TopBanner from './portlets/topBanner/TopBanner'
import Button from './portlets/button/Button'
import Infobar from './portlets/infobar/Infobar'
import WhyUs from './portlets/whyUs/whyUs'
import Steps from './portlets/steps/steps'
import Sale from './portlets/sale/sale'
import CarsReservations from './portlets/carsReservations/CarsReservations'
import SearchBox from "./portlets/searchBox/SearchBox";
import CommentsBox from './portlets/comments/CommentsBox'
import Footer from './portlets/footer/Footer'
import OffersList from './portlets/offersList/OffersList'
import RegistrationForm from './portlets/registration/RegistrationForm'
import LocationsMap from './portlets/locationsMap/LocationsMap'
import OffersBox from './portlets/offersBox/OffersBox'
import PickupBox from './portlets/pickupBox/PickupBox'
import TextTabs from './portlets/textTabs/TextTabs'
import TextForm from './portlets/textForm/TextForm'
import TextImageBox from './portlets/TextImageBox/TextImageBox'
import ContactBox from './portlets/contactBox/ContactBox'
import ContactForm from './portlets/contactForm/ContactForm'
import HistoryBox from './portlets/historyBox/HistoryBox'

const Components = {
    'top-banner-carousel': TopBannerCarousel,
    'text-box': TextBox,
    'text-image': TextImage,
    'news-details': NewsDetails,
    'news-details-teaser': NewsTeaser,
    galleries: Galleries,
    accordion: Accordion,
    infobox: Infobox,
    'top-banner': TopBanner,
    button: Button,
    infobar: Infobar,
    'why-us': WhyUs,
    steps: Steps,
    sale: Sale,
    'cars-reservations': CarsReservations,
    "search-box": SearchBox,
    "comments-box": CommentsBox,
    "footer": Footer,
    "offers-list": OffersList,
    "registration-form": RegistrationForm,
    "locations-map": LocationsMap,
    "offers-box": OffersBox,
    "pickup-box": PickupBox,
    "text-tabs": TextTabs,
    "text-form": TextForm,
    "text-image-box": TextImageBox,
    "contact-box": ContactBox,
    "contact-form": ContactForm,
    "history-box": HistoryBox
}

function Portlets(line, block, pageData, index, locale, settings) {
    function CreateComp(portlet, name, index, min, locale, settings) {
        const Portlet = Components[name]
        return <Portlet block={portlet} key={index} line={line} min={min} locale={locale} settings={settings} />
    }

    if (line === 'online') {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
            console.log('Portlets list www')
            console.log(block)
        }
        if (typeof pageData !== 'undefined' && !block.hasOwnProperty('exception')) {
            if (block.hasOwnProperty('listInfo')) {
                if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
                    console.log('potlet list box')
                }
                //must be wrapped into LIST portlet
                let key = pageData.split('/').pop()
                key = key.substring(0, key.length - 4)
                return <ListBox block={block} line={line} key={key + '_' + index} locale={locale} />
            } else {
                if (block.hasOwnProperty('attributes') && block.attributes.hasOwnProperty('type')) {
                    let type = block.attributes.type
                    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
                        console.log('portlet type - ' + type)
                    }
                    if (Components.hasOwnProperty(type)) {
                        if (type !== 'infobar') {
                            let key = pageData.split('/').pop()
                            key = key.substring(0, key.length - 4)
                            return React.createElement(Components[type], {
                                key: key + '_' + index,
                                block: block,
                                line: line,
                                locale: locale,
                                settings: settings
                            })
                        } else {
                            let key = pageData.split('/').pop()
                            key = key.substring(0, key.length - 4)
                            let portletId = document.getElementById('infobar')
                            if (portletId) {
                                let box = createRoot(portletId)
                                box.render(CreateComp(block, type, key + '_' + index, '', locale))
                            }
                        }
                    }
                } else {
                    console.error('portlet type - is undefined')
                }
            }
        }
    }
    if (line === 'offline') {
        let listComp = []
        block.map((cont, i) => {
            //const type = cont.type; //get container info
            //const id = cont.name; //may be usefull later
            cont.elements.map((item, ii) => {
                const uuid = item.formatterKey
                const path = item.path
                const settings = item.settings
                listComp.push({ uuid, path, settings })
                return null
            })
            return null
        })
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
            console.log('Portlets list cms')
            console.log(pageData.linkedContents)
        }

        listComp.map((elem, i) => {
            let portlet = pageData.linkedContents[elem.path]
            if (!portlet.hasOwnProperty('exception')) {
                if (portlet.hasOwnProperty('list')) {
                    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
                        console.log('potlet list box')
                    }
                    portlet.list.map((teaser, i) => {
                        let type = teaser.attributes.type
                        if (
                            window.origin.includes(environment.localhost) ||
                            window.origin.includes(environment.devName)
                        ) {
                            console.log('portlet type - ' + type)
                        }
                        if (Components.hasOwnProperty(type)) {
                            let id = teaser.path.split('/').pop()
                            id = id.substring(0, id.length - 4)
                            let teaserId = document.getElementById(id)
                            let min = portlet.hasOwnProperty('Minified') ? portlet['Minified'] : false
                            if (teaserId) {
                                let box = createRoot(teaserId)
                                box.render(CreateComp(teaser, type + '-teaser', id + '_' + i, min, locale, elem.settings))
                            }
                        }
                        return null
                    })
                } else {
                    if (portlet.hasOwnProperty('attributes')) {
                        if (portlet.attributes.hasOwnProperty('type')) {
                            let type = portlet.attributes.type
                            if (
                                window.origin.includes(environment.localhost) ||
                                window.origin.includes(environment.devName)
                            ) {
                                console.log('portlet type - ' + type)
                            }
                            if (Components.hasOwnProperty(type)) {
                                let id = elem.path.split('/').pop()
                                id = id.substring(0, id.length - 4)
                                let portletId = document.getElementById(id)
                                if (portletId) {
                                    let box = createRoot(portletId)
                                    box.render(CreateComp(portlet, type, id + '_' + i, '', locale, elem.settings))
                                }
                            }
                        }
                    }
                }
            }
            return null
        })
    }
}
export default Portlets
