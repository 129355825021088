import React from 'react'
import Slider from 'react-slick'
import { environment } from './../../environment'
import Container from '../../components/Container'
import StepsItem from './stepsItem'
import '../../styles/slick-custom.css'
import '../../styles/steps.css'
import Links from '../../components/Links.js'

const Steps = params => {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Steps')
        console.log(params)
    }
    let itemCount = params.block[params.locale].hasOwnProperty('Item') ? params.block[params.locale]['Item'].length : 0,
        showCounter = params.block[params.locale].hasOwnProperty('Counter') ? params.block[params.locale]['Counter'] : false,
        secondVariant = params.block[params.locale].hasOwnProperty('SecondVariant') ? params.block[params.locale]['SecondVariant'] : false,
        Link = params.block[params.locale].hasOwnProperty('ButtonLink') ? params.block[params.locale]['ButtonLink'] : '',
        LinkName = params.block[params.locale].hasOwnProperty('ButtonName') ? params.block[params.locale]['ButtonName'] : '',
        LinkType = params.block[params.locale].hasOwnProperty('ButtonType') ? params.block[params.locale]['ButtonType'] : 'color1',
        InNewOpen = params.block[params.locale].hasOwnProperty('InNewOpen')
            ? params.block[params.locale]['InNewOpen'] === true
                ? true
                : false
            : false;

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: (itemCount > 3 ? 4 : itemCount),
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                },
            },
        ],
    }

    if(secondVariant) {
        return (
            <section className="steps">
                <Container className="my-10">
                    {params.block[params.locale]['Title'] && (
                        <h2 className="text-navy">{params.block[params.locale]['Title']}</h2>
                    )}
                    {params.block[params.locale]['Subtitle'] && (
                        <h3 className="mb-5 md:mb-20 opacity-50">{params.block[params.locale]['Subtitle']}</h3>
                    )}
                </Container>
                    <div className='b-color1'>
                        <Container className="my-10 py-10">
                            <Slider {...settings}>
                                {params.block[params.locale]['Item'] &&
                                    params.block[params.locale]['Item'].map((item, i) => (
                                        <StepsItem key={`item-${i}`} block={item} counter={i + 1} showCounter={showCounter} secondVariant={secondVariant}/>
                                    ))}
                            </Slider>
                            {(!(Link === '') && !(LinkName === '')) && 
                                <div className="button-box text-center mt-5">
                                    <Links
                                        class={'btn-link b-' +LinkType}
                                        url={Link}
                                        target={InNewOpen}
                                        name={LinkName}
                                        line={params.line}
                                    />
                                </div>
                            }
                        </Container>
                    </div>
            </section>
        )
    }

    return (
        <section className="steps">
            <Container className="my-10 !px-4 md:!px-8">
                {params.block[params.locale]['Title'] && (
                    <h2 className="text-navy">{params.block[params.locale]['Title']}</h2>
                )}
                {params.block[params.locale]['Subtitle'] && (
                    <h3 className="mb-5 md:mb-20 opacity-50">{params.block[params.locale]['Subtitle']}</h3>
                )}
                <Slider {...settings}>
                    {params.block[params.locale]['Item'] &&
                        params.block[params.locale]['Item'].map((item, i) => (
                            <StepsItem key={`item-${i}`} block={item} counter={i + 1} showCounter={showCounter}/>
                        ))}
                </Slider>
                {(!(Link === '') && !(LinkName === '')) && 
                    <div className="button-box text-center mt-5">
                        <Links
                            class={'btn-link b-' +LinkType}
                            url={Link}
                            target={InNewOpen}
                            name={LinkName}
                            line={params.line}
                        />
                    </div>
                }
            </Container>
        </section>
    )
}

export default Steps
